import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants/apiconstant";
const Section = {
  width: "99%",
  marginTop: "2%",
};
export default function SampleSchemaDownload(props) {
  const handleDownload = (e) => {
    e.preventDefault();

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = BASE_URL + "media/contacts.csv";
    a.download = "contact.csv";
    document.body.appendChild(a);
    a.click();
  };
  return (
    <div className="App">
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-head-content">
                  <h4>Download</h4>
                </div>
                <section style={Section}>
                  <div className="dropzone">
                    <div style={{ textAlign: "center" }}>
                      <h4>Download a Sample CSV File for Selected Schema.</h4>

                      <em
                        className="icon ni ni-download-cloud"
                        style={{ fontSize: 70 }}
                        onClick={(e) => handleDownload(e)}
                      ></em>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
