import React, { useEffect, useState } from 'react'
import FileUpload from './FileUpload'
import '../assets/css/dashlite.css'
import '../assets/css/theme.css'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useHistory } from 'react-router'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularProgress from '@material-ui/core/CircularProgress'
import FileMenu from '../components/FileMenu'
import { Grid, Container, Modal, TextField, Fab } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Filefetch,
  Folderfetch,
  FileAdd,
  FolderAdd,
  FileDeletion,
  FolderDeletion,
  UpdatefileName,
  Updatefilefolderid,
} from '../actions/assetsAction'
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'
import { html } from './Html'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import FileButton from '../components/FileButton'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { ArrowBack } from '@material-ui/icons'
const Foldersfiles = ({ match }) => {
  const dispatch = useDispatch()
  const [modalStyle] = useState(getModalStyle)
  const { innerWidth, innerHeight } = window
  const history = useHistory()

  function getModalStyle() {
    const { innerWidth, innerHeight } = window

    let top = 50,
      left = innerWidth < 600 ? 0 : 50
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
    }
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: innerWidth > 600 ? 500 : '100%',

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: 'calc(100vh - 100px)',
      overflow: 'auto !important',
      top: '50%',
    },
    multilineColor: {
      color: 'white',
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    rooot: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    fieldroot: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }))
  const classes = useStyles()

  const getAssets = useSelector((state) => state.getAssets)
  const { loading, error, file, folder } = getAssets
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [fileName, setFileName] = useState('')
  const [foldername, setFoldername] = useState('')
  const [newfiles, setNewfiles] = useState('')
  const [currentfileid, setCurrentfileid] = useState('')
  const [openfilename, setOpenfilename] = useState(false)
  const [openfolderid, setOpenfolderid] = useState(false)
  const [updatedfolderid, setUpdatedfolderid] = useState('')

  const [renamefile, setRenamefile] = useState('')

  const [folderid, setFolderid] = useState('')

  const [open, setOpen] = useState(false)
  const [openfolder, setOpenfolder] = useState(false)
  useEffect(() => {
    if (userInfo) {
      dispatch(Filefetch(userInfo?.result?._id))
      dispatch(Folderfetch(userInfo?.result?._id))
    }
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const { id: parentFolderId } = useParams()

  // const handleClickFile = (event, index) => {
  //   let abc = anchorElfile
  //   abc[index] = event.currentTarget
  //   setAnchorElfile(abc)
  // }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const deletefile = (id, num) => {
    // setAnchorElfile(null)

    console.log('deletefile', id, num)
    dispatch(FileDeletion(id))
  }
  const deletefolder = (id, num) => {
    // setAnchorElfolder(null)
    console.log('deletefolder', id, num)
    dispatch(FolderDeletion(id))
  }
  // const sharefolder = () => {
  //   setAnchorElfolder(null)
  //   console.log('shared')
  // }
  // const sharefile = () => {
  //   setAnchorElfile(null)

  //   console.log('shared')
  // }
  const handleOpen = () => {
    setOpen(true)
    setAnchorEl(null)
  }
  const handleOpenfolder = () => {
    setOpenfolder(true)
    setAnchorEl(null)
  }

  const handleClosed = () => {
    setOpen(false)
    setOpenfolder(false)
    setOpenfilename(false)
    setOpenfolderid(false)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    let upload = false
    console.log(
      'submited',
      fileName,
      userInfo?.result?._id,
      folderid,
      html,
      upload
    )

    dispatch(
      FileAdd(
        fileName,
        userInfo?.result?._id,
        parentFolderId,
        html,
        upload,
        (abc) => { }
      )
    )
    handleClosed()
    setFileName('')
    setFolderid('')
  }
  const submitHandlers = (e) => {
    e.preventDefault()
    console.log('submits', foldername, userInfo?.result?._id)
    dispatch(FolderAdd(foldername, userInfo?.result?._id))
    handleClosed()
    setFoldername('')
  }
  const createfilebody = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5 id="simple-modal-title">Create File</h5>
        <IconButton
          onClick={handleClosed}
          color="inherit"
          aria-label="open drawer"
          style={{ marginTop: -10, marginBottom: 10 }}
        >
          <ClearIcon style={{ color: 'grey' }} />
        </IconButton>
      </div>

      <form onSubmit={submitHandler}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              style={{ color: 'black', width: '100%', marginTop: 9 }}
              id="outlined-basic"
              size="small"
              label="File Name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              size="small"
            >
              <InputLabel id="demo-simple-select-label">Folder Name</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={parentFolderId}
                onChange={(e) => setFolderid(e.target.value)}
              >
                {folder?.length > 0
                  ? folder?.map((folders) => {
                    if (folders._id === parentFolderId) {
                      return (
                        <MenuItem value={folders._id}>
                          {folders.folderName}
                        </MenuItem>
                      )
                    }
                    return null
                  })
                  : null}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            marginTop: 13,
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={fileName === '' || parentFolderId === ''}
          >
            <div>Create File</div>
          </Button>
        </div>
      </form>
    </div>
  )
  const createfolderbody = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5 id="simple-modal-title">Create Folder</h5>
        <IconButton
          onClick={handleClosed}
          color="inherit"
          aria-label="open drawer"
          style={{ marginTop: -10, marginBottom: 10 }}
        >
          <ClearIcon style={{ color: 'grey' }} />
        </IconButton>
      </div>

      <form onSubmit={submitHandlers}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              style={{ color: 'black', width: '100%', marginTop: 9 }}
              id="outlined-basic"
              size="small"
              label="Folder Name"
              value={foldername}
              onChange={(e) => setFoldername(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            marginTop: 13,
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={foldername === ''}
          >
            <div>Create Folder</div>
          </Button>
        </div>
      </form>
    </div>
  )
  useEffect(() => {
    console.log('user', userInfo)
  }, [])
  useEffect(() => {
    const filesoffolder = file.filter((fil) => fil.folderId === match.params.id)
    console.log('filesoffolder', filesoffolder)
    setNewfiles(filesoffolder)
  }, [file])
  useEffect(() => {
    if (folder) {
      console.log('folder')
    }
  }, [folder])
  useEffect(() => {
    console.log('folderid', folderid)
  }, [folderid])

  const savehtmldata = (html, uploads, id) => {
    localStorage.setItem('htmlData', html)
    console.log('bas')
    // setIsLoading(false)
    if (uploads === true) {
      history.push(`/editdup/${id}`)
    } else if (uploads === false) {
      history.push(`/edit/${id}`)
    }
  }
  const renamefileHandler = (e) => {
    e.preventDefault()
    console.log('submittsfile', renamefile, currentfileid)
    dispatch(UpdatefileName(currentfileid, renamefile))
    handleClosed()
    setRenamefile('')
  }
  const createfilenamebody = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5 id="simple-modal-title">Rename File</h5>
        <IconButton
          onClick={handleClosed}
          color="inherit"
          aria-label="open drawer"
          style={{ marginTop: -10, marginBottom: 10 }}
        >
          <ClearIcon style={{ color: 'grey' }} />
        </IconButton>
      </div>

      <form onSubmit={renamefileHandler}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              style={{ color: 'black', width: '100%', marginTop: 9 }}
              id="outlined-basic"
              size="small"
              label="Rename File"
              value={renamefile}
              onChange={(e) => setRenamefile(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            marginTop: 13,
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={renamefile === ''}
            style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
          >
            <div>Rename File</div>
          </Button>
        </div>
      </form>
    </div>
  )
  const updatefolderidHandler = (e) => {
    e.preventDefault()
    console.log('submittsfolderr', updatedfolderid, currentfileid)
    dispatch(Updatefilefolderid(currentfileid, updatedfolderid))
    handleClosed()
    setUpdatedfolderid('')
  }

  const createfolderidbody = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5 id="simple-modal-title">Update Folder</h5>
        <IconButton
          onClick={handleClosed}
          color="inherit"
          aria-label="open drawer"
          style={{ marginTop: -10, marginBottom: 10 }}
        >
          <ClearIcon style={{ color: 'grey' }} />
        </IconButton>
      </div>

      <form onSubmit={updatefolderidHandler}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              size="small"
            >
              <InputLabel id="demo-simple-select-label">Folder Name</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={updatedfolderid}
                onChange={(e) => setUpdatedfolderid(e.target.value)}
              >
                {folder?.length > 0
                  ? folder?.map((folders) => (
                    <MenuItem value={folders._id}>
                      {folders.folderName}
                    </MenuItem>
                  ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            marginTop: 13,
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={updatedfolderid === ''}
            style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
          >
            <div>Update Folder</div>
          </Button>
        </div>
      </form>
    </div>
  )

  return (
    <>
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />

              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-fmg">
                      <Sidebar />
                      {/* .nk-fmg-aside */}
                      {loading ? (
                        <div className={classes.rooot}>
                          <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                        </div>
                      ) : (
                        <div className="nk-fmg-body">
                          <div className="nk-fmg-body-content">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between position-relative">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                  <ArrowBack
                                      style={{ cursor: "pointer", margin:"0px 8px 3px 0px" }}
                                      onClick={() =>
                                        history.push("/created-documents")
                                      }
                                    />
                                    Home
                                  </h3>
                                </div>
                                <div className="nk-block-head-content">
                                  <div
                                    className="nk-fmg-actions"
                                  >
                                    <ul className="nk-block-tools g-3">
                                      <li>
                                        <div className="dropdown">
                                          <a
                                            onClick={handleClick}
                                            className="btn btn-light"
                                            data-toggle="dropdown"
                                          >
                                            <em className="icon ni ni-plus" />{' '}
                                            <span>Create</span>
                                          </a>
                                          <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                          >
                                            <MenuItem onClick={handleOpen}>
                                              Create File
                                            </MenuItem>
                                            {/*  <MenuItem
                                              onClick={handleOpenfolder}
                                            >
                                              Create Folder
                                            </MenuItem> */}
                                          </Menu>
                                        </div>
                                      </li>
                                      <li>
                                        <FileUpload />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className="search-wrap px-2 d-lg-none"
                                  data-search="search"
                                >
                                  <div className="search-content">
                                    <a
                                      href="#"
                                      className="search-back btn btn-icon toggle-search"
                                      data-target="search"
                                    >
                                      <em className="icon ni ni-arrow-left" />
                                    </a>
                                    <input
                                      type="text"
                                      className="form-control border-transparent form-focus-none"
                                      placeholder="Search by user or message"
                                    />
                                    <button className="search-submit btn btn-icon">
                                      <em className="icon ni ni-search" />
                                    </button>
                                  </div>
                                </div>
                                {/* .search-wrap */}
                              </div>
                            </div>

                            <div className="nk-fmg-listing nk-block">
                              <div
                                className="toggle-expand-content expanded"
                                data-content="recent-files"
                              >
                                <div className="nk-files nk-files-view-group">
                                  {/* .nk-files-head */}
                                  <div className="nk-files-group">
                                    <h6 className="title">Files</h6>
                                    <div className="nk-files-list">
                                      {newfiles.length === 0 && <h5 style={{ marginLeft: "0.5rem", marginTop: "1rem", fontSize: "1rem" }}>Please move a document here to begin populating this folder</h5>}
                                      {newfiles?.length > 0 &&
                                        newfiles?.map((files, index) => (
                                          <FileButton
                                            files={files}
                                            index={index}
                                            savehtmldata={savehtmldata}
                                            deletefile={deletefile}
                                            setCurrentfileid={setCurrentfileid}
                                            setOpenfilename={setOpenfilename}
                                            setOpenfolderid={setOpenfolderid}
                                          />
                                        ))}
                                    </div>
                                  </div>
                                </div>
                                {/* .nk-files */}
                              </div>
                            </div>
                            {/* .nk-block */}
                          </div>
                          {/* .nk-fmg-body-content */}
                        </div>
                      )}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}
        </div>
        {/* main @e */}
        {/* app-root @e */}
        {/* @@ Share File Modal @e */}
        <div className="modal fade" tabIndex={-1} role="dialog" id="file-share">
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header align-center">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <span className="nk-file-icon-type">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 72 72"
                      >
                        <path
                          fill="#6C87FE"
                          d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
	C60,29.9,58.9,31,57.5,31z"
                        />
                        <path
                          fill="rgb(20, 155, 227)"
                          d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                        />
                        <path
                          display="none"
                          fill="rgb(20, 155, 227)"
                          d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                        />
                        <path
                          fill="#798BFF"
                          d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <span className="title">UI/UX Design</span>
                    </div>
                    <div className="nk-file-name-sub">394.87 MB</div>
                  </div>
                </div>
                <a href="#" className="close" data-dismiss="modal">
                  <em className="icon ni ni-cross-sm" />
                </a>
              </div>
              <div className="modal-body p-0">
                <div className="nk-file-share-header">
                  <div className="nk-file-share-group">
                    <div className="nk-file-share-input-group">
                      <div className="nk-file-share-input nk-file-share-input-to">
                        <label className="label">To</label>
                        <input
                          type="text"
                          className="input-mail tagify"
                          placeholder="Email or Name"
                          data-whitelist="team@softnio.com, help@softnio.com, contact@softnio.com"
                        />
                      </div>
                    </div>
                    <ul className="nk-file-share-nav">
                      <li>
                        <span className="badge badge-sm badge-outline-gray">
                          Can View
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nk-file-share-editor">
                  <div className="nk-file-share-field">
                    <textarea
                      className="form-control form-control-simple no-resize ex-large"
                      placeholder="Add a Message (optional)"
                      defaultValue={''}
                    />
                  </div>
                </div>
                {/* .nk-file-share-editor */}
              </div>
              {/* .modal-body */}
              <div className="modal-footer bg-light">
                <ul className="btn-toolbar g-3">
                  <li>
                    <a
                      href="#"
                      data-dismiss="modal"
                      className="btn btn-outline-light btn-white"
                    >
                      Cancel
                    </a>
                  </li>
                  <li>
                    <a href="#" className="btn btn-primary" style={{backgroundColor: "rgb(20, 155, 227)"}}>
                      Share
                    </a>
                  </li>
                </ul>
              </div>
              {/* .modal-footer */}
            </div>
            {/* .modal-content */}
          </div>
          {/* .modla-dialog */}
        </div>
        {/* .modal */}
        {/* @@ Copy File Modal @e */}
        <div className="modal fade" tabIndex={-1} role="dialog" id="file-copy">
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header align-center border-bottom-0">
                <h5 className="modal-title">Copy item to...</h5>
                <a href="#" className="close" data-dismiss="modal">
                  <em className="icon ni ni-cross-sm" />
                </a>
              </div>
              <div className="modal-body pt-0 mt-n2">
                <ul className="breadcrumb breadcrumb-alt breadcrumb-xs breadcrumb-arrow mb-1">
                  <li className="breadcrumb-item">Project</li>
                  <li className="breadcrumb-item">Apps</li>
                </ul>
                <div className="nk-fmg-listing is-scrollable">
                  <div className="nk-files nk-files-view-list is-compact">
                    <div className="nk-files-list">
                      <div className="nk-file-item nk-file">
                        <div className="nk-file-info">
                          <a className="nk-file-link" href="#">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 72 72"
                                >
                                  <path
                                    fill="#6C87FE"
                                    d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
	C60,29.9,58.9,31,57.5,31z"
                                  />
                                  <path
                                    fill="rgb(20, 155, 227)"
                                    d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                                  />
                                  <path
                                    display="none"
                                    fill="rgb(20, 155, 227)"
                                    d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                                  />
                                  <path
                                    fill="#798BFF"
                                    d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                                  />
                                </svg>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">UI/UX Design</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="nk-file-actions">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </div>
                      </div>
                      {/* .nk-file */}
                      <div className="nk-file-item nk-file selected">
                        <div className="nk-file-info">
                          <a className="nk-file-link" href="#">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 72 72"
                                >
                                  <path
                                    fill="#6C87FE"
                                    d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
	C60,29.9,58.9,31,57.5,31z"
                                  />
                                  <path
                                    fill="rgb(20, 155, 227)"
                                    d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                                  />
                                  <path
                                    display="none"
                                    fill="rgb(20, 155, 227)"
                                    d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                                  />
                                  <path
                                    fill="#798BFF"
                                    d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                                  />
                                </svg>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">UI Design</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="nk-file-actions">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </div>
                      </div>
                      {/* .nk-file */}
                      <div className="nk-file-item nk-file">
                        <div className="nk-file-info">
                          <a className="nk-file-link" href="#">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 72 72"
                                >
                                  <g>
                                    <rect
                                      x={32}
                                      y={16}
                                      width={28}
                                      height={15}
                                      rx="2.5"
                                      ry="2.5"
                                      style={{ fill: '#6c87fe' }}
                                    />
                                    <path
                                      d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z"
                                      style={{ fill: '#8aa3ff' }}
                                    />
                                    <path
                                      d="M7.7454,58.9807C9.9352,61.3864,12.4882,61.0163,14,61H59a6.3373,6.3373,0,0,0,5.2562-2.0193Z"
                                      style={{ fill: 'rgb(20, 155, 227)' }}
                                    />
                                    <path
                                      d="M29.6309,37.36a3.0236,3.0236,0,0,1-.86-2.39A4.3748,4.3748,0,0,1,32.9961,31h.0078a4.36,4.36,0,0,1,4.22,3.9121,3.0532,3.0532,0,0,1-.8545,2.4482A4.4158,4.4158,0,0,1,33.23,38.53c-.0771,0-.1533-.002-.23-.0049A4.519,4.519,0,0,1,29.6309,37.36Zm13.8359,2.7549a1,1,0,1,0-.9336,1.77c.7207.38,1.4658,2.126,1.4668,4.39V48a1,1,0,0,0,2,0V46.2744C45.999,43.3447,45.0049,40.9268,43.4668,40.1152ZM40.165,37.3965c-.1445.084-.29.168-.4316.2549a1,1,0,0,0,.5215,1.8535.9887.9887,0,0,0,.52-.1465c.1289-.0781.2607-.1543.3916-.23a4.2311,4.2311,0,0,0,2.1465-2.124.9839.9839,0,0,0,.0313-.1045A3.8411,3.8411,0,0,0,40.5,32.5352a1,1,0,0,0-.4922,1.9395,1.8773,1.8773,0,0,1,1.4,1.9092A2.835,2.835,0,0,1,40.165,37.3965ZM36.5,41h-7c-2.5234,0-4.5,2.7822-4.5,6.333V48.5a.8355.8355,0,0,0,.0588.2914.9731.9731,0,0,0,.3508.4946C26.4646,50.2812,29.4614,51,33,51s6.5353-.7187,7.59-1.7139a.9726.9726,0,0,0,.3509-.4949A.8361.8361,0,0,0,41,48.5V47.333C41,43.7822,39.0234,41,36.5,41Z"
                                      style={{ fill: '#4b66bc' }}
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">Projects</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="nk-file-actions">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </div>
                      </div>
                      {/* .nk-file */}
                      <div className="nk-file-item nk-file">
                        <div className="nk-file-info">
                          <a className="nk-file-link" href="#">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 72 72"
                                >
                                  <path
                                    fill="#6C87FE"
                                    d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
	C60,29.9,58.9,31,57.5,31z"
                                  />
                                  <path
                                    fill="rgb(20, 155, 227)"
                                    d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                                  />
                                  <path
                                    display="none"
                                    fill="rgb(20, 155, 227)"
                                    d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                                  />
                                  <path
                                    fill="#798BFF"
                                    d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                                  />
                                </svg>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">2019 Project</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="nk-file-actions">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </div>
                      </div>
                      {/* .nk-file */}
                    </div>
                  </div>
                </div>
              </div>
              {/* .modal-body */}
              <div className="modal-footer modal-footer-stretch  bg-light">
                <div className="modal-footer-between">
                  <div className="g">
                    <a href="#" className="link link-primary">
                      Create New Folder
                    </a>
                  </div>
                  <div className="g">
                    <ul className="btn-toolbar g-3">
                      <li>
                        <a
                          href="#"
                          data-dismiss="modal"
                          className="btn btn-outline-light btn-white"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a href="#" className="btn btn-primary" style={{backgroundColor: "rgb(20, 155, 227)"}}>
                          Copy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* .modal-footer */}
            </div>
            {/* .modal-content */}
          </div>
          {/* .modla-dialog */}
        </div>
        {/* .modal */}
        {/* @@ Move File Modal @e */}
        <div className="modal fade" tabIndex={-1} role="dialog" id="file-move">
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header align-center border-bottom-0">
                <h5 className="modal-title">Move item to...</h5>
                <a href="#" className="close" data-dismiss="modal">
                  <em className="icon ni ni-cross-sm" />
                </a>
              </div>
              <div className="modal-body pt-0 mt-n2">
                <ul className="breadcrumb breadcrumb-alt breadcrumb-xs breadcrumb-arrow mb-1">
                  <li className="breadcrumb-item">Project</li>
                  <li className="breadcrumb-item">Apps</li>
                </ul>
                <div className="nk-fmg-listing is-scrollable">
                  <div className="nk-files nk-files-view-list is-compact">
                    <div className="nk-files-list">
                      <div className="nk-file-item nk-file">
                        <div className="nk-file-info">
                          <a className="nk-file-link" href="#">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 72 72"
                                >
                                  <path
                                    fill="#6C87FE"
                                    d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
	C60,29.9,58.9,31,57.5,31z"
                                  />
                                  <path
                                    fill="rgb(20, 155, 227)"
                                    d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                                  />
                                  <path
                                    display="none"
                                    fill="rgb(20, 155, 227)"
                                    d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                                  />
                                  <path
                                    fill="#798BFF"
                                    d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                                  />
                                </svg>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">UI/UX Design</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="nk-file-actions">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </div>
                      </div>
                      {/* .nk-file */}
                      <div className="nk-file-item nk-file">
                        <div className="nk-file-info">
                          <a className="nk-file-link" href="#">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 72 72"
                                >
                                  <path
                                    fill="#6C87FE"
                                    d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
	C60,29.9,58.9,31,57.5,31z"
                                  />
                                  <path
                                    fill="rgb(20, 155, 227)"
                                    d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                                  />
                                  <path
                                    display="none"
                                    fill="rgb(20, 155, 227)"
                                    d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                                  />
                                  <path
                                    fill="#798BFF"
                                    d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                                  />
                                </svg>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">2019 Project</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="nk-file-actions">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </div>
                      </div>
                      {/* .nk-file */}
                      <div className="nk-file-item nk-file selected">
                        <div className="nk-file-info">
                          <a className="nk-file-link" href="#">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 72 72"
                                >
                                  <g>
                                    <rect
                                      x={32}
                                      y={16}
                                      width={28}
                                      height={15}
                                      rx="2.5"
                                      ry="2.5"
                                      style={{ fill: '#6c87fe' }}
                                    />
                                    <path
                                      d="M59.7778,61H12.2222A6.4215,6.4215,0,0,1,6,54.3962V17.6038A6.4215,6.4215,0,0,1,12.2222,11H30.6977a4.6714,4.6714,0,0,1,4.1128,2.5644L38,24H59.7778A5.91,5.91,0,0,1,66,30V54.3962A6.4215,6.4215,0,0,1,59.7778,61Z"
                                      style={{ fill: '#8aa3ff' }}
                                    />
                                    <path
                                      d="M7.7454,58.9807C9.9352,61.3864,12.4882,61.0163,14,61H59a6.3373,6.3373,0,0,0,5.2562-2.0193Z"
                                      style={{ fill: 'rgb(20, 155, 227)' }}
                                    />
                                    <path
                                      d="M29.6309,37.36a3.0236,3.0236,0,0,1-.86-2.39A4.3748,4.3748,0,0,1,32.9961,31h.0078a4.36,4.36,0,0,1,4.22,3.9121,3.0532,3.0532,0,0,1-.8545,2.4482A4.4158,4.4158,0,0,1,33.23,38.53c-.0771,0-.1533-.002-.23-.0049A4.519,4.519,0,0,1,29.6309,37.36Zm13.8359,2.7549a1,1,0,1,0-.9336,1.77c.7207.38,1.4658,2.126,1.4668,4.39V48a1,1,0,0,0,2,0V46.2744C45.999,43.3447,45.0049,40.9268,43.4668,40.1152ZM40.165,37.3965c-.1445.084-.29.168-.4316.2549a1,1,0,0,0,.5215,1.8535.9887.9887,0,0,0,.52-.1465c.1289-.0781.2607-.1543.3916-.23a4.2311,4.2311,0,0,0,2.1465-2.124.9839.9839,0,0,0,.0313-.1045A3.8411,3.8411,0,0,0,40.5,32.5352a1,1,0,0,0-.4922,1.9395,1.8773,1.8773,0,0,1,1.4,1.9092A2.835,2.835,0,0,1,40.165,37.3965ZM36.5,41h-7c-2.5234,0-4.5,2.7822-4.5,6.333V48.5a.8355.8355,0,0,0,.0588.2914.9731.9731,0,0,0,.3508.4946C26.4646,50.2812,29.4614,51,33,51s6.5353-.7187,7.59-1.7139a.9726.9726,0,0,0,.3509-.4949A.8361.8361,0,0,0,41,48.5V47.333C41,43.7822,39.0234,41,36.5,41Z"
                                      style={{ fill: '#4b66bc' }}
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">Projects</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="nk-file-actions">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </div>
                      </div>
                      {/* .nk-file */}
                      <div className="nk-file-item nk-file">
                        <div className="nk-file-info">
                          <a className="nk-file-link" href="#">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 72 72"
                                >
                                  <path
                                    fill="#6C87FE"
                                    d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
	C60,29.9,58.9,31,57.5,31z"
                                  />
                                  <path
                                    fill="rgb(20, 155, 227)"
                                    d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                                  />
                                  <path
                                    display="none"
                                    fill="rgb(20, 155, 227)"
                                    d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                                  />
                                  <path
                                    fill="#798BFF"
                                    d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                                  />
                                </svg>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">UI Design</span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="nk-file-actions">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-trigger"
                          >
                            <em className="icon ni ni-chevron-right" />
                          </a>
                        </div>
                      </div>
                      {/* .nk-file */}
                    </div>
                  </div>
                </div>
              </div>
              {/* .modal-body */}
              <div className="modal-footer modal-footer-stretch  bg-light">
                <div className="modal-footer-between">
                  <div className="g">
                    <a href="#" className="link link-primary">
                      Create New Folder
                    </a>
                  </div>
                  <div className="g">
                    <ul className="btn-toolbar g-3">
                      <li>
                        <a
                          href="#"
                          data-dismiss="modal"
                          className="btn btn-outline-light btn-white"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a href="#" className="btn btn-primary" style={{backgroundColor: "rgb(20, 155, 227)"}}>
                          Move
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* .modal-footer */}
            </div>
            {/* .modal-content */}
          </div>
          {/* .modla-dialog */}
        </div>
        {/* .modal */}
        {/* @@ File Details Modal @e */}
        <div
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          id="file-details"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header align-center">
                <div className="nk-file-title">
                  <div className="nk-file-icon">
                    <span className="nk-file-icon-type">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 72 72"
                      >
                        <path
                          fill="#6C87FE"
                          d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
	C60,29.9,58.9,31,57.5,31z"
                        />
                        <path
                          fill="rgb(20, 155, 227)"
                          d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                        />
                        <path
                          display="none"
                          fill="rgb(20, 155, 227)"
                          d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                        />
                        <path
                          fill="#798BFF"
                          d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="nk-file-name">
                    <div className="nk-file-name-text">
                      <span className="title">UI/UX Design</span>
                    </div>
                    <div className="nk-file-name-sub">Project</div>
                  </div>
                </div>
                <a href="#" className="close" data-dismiss="modal">
                  <em className="icon ni ni-cross-sm" />
                </a>
              </div>
              <div className="modal-body">
                <div className="nk-file-details">
                  <div className="nk-file-details-row">
                    <div className="nk-file-details-col">Type</div>
                    <div className="nk-file-details-col">Folder</div>
                  </div>
                  <div className="nk-file-details-row">
                    <div className="nk-file-details-col">Size</div>
                    <div className="nk-file-details-col">35.48 MB</div>
                  </div>
                  <div className="nk-file-details-row">
                    <div className="nk-file-details-col">Location</div>
                    <div className="nk-file-details-col">
                      <ul className="breadcrumb breadcrumb-sm breadcrumb-alt breadcrumb-arrow">
                        <li className="breadcrumb-item">ThemeForest</li>
                        <li className="breadcrumb-item">Project</li>
                      </ul>
                    </div>
                  </div>
                  <div className="nk-file-details-row">
                    <div className="nk-file-details-col">Owner</div>
                    <div className="nk-file-details-col">Me</div>
                  </div>
                  <div className="nk-file-details-row">
                    <div className="nk-file-details-col">Shared with</div>
                    <div className="nk-file-details-col">
                      <div className="user-avatar-group">
                        <div className="user-avatar xs">
                          <img src="./images/avatar/b-sm.jpg" alt="" />
                        </div>
                        <div className="user-avatar xs bg-purple">
                          <span>IH</span>
                        </div>
                        <div className="user-avatar xs bg-pink">
                          <span>AB</span>
                        </div>
                        <div className="user-avatar xs bg-light">
                          <span>+2</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-file-details-row">
                    <div className="nk-file-details-col">Modified</div>
                    <div className="nk-file-details-col">
                      Feb 19, 2020 by Abu Bit Istiyak
                    </div>
                  </div>
                  <div className="nk-file-details-row">
                    <div className="nk-file-details-col">Opened</div>
                    <div className="nk-file-details-col">
                      Apr 23, 2020 by Me
                    </div>
                  </div>
                  <div className="nk-file-details-row">
                    <div className="nk-file-details-col">Created</div>
                    <div className="nk-file-details-col">Feb 19, 2020</div>
                  </div>
                </div>
              </div>
              {/* .modal-body */}
              <div className="modal-footer modal-footer-stretch bg-light">
                <div className="modal-footer-between">
                  <div className="g">
                    <a href="#" className="link link-primary">
                      View All Activity
                    </a>
                  </div>
                  <div className="g">
                    <ul className="btn-toolbar g-3">
                      <li>
                        <a
                          href="#file-share"
                          data-toggle="modal"
                          data-dismiss="modal"
                          className="btn btn-outline-light btn-white"
                        >
                          Share
                        </a>
                      </li>
                      <li>
                        <a href="#" className="btn btn-primary file-dl-toast" style={{backgroundColor: "rgb(20, 155, 227)"}}>
                          Download
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* .modal-footer */}
            </div>
            {/* .modal-content */}
          </div>
          {/* .modla-dialog */}
        </div>
        {/* .modal */}
        {/* @@ File Upload Modal @e */}
        <div
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          id="file-upload"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <a href="#" className="close" data-dismiss="modal">
                <em className="icon ni ni-cross-sm" />
              </a>
              <div className="modal-body modal-body-md">
                <div className="nk-upload-form">
                  <h5 className="title mb-3">Upload File</h5>
                  <div className="upload-zone small bg-lighter">
                    <div className="dz-message" data-dz-message>
                      <span className="dz-message-text">
                        <span>Drag and drop</span> file here or{' '}
                        <span>browse</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="nk-upload-list">
                  <h6 className="title">Uploaded Files</h6>
                  <div className="nk-upload-item">
                    <div className="nk-upload-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 72 72"
                      >
                        <g>
                          <rect
                            x={16}
                            y={14}
                            width={40}
                            height={44}
                            rx={6}
                            ry={6}
                            style={{ fill: '#7e95c4' }}
                          />
                          <rect
                            x={32}
                            y={17}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                          <rect
                            x={32}
                            y={22}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                          <rect
                            x={32}
                            y={27}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                          <rect
                            x={32}
                            y={32}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                          <rect
                            x={32}
                            y={37}
                            width={8}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                          <path
                            d="M35,14h2a0,0,0,0,1,0,0V43a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V14A0,0,0,0,1,35,14Z"
                            style={{ fill: '#fff' }}
                          />
                          <path
                            d="M38.0024,42H33.9976A1.9976,1.9976,0,0,0,32,43.9976v2.0047A1.9976,1.9976,0,0,0,33.9976,48h4.0047A1.9976,1.9976,0,0,0,40,46.0024V43.9976A1.9976,1.9976,0,0,0,38.0024,42Zm-.0053,4H34V44h4Z"
                            style={{ fill: '#fff' }}
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="nk-upload-info">
                      <div className="nk-upload-title">
                        <span className="title">
                          dashlite-latest-version.zip
                        </span>
                      </div>
                      <div className="nk-upload-size">25.49 MB</div>
                    </div>
                    <div className="nk-upload-action">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger"
                        data-dismiss="modal"
                      >
                        <em className="icon ni ni-trash" />
                      </a>
                    </div>
                  </div>
                  <div className="nk-upload-item">
                    <div className="nk-upload-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 72 72"
                      >
                        <g>
                          <path
                            d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
                            style={{ fill: '#599def' }}
                          />
                          <path
                            d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
                            style={{ fill: '#c2e1ff' }}
                          />
                          <rect
                            x={27}
                            y={31}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                          <rect
                            x={27}
                            y={36}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                          <rect
                            x={27}
                            y={41}
                            width={18}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                          <rect
                            x={27}
                            y={46}
                            width={12}
                            height={2}
                            rx={1}
                            ry={1}
                            style={{ fill: '#fff' }}
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="nk-upload-info">
                      <div className="nk-upload-title">
                        <span className="title">Update work history.docx</span>{' '}
                        <span className="meta">70% Done</span>
                      </div>
                      <div className="nk-upload-progress">
                        <div className="progress progress-sm">
                          <div className="progress-bar" data-progress={70} />
                        </div>
                      </div>
                    </div>
                    <div className="nk-upload-action">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger"
                        data-dismiss="modal"
                      >
                        <em className="icon ni ni-trash" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="nk-modal-action justify-end">
                  <ul className="btn-toolbar g-4 align-center">
                    <li>
                      <a href="#" className="link link-primary">
                        Cancel
                      </a>
                    </li>
                    <li>
                      <button className="btn btn-primary" style={{backgroundColor: "rgb(20, 155, 227)"}}>Add Files</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* .modal-content */}
          </div>
          {/* .modla-dialog */}
        </div>
        {/* .modal */}
        {/* JavaScript */}
      </div>
      <Modal
        open={open}
        onClose={handleClosed}
        aria-labelledby="simple-modal-title"
      >
        {createfilebody}
      </Modal>
      <Modal
        open={openfolder}
        onClose={handleClosed}
        aria-labelledby="simple-modal-title"
      >
        {createfolderbody}
      </Modal>
      <Modal
        open={openfilename}
        onClose={handleClosed}
        aria-labelledby="simple-modal-title"
      >
        {createfilenamebody}
      </Modal>
      <Modal
        open={openfolderid}
        onClose={handleClosed}
        aria-labelledby="simple-modal-title"
      >
        {createfolderidbody}
      </Modal>
    </>
  )
}

export default Foldersfiles
