import { fontsArray, pdfData } from "./constant";
import $ from "jquery";
import { BASE_URL } from "../../constants/apiconstant";
import { jsPDF } from "jspdf";
var FontFaceObserver = require("fontfaceobserver");

let canvasArray = [];

const loadFonts = async () => {
  let fontsPromise = fontsArray.map(async (fontName) => {
    try {
      let font = new FontFaceObserver(fontName);
      await font.load();
      console.log("font laded", fontName);
    } catch (error) {
      console.log("font error", fontName, error);
    }
  });
  await Promise.all(fontsPromise);
};

export const renderPdf = async (fileUrl) => {
  await loadFonts();
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      canvasArray = [];
      try {
        const loadingTask = window.pdfjsLib.getDocument(fileUrl);
        loadingTask.promise.then(async function (pdf) {
          await renderAllPages(pdf.numPages, pdf);
          resolve({ canvasArray, pdf });
        });
      } catch (error) {
        throw error;
      }
    }, 1000);
  });
};

async function renderAllPages(numPages, pdf) {
  console.log("numpages", numPages);
  for (var i = 1; i <= numPages; i++) {
    const canvasElm = await renderPage(i, pdf);
    console.log("canaaaaa", canvasElm);

    canvasArray.push(canvasElm);
  }
}

async function renderPage(num, pdf) {
  const page = await pdf.getPage(num);
  var scale = 1.0;
  var viewport = page.getViewport(scale);
  let height = viewport.height;
  let width = viewport.width;
  const canvasObject = { page: num, id: "c" + num, bg: null };

  $("#pdf-viewer").append(
    $(
      `<div
            id="page${canvasObject.id}"
            style="border:1px solid #ccc;position:relative;
            width:${width}px;
            margin-bottom:2rem;
            height:${height}px;margin: 13px auto"><canvas id="${canvasObject.id}"  /></div>`
    )
  );
  var canvas = new window.fabric.Canvas(canvasObject.id, {
    backgroundColor: "#fff",
  });
  canvasObject.width = width;
  canvasObject.height = height;
  canvas.setDimensions({
    width: width,
    height: height,
  });

  var context = canvas.getContext("2d");

  var renderContext = {
    canvasContext: context,
    viewport: viewport,
  };

  await page.render(renderContext);

  const bg = $("#" + canvasObject.id)
    .get(0)
    .toDataURL();

  canvasObject.bg = bg;

  /*  $('#image-container').prepend(`<img  style="    width: 100px;
     border: 1px solid blue;
     margin-bottom:2rem;
     height: 130px;" src="${bg}" />`) */
  window.fabric.Image.fromURL(bg, function (img, isError) {
    if (isError) {
      console.error("isError", isError);
    }
    img.set({
      originX: "left",
      originY: "top",
    });
    img.scaleToHeight(height);
    img.scaleToWidth(width);

    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
  });

  return { ...canvasObject, canvas };
}

export const printPdf = (canvasArray) => {
  let canvasFirst = document.getElementById(canvasArray[0].id);
  let width = canvasFirst.width;
  let height = canvasFirst.height;
  let pdf;
  //set the orientation
  if (width > height) {
    pdf = new jsPDF("l", "px", [width, height]);
  } else {
    pdf = new jsPDF("p", "px", [height, width]);
  }
  //then we get the dimensions from the 'pdf' file itself
  canvasArray.forEach(({ id }, index) => {
    let canvas = document.getElementById(id);
    width = pdf.internal.pageSize.getWidth();
    height = pdf.internal.pageSize.getHeight();
    if (index === 0)
      pdf.addImage(
        canvas.toDataURL("image/png", 1),
        "PNG",
        0,
        0,
        width,
        height
      );
    else {
      pdf.addPage();
      pdf.addImage(
        canvas.toDataURL("image/png", 1),
        "PNG",
        0,
        0,
        width,
        height
      );
    }
  });
  pdf.autoPrint();
  /*  pdf.addImage(canvas.toDataURL(), 'PNG', 0, 0, width, height); */
  window.open(pdf.output("bloburl"));
};
async function modifyPdf(nodes) {
  try {
    const url = BASE_URL + "media/daniel2pdf.pdf";
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await window.PDFLib.PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(
      window.PDFLib.StandardFonts.Helvetica
    );

    const pages = pdfDoc.getPages();

    /* const form = pdfDoc.getForm()

        const { width, height } = firstPage.getSize()
        const superheroField = form.createTextField('favorite.superhero')
        superheroField.setText('One Punch Man') */
    /* let xPos = x - width
        let yPos = height - y - h
        if (xPos < 0) {
            xPos = xPos * -1
        }

        if (yPos < 0) {
            yPos = yPos * -1
        }
        console.log("x", xPos, x, width)
        superheroField.addToPage(firstPage, {
            x: x, y: yPos, width: w,
            height: h,
        }) */

    /*     setTimeout(async () => {
                superheroField.setText("hello world")
                const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
                document.getElementById('pdf').src = pdfDataUri
            }, [6000]) */

    /*   firstPage.drawText('This text was added with JavaScript!', {
              x: x,
              y: y,
              size: 50,
              font: helveticaFont,
              color: window.PDFLib.rgb(0.95, 0.1, 0.1),
              rotate: window.PDFLib.degrees(-45),
          }) */
    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    document.getElementById("pdf").src = pdfDataUri;
    /*  const pdfBytes = await pdfDoc.save()
         console.log("pdfby", pdfBytes)
         var blob = new Blob([pdfBytes], { type: "application/pdf" });
         const docUrl = URL.createObjectURL(blob);
         console.log("docurl", docUrl) */
  } catch (error) {
    console.log("error", error);
  }
}
