import React, { useEffect, useRef, useState } from "react";

import "../assets/css/dashlite.css";
import "../assets/css/theme.css";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Grid, Container, Modal, TextField, Fab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";

import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";

import "./homepage.css";

import axios from "axios";
import { getHeaders } from "../utils";
import { BASE_URL } from "../constants/apiconstant";

import { useSnackbar } from "notistack";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowBack } from "@material-ui/icons";
import { removeEsignDOcuments, OcrUpdate } from "../actions/assetsAction";

import OcrButton from "../components/OcrButton";
import { MoveInFolder } from "./OcrDocument";

const OcrFolderFiles = () => {
  const [showCreateFolderModal, setShowClearFolderModal] = useState(false);
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const { innerWidth, innerHeight } = window;
  const history = useHistory();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: innerWidth > 600 ? 500 : "100%",

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto !important",
      top: "50%",
      fontFamily: "Nunito, sans-serif !important",
    },
    multilineColor: {
      color: "white",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    rooot: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fieldroot: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const classes = useStyles();

  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [completedDocuments, setCompletedDocuments] = useState([]);
  const [uncompletedDocuments, setUnCompletedDocuments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [openfolderid, setOpenfolderid] = useState(false);
  const [loading, setLoading] = useState(true);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [openocrfile, setOpenocrfile] = useState(false);
  const [fileToMoveId, setFileToMoveId] = useState([]);
  const [showMoveInFolderModal, setShowMoveInFolderModal] = useState(false);
  const [bodyWidth, setBodyWidth] = React.useState(false);
  const [folderClicked, setFolderClicked] = useState(null);
  const [currentOcrFile, setCurrentOcrFile] = useState("");
  const [folderFilesGetLoading, setFolderFilesGetLoading] = useState(false);
  const [folderFiles, setFolderFiles] = useState([]);
  const [showSendMultipleCheckbox, setShowSendMultipleCheckbox] =
    useState(false);
  const [exportTaskId, setExportTaskId] = useState([]);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [originamFilename, setOriginalFilname] = useState("");
  const [showMoveButton, setShowMoveButton] = useState(false);
  const [renameocrfile, setRenameocrfile] = useState("");
  const [apikey, setApikey] = useState(null);

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };
  useEffect(() => {
    getFilesByFolderId(localStorage.getItem("id"));
    getFolders();
  }, []);

  const getFilesByFolderId = async (_id) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        BASE_URL + "get-ocr-documents-by-folder-id?folderId=" + _id,
        getHeaders()
      );
      console.log("folder files", data);
      setFolderFiles(data.data);
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }

    setLoading(false);
  };

  const onMenuItemClick = async (type, id, documentType, _originalFilename) => {
    if (type === "delete") {
      await removeEsignDOcuments(id);
      getFilesByFolderId(localStorage.getItem("id"));
      return;
    }
    if (type === "info") {
      history.push("/esign-document-device-info?document_id=" + id);
      return;
    }
    if (type === "rename") {
      setOriginalFilname(_originalFilename);
      setShowRenameModal(id);
    }
  };
  const handleClosed = () => {
    setOpenocrfile(false);
  };

  const renameocrfileHandler = (e) => {
    e.preventDefault();
    console.log("submittsfile", currentOcrFile);
    // dispatch(UpdatefileName(currentfileid, renamefile))
    let ocrData = currentOcrFile;
    ocrData.name = renameocrfile;
    ocrData.data = JSON.parse(currentOcrFile.data);
    // try{
    //   ocrData.data = JSON.parse(currentOcrFile.data)
    // }catch(e){
    //   ocrData.data = currentOcrFile.data
    // }

    console.log("ocrData = ", ocrData);
    dispatch(OcrUpdate(ocrData._id, ocrData));
    handleClosed();
    setRenameocrfile({});
    ocrData.data = JSON.stringify(currentOcrFile.data);
  };
  const addOrRemoveTaskIdFromExport = (taskId) => {
    const index = exportTaskId.indexOf(taskId);
    if (index >= 0) {
      exportTaskId.splice(index, 1);
    } else {
      exportTaskId.push(taskId);
    }
    setExportTaskId([...exportTaskId]);
  };
  const saveOcrDocumentData = (data, id) => {
    console.log("dagergergta = ", data);
    localStorage.setItem("ocrData", JSON.stringify(data));
    console.log("bas");

    history.push(`/editocrdocument/${id}`, {
      canExport: data.task_id,
      apikey,
      task_id: data.task_id,
    });

    // setIsLoading(false)
  };
  const getFolders = async () => {
    try {
      const { data } = await axios.get(
        BASE_URL + "get-ocr-folders",
        getHeaders()
      );

      setFolders(data.data);
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    // setFolderLoadLoading(false);
  };
  const createocrfilebody = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5 id="simple-modal-title">Rename File</h5>
        <IconButton
          onClick={handleClosed}
          color="inherit"
          aria-label="open drawer"
          style={{ marginTop: -10, marginBottom: 10 }}
        >
          <ClearIcon style={{ color: "grey" }} />
        </IconButton>
      </div>

      <form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              style={{ color: "black", width: "100%", marginTop: 9 }}
              id="outlined-basic"
              size="small"
              label="Rename File"
              value={renameocrfile}
              onChange={(e) => setRenameocrfile(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: 13,
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={renameocrfileHandler}
            disabled={renameocrfile === ""}
            style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
          >
            <div>Rename File</div>
          </Button>
        </div>
      </form>
    </div>
  );
  const handleOcrDelete = (id) => {
    setFolderFiles((prev) => prev.filter(({ _id }) => id != _id));
  };
  const onMoved = () => {
    getFilesByFolderId(localStorage.getItem("id"));
  };
  console.log(
    "chekee",
    fileToMoveId,
    folders,
    fileToMoveId.length > 0 && folders.length > 0
  );
  return (
    <>
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />
              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div
                      className="nk-fmg"
                      style={bodyWidth ? { paddingLeft: 80 } : null}
                    >
                      <Sidebar page="shared" getState={sidebarState} />
                      {/* .nk-fmg-aside */}
                      {loading ? (
                        <div className={classes.rooot}>
                          <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                        </div>
                      ) : (
                        <div className="nk-fmg-body">
                          <div className="nk-fmg-body-content">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between position-relative">
                                <div
                                  className="nk-block-head-content"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <h3 className="nk-block-title page-title">
                                    <ArrowBack
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        history.push("/document-ocr")
                                      }
                                    />
                                  </h3>
                                  {fileToMoveId.length > 0 &&
                                    folders.length > 0 && (
                                      <button
                                        style={{ marginLeft: "1rem", backgroundColor: "rgb(20, 155, 227)"}}
                                        onClick={() =>
                                          setShowMoveInFolderModal(true)
                                        }
                                        className="btn btn-primary"
                                        data-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-upload" />{" "}
                                        <span>Move</span>
                                      </button>
                                    )}
                                </div>

                                <div
                                  className="search-wrap px-2 d-lg-none"
                                  data-search="search"
                                >
                                  <div className="search-content">
                                    <a
                                      href="#"
                                      className="search-back btn btn-icon toggle-search"
                                      data-target="search"
                                    >
                                      <em className="icon ni ni-arrow-left" />
                                    </a>
                                    <input
                                      type="text"
                                      className="form-control border-transparent form-focus-none"
                                      placeholder="Search by user or message"
                                    />
                                    <button className="search-submit btn btn-icon">
                                      <em className="icon ni ni-search" />
                                    </button>
                                  </div>
                                </div>
                                {/* .search-wrap */}
                              </div>
                            </div>

                            <div className="nk-fmg-listing nk-block">
                              <div
                                className="toggle-expand-content expanded"
                                data-content="recent-files"
                              >
                                <div className="nk-files nk-files-view-group">
                                  <div className="nk-files-group">
                                    <h6 className="title">
                                      Files for{" "}
                                      {localStorage.getItem("foldername")}
                                    </h6>
                                    <div className="nk-files-list">
                                      {folderFiles.length === 0 && (
                                        <h5
                                          style={{
                                            marginLeft: "0.5rem",
                                            marginTop: "1rem",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          Please move a document here to begin
                                          populating this folder
                                        </h5>
                                      )}
                                      {folderFiles?.length > 0 &&
                                        folderFiles?.map((files, index) => (
                                          <OcrButton
                                            moveFolder={() =>
                                              setShowMoveButton(true)
                                            }
                                            files={files}
                                            index={index}
                                            saveocrdata={saveOcrDocumentData}
                                            setRenameocrfile={setRenameocrfile}
                                            setCurrentOcrFile={
                                              setCurrentOcrFile
                                            }
                                            ocrDeleted={() =>
                                              handleOcrDelete(files._id)
                                            }
                                            setOpenocrfile={setOpenocrfile}
                                            setOpenfolderid={setOpenfolderid}
                                            checkBoxClicked={(taskId, _id) => {
                                              if (showMoveButton) {
                                                setFileToMoveId((prev) => [
                                                  ...prev,
                                                  _id,
                                                ]);
                                              } else {
                                                addOrRemoveTaskIdFromExport(
                                                  taskId
                                                );
                                              }
                                            }}
                                            showCheckBox={
                                              showSendMultipleCheckbox ||
                                              showMoveButton
                                            }
                                          />
                                        ))}
                                    </div>
                                  </div>
                                </div>
                                {/* .nk-files */}
                              </div>
                            </div>

                            {/* .nk-block */}
                          </div>
                          {/* .nk-fmg-body-content */}
                        </div>
                      )}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
              {showMoveInFolderModal && (
                <MoveInFolder
                  onMoved={onMoved}
                  onClose={() => setShowMoveInFolderModal(false)}
                  folders={folders}
                  showMoveToRootFolder={true}
                  fileToMoveId={fileToMoveId}
                />
              )}
              <Modal open={openocrfile} aria-labelledby="simple-modal-title">
                {createocrfilebody}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OcrFolderFiles;
