import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import axios from "axios";
import { BASE_URL } from "../../constants/apiconstant";
import { useSnackbar } from "notistack";
import { getHeaders } from "../../utils";
import { snackBarPosition } from "../../constants/constants";
import { CircularProgress } from "@material-ui/core";

const SharedOutFileMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [storjdownloading, setstorjdownloading] = useState(false)
  const open = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();

  const deleteOutFile = (id) => {
    console.log("Outgoing deleteOutFile", id);
    props.setCurrentOutfileid(id);
    props.setRemoveOutFile(true);
  };
  const renameOutFile = (id, name) => {
    console.log("Outgoing renameOutFile id==>", id);
    console.log("Outgoing renameOutFile props==>", props);
    props.setCurrentOutfileid(id);
    props.setRenameOutFile(name);
    props.setOpenOutFilename(true);
  };
  const moveOutFile = (file_id, folder_id) => {
    console.log("Outgoing moveOutFile file_id==>", file_id);
    console.log("Outgoing moveOutFile folder_id==>", folder_id);
    props.setCurrentOutfileid(file_id);
    props.setCurrentOutFolderid(folder_id);
    props.setOrgOutFolderid(folder_id);
    props.setMoveOutFile(true);
  };
  const downloadOutFile = (id) => {
    console.log("Outgoing downloadOutFile", id);
    props.setCurrentOutfileid(id);
    props.setDownloadOutFile(true);
    props.downloadDocument(id);
  };
  const sharedRemove = (id) => {
    console.log("Outgoing sharedRemove", id);
    props.setCurrentOutfileid(id);
    props.setSharedRemove(true);
  };
  const downloadFile = async (type) => {
    if (type == "storj") setstorjdownloading(true)
    else setDownloadLoading(true);
    
    try {
      setAnchorEl(null);
      const { data } = await axios.post(
        BASE_URL + "addNotificationAndSaveLocationWhenFileIsDownloaded/",
        { file_id: props.id },
        getHeaders()
      );
      if (data.success) {
        if (type == "storj") {
          window.open(props.storjFileurl, "_blank").focus();
        }
        else {
          window.open(props.fileurl, "_blank").focus();
          //if (props.fileurl) {
          //  window.open(props.fileurl, "_blank").focus();
          //} else downloadOutFile(props?.id);
        }
      } else {
        enqueueSnackbar(data.message, {
          variant: "error",
          ...snackBarPosition,
        });
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong while downloading files", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    if (type == "storj") setstorjdownloading(false)
    else setDownloadLoading(false);
  };
  return (
    <>
      <IconButton color="inherit" aria-label="open drawer" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MoreHorizIcon style={{ color: "grey" }} />
      </IconButton>
      <Menu
        elevation={0}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setAnchorEl(null)}
      >
        {props.fileurl && <MenuItem onClick={() =>downloadFile("aws")}>{downloadLoading ? <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={20} /> : "Download"} </MenuItem>} 
        {props.storjFileurl && <MenuItem onClick={() => downloadFile("storj")}>{storjdownloading ? <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={20} /> : "Download from Storj"} </MenuItem>}
        {/* {props && (props.menuFor=="outgoing" || props.hasAdminAccess) ? ( */}
        {props.showDeletedFiles || props.showExpiredFiles ? null : (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              moveOutFile(props?.id, props?.folder_id);
              console.log("Shared Outgoing dot Menu-Move-curr id==>", props?.id); // should be each individual id, but here always "test3"
            }}
          >
            Move{" "}
          </MenuItem>
        )}

        {props.showDeletedFiles || props.showExpiredFiles ? null : props &&
          (props.menuFor == "outgoing" || props.hasAdminAccess) ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              renameOutFile(props?.id, props?.name);
              console.log("Shared Outgoing dot Menu-Rename-curr id==>", props?.id); // should be each individual id, but here always "test3"
            }}
          >
            Rename{" "}
          </MenuItem>
        ) : (
          ""
        )}

        {props.showDeletedFiles || props.showExpiredFiles ? null : props &&
          (props.menuFor == "outgoing" || props.hasAdminAccess) ? (
          <MenuItem
            onClick={() => {
              deleteOutFile(props?.id);
              setAnchorEl(null);
              console.log("Shared Outgoing dot Menu-Delete-curr id==>", props?.id); // should be each individual id, but here always "test3"
            }}
          >
            {" "}
            Delete
          </MenuItem>
        ) : (
          ""
        )}

        {props.showDeletedFiles || props.showExpiredFiles ? null : props && props.menuFor == "outgoing" ? (
          <MenuItem
            onClick={() => {
              sharedRemove(props?.id);
              setAnchorEl(null);
              console.log("Shared Outgoing dot Menu-Delete-curr id==>", props?.id); // should be each individual id, but here always "test3"
            }}
          >
            {" "}
            Shared Remove
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
    </>
  );
};

export default SharedOutFileMenu;
