/*export const countryShortCodes = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote DIvoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
 */

const countryNameWithCOde = [
  { name: "Afghanistan", "alpha-2": "AF", "country-code": "004" },
  { name: "Åland Islands", "alpha-2": "AX", "country-code": "248" },
  { name: "Albania", "alpha-2": "AL", "country-code": "008" },
  { name: "Algeria", "alpha-2": "DZ", "country-code": "012" },
  { name: "American Samoa", "alpha-2": "AS", "country-code": "016" },
  { name: "Andorra", "alpha-2": "AD", "country-code": "020" },
  { name: "Angola", "alpha-2": "AO", "country-code": "024" },
  { name: "Anguilla", "alpha-2": "AI", "country-code": "660" },
  { name: "Antarctica", "alpha-2": "AQ", "country-code": "010" },
  { name: "Antigua and Barbuda", "alpha-2": "AG", "country-code": "028" },
  { name: "Argentina", "alpha-2": "AR", "country-code": "032" },
  { name: "Armenia", "alpha-2": "AM", "country-code": "051" },
  { name: "Aruba", "alpha-2": "AW", "country-code": "533" },
  { name: "Australia", "alpha-2": "AU", "country-code": "036" },
  { name: "Austria", "alpha-2": "AT", "country-code": "040" },
  { name: "Azerbaijan", "alpha-2": "AZ", "country-code": "031" },
  { name: "Bahamas", "alpha-2": "BS", "country-code": "044" },
  { name: "Bahrain", "alpha-2": "BH", "country-code": "048" },
  { name: "Bangladesh", "alpha-2": "BD", "country-code": "050" },
  { name: "Barbados", "alpha-2": "BB", "country-code": "052" },
  { name: "Belarus", "alpha-2": "BY", "country-code": "112" },
  { name: "Belgium", "alpha-2": "BE", "country-code": "056" },
  { name: "Belize", "alpha-2": "BZ", "country-code": "084" },
  { name: "Benin", "alpha-2": "BJ", "country-code": "204" },
  { name: "Bermuda", "alpha-2": "BM", "country-code": "060" },
  { name: "Bhutan", "alpha-2": "BT", "country-code": "064" },
  {
    name: "Bolivia (Plurinational State of)",
    "alpha-2": "BO",
    "country-code": "068",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    "alpha-2": "BQ",
    "country-code": "535",
  },
  { name: "Bosnia and Herzegovina", "alpha-2": "BA", "country-code": "070" },
  { name: "Botswana", "alpha-2": "BW", "country-code": "072" },
  { name: "Bouvet Island", "alpha-2": "BV", "country-code": "074" },
  { name: "Brazil", "alpha-2": "BR", "country-code": "076" },
  {
    name: "British Indian Ocean Territory",
    "alpha-2": "IO",
    "country-code": "086",
  },
  { name: "Brunei Darussalam", "alpha-2": "BN", "country-code": "096" },
  { name: "Bulgaria", "alpha-2": "BG", "country-code": "100" },
  { name: "Burkina Faso", "alpha-2": "BF", "country-code": "854" },
  { name: "Burundi", "alpha-2": "BI", "country-code": "108" },
  { name: "Cabo Verde", "alpha-2": "CV", "country-code": "132" },
  { name: "Cambodia", "alpha-2": "KH", "country-code": "116" },
  { name: "Cameroon", "alpha-2": "CM", "country-code": "120" },
  { name: "Canada", "alpha-2": "CA", "country-code": "124" },
  { name: "Cayman Islands", "alpha-2": "KY", "country-code": "136" },
  { name: "Central African Republic", "alpha-2": "CF", "country-code": "140" },
  { name: "Chad", "alpha-2": "TD", "country-code": "148" },
  { name: "Chile", "alpha-2": "CL", "country-code": "152" },
  { name: "China", "alpha-2": "CN", "country-code": "156" },
  { name: "Christmas Island", "alpha-2": "CX", "country-code": "162" },
  { name: "Cocos (Keeling) Islands", "alpha-2": "CC", "country-code": "166" },
  { name: "Colombia", "alpha-2": "CO", "country-code": "170" },
  { name: "Comoros", "alpha-2": "KM", "country-code": "174" },
  { name: "Congo", "alpha-2": "CG", "country-code": "178" },
  {
    name: "Congo, Democratic Republic of the",
    "alpha-2": "CD",
    "country-code": "180",
  },
  { name: "Cook Islands", "alpha-2": "CK", "country-code": "184" },
  { name: "Costa Rica", "alpha-2": "CR", "country-code": "188" },
  { name: "Côte d'Ivoire", "alpha-2": "CI", "country-code": "384" },
  { name: "Croatia", "alpha-2": "HR", "country-code": "191" },
  { name: "Cuba", "alpha-2": "CU", "country-code": "192" },
  { name: "Curaçao", "alpha-2": "CW", "country-code": "531" },
  { name: "Cyprus", "alpha-2": "CY", "country-code": "196" },
  { name: "Czechia", "alpha-2": "CZ", "country-code": "203" },
  { name: "Denmark", "alpha-2": "DK", "country-code": "208" },
  { name: "Djibouti", "alpha-2": "DJ", "country-code": "262" },
  { name: "Dominica", "alpha-2": "DM", "country-code": "212" },
  { name: "Dominican Republic", "alpha-2": "DO", "country-code": "214" },
  { name: "Ecuador", "alpha-2": "EC", "country-code": "218" },
  { name: "Egypt", "alpha-2": "EG", "country-code": "818" },
  { name: "El Salvador", "alpha-2": "SV", "country-code": "222" },
  { name: "Equatorial Guinea", "alpha-2": "GQ", "country-code": "226" },
  { name: "Eritrea", "alpha-2": "ER", "country-code": "232" },
  { name: "Estonia", "alpha-2": "EE", "country-code": "233" },
  { name: "Eswatini", "alpha-2": "SZ", "country-code": "748" },
  { name: "Ethiopia", "alpha-2": "ET", "country-code": "231" },
  {
    name: "Falkland Islands (Malvinas)",
    "alpha-2": "FK",
    "country-code": "238",
  },
  { name: "Faroe Islands", "alpha-2": "FO", "country-code": "234" },
  { name: "Fiji", "alpha-2": "FJ", "country-code": "242" },
  { name: "Finland", "alpha-2": "FI", "country-code": "246" },
  { name: "France", "alpha-2": "FR", "country-code": "250" },
  { name: "French Guiana", "alpha-2": "GF", "country-code": "254" },
  { name: "French Polynesia", "alpha-2": "PF", "country-code": "258" },
  {
    name: "French Southern Territories",
    "alpha-2": "TF",
    "country-code": "260",
  },
  { name: "Gabon", "alpha-2": "GA", "country-code": "266" },
  { name: "Gambia", "alpha-2": "GM", "country-code": "270" },
  { name: "Georgia", "alpha-2": "GE", "country-code": "268" },
  { name: "Germany", "alpha-2": "DE", "country-code": "276" },
  { name: "Ghana", "alpha-2": "GH", "country-code": "288" },
  { name: "Gibraltar", "alpha-2": "GI", "country-code": "292" },
  { name: "Greece", "alpha-2": "GR", "country-code": "300" },
  { name: "Greenland", "alpha-2": "GL", "country-code": "304" },
  { name: "Grenada", "alpha-2": "GD", "country-code": "308" },
  { name: "Guadeloupe", "alpha-2": "GP", "country-code": "312" },
  { name: "Guam", "alpha-2": "GU", "country-code": "316" },
  { name: "Guatemala", "alpha-2": "GT", "country-code": "320" },
  { name: "Guernsey", "alpha-2": "GG", "country-code": "831" },
  { name: "Guinea", "alpha-2": "GN", "country-code": "324" },
  { name: "Guinea-Bissau", "alpha-2": "GW", "country-code": "624" },
  { name: "Guyana", "alpha-2": "GY", "country-code": "328" },
  { name: "Haiti", "alpha-2": "HT", "country-code": "332" },
  {
    name: "Heard Island and McDonald Islands",
    "alpha-2": "HM",
    "country-code": "334",
  },
  { name: "Holy See", "alpha-2": "VA", "country-code": "336" },
  { name: "Honduras", "alpha-2": "HN", "country-code": "340" },
  { name: "Hong Kong", "alpha-2": "HK", "country-code": "344" },
  { name: "Hungary", "alpha-2": "HU", "country-code": "348" },
  { name: "Iceland", "alpha-2": "IS", "country-code": "352" },
  { name: "India", "alpha-2": "IN", "country-code": "356" },
  { name: "Indonesia", "alpha-2": "ID", "country-code": "360" },
  {
    name: "Iran (Islamic Republic of)",
    "alpha-2": "IR",
    "country-code": "364",
  },
  { name: "Iraq", "alpha-2": "IQ", "country-code": "368" },
  { name: "Ireland", "alpha-2": "IE", "country-code": "372" },
  { name: "Isle of Man", "alpha-2": "IM", "country-code": "833" },
  { name: "Israel", "alpha-2": "IL", "country-code": "376" },
  { name: "Italy", "alpha-2": "IT", "country-code": "380" },
  { name: "Jamaica", "alpha-2": "JM", "country-code": "388" },
  { name: "Japan", "alpha-2": "JP", "country-code": "392" },
  { name: "Jersey", "alpha-2": "JE", "country-code": "832" },
  { name: "Jordan", "alpha-2": "JO", "country-code": "400" },
  { name: "Kazakhstan", "alpha-2": "KZ", "country-code": "398" },
  { name: "Kenya", "alpha-2": "KE", "country-code": "404" },
  { name: "Kiribati", "alpha-2": "KI", "country-code": "296" },
  {
    name: "Korea (Democratic People's Republic of)",
    "alpha-2": "KP",
    "country-code": "408",
  },
  { name: "Korea, Republic of", "alpha-2": "KR", "country-code": "410" },
  { name: "Kuwait", "alpha-2": "KW", "country-code": "414" },
  { name: "Kyrgyzstan", "alpha-2": "KG", "country-code": "417" },
  {
    name: "Lao People's Democratic Republic",
    "alpha-2": "LA",
    "country-code": "418",
  },
  { name: "Latvia", "alpha-2": "LV", "country-code": "428" },
  { name: "Lebanon", "alpha-2": "LB", "country-code": "422" },
  { name: "Lesotho", "alpha-2": "LS", "country-code": "426" },
  { name: "Liberia", "alpha-2": "LR", "country-code": "430" },
  { name: "Libya", "alpha-2": "LY", "country-code": "434" },
  { name: "Liechtenstein", "alpha-2": "LI", "country-code": "438" },
  { name: "Lithuania", "alpha-2": "LT", "country-code": "440" },
  { name: "Luxembourg", "alpha-2": "LU", "country-code": "442" },
  { name: "Macao", "alpha-2": "MO", "country-code": "446" },
  { name: "Madagascar", "alpha-2": "MG", "country-code": "450" },
  { name: "Malawi", "alpha-2": "MW", "country-code": "454" },
  { name: "Malaysia", "alpha-2": "MY", "country-code": "458" },
  { name: "Maldives", "alpha-2": "MV", "country-code": "462" },
  { name: "Mali", "alpha-2": "ML", "country-code": "466" },
  { name: "Malta", "alpha-2": "MT", "country-code": "470" },
  { name: "Marshall Islands", "alpha-2": "MH", "country-code": "584" },
  { name: "Martinique", "alpha-2": "MQ", "country-code": "474" },
  { name: "Mauritania", "alpha-2": "MR", "country-code": "478" },
  { name: "Mauritius", "alpha-2": "MU", "country-code": "480" },
  { name: "Mayotte", "alpha-2": "YT", "country-code": "175" },
  { name: "Mexico", "alpha-2": "MX", "country-code": "484" },
  {
    name: "Micronesia (Federated States of)",
    "alpha-2": "FM",
    "country-code": "583",
  },
  { name: "Moldova, Republic of", "alpha-2": "MD", "country-code": "498" },
  { name: "Monaco", "alpha-2": "MC", "country-code": "492" },
  { name: "Mongolia", "alpha-2": "MN", "country-code": "496" },
  { name: "Montenegro", "alpha-2": "ME", "country-code": "499" },
  { name: "Montserrat", "alpha-2": "MS", "country-code": "500" },
  { name: "Morocco", "alpha-2": "MA", "country-code": "504" },
  { name: "Mozambique", "alpha-2": "MZ", "country-code": "508" },
  { name: "Myanmar", "alpha-2": "MM", "country-code": "104" },
  { name: "Namibia", "alpha-2": "NA", "country-code": "516" },
  { name: "Nauru", "alpha-2": "NR", "country-code": "520" },
  { name: "Nepal", "alpha-2": "NP", "country-code": "524" },
  { name: "Netherlands", "alpha-2": "NL", "country-code": "528" },
  { name: "New Caledonia", "alpha-2": "NC", "country-code": "540" },
  { name: "New Zealand", "alpha-2": "NZ", "country-code": "554" },
  { name: "Nicaragua", "alpha-2": "NI", "country-code": "558" },
  { name: "Niger", "alpha-2": "NE", "country-code": "562" },
  { name: "Nigeria", "alpha-2": "NG", "country-code": "566" },
  { name: "Niue", "alpha-2": "NU", "country-code": "570" },
  { name: "Norfolk Island", "alpha-2": "NF", "country-code": "574" },
  { name: "North Macedonia", "alpha-2": "MK", "country-code": "807" },
  { name: "Northern Mariana Islands", "alpha-2": "MP", "country-code": "580" },
  { name: "Norway", "alpha-2": "NO", "country-code": "578" },
  { name: "Oman", "alpha-2": "OM", "country-code": "512" },
  { name: "Pakistan", "alpha-2": "PK", "country-code": "586" },
  { name: "Palau", "alpha-2": "PW", "country-code": "585" },
  { name: "Palestine, State of", "alpha-2": "PS", "country-code": "275" },
  { name: "Panama", "alpha-2": "PA", "country-code": "591" },
  { name: "Papua New Guinea", "alpha-2": "PG", "country-code": "598" },
  { name: "Paraguay", "alpha-2": "PY", "country-code": "600" },
  { name: "Peru", "alpha-2": "PE", "country-code": "604" },
  { name: "Philippines", "alpha-2": "PH", "country-code": "608" },
  { name: "Pitcairn", "alpha-2": "PN", "country-code": "612" },
  { name: "Poland", "alpha-2": "PL", "country-code": "616" },
  { name: "Portugal", "alpha-2": "PT", "country-code": "620" },
  { name: "Puerto Rico", "alpha-2": "PR", "country-code": "630" },
  { name: "Qatar", "alpha-2": "QA", "country-code": "634" },
  { name: "Réunion", "alpha-2": "RE", "country-code": "638" },
  { name: "Romania", "alpha-2": "RO", "country-code": "642" },
  { name: "Russian Federation", "alpha-2": "RU", "country-code": "643" },
  { name: "Rwanda", "alpha-2": "RW", "country-code": "646" },
  { name: "Saint Barthélemy", "alpha-2": "BL", "country-code": "652" },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    "alpha-2": "SH",
    "country-code": "654",
  },
  { name: "Saint Kitts and Nevis", "alpha-2": "KN", "country-code": "659" },
  { name: "Saint Lucia", "alpha-2": "LC", "country-code": "662" },
  {
    name: "Saint Martin (French part)",
    "alpha-2": "MF",
    "country-code": "663",
  },
  { name: "Saint Pierre and Miquelon", "alpha-2": "PM", "country-code": "666" },
  {
    name: "Saint Vincent and the Grenadines",
    "alpha-2": "VC",
    "country-code": "670",
  },
  { name: "Samoa", "alpha-2": "WS", "country-code": "882" },
  { name: "San Marino", "alpha-2": "SM", "country-code": "674" },
  { name: "Sao Tome and Principe", "alpha-2": "ST", "country-code": "678" },
  { name: "Saudi Arabia", "alpha-2": "SA", "country-code": "682" },
  { name: "Senegal", "alpha-2": "SN", "country-code": "686" },
  { name: "Serbia", "alpha-2": "RS", "country-code": "688" },
  { name: "Seychelles", "alpha-2": "SC", "country-code": "690" },
  { name: "Sierra Leone", "alpha-2": "SL", "country-code": "694" },
  { name: "Singapore", "alpha-2": "SG", "country-code": "702" },
  { name: "Sint Maarten (Dutch part)", "alpha-2": "SX", "country-code": "534" },
  { name: "Slovakia", "alpha-2": "SK", "country-code": "703" },
  { name: "Slovenia", "alpha-2": "SI", "country-code": "705" },
  { name: "Solomon Islands", "alpha-2": "SB", "country-code": "090" },
  { name: "Somalia", "alpha-2": "SO", "country-code": "706" },
  { name: "South Africa", "alpha-2": "ZA", "country-code": "710" },
  {
    name: "South Georgia and the South Sandwich Islands",
    "alpha-2": "GS",
    "country-code": "239",
  },
  { name: "South Sudan", "alpha-2": "SS", "country-code": "728" },
  { name: "Spain", "alpha-2": "ES", "country-code": "724" },
  { name: "Sri Lanka", "alpha-2": "LK", "country-code": "144" },
  { name: "Sudan", "alpha-2": "SD", "country-code": "729" },
  { name: "Suriname", "alpha-2": "SR", "country-code": "740" },
  { name: "Svalbard and Jan Mayen", "alpha-2": "SJ", "country-code": "744" },
  { name: "Sweden", "alpha-2": "SE", "country-code": "752" },
  { name: "Switzerland", "alpha-2": "CH", "country-code": "756" },
  { name: "Syrian Arab Republic", "alpha-2": "SY", "country-code": "760" },
  { name: "Taiwan, Province of China", "alpha-2": "TW", "country-code": "158" },
  { name: "Tajikistan", "alpha-2": "TJ", "country-code": "762" },
  {
    name: "Tanzania, United Republic of",
    "alpha-2": "TZ",
    "country-code": "834",
  },
  { name: "Thailand", "alpha-2": "TH", "country-code": "764" },
  { name: "Timor-Leste", "alpha-2": "TL", "country-code": "626" },
  { name: "Togo", "alpha-2": "TG", "country-code": "768" },
  { name: "Tokelau", "alpha-2": "TK", "country-code": "772" },
  { name: "Tonga", "alpha-2": "TO", "country-code": "776" },
  { name: "Trinidad and Tobago", "alpha-2": "TT", "country-code": "780" },
  { name: "Tunisia", "alpha-2": "TN", "country-code": "788" },
  { name: "Turkey", "alpha-2": "TR", "country-code": "792" },
  { name: "Turkmenistan", "alpha-2": "TM", "country-code": "795" },
  { name: "Turks and Caicos Islands", "alpha-2": "TC", "country-code": "796" },
  { name: "Tuvalu", "alpha-2": "TV", "country-code": "798" },
  { name: "Uganda", "alpha-2": "UG", "country-code": "800" },
  { name: "Ukraine", "alpha-2": "UA", "country-code": "804" },
  { name: "United Arab Emirates", "alpha-2": "AE", "country-code": "784" },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    "alpha-2": "GB",
    "country-code": "826",
  },
  { name: "United States of America", "alpha-2": "US", "country-code": "840" },
  {
    name: "United States Minor Outlying Islands",
    "alpha-2": "UM",
    "country-code": "581",
  },
  { name: "Uruguay", "alpha-2": "UY", "country-code": "858" },
  { name: "Uzbekistan", "alpha-2": "UZ", "country-code": "860" },
  { name: "Vanuatu", "alpha-2": "VU", "country-code": "548" },
  {
    name: "Venezuela (Bolivarian Republic of)",
    "alpha-2": "VE",
    "country-code": "862",
  },
  { name: "Viet Nam", "alpha-2": "VN", "country-code": "704" },
  { name: "Virgin Islands (British)", "alpha-2": "VG", "country-code": "092" },
  { name: "Virgin Islands (U.S.)", "alpha-2": "VI", "country-code": "850" },
  { name: "Wallis and Futuna", "alpha-2": "WF", "country-code": "876" },
  { name: "Western Sahara", "alpha-2": "EH", "country-code": "732" },
  { name: "Yemen", "alpha-2": "YE", "country-code": "887" },
  { name: "Zambia", "alpha-2": "ZM", "country-code": "894" },
  { name: "Zimbabwe", "alpha-2": "ZW", "country-code": "716" },
];

const amcharts = {
  AD: {
    country: "Andorra",
    continent_code: "EU",
    continent: "Europe",
    maps: ["andorraLow", "andorraHigh"],
  },
  AE: {
    country: "United Arab Emirates",
    continent_code: "AS",
    continent: "Asia",
    maps: ["uaeLow", "uaeHigh"],
  },
  AF: {
    country: "Afghanistan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["afghanistanLow", "afghanistanHigh"],
  },
  AG: {
    country: "Antigua and Barbuda",
    continent_code: "NA",
    continent: "North America",
    maps: ["antiguaBarbudaLow", "antiguaBarbudaHigh"],
  },
  AI: {
    country: "Anguilla",
    continent_code: "NA",
    continent: "North America",
    maps: ["anguillaLow", "anguillaHigh"],
  },
  AL: {
    country: "Albania",
    continent_code: "EU",
    continent: "Europe",
    maps: ["albaniaLow", "albaniaHigh"],
  },
  AM: {
    country: "Armenia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["armeniaLow", "armeniaHigh"],
  },
  AO: {
    country: "Angola",
    continent_code: "AF",
    continent: "Africa",
    maps: ["angolaLow", "angolaHigh"],
  },
  AQ: {
    country: "Antarctica",
    continent_code: "AN",
    continent: "Antarctica",
    maps: [],
  },
  AR: {
    country: "Argentina",
    continent_code: "SA",
    continent: "South America",
    maps: ["argentinaLow", "argentinaHigh"],
  },
  AS: {
    country: "American Samoa",
    continent_code: "OC",
    continent: "Oceania",
    maps: ["americanSamoaLow", "americanSamoaHigh"],
  },
  AT: {
    country: "Austria",
    continent_code: "EU",
    continent: "Europe",
    maps: ["austriaLow", "austriaHigh"],
  },
  AU: {
    country: "Australia",
    continent_code: "OC",
    continent: "Oceania",
    maps: ["australiaLow", "australiaHigh"],
  },
  AW: {
    country: "Aruba",
    continent_code: "NA",
    continent: "North America",
    maps: ["arubaLow", "arubaHigh"],
  },
  AX: {
    country: "Aland Islands",
    continent_code: "EU",
    continent: "Europe",
    maps: [],
  },
  AZ: {
    country: "Azerbaijan",
    continent_code: "EU",
    continent: "Europe",
    maps: ["azerbaijanLow", "azerbaijanHigh"],
  },
  BA: {
    country: "Bosnia and Herzegovina",
    continent_code: "EU",
    continent: "Europe",
    maps: [
      "bosniaHerzegovinaLow",
      "bosniaHerzegovinaHigh",
      "bosniaHerzegovinaCantonsLow",
      "bosniaHerzegovinaCantonsHigh",
    ],
  },
  BB: {
    country: "Barbados",
    continent_code: "NA",
    continent: "North America",
    maps: ["barbadosLow", "barbadosHigh"],
  },
  BD: {
    country: "Bangladesh",
    continent_code: "AS",
    continent: "Asia",
    maps: ["bangladeshLow", "bangladeshHigh"],
  },
  BE: {
    country: "Belgium",
    continent_code: "EU",
    continent: "Europe",
    maps: ["belgiumLow", "belgiumHigh"],
  },
  BF: {
    country: "Burkina Faso",
    continent_code: "AF",
    continent: "Africa",
    maps: ["burkinaFasoLow", "burkinaFasoHigh"],
  },
  BG: {
    country: "Bulgaria",
    continent_code: "EU",
    continent: "Europe",
    maps: ["bulgariaLow", "bulgariaHigh"],
  },
  BH: {
    country: "Bahrain",
    continent_code: "AS",
    continent: "Asia",
    maps: ["bahrainLow", "bahrainHigh"],
  },
  BI: {
    country: "Burundi",
    continent_code: "AF",
    continent: "Africa",
    maps: ["burundiLow", "burundiHigh"],
  },
  BJ: {
    country: "Benin",
    continent_code: "AF",
    continent: "Africa",
    maps: ["beninLow", "beninHigh"],
  },
  BL: {
    country: "Saint Barthelemy",
    continent_code: "NA",
    continent: "North America",
    maps: [],
  },
  BM: {
    country: "Bermuda",
    continent_code: "NA",
    continent: "North America",
    maps: ["bermudaLow", "bermudaHigh"],
  },
  BN: {
    country: "Brunei Darussalam",
    continent_code: "AS",
    continent: "Asia",
    maps: ["bruneiDarussalamLow", "bruneiDarussalamHigh"],
  },
  BO: {
    country: "Bolivia, Plurinational State of",
    continent_code: "SA",
    continent: "South America",
    maps: ["boliviaLow", "boliviaHigh"],
  },
  BQ: {
    country: "Bonaire, Sint Eustatius and Saba",
    continent_code: "NA",
    continent: "North America",
    maps: ["bonaireSintEustatiusSabaLow", "bonaireSintEustatiusSabaHigh"],
  },
  BR: {
    country: "Brazil",
    continent_code: "SA",
    continent: "South America",
    maps: ["brazilLow", "brazilHigh"],
  },
  BS: {
    country: "Bahamas",
    continent_code: "NA",
    continent: "North America",
    maps: ["bahamasLow", "bahamasHigh"],
  },
  BT: {
    country: "Bhutan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["bhutanLow", "bhutanHigh"],
  },
  BV: {
    country: "Bouvet Island",
    continent_code: "AN",
    continent: "Antarctica",
    maps: [],
  },
  BW: {
    country: "Botswana",
    continent_code: "AF",
    continent: "Africa",
    maps: ["botswanaLow", "botswanaHigh"],
  },
  BY: {
    country: "Belarus",
    continent_code: "EU",
    continent: "Europe",
    maps: ["belarusLow", "belarusHigh"],
  },
  BZ: {
    country: "Belize",
    continent_code: "NA",
    continent: "North America",
    maps: ["belizeLow", "belizeHigh"],
  },
  CA: {
    country: "Canada",
    continent_code: "NA",
    continent: "North America",
    maps: ["canadaLow", "canadaHigh"],
  },
  CC: {
    country: "Cocos (Keeling) Islands",
    continent_code: "AS",
    continent: "Asia",
    maps: ["cocosKeelingLow", "cocosKeelingHigh"],
  },
  CD: {
    country: "Congo, the Democratic Republic of the",
    continent_code: "AF",
    continent: "Africa",
    maps: ["congoDRLow", "congoDRHigh"],
  },
  CF: {
    country: "Central African Republic",
    continent_code: "AF",
    continent: "Africa",
    maps: ["centralAfricanRepublicLow", "centralAfricanRepublicHigh"],
  },
  CG: {
    country: "Congo",
    continent_code: "AF",
    continent: "Africa",
    maps: ["congoLow", "congoHigh"],
  },
  CH: {
    country: "Switzerland",
    continent_code: "EU",
    continent: "Europe",
    maps: ["switzerlandLow", "switzerlandHigh"],
  },
  CI: {
    country: "Cote d'Ivoire",
    continent_code: "AF",
    continent: "Africa",
    maps: ["cotedIvoireLow", "cotedIvoireHigh"],
  },
  CK: {
    country: "Cook Islands",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  CL: {
    country: "Chile",
    continent_code: "SA",
    continent: "South America",
    maps: ["chileLow", "chileHigh"],
  },
  CM: {
    country: "Cameroon",
    continent_code: "AF",
    continent: "Africa",
    maps: ["cameroonLow", "cameroonHigh"],
  },
  CN: {
    country: "China",
    continent_code: "AS",
    continent: "Asia",
    maps: ["chinaLow", "chinaHigh"],
  },
  CO: {
    country: "Colombia",
    continent_code: "SA",
    continent: "South America",
    maps: ["colombiaLow", "colombiaHigh", "colombiaMuniLow", "colombiaMuniHigh"],
  },
  CR: {
    country: "Costa Rica",
    continent_code: "NA",
    continent: "North America",
    maps: ["costaRicaLow", "costaRicaHigh"],
  },
  CU: {
    country: "Cuba",
    continent_code: "NA",
    continent: "North America",
    maps: ["cubaLow", "cubaHigh"],
  },
  CV: {
    country: "Cape Verde",
    continent_code: "AF",
    continent: "Africa",
    maps: ["capeVerdeLow", "capeVerdeHigh"],
  },
  CW: {
    country: "Curacao",
    continent_code: "NA",
    continent: "North America",
    maps: ["curacaoLow", "curacaoHigh"],
  },
  CX: {
    country: "Christmas Island",
    continent_code: "AS",
    continent: "Asia",
    maps: [],
  },
  CY: {
    country: "Cyprus",
    continent_code: "EU",
    continent: "Europe",
    maps: ["cyprusLow", "cyprusHigh", "cyprusNorthCyprusLow", "cyprusNorthCyprusHigh"],
  },
  CZ: {
    country: "Czech Republic",
    continent_code: "EU",
    continent: "Europe",
    maps: ["czechiaLow", "czechiaHigh"],
  },
  DE: {
    country: "Germany",
    continent_code: "EU",
    continent: "Europe",
    maps: ["germanyLow", "germanyHigh"],
  },
  DJ: {
    country: "Djibouti",
    continent_code: "AF",
    continent: "Africa",
    maps: ["djiboutiLow", "djiboutiHigh"],
  },
  DK: {
    country: "Denmark",
    continent_code: "EU",
    continent: "Europe",
    maps: ["denmarkLow", "denmarkHigh"],
  },
  DM: {
    country: "Dominica",
    continent_code: "NA",
    continent: "North America",
    maps: ["dominicaLow", "dominicaHigh"],
  },
  DO: {
    country: "Dominican Republic",
    continent_code: "NA",
    continent: "North America",
    maps: ["dominicanRepublicLow", "dominicanRepublicHigh", "dominicanRepublicMuniLow", "dominicanRepublicMuniHigh"],
  },
  DZ: {
    country: "Algeria",
    continent_code: "AF",
    continent: "Africa",
    maps: ["algeriaLow", "algeriaHigh"],
  },
  EC: {
    country: "Ecuador",
    continent_code: "SA",
    continent: "South America",
    maps: ["ecuadorLow", "ecuadorHigh"],
  },
  EE: {
    country: "Estonia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["estoniaLow", "estoniaHigh"],
  },
  EG: {
    country: "Egypt",
    continent_code: "AF",
    continent: "Africa",
    maps: ["egyptLow", "egyptHigh"],
  },
  EH: {
    country: "Western Sahara",
    continent_code: "AF",
    continent: "Africa",
    maps: [],
  },
  ER: {
    country: "Eritrea",
    continent_code: "AF",
    continent: "Africa",
    maps: ["eritreaLow", "eritreaHigh"],
  },
  ES: {
    country: "Spain",
    continent_code: "EU",
    continent: "Europe",
    maps: ["spainLow", "spainHigh", "spainProvincesLow", "spainProvincesHigh"],
  },
  ET: {
    country: "Ethiopia",
    continent_code: "AF",
    continent: "Africa",
    maps: ["ethiopiaLow", "ethiopiaHigh"],
  },
  FI: {
    country: "Finland",
    continent_code: "EU",
    continent: "Europe",
    maps: ["finlandLow", "finlandHigh"],
  },
  FJ: {
    country: "Fiji",
    continent_code: "OC",
    continent: "Oceania",
    maps: ["fijiEastLow", "fijiEastHigh", "fijiWestLow", "fijiWestHigh"],
  },
  FK: {
    country: "Falkland Islands (Malvinas)",
    continent_code: "SA",
    continent: "South America",
    maps: ["falklandIslandsLow", "falklandIslandsHigh"],
  },
  FM: {
    country: "Micronesia, Federated States of",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  FO: {
    country: "Faroe Islands",
    continent_code: "EU",
    continent: "Europe",
    maps: ["faroeIslandsLow", "faroeIslandsHigh"],
  },
  FR: {
    country: "France",
    continent_code: "EU",
    continent: "Europe",
    maps: ["franceLow", "franceHigh", "franceDepartmentsLow", "franceDepartmentsHigh"],
  },
  GA: {
    country: "Gabon",
    continent_code: "AF",
    continent: "Africa",
    maps: ["gabonLow", "gabonHigh"],
  },
  GB: {
    country: "United Kingdom",
    continent_code: "EU",
    continent: "Europe",
    maps: ["ukLow", "ukHigh", "ukCountiesLow", "ukCountiesHigh", "ukCountriesLow", "ukCountriesHigh"],
  },
  "GB-CHA": {
    country: "Channel Islands",
    continent_code: "EU",
    continent: "Europe",
    maps: ["channelIslandsLow", "channelIslandsHigh"],
  },
  GD: {
    country: "Grenada",
    continent_code: "NA",
    continent: "North America",
    maps: ["grenadaLow", "grenadaHigh"],
  },
  GE: {
    country: "Georgia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["georgiaLow", "georgiaHigh", "georgiaSouthOssetiaLow", "georgiaSouthOssetiaHigh"],
  },
  GF: {
    country: "French Guiana",
    continent_code: "SA",
    continent: "South America",
    maps: ["frenchGuianaLow", "frenchGuianaHigh"],
  },
  GG: {
    country: "Guernsey",
    continent_code: "EU",
    continent: "Europe",
    maps: [],
  },
  GH: {
    country: "Ghana",
    continent_code: "AF",
    continent: "Africa",
    maps: ["ghanaLow", "ghanaHigh"],
  },
  GI: {
    country: "Gibraltar",
    continent_code: "EU",
    continent: "Europe",
    maps: [],
  },
  GL: {
    country: "Greenland",
    continent_code: "NA",
    continent: "North America",
    maps: ["greenlandLow", "greenlandHigh"],
  },
  GM: {
    country: "Gambia",
    continent_code: "AF",
    continent: "Africa",
    maps: ["gambiaLow", "gambiaHigh"],
  },
  GN: {
    country: "Guinea",
    continent_code: "AF",
    continent: "Africa",
    maps: ["guineaLow", "guineaHigh"],
  },
  GP: {
    country: "Guadeloupe",
    continent_code: "NA",
    continent: "North America",
    maps: ["guadeloupeLow", "guadeloupeHigh"],
  },
  GQ: {
    country: "Equatorial Guinea",
    continent_code: "AF",
    continent: "Africa",
    maps: ["equatorialGuineaLow", "equatorialGuineaHigh"],
  },
  GR: {
    country: "Greece",
    continent_code: "EU",
    continent: "Europe",
    maps: ["greeceLow", "greeceHigh"],
  },
  GS: {
    country: "South Georgia and the South Sandwich Islands",
    continent_code: "AN",
    continent: "Antarctica",
    maps: [],
  },
  GT: {
    country: "Guatemala",
    continent_code: "NA",
    continent: "North America",
    maps: ["guatemalaLow", "guatemalaHigh"],
  },
  GU: {
    country: "Guam",
    continent_code: "OC",
    continent: "Oceania",
    maps: ["guamLow", "guamHigh"],
  },
  GW: {
    country: "Guinea-Bissau",
    continent_code: "AF",
    continent: "Africa",
    maps: ["guineaBissauLow", "guineaBissauHigh"],
  },
  GY: {
    country: "Guyana",
    continent_code: "SA",
    continent: "South America",
    maps: ["guyanaLow", "guyanaHigh"],
  },
  HK: {
    country: "Hong Kong",
    continent_code: "AS",
    continent: "Asia",
    maps: ["hongKongLow", "hongKongHigh"],
  },
  HM: {
    country: "Heard Island and McDonald Islands",
    continent_code: "AN",
    continent: "Antarctica",
    maps: [],
  },
  HN: {
    country: "Honduras",
    continent_code: "NA",
    continent: "North America",
    maps: ["hondurasLow", "hondurasHigh"],
  },
  HR: {
    country: "Croatia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["croatiaLow", "croatiaHigh"],
  },
  HT: {
    country: "Haiti",
    continent_code: "NA",
    continent: "North America",
    maps: ["haitiLow", "haitiHigh"],
  },
  HU: {
    country: "Hungary",
    continent_code: "EU",
    continent: "Europe",
    maps: ["hungaryLow", "hungaryHigh"],
  },
  ID: {
    country: "Indonesia",
    continent_code: "AS",
    continent: "Asia",
    maps: ["indonesiaLow", "indonesiaHigh"],
  },
  IE: {
    country: "Ireland",
    continent_code: "EU",
    continent: "Europe",
    maps: ["irelandLow", "irelandHigh"],
  },
  IL: {
    country: "Israel",
    continent_code: "AS",
    continent: "Asia",
    maps: ["israelLow", "israelHigh", "israelPalestineLow", "israelPalestineHigh"],
  },
  IM: {
    country: "Isle of Man",
    continent_code: "EU",
    continent: "Europe",
    maps: [],
  },
  IN: {
    country: "India",
    continent_code: "AS",
    continent: "Asia",
    maps: ["india2020Low", "india2020High", "india2019Low", "india2019High", "indiaLow", "indiaHigh"],
  },
  IO: {
    country: "British Indian Ocean Territory",
    continent_code: "AS",
    continent: "Asia",
    maps: ["britishIndianOceanTerritoryLow", "britishIndianOceanTerritoryHigh"],
  },
  IQ: {
    country: "Iraq",
    continent_code: "AS",
    continent: "Asia",
    maps: ["iraqLow", "iraqHigh"],
  },
  IR: {
    country: "Iran, Islamic Republic of",
    continent_code: "AS",
    continent: "Asia",
    maps: ["iranLow", "iranHigh"],
  },
  IS: {
    country: "Iceland",
    continent_code: "EU",
    continent: "Europe",
    maps: ["icelandLow", "icelandHigh"],
  },
  IT: {
    country: "Italy",
    continent_code: "EU",
    continent: "Europe",
    maps: ["italyLow", "italyHigh"],
  },
  JE: {
    country: "Jersey",
    continent_code: "EU",
    continent: "Europe",
    maps: [],
  },
  JM: {
    country: "Jamaica",
    continent_code: "NA",
    continent: "North America",
    maps: ["jamaicaLow", "jamaicaHigh"],
  },
  JO: {
    country: "Jordan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["jordanLow", "jordanHigh"],
  },
  JP: {
    country: "Japan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["japanLow", "japanHigh"],
  },
  KE: {
    country: "Kenya",
    continent_code: "AF",
    continent: "Africa",
    maps: ["kenyaLow", "kenyaHigh"],
  },
  KG: {
    country: "Kyrgyzstan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["kyrgyzstanLow", "kyrgyzstanHigh"],
  },
  KH: {
    country: "Cambodia",
    continent_code: "AS",
    continent: "Asia",
    maps: ["cambodiaLow", "cambodiaHigh"],
  },
  KI: {
    country: "Kiribati",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  KM: {
    country: "Comoros",
    continent_code: "AF",
    continent: "Africa",
    maps: ["comorosLow", "comorosHigh"],
  },
  KN: {
    country: "Saint Kitts and Nevis",
    continent_code: "NA",
    continent: "North America",
    maps: [],
  },
  KP: {
    country: "Korea, Democratic People's Republic of",
    continent_code: "AS",
    continent: "Asia",
    maps: ["northKoreaLow", "northKoreaHigh"],
  },
  KR: {
    country: "Korea, Republic of",
    continent_code: "AS",
    continent: "Asia",
    maps: ["southKoreaLow", "southKoreaHigh"],
  },
  KT: {
    country: "Saint Kitts and Nevis",
    continent_code: "AS",
    continent: "Asia",
    maps: ["stKittsNevisLow", "stKittsNevisHigh"],
  },
  KW: {
    country: "Kuwait",
    continent_code: "AS",
    continent: "Asia",
    maps: ["kuwaitLow", "kuwaitHigh"],
  },
  KY: {
    country: "Cayman Islands",
    continent_code: "NA",
    continent: "North America",
    maps: ["caymanIslandsLow", "caymanIslandsHigh"],
  },
  KZ: {
    country: "Kazakhstan",
    continent_code: "EU",
    continent: "Europe",
    maps: ["kazakhstanLow", "kazakhstanHigh"],
  },
  LA: {
    country: "Lao People's Democratic Republic",
    continent_code: "AS",
    continent: "Asia",
    maps: ["laosLow", "laosHigh"],
  },
  LB: {
    country: "Lebanon",
    continent_code: "AS",
    continent: "Asia",
    maps: ["lebanonLow", "lebanonHigh"],
  },
  LC: {
    country: "Saint Lucia",
    continent_code: "NA",
    continent: "North America",
    maps: ["saintLuciaLow", "saintLuciaHigh"],
  },
  LI: {
    country: "Liechtenstein",
    continent_code: "EU",
    continent: "Europe",
    maps: ["liechtensteinLow", "liechtensteinHigh"],
  },
  LK: {
    country: "Sri Lanka",
    continent_code: "AS",
    continent: "Asia",
    maps: ["sriLankaLow", "sriLankaHigh"],
  },
  LR: {
    country: "Liberia",
    continent_code: "AF",
    continent: "Africa",
    maps: [],
  },
  LS: {
    country: "Lesotho",
    continent_code: "AF",
    continent: "Africa",
    maps: [],
  },
  LT: {
    country: "Lithuania",
    continent_code: "EU",
    continent: "Europe",
    maps: ["lithuaniaLow", "lithuaniaHigh"],
  },
  LU: {
    country: "Luxembourg",
    continent_code: "EU",
    continent: "Europe",
    maps: ["luxembourgLow", "luxembourgHigh"],
  },
  LV: {
    country: "Latvia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["latviaLow", "latviaHigh"],
  },
  LY: {
    country: "Libya",
    continent_code: "AF",
    continent: "Africa",
    maps: ["libyaLow", "libyaHigh"],
  },
  MA: {
    country: "Morocco",
    continent_code: "AF",
    continent: "Africa",
    maps: ["moroccoLow", "moroccoHigh"],
  },
  MC: {
    country: "Monaco",
    continent_code: "EU",
    continent: "Europe",
    maps: [],
  },
  MD: {
    country: "Moldova, Republic of",
    continent_code: "EU",
    continent: "Europe",
    maps: ["moldovaLow", "moldovaHigh"],
  },
  ME: {
    country: "Montenegro",
    continent_code: "EU",
    continent: "Europe",
    maps: ["montenegroLow", "montenegroHigh"],
  },
  MF: {
    country: "Saint Martin (French Part)",
    continent_code: "NA",
    continent: "North America",
    maps: [],
  },
  MG: {
    country: "Madagascar",
    continent_code: "AF",
    continent: "Africa",
    maps: ["madagascarProvinceLow", "madagascarProvinceHigh", "madagascarRegionLow", "madagascarRegionHigh"],
  },
  MH: {
    country: "Marshall Islands",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  MK: {
    country: "North Macedonia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["northMacedoniaLow", "northMacedoniaHigh"],
  },
  ML: {
    country: "Mali",
    continent_code: "AF",
    continent: "Africa",
    maps: ["maliLow", "maliHigh"],
  },
  MM: {
    country: "Myanmar",
    continent_code: "AS",
    continent: "Asia",
    maps: ["myanmarLow", "myanmarHigh"],
  },
  MN: {
    country: "Mongolia",
    continent_code: "AS",
    continent: "Asia",
    maps: ["mongoliaLow", "mongoliaHigh"],
  },
  MO: {
    country: "Macao",
    continent_code: "AS",
    continent: "Asia",
    maps: [],
  },
  MP: {
    country: "Northern Mariana Islands",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  MQ: {
    country: "Martinique",
    continent_code: "NA",
    continent: "North America",
    maps: ["martiniqueLow", "martiniqueHigh"],
  },
  MR: {
    country: "Mauritania",
    continent_code: "AF",
    continent: "Africa",
    maps: ["mauritaniaLow", "mauritaniaHigh"],
  },
  MS: {
    country: "Montserrat",
    continent_code: "NA",
    continent: "North America",
    maps: ["montserratLow", "montserratHigh"],
  },
  MT: {
    country: "Malta",
    continent_code: "EU",
    continent: "Europe",
    maps: ["maltaLow", "maltaHigh"],
  },
  MU: {
    country: "Mauritius",
    continent_code: "AF",
    continent: "Africa",
    maps: [],
  },
  MV: {
    country: "Maldives",
    continent_code: "AS",
    continent: "Asia",
    maps: ["maldivesLow", "maldivesHigh", "maldivesIslandsLow", "maldivesIslandsHigh"],
  },
  MW: {
    country: "Malawi",
    continent_code: "AF",
    continent: "Africa",
    maps: ["malawiLow", "malawiHigh"],
  },
  MX: {
    country: "Mexico",
    continent_code: "NA",
    continent: "North America",
    maps: ["mexicoLow", "mexicoHigh"],
  },
  MY: {
    country: "Malaysia",
    continent_code: "AS",
    continent: "Asia",
    maps: ["malaysiaLow", "malaysiaHigh"],
  },
  MZ: {
    country: "Mozambique",
    continent_code: "AF",
    continent: "Africa",
    maps: ["mozambiqueLow", "mozambiqueHigh"],
  },
  NA: {
    country: "Namibia",
    continent_code: "AF",
    continent: "Africa",
    maps: ["namibiaLow", "namibiaHigh"],
  },
  NC: {
    country: "New Caledonia",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  NE: {
    country: "Niger",
    continent_code: "AF",
    continent: "Africa",
    maps: ["nigerLow", "nigerHigh"],
  },
  NF: {
    country: "Norfolk Island",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  NG: {
    country: "Nigeria",
    continent_code: "AF",
    continent: "Africa",
    maps: ["nigeriaLow", "nigeriaHigh"],
  },
  NI: {
    country: "Nicaragua",
    continent_code: "NA",
    continent: "North America",
    maps: ["nicaraguaLow", "nicaraguaHigh"],
  },
  NL: {
    country: "Netherlands",
    continent_code: "EU",
    continent: "Europe",
    maps: ["netherlandsLow", "netherlandsHigh"],
  },
  NO: {
    country: "Norway",
    continent_code: "EU",
    continent: "Europe",
    maps: ["norwayLow", "norwayHigh"],
  },
  NP: {
    country: "Nepal",
    continent_code: "AS",
    continent: "Asia",
    maps: ["nepalLow", "nepalHigh"],
  },
  NR: {
    country: "Nauru",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  NU: {
    country: "Niue",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  NZ: {
    country: "New Zealand",
    continent_code: "OC",
    continent: "Oceania",
    maps: ["newZealandLow", "newZealandHigh"],
  },
  OM: {
    country: "Oman",
    continent_code: "AS",
    continent: "Asia",
    maps: ["omanLow", "omanHigh"],
  },
  PA: {
    country: "Panama",
    continent_code: "NA",
    continent: "North America",
    maps: ["panamaLow", "panamaHigh"],
  },
  PE: {
    country: "Peru",
    continent_code: "SA",
    continent: "South America",
    maps: ["peruLow", "peruHigh"],
  },
  PF: {
    country: "French Polynesia",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  PG: {
    country: "Papua New Guinea",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  PH: {
    country: "Philippines",
    continent_code: "AS",
    continent: "Asia",
    maps: ["philippinesLow", "philippinesHigh"],
  },
  PK: {
    country: "Pakistan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["pakistanLow", "pakistanHigh"],
  },
  PL: {
    country: "Poland",
    continent_code: "EU",
    continent: "Europe",
    maps: ["polandLow", "polandHigh"],
  },
  PM: {
    country: "Saint Pierre and Miquelon",
    continent_code: "NA",
    continent: "North America",
    maps: ["stPierreMiquelonLow", "stPierreMiquelonHigh"],
  },
  PN: {
    country: "Pitcairn",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  PR: {
    country: "Puerto Rico",
    continent_code: "NA",
    continent: "North America",
    maps: ["puertoRicoLow", "puertoRicoHigh"],
  },
  PS: {
    country: "Palestinian, State of",
    continent_code: "AS",
    continent: "Asia",
    maps: ["palestineLow", "palestineHigh"],
  },
  PT: {
    country: "Portugal",
    continent_code: "EU",
    continent: "Europe",
    maps: ["portugalLow", "portugalHigh", "portugalRegionsLow", "portugalRegionsHigh"],
  },
  PW: {
    country: "Palau",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  PY: {
    country: "Paraguay",
    continent_code: "SA",
    continent: "South America",
    maps: ["paraguayLow", "paraguayHigh"],
  },
  QA: {
    country: "Qatar",
    continent_code: "AS",
    continent: "Asia",
    maps: ["qatarLow", "qatarHigh"],
  },
  RE: {
    country: "Reunion",
    continent_code: "AF",
    continent: "Africa",
    maps: [],
  },
  RO: {
    country: "Romania",
    continent_code: "EU",
    continent: "Europe",
    maps: ["romaniaLow", "romaniaHigh"],
  },
  RS: {
    country: "Serbia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["serbiaLow", "serbiaHigh", "serbiaNoKosovoLow", "serbiaNoKosovoHigh"],
  },
  RU: {
    country: "Russian Federation",
    continent_code: "EU",
    continent: "Europe",
    maps: ["russiaLow", "russiaHigh", "russiaCrimeaLow", "russiaCrimeaHigh"],
  },
  RW: {
    country: "Rwanda",
    continent_code: "AF",
    continent: "Africa",
    maps: ["rwandaLow", "rwandaHigh"],
  },
  SA: {
    country: "Saudi Arabia",
    continent_code: "AS",
    continent: "Asia",
    maps: ["saudiArabiaLow", "saudiArabiaHigh"],
  },
  SB: {
    country: "Solomon Islands",
    continent_code: "OC",
    continent: "Oceania",
    maps: ["solomonIslandsLow", "solomonIslandsHigh"],
  },
  SC: {
    country: "Seychelles",
    continent_code: "AF",
    continent: "Africa",
    maps: ["seychellesLow", "seychellesHigh"],
  },
  SD: {
    country: "Sudan",
    continent_code: "AF",
    continent: "Africa",
    maps: ["sudanLow", "sudanHigh"],
  },
  SE: {
    country: "Sweden",
    continent_code: "EU",
    continent: "Europe",
    maps: ["swedenLow", "swedenHigh"],
  },
  SG: {
    country: "Singapore",
    continent_code: "AS",
    continent: "Asia",
    maps: ["singaporeLow", "singaporeHigh"],
  },
  SH: {
    country: "Saint Helena, Ascension and Tristan da Cunha",
    continent_code: "AF",
    continent: "Africa",
    maps: ["saintHelenaLow", "saintHelenaHigh"],
  },
  SI: {
    country: "Slovenia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["sloveniaLow", "sloveniaHigh", "sloveniaRegionsLow", "sloveniaRegionsHigh"],
  },
  SJ: {
    country: "Svalbard and Jan Mayen",
    continent_code: "EU",
    continent: "Europe",
    maps: ["svalbardLow", "svalbardHigh"],
  },
  SK: {
    country: "Slovakia",
    continent_code: "EU",
    continent: "Europe",
    maps: ["slovakiaLow", "slovakiaHigh"],
  },
  SL: {
    country: "Sierra Leone",
    continent_code: "AF",
    continent: "Africa",
    maps: ["sierraLeoneLow", "sierraLeoneHigh"],
  },
  SM: {
    country: "San Marino",
    continent_code: "EU",
    continent: "Europe",
    maps: ["sanMarinoLow", "sanMarinoHigh"],
  },
  SN: {
    country: "Senegal",
    continent_code: "AF",
    continent: "Africa",
    maps: ["senegalLow", "senegalHigh"],
  },
  SO: {
    country: "Somalia",
    continent_code: "AF",
    continent: "Africa",
    maps: ["somaliaLow", "somaliaHigh"],
  },
  SR: {
    country: "Suriname",
    continent_code: "SA",
    continent: "South America",
    maps: ["surinameLow", "surinameHigh"],
  },
  SS: {
    country: "South Sudan",
    continent_code: "AF",
    continent: "Africa",
    maps: ["southSudanLow", "southSudanHigh", "southSudan2015Low", "southSudan2015High"],
  },
  ST: {
    country: "Sao Tome and Principe",
    continent_code: "AF",
    continent: "Africa",
    maps: ["saoTomePrincipeLow", "saoTomePrincipeHigh"],
  },
  SV: {
    country: "El Salvador",
    continent_code: "NA",
    continent: "North America",
    maps: ["elSalvadorLow", "elSalvadorHigh"],
  },
  SX: {
    country: "Sint Maarten (Dutch Part)",
    continent_code: "NA",
    continent: "North America",
    maps: [],
  },
  SY: {
    country: "Syrian Arab Republic",
    continent_code: "AS",
    continent: "Asia",
    maps: ["syriaLow", "syriaHigh"],
  },
  SZ: {
    country: "Swaziland",
    continent_code: "AF",
    continent: "Africa",
    maps: ["eswatiniLow", "eswatiniHigh"],
  },
  TC: {
    country: "Turks and Caicos Islands",
    continent_code: "NA",
    continent: "North America",
    maps: [],
  },
  TD: {
    country: "Chad",
    continent_code: "AF",
    continent: "Africa",
    maps: ["chadLow", "chadHigh"],
  },
  TF: {
    country: "French Southern Territories",
    continent_code: "AN",
    continent: "Antarctica",
    maps: [],
  },
  TG: {
    country: "Togo",
    continent_code: "AF",
    continent: "Africa",
    maps: ["togoLow", "togoHigh"],
  },
  TH: {
    country: "Thailand",
    continent_code: "AS",
    continent: "Asia",
    maps: ["thailandLow", "thailandHigh"],
  },
  TJ: {
    country: "Tajikistan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["tajikistanLow", "tajikistanHigh"],
  },
  TK: {
    country: "Tokelau",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  TL: {
    country: "Timor-Leste",
    continent_code: "AS",
    continent: "Asia",
    maps: ["timorLesteLow", "timorLesteHigh"],
  },
  TM: {
    country: "Turkmenistan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["turkmenistanLow", "turkmenistanHigh"],
  },
  TN: {
    country: "Tunisia",
    continent_code: "AF",
    continent: "Africa",
    maps: ["tunisiaLow", "tunisiaHigh"],
  },
  TO: {
    country: "Tonga",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  TR: {
    country: "Turkey",
    continent_code: "EU",
    continent: "Europe",
    maps: ["turkeyLow", "turkeyHigh"],
  },
  TT: {
    country: "Trinidad and Tobago",
    continent_code: "NA",
    continent: "North America",
    maps: ["trinidadTobagoLow", "trinidadTobagoHigh"],
  },
  TV: {
    country: "Tuvalu",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  TW: {
    country: "Taiwan",
    continent_code: "AS",
    continent: "Asia",
    maps: [],
  },
  TZ: {
    country: "Tanzania, United Republic of",
    continent_code: "AF",
    continent: "Africa",
    maps: ["tanzaniaLow", "tanzaniaHigh"],
  },
  UA: {
    country: "Ukraine",
    continent_code: "EU",
    continent: "Europe",
    maps: ["ukraineLow", "ukraineHigh"],
  },
  UG: {
    country: "Uganda",
    continent_code: "AF",
    continent: "Africa",
    maps: ["ugandaLow", "ugandaHigh"],
  },
  UM: {
    country: "United States Minor Outlying Islands",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  US: {
    country: "United States",
    continent_code: "NA",
    continent: "North America",
    maps: ["usaLow", "usaHigh", "usaTerritoriesLow", "usaTerritoriesHigh", "usaTerritories2Low", "usaTerritories2High"],
  },
  UY: {
    country: "Uruguay",
    continent_code: "SA",
    continent: "South America",
    maps: ["uruguayLow", "uruguayHigh"],
  },
  UZ: {
    country: "Uzbekistan",
    continent_code: "AS",
    continent: "Asia",
    maps: ["uzbekistanLow", "uzbekistanHigh"],
  },
  VA: {
    country: "Holy See (Vatican City State)",
    continent_code: "EU",
    continent: "Europe",
    maps: ["vaticanLow", "vaticanHigh"],
  },
  VC: {
    country: "Saint Vincent and the Grenadines",
    continent_code: "NA",
    continent: "North America",
    maps: ["saintVincentLow", "saintVincentHigh"],
  },
  VE: {
    country: "Venezuela, Bolivarian Republic of",
    continent_code: "SA",
    continent: "South America",
    maps: ["venezuelaLow", "venezuelaHigh"],
  },
  VG: {
    country: "Virgin Islands, British",
    continent_code: "NA",
    continent: "North America",
    maps: ["britishVirginIslandsLow", "britishVirginIslandsHigh"],
  },
  VI: {
    country: "Virgin Islands, U.S.",
    continent_code: "NA",
    continent: "North America",
    maps: ["usVirginIslandsLow", "usVirginIslandsHigh"],
  },
  VN: {
    country: "Viet Nam",
    continent_code: "AS",
    continent: "Asia",
    maps: ["vietnamLow", "vietnamHigh"],
  },
  VU: {
    country: "Vanuatu",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  WF: {
    country: "Wallis and Futuna",
    continent_code: "OC",
    continent: "Oceania",
    maps: [],
  },
  WS: {
    country: "Samoa",
    continent_code: "OC",
    continent: "Oceania",
    maps: ["samoaLow", "samoaHigh"],
  },
  YE: {
    country: "Yemen",
    continent_code: "AS",
    continent: "Asia",
    maps: ["yemenLow", "yemenHigh"],
  },
  YT: {
    country: "Mayotte",
    continent_code: "AF",
    continent: "Africa",
    maps: [],
  },
  ZA: {
    country: "South Africa",
    continent_code: "AF",
    continent: "Africa",
    maps: ["southAfricaLow", "southAfricaHigh"],
  },
  ZM: {
    country: "Zambia",
    continent_code: "AF",
    continent: "Africa",
    maps: ["zambiaLow", "zambiaHigh"],
  },
  ZW: {
    country: "Zimbabwe",
    continent_code: "AF",
    continent: "Africa",
    maps: ["zimbabweLow", "zimbabweHigh"],
  },
  XK: {
    country: "Kosovo",
    continent_code: "EU",
    continent: "Europe",
    maps: ["kosovoLow", "kosovoHigh"],
  },
};
/* let countryObject = {};
countryNameWithCOde.map((c) => {
  countryObject[c.name] = {
    amchartCountryName: amcharts[c["alpha-2"]].country,
    countryCode: c["alpha-2"],
    ip2locationCountryName: c.name,
  };
}); */
/* console.log(JSON.stringify(countryObject)); */
export const countryShortCodes = {
  Afghanistan: {
    amchartCountryName: "Afghanistan",
    countryCode: "AF",
    ip2locationCountryName: "Afghanistan",
  },
  "Åland Islands": {
    amchartCountryName: "Aland Islands",
    countryCode: "AX",
    ip2locationCountryName: "Åland Islands",
  },
  Albania: {
    amchartCountryName: "Albania",
    countryCode: "AL",
    ip2locationCountryName: "Albania",
  },
  Algeria: {
    amchartCountryName: "Algeria",
    countryCode: "DZ",
    ip2locationCountryName: "Algeria",
  },
  "American Samoa": {
    amchartCountryName: "American Samoa",
    countryCode: "AS",
    ip2locationCountryName: "American Samoa",
  },
  Andorra: {
    amchartCountryName: "Andorra",
    countryCode: "AD",
    ip2locationCountryName: "Andorra",
  },
  Angola: {
    amchartCountryName: "Angola",
    countryCode: "AO",
    ip2locationCountryName: "Angola",
  },
  Anguilla: {
    amchartCountryName: "Anguilla",
    countryCode: "AI",
    ip2locationCountryName: "Anguilla",
  },
  Antarctica: {
    amchartCountryName: "Antarctica",
    countryCode: "AQ",
    ip2locationCountryName: "Antarctica",
  },
  "Antigua and Barbuda": {
    amchartCountryName: "Antigua and Barbuda",
    countryCode: "AG",
    ip2locationCountryName: "Antigua and Barbuda",
  },
  Argentina: {
    amchartCountryName: "Argentina",
    countryCode: "AR",
    ip2locationCountryName: "Argentina",
  },
  Armenia: {
    amchartCountryName: "Armenia",
    countryCode: "AM",
    ip2locationCountryName: "Armenia",
  },
  Aruba: {
    amchartCountryName: "Aruba",
    countryCode: "AW",
    ip2locationCountryName: "Aruba",
  },
  Australia: {
    amchartCountryName: "Australia",
    countryCode: "AU",
    ip2locationCountryName: "Australia",
  },
  Austria: {
    amchartCountryName: "Austria",
    countryCode: "AT",
    ip2locationCountryName: "Austria",
  },
  Azerbaijan: {
    amchartCountryName: "Azerbaijan",
    countryCode: "AZ",
    ip2locationCountryName: "Azerbaijan",
  },
  Bahamas: {
    amchartCountryName: "Bahamas",
    countryCode: "BS",
    ip2locationCountryName: "Bahamas",
  },
  Bahrain: {
    amchartCountryName: "Bahrain",
    countryCode: "BH",
    ip2locationCountryName: "Bahrain",
  },
  Bangladesh: {
    amchartCountryName: "Bangladesh",
    countryCode: "BD",
    ip2locationCountryName: "Bangladesh",
  },
  Barbados: {
    amchartCountryName: "Barbados",
    countryCode: "BB",
    ip2locationCountryName: "Barbados",
  },
  Belarus: {
    amchartCountryName: "Belarus",
    countryCode: "BY",
    ip2locationCountryName: "Belarus",
  },
  Belgium: {
    amchartCountryName: "Belgium",
    countryCode: "BE",
    ip2locationCountryName: "Belgium",
  },
  Belize: {
    amchartCountryName: "Belize",
    countryCode: "BZ",
    ip2locationCountryName: "Belize",
  },
  Benin: {
    amchartCountryName: "Benin",
    countryCode: "BJ",
    ip2locationCountryName: "Benin",
  },
  Bermuda: {
    amchartCountryName: "Bermuda",
    countryCode: "BM",
    ip2locationCountryName: "Bermuda",
  },
  Bhutan: {
    amchartCountryName: "Bhutan",
    countryCode: "BT",
    ip2locationCountryName: "Bhutan",
  },
  "Bolivia (Plurinational State of)": {
    amchartCountryName: "Bolivia, Plurinational State of",
    countryCode: "BO",
    ip2locationCountryName: "Bolivia (Plurinational State of)",
  },
  "Bonaire, Sint Eustatius and Saba": {
    amchartCountryName: "Bonaire, Sint Eustatius and Saba",
    countryCode: "BQ",
    ip2locationCountryName: "Bonaire, Sint Eustatius and Saba",
  },
  "Bosnia and Herzegovina": {
    amchartCountryName: "Bosnia and Herzegovina",
    countryCode: "BA",
    ip2locationCountryName: "Bosnia and Herzegovina",
  },
  Botswana: {
    amchartCountryName: "Botswana",
    countryCode: "BW",
    ip2locationCountryName: "Botswana",
  },
  "Bouvet Island": {
    amchartCountryName: "Bouvet Island",
    countryCode: "BV",
    ip2locationCountryName: "Bouvet Island",
  },
  Brazil: {
    amchartCountryName: "Brazil",
    countryCode: "BR",
    ip2locationCountryName: "Brazil",
  },
  "British Indian Ocean Territory": {
    amchartCountryName: "British Indian Ocean Territory",
    countryCode: "IO",
    ip2locationCountryName: "British Indian Ocean Territory",
  },
  "Brunei Darussalam": {
    amchartCountryName: "Brunei Darussalam",
    countryCode: "BN",
    ip2locationCountryName: "Brunei Darussalam",
  },
  Bulgaria: {
    amchartCountryName: "Bulgaria",
    countryCode: "BG",
    ip2locationCountryName: "Bulgaria",
  },
  "Burkina Faso": {
    amchartCountryName: "Burkina Faso",
    countryCode: "BF",
    ip2locationCountryName: "Burkina Faso",
  },
  Burundi: {
    amchartCountryName: "Burundi",
    countryCode: "BI",
    ip2locationCountryName: "Burundi",
  },
  "Cabo Verde": {
    amchartCountryName: "Cape Verde",
    countryCode: "CV",
    ip2locationCountryName: "Cabo Verde",
  },
  Cambodia: {
    amchartCountryName: "Cambodia",
    countryCode: "KH",
    ip2locationCountryName: "Cambodia",
  },
  Cameroon: {
    amchartCountryName: "Cameroon",
    countryCode: "CM",
    ip2locationCountryName: "Cameroon",
  },
  Canada: {
    amchartCountryName: "Canada",
    countryCode: "CA",
    ip2locationCountryName: "Canada",
  },
  "Cayman Islands": {
    amchartCountryName: "Cayman Islands",
    countryCode: "KY",
    ip2locationCountryName: "Cayman Islands",
  },
  "Central African Republic": {
    amchartCountryName: "Central African Republic",
    countryCode: "CF",
    ip2locationCountryName: "Central African Republic",
  },
  Chad: {
    amchartCountryName: "Chad",
    countryCode: "TD",
    ip2locationCountryName: "Chad",
  },
  Chile: {
    amchartCountryName: "Chile",
    countryCode: "CL",
    ip2locationCountryName: "Chile",
  },
  China: {
    amchartCountryName: "China",
    countryCode: "CN",
    ip2locationCountryName: "China",
  },
  "Christmas Island": {
    amchartCountryName: "Christmas Island",
    countryCode: "CX",
    ip2locationCountryName: "Christmas Island",
  },
  "Cocos (Keeling) Islands": {
    amchartCountryName: "Cocos (Keeling) Islands",
    countryCode: "CC",
    ip2locationCountryName: "Cocos (Keeling) Islands",
  },
  Colombia: {
    amchartCountryName: "Colombia",
    countryCode: "CO",
    ip2locationCountryName: "Colombia",
  },
  Comoros: {
    amchartCountryName: "Comoros",
    countryCode: "KM",
    ip2locationCountryName: "Comoros",
  },
  Congo: {
    amchartCountryName: "Congo",
    countryCode: "CG",
    ip2locationCountryName: "Congo",
  },
  "Congo, Democratic Republic of the": {
    amchartCountryName: "Congo, the Democratic Republic of the",
    countryCode: "CD",
    ip2locationCountryName: "Congo, Democratic Republic of the",
  },
  "Cook Islands": {
    amchartCountryName: "Cook Islands",
    countryCode: "CK",
    ip2locationCountryName: "Cook Islands",
  },
  "Costa Rica": {
    amchartCountryName: "Costa Rica",
    countryCode: "CR",
    ip2locationCountryName: "Costa Rica",
  },
  "Côte d'Ivoire": {
    amchartCountryName: "Cote d'Ivoire",
    countryCode: "CI",
    ip2locationCountryName: "Côte d'Ivoire",
  },
  Croatia: {
    amchartCountryName: "Croatia",
    countryCode: "HR",
    ip2locationCountryName: "Croatia",
  },
  Cuba: {
    amchartCountryName: "Cuba",
    countryCode: "CU",
    ip2locationCountryName: "Cuba",
  },
  Curaçao: {
    amchartCountryName: "Curacao",
    countryCode: "CW",
    ip2locationCountryName: "Curaçao",
  },
  Cyprus: {
    amchartCountryName: "Cyprus",
    countryCode: "CY",
    ip2locationCountryName: "Cyprus",
  },
  Czechia: {
    amchartCountryName: "Czech Republic",
    countryCode: "CZ",
    ip2locationCountryName: "Czechia",
  },
  Denmark: {
    amchartCountryName: "Denmark",
    countryCode: "DK",
    ip2locationCountryName: "Denmark",
  },
  Djibouti: {
    amchartCountryName: "Djibouti",
    countryCode: "DJ",
    ip2locationCountryName: "Djibouti",
  },
  Dominica: {
    amchartCountryName: "Dominica",
    countryCode: "DM",
    ip2locationCountryName: "Dominica",
  },
  "Dominican Republic": {
    amchartCountryName: "Dominican Republic",
    countryCode: "DO",
    ip2locationCountryName: "Dominican Republic",
  },
  Ecuador: {
    amchartCountryName: "Ecuador",
    countryCode: "EC",
    ip2locationCountryName: "Ecuador",
  },
  Egypt: {
    amchartCountryName: "Egypt",
    countryCode: "EG",
    ip2locationCountryName: "Egypt",
  },
  "El Salvador": {
    amchartCountryName: "El Salvador",
    countryCode: "SV",
    ip2locationCountryName: "El Salvador",
  },
  "Equatorial Guinea": {
    amchartCountryName: "Equatorial Guinea",
    countryCode: "GQ",
    ip2locationCountryName: "Equatorial Guinea",
  },
  Eritrea: {
    amchartCountryName: "Eritrea",
    countryCode: "ER",
    ip2locationCountryName: "Eritrea",
  },
  Estonia: {
    amchartCountryName: "Estonia",
    countryCode: "EE",
    ip2locationCountryName: "Estonia",
  },
  Eswatini: {
    amchartCountryName: "Swaziland",
    countryCode: "SZ",
    ip2locationCountryName: "Eswatini",
  },
  Ethiopia: {
    amchartCountryName: "Ethiopia",
    countryCode: "ET",
    ip2locationCountryName: "Ethiopia",
  },
  "Falkland Islands (Malvinas)": {
    amchartCountryName: "Falkland Islands (Malvinas)",
    countryCode: "FK",
    ip2locationCountryName: "Falkland Islands (Malvinas)",
  },
  "Faroe Islands": {
    amchartCountryName: "Faroe Islands",
    countryCode: "FO",
    ip2locationCountryName: "Faroe Islands",
  },
  Fiji: {
    amchartCountryName: "Fiji",
    countryCode: "FJ",
    ip2locationCountryName: "Fiji",
  },
  Finland: {
    amchartCountryName: "Finland",
    countryCode: "FI",
    ip2locationCountryName: "Finland",
  },
  France: {
    amchartCountryName: "France",
    countryCode: "FR",
    ip2locationCountryName: "France",
  },
  "French Guiana": {
    amchartCountryName: "French Guiana",
    countryCode: "GF",
    ip2locationCountryName: "French Guiana",
  },
  "French Polynesia": {
    amchartCountryName: "French Polynesia",
    countryCode: "PF",
    ip2locationCountryName: "French Polynesia",
  },
  "French Southern Territories": {
    amchartCountryName: "French Southern Territories",
    countryCode: "TF",
    ip2locationCountryName: "French Southern Territories",
  },
  Gabon: {
    amchartCountryName: "Gabon",
    countryCode: "GA",
    ip2locationCountryName: "Gabon",
  },
  Gambia: {
    amchartCountryName: "Gambia",
    countryCode: "GM",
    ip2locationCountryName: "Gambia",
  },
  Georgia: {
    amchartCountryName: "Georgia",
    countryCode: "GE",
    ip2locationCountryName: "Georgia",
  },
  Germany: {
    amchartCountryName: "Germany",
    countryCode: "DE",
    ip2locationCountryName: "Germany",
  },
  Ghana: {
    amchartCountryName: "Ghana",
    countryCode: "GH",
    ip2locationCountryName: "Ghana",
  },
  Gibraltar: {
    amchartCountryName: "Gibraltar",
    countryCode: "GI",
    ip2locationCountryName: "Gibraltar",
  },
  Greece: {
    amchartCountryName: "Greece",
    countryCode: "GR",
    ip2locationCountryName: "Greece",
  },
  Greenland: {
    amchartCountryName: "Greenland",
    countryCode: "GL",
    ip2locationCountryName: "Greenland",
  },
  Grenada: {
    amchartCountryName: "Grenada",
    countryCode: "GD",
    ip2locationCountryName: "Grenada",
  },
  Guadeloupe: {
    amchartCountryName: "Guadeloupe",
    countryCode: "GP",
    ip2locationCountryName: "Guadeloupe",
  },
  Guam: {
    amchartCountryName: "Guam",
    countryCode: "GU",
    ip2locationCountryName: "Guam",
  },
  Guatemala: {
    amchartCountryName: "Guatemala",
    countryCode: "GT",
    ip2locationCountryName: "Guatemala",
  },
  Guernsey: {
    amchartCountryName: "Guernsey",
    countryCode: "GG",
    ip2locationCountryName: "Guernsey",
  },
  Guinea: {
    amchartCountryName: "Guinea",
    countryCode: "GN",
    ip2locationCountryName: "Guinea",
  },
  "Guinea-Bissau": {
    amchartCountryName: "Guinea-Bissau",
    countryCode: "GW",
    ip2locationCountryName: "Guinea-Bissau",
  },
  Guyana: {
    amchartCountryName: "Guyana",
    countryCode: "GY",
    ip2locationCountryName: "Guyana",
  },
  Haiti: {
    amchartCountryName: "Haiti",
    countryCode: "HT",
    ip2locationCountryName: "Haiti",
  },
  "Heard Island and McDonald Islands": {
    amchartCountryName: "Heard Island and McDonald Islands",
    countryCode: "HM",
    ip2locationCountryName: "Heard Island and McDonald Islands",
  },
  "Holy See": {
    amchartCountryName: "Holy See (Vatican City State)",
    countryCode: "VA",
    ip2locationCountryName: "Holy See",
  },
  Honduras: {
    amchartCountryName: "Honduras",
    countryCode: "HN",
    ip2locationCountryName: "Honduras",
  },
  "Hong Kong": {
    amchartCountryName: "Hong Kong",
    countryCode: "HK",
    ip2locationCountryName: "Hong Kong",
  },
  Hungary: {
    amchartCountryName: "Hungary",
    countryCode: "HU",
    ip2locationCountryName: "Hungary",
  },
  Iceland: {
    amchartCountryName: "Iceland",
    countryCode: "IS",
    ip2locationCountryName: "Iceland",
  },
  India: {
    amchartCountryName: "India",
    countryCode: "IN",
    ip2locationCountryName: "India",
  },
  Indonesia: {
    amchartCountryName: "Indonesia",
    countryCode: "ID",
    ip2locationCountryName: "Indonesia",
  },
  "Iran (Islamic Republic of)": {
    amchartCountryName: "Iran, Islamic Republic of",
    countryCode: "IR",
    ip2locationCountryName: "Iran (Islamic Republic of)",
  },
  Iraq: {
    amchartCountryName: "Iraq",
    countryCode: "IQ",
    ip2locationCountryName: "Iraq",
  },
  Ireland: {
    amchartCountryName: "Ireland",
    countryCode: "IE",
    ip2locationCountryName: "Ireland",
  },
  "Isle of Man": {
    amchartCountryName: "Isle of Man",
    countryCode: "IM",
    ip2locationCountryName: "Isle of Man",
  },
  Israel: {
    amchartCountryName: "Israel",
    countryCode: "IL",
    ip2locationCountryName: "Israel",
  },
  Italy: {
    amchartCountryName: "Italy",
    countryCode: "IT",
    ip2locationCountryName: "Italy",
  },
  Jamaica: {
    amchartCountryName: "Jamaica",
    countryCode: "JM",
    ip2locationCountryName: "Jamaica",
  },
  Japan: {
    amchartCountryName: "Japan",
    countryCode: "JP",
    ip2locationCountryName: "Japan",
  },
  Jersey: {
    amchartCountryName: "Jersey",
    countryCode: "JE",
    ip2locationCountryName: "Jersey",
  },
  Jordan: {
    amchartCountryName: "Jordan",
    countryCode: "JO",
    ip2locationCountryName: "Jordan",
  },
  Kazakhstan: {
    amchartCountryName: "Kazakhstan",
    countryCode: "KZ",
    ip2locationCountryName: "Kazakhstan",
  },
  Kenya: {
    amchartCountryName: "Kenya",
    countryCode: "KE",
    ip2locationCountryName: "Kenya",
  },
  Kiribati: {
    amchartCountryName: "Kiribati",
    countryCode: "KI",
    ip2locationCountryName: "Kiribati",
  },
  "Korea (Democratic People's Republic of)": {
    amchartCountryName: "Korea, Democratic People's Republic of",
    countryCode: "KP",
    ip2locationCountryName: "Korea (Democratic People's Republic of)",
  },
  "Korea, Republic of": {
    amchartCountryName: "Korea, Republic of",
    countryCode: "KR",
    ip2locationCountryName: "Korea, Republic of",
  },
  Kuwait: {
    amchartCountryName: "Kuwait",
    countryCode: "KW",
    ip2locationCountryName: "Kuwait",
  },
  Kyrgyzstan: {
    amchartCountryName: "Kyrgyzstan",
    countryCode: "KG",
    ip2locationCountryName: "Kyrgyzstan",
  },
  "Lao People's Democratic Republic": {
    amchartCountryName: "Lao People's Democratic Republic",
    countryCode: "LA",
    ip2locationCountryName: "Lao People's Democratic Republic",
  },
  Latvia: {
    amchartCountryName: "Latvia",
    countryCode: "LV",
    ip2locationCountryName: "Latvia",
  },
  Lebanon: {
    amchartCountryName: "Lebanon",
    countryCode: "LB",
    ip2locationCountryName: "Lebanon",
  },
  Lesotho: {
    amchartCountryName: "Lesotho",
    countryCode: "LS",
    ip2locationCountryName: "Lesotho",
  },
  Liberia: {
    amchartCountryName: "Liberia",
    countryCode: "LR",
    ip2locationCountryName: "Liberia",
  },
  Libya: {
    amchartCountryName: "Libya",
    countryCode: "LY",
    ip2locationCountryName: "Libya",
  },
  Liechtenstein: {
    amchartCountryName: "Liechtenstein",
    countryCode: "LI",
    ip2locationCountryName: "Liechtenstein",
  },
  Lithuania: {
    amchartCountryName: "Lithuania",
    countryCode: "LT",
    ip2locationCountryName: "Lithuania",
  },
  Luxembourg: {
    amchartCountryName: "Luxembourg",
    countryCode: "LU",
    ip2locationCountryName: "Luxembourg",
  },
  Macao: {
    amchartCountryName: "Macao",
    countryCode: "MO",
    ip2locationCountryName: "Macao",
  },
  Madagascar: {
    amchartCountryName: "Madagascar",
    countryCode: "MG",
    ip2locationCountryName: "Madagascar",
  },
  Malawi: {
    amchartCountryName: "Malawi",
    countryCode: "MW",
    ip2locationCountryName: "Malawi",
  },
  Malaysia: {
    amchartCountryName: "Malaysia",
    countryCode: "MY",
    ip2locationCountryName: "Malaysia",
  },
  Maldives: {
    amchartCountryName: "Maldives",
    countryCode: "MV",
    ip2locationCountryName: "Maldives",
  },
  Mali: {
    amchartCountryName: "Mali",
    countryCode: "ML",
    ip2locationCountryName: "Mali",
  },
  Malta: {
    amchartCountryName: "Malta",
    countryCode: "MT",
    ip2locationCountryName: "Malta",
  },
  "Marshall Islands": {
    amchartCountryName: "Marshall Islands",
    countryCode: "MH",
    ip2locationCountryName: "Marshall Islands",
  },
  Martinique: {
    amchartCountryName: "Martinique",
    countryCode: "MQ",
    ip2locationCountryName: "Martinique",
  },
  Mauritania: {
    amchartCountryName: "Mauritania",
    countryCode: "MR",
    ip2locationCountryName: "Mauritania",
  },
  Mauritius: {
    amchartCountryName: "Mauritius",
    countryCode: "MU",
    ip2locationCountryName: "Mauritius",
  },
  Mayotte: {
    amchartCountryName: "Mayotte",
    countryCode: "YT",
    ip2locationCountryName: "Mayotte",
  },
  Mexico: {
    amchartCountryName: "Mexico",
    countryCode: "MX",
    ip2locationCountryName: "Mexico",
  },
  "Micronesia (Federated States of)": {
    amchartCountryName: "Micronesia, Federated States of",
    countryCode: "FM",
    ip2locationCountryName: "Micronesia (Federated States of)",
  },
  "Moldova, Republic of": {
    amchartCountryName: "Moldova, Republic of",
    countryCode: "MD",
    ip2locationCountryName: "Moldova, Republic of",
  },
  Monaco: {
    amchartCountryName: "Monaco",
    countryCode: "MC",
    ip2locationCountryName: "Monaco",
  },
  Mongolia: {
    amchartCountryName: "Mongolia",
    countryCode: "MN",
    ip2locationCountryName: "Mongolia",
  },
  Montenegro: {
    amchartCountryName: "Montenegro",
    countryCode: "ME",
    ip2locationCountryName: "Montenegro",
  },
  Montserrat: {
    amchartCountryName: "Montserrat",
    countryCode: "MS",
    ip2locationCountryName: "Montserrat",
  },
  Morocco: {
    amchartCountryName: "Morocco",
    countryCode: "MA",
    ip2locationCountryName: "Morocco",
  },
  Mozambique: {
    amchartCountryName: "Mozambique",
    countryCode: "MZ",
    ip2locationCountryName: "Mozambique",
  },
  Myanmar: {
    amchartCountryName: "Myanmar",
    countryCode: "MM",
    ip2locationCountryName: "Myanmar",
  },
  Namibia: {
    amchartCountryName: "Namibia",
    countryCode: "NA",
    ip2locationCountryName: "Namibia",
  },
  Nauru: {
    amchartCountryName: "Nauru",
    countryCode: "NR",
    ip2locationCountryName: "Nauru",
  },
  Nepal: {
    amchartCountryName: "Nepal",
    countryCode: "NP",
    ip2locationCountryName: "Nepal",
  },
  Netherlands: {
    amchartCountryName: "Netherlands",
    countryCode: "NL",
    ip2locationCountryName: "Netherlands",
  },
  "New Caledonia": {
    amchartCountryName: "New Caledonia",
    countryCode: "NC",
    ip2locationCountryName: "New Caledonia",
  },
  "New Zealand": {
    amchartCountryName: "New Zealand",
    countryCode: "NZ",
    ip2locationCountryName: "New Zealand",
  },
  Nicaragua: {
    amchartCountryName: "Nicaragua",
    countryCode: "NI",
    ip2locationCountryName: "Nicaragua",
  },
  Niger: {
    amchartCountryName: "Niger",
    countryCode: "NE",
    ip2locationCountryName: "Niger",
  },
  Nigeria: {
    amchartCountryName: "Nigeria",
    countryCode: "NG",
    ip2locationCountryName: "Nigeria",
  },
  Niue: {
    amchartCountryName: "Niue",
    countryCode: "NU",
    ip2locationCountryName: "Niue",
  },
  "Norfolk Island": {
    amchartCountryName: "Norfolk Island",
    countryCode: "NF",
    ip2locationCountryName: "Norfolk Island",
  },
  "North Macedonia": {
    amchartCountryName: "North Macedonia",
    countryCode: "MK",
    ip2locationCountryName: "North Macedonia",
  },
  "Northern Mariana Islands": {
    amchartCountryName: "Northern Mariana Islands",
    countryCode: "MP",
    ip2locationCountryName: "Northern Mariana Islands",
  },
  Norway: {
    amchartCountryName: "Norway",
    countryCode: "NO",
    ip2locationCountryName: "Norway",
  },
  Oman: {
    amchartCountryName: "Oman",
    countryCode: "OM",
    ip2locationCountryName: "Oman",
  },
  Pakistan: {
    amchartCountryName: "Pakistan",
    countryCode: "PK",
    ip2locationCountryName: "Pakistan",
  },
  Palau: {
    amchartCountryName: "Palau",
    countryCode: "PW",
    ip2locationCountryName: "Palau",
  },
  "Palestine, State of": {
    amchartCountryName: "Palestinian, State of",
    countryCode: "PS",
    ip2locationCountryName: "Palestine, State of",
  },
  Panama: {
    amchartCountryName: "Panama",
    countryCode: "PA",
    ip2locationCountryName: "Panama",
  },
  "Papua New Guinea": {
    amchartCountryName: "Papua New Guinea",
    countryCode: "PG",
    ip2locationCountryName: "Papua New Guinea",
  },
  Paraguay: {
    amchartCountryName: "Paraguay",
    countryCode: "PY",
    ip2locationCountryName: "Paraguay",
  },
  Peru: {
    amchartCountryName: "Peru",
    countryCode: "PE",
    ip2locationCountryName: "Peru",
  },
  Philippines: {
    amchartCountryName: "Philippines",
    countryCode: "PH",
    ip2locationCountryName: "Philippines",
  },
  Pitcairn: {
    amchartCountryName: "Pitcairn",
    countryCode: "PN",
    ip2locationCountryName: "Pitcairn",
  },
  Poland: {
    amchartCountryName: "Poland",
    countryCode: "PL",
    ip2locationCountryName: "Poland",
  },
  Portugal: {
    amchartCountryName: "Portugal",
    countryCode: "PT",
    ip2locationCountryName: "Portugal",
  },
  "Puerto Rico": {
    amchartCountryName: "Puerto Rico",
    countryCode: "PR",
    ip2locationCountryName: "Puerto Rico",
  },
  Qatar: {
    amchartCountryName: "Qatar",
    countryCode: "QA",
    ip2locationCountryName: "Qatar",
  },
  Réunion: {
    amchartCountryName: "Reunion",
    countryCode: "RE",
    ip2locationCountryName: "Réunion",
  },
  Romania: {
    amchartCountryName: "Romania",
    countryCode: "RO",
    ip2locationCountryName: "Romania",
  },
  "Russian Federation": {
    amchartCountryName: "Russian Federation",
    countryCode: "RU",
    ip2locationCountryName: "Russian Federation",
  },
  Rwanda: {
    amchartCountryName: "Rwanda",
    countryCode: "RW",
    ip2locationCountryName: "Rwanda",
  },
  "Saint Barthélemy": {
    amchartCountryName: "Saint Barthelemy",
    countryCode: "BL",
    ip2locationCountryName: "Saint Barthélemy",
  },
  "Saint Helena, Ascension and Tristan da Cunha": {
    amchartCountryName: "Saint Helena, Ascension and Tristan da Cunha",
    countryCode: "SH",
    ip2locationCountryName: "Saint Helena, Ascension and Tristan da Cunha",
  },
  "Saint Kitts and Nevis": {
    amchartCountryName: "Saint Kitts and Nevis",
    countryCode: "KN",
    ip2locationCountryName: "Saint Kitts and Nevis",
  },
  "Saint Lucia": {
    amchartCountryName: "Saint Lucia",
    countryCode: "LC",
    ip2locationCountryName: "Saint Lucia",
  },
  "Saint Martin (French part)": {
    amchartCountryName: "Saint Martin (French Part)",
    countryCode: "MF",
    ip2locationCountryName: "Saint Martin (French part)",
  },
  "Saint Pierre and Miquelon": {
    amchartCountryName: "Saint Pierre and Miquelon",
    countryCode: "PM",
    ip2locationCountryName: "Saint Pierre and Miquelon",
  },
  "Saint Vincent and the Grenadines": {
    amchartCountryName: "Saint Vincent and the Grenadines",
    countryCode: "VC",
    ip2locationCountryName: "Saint Vincent and the Grenadines",
  },
  Samoa: {
    amchartCountryName: "Samoa",
    countryCode: "WS",
    ip2locationCountryName: "Samoa",
  },
  "San Marino": {
    amchartCountryName: "San Marino",
    countryCode: "SM",
    ip2locationCountryName: "San Marino",
  },
  "Sao Tome and Principe": {
    amchartCountryName: "Sao Tome and Principe",
    countryCode: "ST",
    ip2locationCountryName: "Sao Tome and Principe",
  },
  "Saudi Arabia": {
    amchartCountryName: "Saudi Arabia",
    countryCode: "SA",
    ip2locationCountryName: "Saudi Arabia",
  },
  Senegal: {
    amchartCountryName: "Senegal",
    countryCode: "SN",
    ip2locationCountryName: "Senegal",
  },
  Serbia: {
    amchartCountryName: "Serbia",
    countryCode: "RS",
    ip2locationCountryName: "Serbia",
  },
  Seychelles: {
    amchartCountryName: "Seychelles",
    countryCode: "SC",
    ip2locationCountryName: "Seychelles",
  },
  "Sierra Leone": {
    amchartCountryName: "Sierra Leone",
    countryCode: "SL",
    ip2locationCountryName: "Sierra Leone",
  },
  Singapore: {
    amchartCountryName: "Singapore",
    countryCode: "SG",
    ip2locationCountryName: "Singapore",
  },
  "Sint Maarten (Dutch part)": {
    amchartCountryName: "Sint Maarten (Dutch Part)",
    countryCode: "SX",
    ip2locationCountryName: "Sint Maarten (Dutch part)",
  },
  Slovakia: {
    amchartCountryName: "Slovakia",
    countryCode: "SK",
    ip2locationCountryName: "Slovakia",
  },
  Slovenia: {
    amchartCountryName: "Slovenia",
    countryCode: "SI",
    ip2locationCountryName: "Slovenia",
  },
  "Solomon Islands": {
    amchartCountryName: "Solomon Islands",
    countryCode: "SB",
    ip2locationCountryName: "Solomon Islands",
  },
  Somalia: {
    amchartCountryName: "Somalia",
    countryCode: "SO",
    ip2locationCountryName: "Somalia",
  },
  "South Africa": {
    amchartCountryName: "South Africa",
    countryCode: "ZA",
    ip2locationCountryName: "South Africa",
  },
  "South Georgia and the South Sandwich Islands": {
    amchartCountryName: "South Georgia and the South Sandwich Islands",
    countryCode: "GS",
    ip2locationCountryName: "South Georgia and the South Sandwich Islands",
  },
  "South Sudan": {
    amchartCountryName: "South Sudan",
    countryCode: "SS",
    ip2locationCountryName: "South Sudan",
  },
  Spain: {
    amchartCountryName: "Spain",
    countryCode: "ES",
    ip2locationCountryName: "Spain",
  },
  "Sri Lanka": {
    amchartCountryName: "Sri Lanka",
    countryCode: "LK",
    ip2locationCountryName: "Sri Lanka",
  },
  Sudan: {
    amchartCountryName: "Sudan",
    countryCode: "SD",
    ip2locationCountryName: "Sudan",
  },
  Suriname: {
    amchartCountryName: "Suriname",
    countryCode: "SR",
    ip2locationCountryName: "Suriname",
  },
  "Svalbard and Jan Mayen": {
    amchartCountryName: "Svalbard and Jan Mayen",
    countryCode: "SJ",
    ip2locationCountryName: "Svalbard and Jan Mayen",
  },
  Sweden: {
    amchartCountryName: "Sweden",
    countryCode: "SE",
    ip2locationCountryName: "Sweden",
  },
  Switzerland: {
    amchartCountryName: "Switzerland",
    countryCode: "CH",
    ip2locationCountryName: "Switzerland",
  },
  "Syrian Arab Republic": {
    amchartCountryName: "Syrian Arab Republic",
    countryCode: "SY",
    ip2locationCountryName: "Syrian Arab Republic",
  },
  "Taiwan, Province of China": {
    amchartCountryName: "Taiwan",
    countryCode: "TW",
    ip2locationCountryName: "Taiwan, Province of China",
  },
  Tajikistan: {
    amchartCountryName: "Tajikistan",
    countryCode: "TJ",
    ip2locationCountryName: "Tajikistan",
  },
  "Tanzania, United Republic of": {
    amchartCountryName: "Tanzania, United Republic of",
    countryCode: "TZ",
    ip2locationCountryName: "Tanzania, United Republic of",
  },
  Thailand: {
    amchartCountryName: "Thailand",
    countryCode: "TH",
    ip2locationCountryName: "Thailand",
  },
  "Timor-Leste": {
    amchartCountryName: "Timor-Leste",
    countryCode: "TL",
    ip2locationCountryName: "Timor-Leste",
  },
  Togo: {
    amchartCountryName: "Togo",
    countryCode: "TG",
    ip2locationCountryName: "Togo",
  },
  Tokelau: {
    amchartCountryName: "Tokelau",
    countryCode: "TK",
    ip2locationCountryName: "Tokelau",
  },
  Tonga: {
    amchartCountryName: "Tonga",
    countryCode: "TO",
    ip2locationCountryName: "Tonga",
  },
  "Trinidad and Tobago": {
    amchartCountryName: "Trinidad and Tobago",
    countryCode: "TT",
    ip2locationCountryName: "Trinidad and Tobago",
  },
  Tunisia: {
    amchartCountryName: "Tunisia",
    countryCode: "TN",
    ip2locationCountryName: "Tunisia",
  },
  Turkey: {
    amchartCountryName: "Turkey",
    countryCode: "TR",
    ip2locationCountryName: "Turkey",
  },
  Turkmenistan: {
    amchartCountryName: "Turkmenistan",
    countryCode: "TM",
    ip2locationCountryName: "Turkmenistan",
  },
  "Turks and Caicos Islands": {
    amchartCountryName: "Turks and Caicos Islands",
    countryCode: "TC",
    ip2locationCountryName: "Turks and Caicos Islands",
  },
  Tuvalu: {
    amchartCountryName: "Tuvalu",
    countryCode: "TV",
    ip2locationCountryName: "Tuvalu",
  },
  Uganda: {
    amchartCountryName: "Uganda",
    countryCode: "UG",
    ip2locationCountryName: "Uganda",
  },
  Ukraine: {
    amchartCountryName: "Ukraine",
    countryCode: "UA",
    ip2locationCountryName: "Ukraine",
  },
  "United Arab Emirates": {
    amchartCountryName: "United Arab Emirates",
    countryCode: "AE",
    ip2locationCountryName: "United Arab Emirates",
  },
  "United Kingdom of Great Britain and Northern Ireland": {
    amchartCountryName: "United Kingdom",
    countryCode: "GB",
    ip2locationCountryName: "United Kingdom of Great Britain and Northern Ireland",
  },
  "United States of America": {
    amchartCountryName: "United States",
    countryCode: "US",
    ip2locationCountryName: "United States of America",
  },
  "United States Minor Outlying Islands": {
    amchartCountryName: "United States Minor Outlying Islands",
    countryCode: "UM",
    ip2locationCountryName: "United States Minor Outlying Islands",
  },
  Uruguay: {
    amchartCountryName: "Uruguay",
    countryCode: "UY",
    ip2locationCountryName: "Uruguay",
  },
  Uzbekistan: {
    amchartCountryName: "Uzbekistan",
    countryCode: "UZ",
    ip2locationCountryName: "Uzbekistan",
  },
  Vanuatu: {
    amchartCountryName: "Vanuatu",
    countryCode: "VU",
    ip2locationCountryName: "Vanuatu",
  },
  "Venezuela (Bolivarian Republic of)": {
    amchartCountryName: "Venezuela, Bolivarian Republic of",
    countryCode: "VE",
    ip2locationCountryName: "Venezuela (Bolivarian Republic of)",
  },
  "Viet Nam": {
    amchartCountryName: "Viet Nam",
    countryCode: "VN",
    ip2locationCountryName: "Viet Nam",
  },
  "Virgin Islands (British)": {
    amchartCountryName: "Virgin Islands, British",
    countryCode: "VG",
    ip2locationCountryName: "Virgin Islands (British)",
  },
  "Virgin Islands (U.S.)": {
    amchartCountryName: "Virgin Islands, U.S.",
    countryCode: "VI",
    ip2locationCountryName: "Virgin Islands (U.S.)",
  },
  "Wallis and Futuna": {
    amchartCountryName: "Wallis and Futuna",
    countryCode: "WF",
    ip2locationCountryName: "Wallis and Futuna",
  },
  "Western Sahara": {
    amchartCountryName: "Western Sahara",
    countryCode: "EH",
    ip2locationCountryName: "Western Sahara",
  },
  Yemen: {
    amchartCountryName: "Yemen",
    countryCode: "YE",
    ip2locationCountryName: "Yemen",
  },
  Zambia: {
    amchartCountryName: "Zambia",
    countryCode: "ZM",
    ip2locationCountryName: "Zambia",
  },
  Zimbabwe: {
    amchartCountryName: "Zimbabwe",
    countryCode: "ZW",
    ip2locationCountryName: "Zimbabwe",
  },
};

export const snackBarPosition = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export const ERROR = "error";
export const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
