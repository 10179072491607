import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { Link as Links } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { register } from '../actions/userActions'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { CircularProgress } from '@material-ui/core'
import logo from './../assets/images/logo.jpeg'


import axios from "axios";
import { BASE_URL } from "../constants/apiconstant";
import { getHeaders } from "../utils";
import { useSnackbar } from "notistack";
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function UserSignUp() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)
  const classes = useStyles()

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo, isNormalUser } = userRegister

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const submitHandler = async (e) => {
    console.log("submitHandler Is loading==>", loading)
    e.preventDefault()
    console.log('helo')
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
      setTimeout(() => {
        setMessage('')
      }, 2000)
    } else {
      try {
        // const { data } = await axios.post(
        //   BASE_URL + "verifyContactUser/",
        //   { email: email }
        // );
  
        // console.log("verifyContactUser Checking...==>", data)
        // if(data.success) {
          console.log("data.success userInfo==>", userInfo)
          const data = await axios.post(
            BASE_URL + "AllowUserToSignUp/",
            {email},
          );
          if(data.data.allow_to_signup){
          dispatch(register(name, email, password, true))
          console.log("dispatch userInfo==>", userInfo)
          if (userInfo?.result == 'User Already Exist') {
            console.log("User Exist userInfo==>", userInfo)
            toast.error(userInfo?.result, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            console.log("Redirected to Signin");
            localStorage.setItem("email", email);
            history.push("/verify-otp");
            enqueueSnackbar("You can now successfully login", { variant: "success" });
          }
        } else {
          enqueueSnackbar(
            "You cannot sign up because you are not invited.",
            { variant: "error" }
          );
        }
        // } else {
        //   enqueueSnackbar("User email does not exsist", { variant: "error" });
        // }
      } catch (error) {
        console.log("Signup error", error);
        enqueueSnackbar(
          "Something went wrong, please try again after some time",
          { variant: "error" }
        );
      }
    }
  }

  if (message) {
    toast.error(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  useEffect(() => {
    console.log('user', userInfo)
  }, [userInfo])
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <ToastContainer />
      <div className={classes.paper}>
        <img style={{ width: "7rem", borderRadius: "50%" }} alt="" src={logo} />
        {/* <Typography component='h1' variant='h5'>
          Sign up
        </Typography> */}
        <form className={classes.form} onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete='name'
                type='name'
                name='name'
                variant='outlined'
                required
                fullWidth
                id='name'
                label='Name'
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                type='email'
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='password'
                label='Enter Password'
                type='password'
                id='password'
                autoComplete='current-password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='confirmPassword'
                label='Confirm Password'
                type='password'
                id='confirmPassword'
                autoComplete='current-password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid> */}
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
            className={classes.submit}
            disabled={loading}
          >
            {
              loading
                ? <CircularProgress style={{color:"white"}} size={24} />
                : 'Sign Up'
            }
          </Button>
          <div style={{textAlign:"end", marginTop:"-8px", fontSize:"11px"}}> <a href="https://ahrvo.com/data-privacy-policy/" style={{textDecoration:"underline", fontWeight:"600"}}>Privacy and Data Protection Policy</a> and <a href="https://ahrvo.com/terms-of-service/" style={{textDecoration:"underline", fontWeight:"600"}}>Terms of Services</a></div>
          <Grid style={{ marginTop: 6 }} container justify='flex-end'>
            <Grid item>
              <Links to='/user-login'>Already have an account? Sign in</Links>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}
