import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "../assets/css/dashlite.css";
import "../assets/css/theme.css";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import { BASE_URL } from "../constants/apiconstant";
import { getHeaders } from "../utils";
import { useSnackbar } from "notistack";
import moment from "moment";
import Paginator from "../components/Pagination";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

export default function EsignDocInfo() {
  const [search, setSearch] = React.useState(false);
  const [filter, setFilter] = React.useState(false);
  const [setting, setSetting] = React.useState(false);
  const [info, setInfo] = useState([]);
  const [esignDoc, setEsignDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [pageCount, setPageCount] = useState([]);
  const [csvGenerateLoading, setCsvGenerateLoading] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [showExportDiv, setShowExportDiv] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("created_at");
  const [showFilterDiv, setShowFilterDiv] = useState(false);

  const [bodyWidth, setBodyWidth] = React.useState(false);
  useEffect(() => {
    getInfo(page, rowsPerPage, order, selectedSortColumn);
  }, []);

  const useStyles = makeStyles((theme) => ({
    rooot: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const getInfo = async (_page, rowsPerPage, sortOrder, sortColumn) => {
    try {
      /*  const urlParams = new URLSearchParams(window.location.search);
      const document_id = urlParams.get("document_id"); */
      if (_page !== page) setPage(_page);
      const { data } = await axios.get(
        BASE_URL +
          `get-device-info?page=${_page}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`,
        getHeaders()
      );
      console.log("dataaaa", data);
      setInfo(data.data);
      setPageCount(data.count);
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong while getting the info", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    setLoading(false);
  };
  const generateCsv = async () => {
    try {
      setCsvGenerateLoading(true);
      const { data } = await axios.get(
        BASE_URL + "generateCsvForDeviceAndLocation",
        getHeaders()
      );

      console.log("data", data);
      axios({
        url: BASE_URL + "media/" + data.csvFileName, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "deviceAndLocation.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went while generating csv file", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    setCsvGenerateLoading(false);
  };

  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };
  const getSortOrder = (selected)=> {
    
    let sortOrder = order
    if (selectedSortColumn === selected) {
      if (order === "ASC") {
        sortOrder = "DESC"
      }
      else {
        sortOrder = "ASC"
      }
    }
    else {
      sortOrder = "DESC"
    }
    setOrder(sortOrder)
    return sortOrder
  }

  return (
    <div className="p-2">
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            <div className="nk-wrap ">
              <Navbar />

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div
                      className="nk-fmg"
                      style={bodyWidth ? { paddingLeft: 80 } : null}
                    >
                      <Sidebar getState={sidebarState} />

                      <div className="nk-fmg-body">
                        <div className="nk-block">
                          <div className="my-3 row">
                            <h3
                              className="col-lg-8 nunito-font"
                              style={{ fontSize: "1.75rem", marginBottom: 0 }}
                            >
                              Document Audit Trail
                            </h3>
                            <div className="col-lg-4 text-right">
                              <Link
                                to={"/dashboard"}
                                className="btn btn-outline-light text-white d-none d-sm-inline-flex"
                                style={{ backgroundColor: 'rgb(20, 155, 227)'}}
                              >
                                <em className="icon ni ni-arrow-left"></em>
                                <span>Back</span>
                              </Link>
                            </div>
                          </div>
                          <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                              <div className="card-inner position-relative card-tools-toggle">
                                <div
                                  class={
                                    "card-title-group " +
                                    (search ? "d-none" : null)
                                  }
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <div className="card-tools mr-n1">
                                    <ul className="btn-toolbar gx-1">
                                      <li>
                                        <div className="toggle-wrap d-block">
                                          <a
                                            href="#"
                                            className="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                          >
                                            <em className="icon ni ni-menu-right"></em>
                                          </a>
                                          <div
                                            className="toggle-content"
                                            data-content="cardTools"
                                          >
                                            <ul className="btn-toolbar gx-1">
                                              <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() =>
                                                      setShowExportDiv(
                                                        !showExportDiv
                                                      )
                                                    }
                                                    data-toggle="dropdown11"
                                                  >
                                                    <em className="icon ni ni-filter-alt"></em>
                                                  </a>
                                                  <div
                                                    style={{
                                                      display: showExportDiv
                                                        ? "block"
                                                        : "none",
                                                    }}
                                                    class={
                                                      "filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right " +
                                                      (filter
                                                        ? "d-block"
                                                        : null)
                                                    }
                                                  >
                                                    <div className="dropdown-head">
                                                      {csvGenerateLoading ? (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <CircularProgress
                                                            style={{color:"rgb(20, 155, 227)"}}
                                                            size={20}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <span
                                                          onClick={generateCsv}
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="sub-title dropdown-title"
                                                        >
                                                          Export CSV
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() =>
                                                      setShowFilterDiv(
                                                        !showFilterDiv
                                                      )
                                                    }
                                                    data-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-setting"></em>
                                                  </a>
                                                  <div
                                                    style={{
                                                      display: showFilterDiv
                                                        ? "block"
                                                        : "none",
                                                        minWidth:"auto"
                                                    }}
                                                    class={
                                                      "filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right " +
                                                      (filter
                                                        ? "d-block"
                                                        : null)
                                                    }
                                                  >
                                                    <div className="dropdown-head">
                                                      <span className="sub-title dropdown-title">
                                                        Filter
                                                      </span>
                                                      <div className="dropdown">
                                                        <a
                                                          href="#"
                                                          className="btn btn-sm btn-icon"
                                                        >
                                                          <em className="icon ni ni-more-h"></em>
                                                        </a>
                                                      </div>
                                                    </div>
                                                    <div className="dropdown-body dropdown-body-rg">
                                                      
                                                          <div className="form-group">
                                                            <label className="overline-title overline-title-alt">
                                                              Size
                                                            </label>
                                                            <ul className="link-check">
                                                              <li
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                class={
                                                                  rowsPerPage ===
                                                                    20 &&
                                                                  "active"
                                                                }
                                                                onClick={() => {
                                                                  setRowsPerPage(
                                                                    20
                                                                  );
                                                                  getInfo(
                                                                    1,
                                                                    20,
                                                                    order,
                                                                    selectedSortColumn
                                                                  );
                                                                }}
                                                              >
                                                                <a
                                                                  style={{
                                                                    paddingLeft: 0,
                                                                  }}
                                                                >
                                                                  20
                                                                </a>
                                                              </li>
                                                              <li
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  setRowsPerPage(
                                                                    50
                                                                  );
                                                                  getInfo(
                                                                    1,
                                                                    50,
                                                                    order,
                                                                    selectedSortColumn
                                                                  );
                                                                }}
                                                                class={
                                                                  rowsPerPage ===
                                                                    50 &&
                                                                  "active"
                                                                }
                                                              >
                                                                <a
                                                                  style={{
                                                                    paddingLeft: 0,
                                                                  }}
                                                                >
                                                                  50
                                                                </a>
                                                              </li>
                                                              <li
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  getInfo(
                                                                    1,
                                                                    100,
                                                                    order,
                                                                    selectedSortColumn
                                                                  );
                                                                  setRowsPerPage(
                                                                    100
                                                                  );
                                                                }}
                                                                class={
                                                                  rowsPerPage ===
                                                                    100 &&
                                                                  "active"
                                                                }
                                                              >
                                                                <a
                                                                  style={{
                                                                    paddingLeft: 0,
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  100
                                                                </a>
                                                              </li>
                                                            </ul>
                                                        
                                                        {/*   <div className="col-12">
                                                          <div className="form-group">
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary"
                                                            >
                                                              Filter
                                                            </button>
                                                          </div>
                                                        </div> */}
                                                      </div>
                                                    </div>
                                                    {/*   <div className="dropdown-foot between">
                                                      <a
                                                        className="clickable"
                                                        href="#"
                                                      >
                                                        Reset Filter
                                                      </a>
                                                      <a href="#">
                                                        Save Filter
                                                      </a>
                                                    </div> */}
                                                  </div>
                                                </div>

                                                {/*       <div className="dropdown"> */}
                                                {/*  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() =>
                                                      setSetting(!setting)
                                                    }
                                                    data-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-setting"></em>
                                                  </a> */}

                                                {/*     <div
                                                    class={
                                                      "dropdown-menu dropdown-menu-xs dropdown-menu-right " +
                                                      (setting
                                                        ? "d-block"
                                                        : null)
                                                    }
                                                  >
                                                    <ul className="link-check">
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        class={
                                                          rowsPerPage === 20 &&
                                                          "active"
                                                        }
                                                        onClick={() => {
                                                          setRowsPerPage(20);
                                                          getInfo(1, 20);
                                                        }}
                                                      >
                                                        <a>20</a>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setRowsPerPage(50);
                                                          getInfo(1, 50);
                                                        }}
                                                        class={
                                                          rowsPerPage === 50 &&
                                                          "active"
                                                        }
                                                      >
                                                        <a>50</a>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          getInfo(1, 100);
                                                          setRowsPerPage(100);
                                                        }}
                                                        class={
                                                          rowsPerPage === 100 &&
                                                          "active"
                                                        }
                                                      >
                                                        <a>100</a>
                                                      </li>


                                                    </ul>
                                                  </div>
                                               */}
                                                {/*  </div> */}
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  class={
                                    "card-search search-wrap " +
                                    (search ? "active" : null)
                                  }
                                  data-search="search"
                                >
                                  <div className="card-body">
                                    <div className="search-content">
                                      <a
                                        href="#"
                                        className="search-back btn btn-icon toggle-search"
                                        onClick={() => setSearch(false)}
                                        data-target="search"
                                      >
                                        <em className="icon ni ni-arrow-left"></em>
                                      </a>
                                      <input
                                        type="text"
                                        className="form-control border-transparent form-focus-none"
                                        placeholder="Search by user or email"
                                      />
                                      <button className="search-submit btn btn-icon">
                                        <em className="icon ni ni-search"></em>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist is-compact">
                                  <div className="nk-tb-item nk-tb-head">
                                    {/*  <div className="nk-tb-col p-3">
                                      <span className="sub-text">ID</span>
                                    </div> */}
                                    <div className="nk-tb-col p-3 tb-col-md"
                                   onClick={(e) => {
                                    getInfo(
                                      1,
                                      20,
                                     getSortOrder("document"),
                                      "document"
                                      );
                                      setSelectedSortColumn(
                                       "document"
                                      );
                                     }}
                                    >
                                      <span className="sub-text" style={{cursor:"pointer"}}>
                                        DOCUMENT NAME {selectedSortColumn === "document" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}
                                      </span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-sm"
                                     onClick={(e) => {
                                      getInfo(
                                        1,
                                        20,
                                       getSortOrder("ip"),
                                        "ip"
                                        );
                                        setSelectedSortColumn(
                                         "ip"
                                        );
                                       }}
                                    >
                                      <span className="sub-text"
                                      style={{cursor:"pointer"}}>IP {selectedSortColumn === "ip" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-md"
                                     onClick={(e) => {
                                      getInfo(
                                        1,
                                        20,
                                       getSortOrder("country"),
                                        "country"
                                        );
                                        setSelectedSortColumn(
                                         "country"
                                        );
                                       }}
                                    >
                                      <span className="sub-text" style={{cursor:"pointer"}}>COUNTRY {selectedSortColumn === "country" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}</span>
                                    </div>

                                    <div className="nk-tb-col p-3 tb-col-md"
                                      onClick={(e) => {
                                        getInfo(
                                          1,
                                          20,
                                         getSortOrder("region"),
                                          "region"
                                          );
                                          setSelectedSortColumn(
                                           "region"
                                          );
                                         }}
                                    >
                                      <span className="sub-text" style={{cursor:"pointer"}}>REGION {selectedSortColumn === "region" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}</span>
                                    </div>

                                    <div className="nk-tb-col p-3 tb-col-md"
                                    
                                    onClick={(e) => {
                                      getInfo(
                                        1,
                                        20,
                                       getSortOrder("city"),
                                        "city"
                                        );
                                        setSelectedSortColumn(
                                         "city"
                                        );
                                       }}
                                    >
                                      <span className="sub-text" style={{cursor:"pointer"}}>CITY {selectedSortColumn === "city" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-md"
                                     onClick={(e) => {
                                      getInfo(
                                        1,
                                        20,
                                       getSortOrder("os_name"),
                                        "os_name"
                                        );
                                        setSelectedSortColumn(
                                         "os_name"
                                        );
                                       }}
                                    
                                    >
                                      <span className="sub-text" style={{cursor:"pointer"}}>
                                        OPERATING SYSTEM {selectedSortColumn === "os_name" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}
                                      </span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-md"
                                     onClick={(e) => {
                                      getInfo(
                                        1,
                                        20,
                                       getSortOrder("device_type"),
                                        "device_type"
                                        );
                                        setSelectedSortColumn(
                                         "device_type"
                                        );
                                       }}
                                    >
                                      <span className="sub-text" style={{cursor:"pointer"}}>DEVICE TYPE {selectedSortColumn === "device_type" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-md"
                                     onClick={(e) => {
                                      getInfo(
                                        1,
                                        20,
                                       getSortOrder("browser"),
                                        "browser"
                                        );
                                        setSelectedSortColumn(
                                         "browser"
                                        );
                                       }}
                                    >
                                      <span className="sub-text" style={{cursor:"pointer"}}>BROWSER {selectedSortColumn === "browser" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-md"
                                     onClick={(e) => {
                                      getInfo(
                                        1,
                                        20,
                                       getSortOrder("created_at"),
                                        "created_at"
                                        );
                                        setSelectedSortColumn(
                                         "created_at"
                                        );
                                       }}
                                    >
                                      <span className="sub-text" style={{cursor:"pointer"}}>DATE {selectedSortColumn === "created_at" ? order==="ASC" ?  <ArrowUpward /> : <ArrowDownward /> :null}</span>
                                    </div>
                                    <div className="nk-tb-col nk-tb-col-tools text-right"></div>
                                  </div>
                                  {loading ? (
                                    <div className={classes.rooot}>
                                      <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                                    </div>
                                  ) : (
                                    info.map((i, index) => (
                                      <div className="nk-tb-item">
                                        {/* <div className="nk-tb-col">
                                          <span>{index + 1}</span>
                                        </div> */}
                                        <div className="nk-tb-col tb-col-md">
                                          <span>
                                            {i.document.originalFilename || "-"}
                                          </span>
                                        </div>

                                        <div className="nk-tb-col tb-col-md">
                                          <span>{i.ip || "-"}</span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span>{i.country || "-"}</span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span>{i.region || "-"}</span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span>{i.city || "-"}</span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span>{i.os_name || "-"}</span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span>{i.device_type || "-"}</span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span>{i.browser || "-"}</span>
                                        </div>

                                        <div className="nk-tb-col tb-col-lg">
                                          <span>
                                            {moment(i.created_at).format(
                                              "MMM-DD-YYYY hh:mma"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                              {info.length === 0 && (
                                <div>
                                  <p
                                    style={{
                                      margin: "1rem",
                                      textAlign: "center",
                                      width: "100%",
                                    }}
                                  >
                                    No Information Found
                                  </p>
                                </div>
                              )}

                              <div className="card-inner">
                                <Paginator
                                  pageCount={pageCount}
                                  onChange={(_page) =>
                                    getInfo(
                                      _page,
                                      rowsPerPage,
                                      order,
                                      selectedSortColumn
                                    )
                                  }
                                />
                              </div>

                              {/*  <div className="card-inner">
                                <Paginator
                                  pageCount={pageCount}
                                  onChange={(_page) => getNotifications(_page)}
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
