import axios from "axios";

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from "../constants/userConstants";
import { BASE_URL, NEST_BACKEND_URL } from "../constants/apiconstant";

export const login =
  (email, password, isNormalUser = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(NEST_BACKEND_URL + "auth/login", { email, password }, config);

      if (data?.result?.name) {
        console.log("dataaa", data);
        localStorage.setItem("userInfo", JSON.stringify(data));
        localStorage.setItem("token", data.result.token);
        localStorage.setItem("isNormalUser", isNormalUser);
        localStorage.setItem("expiry", new Date(new Date().getTime() + 24 * 60 * 60 * 1000).valueOf());
      }
      if (data?.result?.email && data?.result?.token) {
        const contact = await axios.get(`${BASE_URL}getContactDetails`, {
          headers: {
            Authorization: data.result.token,
            "Content-Type": "application/json",
          },
        });

        console.log("contact==>", contact);
        const contact_data = contact?.data ? contact.data : "";
        if (contact_data) {
          localStorage.setItem("userRole", contact_data.contact_role.toLowerCase());
        } else {
          console.log("Inside Else");
          localStorage.setItem("userRole", "");
        }
      } else {
        localStorage.setItem("userRole", "");
      }
      console.log("Calling setTimeout");
      setTimeout(() => {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: data,
        });
      }, 1000);
      /*   dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    }) */
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error,
      });
    }
  };

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("token");
  dispatch({ type: USER_LOGOUT });
};

export const register =
  (name, email, password, isNormalUser = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(NEST_BACKEND_URL + "auth/signup", { name, email, password, role:isNormalUser ? "member": "admin" }, config);

      if (data?.result?.name) {
        localStorage.setItem("userInfo", JSON.stringify(data));
        localStorage.setItem("token", data.result.token);
        localStorage.setItem("isNormalUser", isNormalUser);
        localStorage.setItem("expiry", new Date(new Date().getTime() + 24 * 60 * 60 * 1000).valueOf());
      }
      setTimeout(() => {
        dispatch({
          type: USER_REGISTER_SUCCESS,
          payload: data,
        });
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: data,
        });
      }, 1000);
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: error,
      });
    }
  };

export const logoutuser = () => async (dispatch) => {
  localStorage.clear();

  const isNormalUser = localStorage.getItem("isNormalUser");
  console.log("logoutuser isNormalUser==>", isNormalUser);
  var redirect_url = "/";

  if (isNormalUser === true || isNormalUser === "true") {
    redirect_url = "/user-login";
  } else {
    redirect_url = "/";
  }
  console.log("logoutuser redirect_url==>", redirect_url);

  localStorage.removeItem("isNormalUser");
  dispatch({ type: USER_LOGOUT });
  document.location.href = redirect_url;
};
