import React from 'react'
import Button from '@material-ui/core/Button'
import { Menu as ReactMenu } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { useDispatch, useSelector } from 'react-redux'

export const Menu = ({ menuArray, onMenuItemClick }) => {
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)




    return (
        <>
            <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={(event) => {
                    event.stopPropagation()
                    setAnchorEl(event.currentTarget)
                }}
            >
                <MoreHorizIcon style={{ color: 'grey' }} />
            </IconButton>
            <ReactMenu
                elevation={0}
                id='long-menu'
                anchorEl={anchorEl}
                keepMounted
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={() => setAnchorEl(null)}
            >
                {menuArray.map(((menuItem, index) => (
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation()
                            onMenuItemClick(menuItem, index)
                            setAnchorEl(null)


                        }}
                    >
                        {menuItem}
                    </MenuItem>
                )))}


            </ReactMenu>
        </>
    )
}

