import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import ReactTimeAgo from 'react-time-ago'
import { Menu } from "./Menu"

export const Card = ({ title, subtitle, subtitleStyle, menuArray = [], onListItemClick = () => { }, onMenuItemClick, containerStyle = {} }) => {
    return (
        <div className='nk-file-item nk-file' onClick={onListItemClick} style={{ ...containerStyle, height: "100%" }}>
            <div className='nk-file-info'>
                <div className='nk-file-title'>

                    <Link to={`#`}>
                        <div className='nk-file-name'>
                            <div className='nk-file-name-text'>
                                {title}
                            </div>
                        </div>
                    </Link>
                </div>
                {subtitle && (React.isValidElement(subtitle) ? subtitle : (
                    <ul className='nk-file-desc'>
                        <li className='date' style={subtitleStyle}>
                            {subtitle}
                        </li>
                    </ul>
                ))}

            </div>

            <div className='nk-file-actions'>
                <div className='dropdown'>

                    {menuArray.length > 0 && (
                        <Menu
                            onMenuItemClick={onMenuItemClick}
                            menuArray={menuArray}


                        />
                    )}

                </div>
            </div>
        </div>
    )
}

