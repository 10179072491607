import React, { useEffect, useState } from 'react'
import FileUpload from '../FileUpload'
import '../../assets/css/dashlite.css'
import '../../assets/css/theme.css'
import "../../assets/css/custom-shared-docs.css";
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useHistory } from 'react-router'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularProgress from '@material-ui/core/CircularProgress'
import FileMenu from '../../components/FileMenu'
import { Grid, Container, Modal, TextField, Fab } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Filefetch,
  Folderfetch,
  FileAdd,
  FolderAdd,
  FileDeletion,
  FolderDeletion,
  UpdatefileName,
  Updatefilefolderid,
} from '../../actions/assetsAction'
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'
import { html } from '../Html'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import FileButton from './FileButton'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import axios from "axios";
import { BASE_URL } from "../../constants/apiconstant";
import { useSnackbar } from "notistack";
import { getCSVSVG, getDocSVG, getFileSVG, getFolderSVG, getHeaders, 
          getMediaSVG, getPDFSVG, getZipSVG } from "../../utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const SharedFoldersfiles = ({ match }) => {
  const location = useLocation();
  const dispatch = useDispatch()
  const [modalStyle] = useState(getModalStyle)
  const { innerWidth, innerHeight } = window
  const history = useHistory()
  const url = window.location.href;
  const urlParams = new URLSearchParams(url.split('?')[1]);
  const folder_name = urlParams.get('folder_name');
  function getModalStyle() {
    const { innerWidth, innerHeight } = window

    let top = 50,
      left = innerWidth < 600 ? 0 : 50
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      borderRadius: '6px',
    }
  }

  const useStyles = makeStyles((theme) => ({
      customTextField: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused': {
            borderColor: 'red',
          },
        },
      },
    paper: {
      position: 'absolute',
      width: innerWidth > 600 ? 500 : '100%',

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: 'calc(100vh - 100px)',
      overflow: 'auto !important',
      top: '50%',
    },
    multilineColor: {
      color: 'white',
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    rooot: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    fieldroot: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }))
  const classes = useStyles()

  const getAssets = useSelector((state) => state.getAssets)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [fileName, setFileName] = useState('')
  const [foldername, setFoldername] = useState('')
  const [newfiles, setNewfiles] = useState('')
  const [ userOptionVisibility, setUserOptionVisibility] = useState('')
  const [ moveFilePermission, setMoveFilePermission ] = useState(false)
  const [ role, setRole ] = useState("viewer")
  const [currentfileid, setCurrentfileid] = useState('')
  const [openfilename, setOpenfilename] = useState(false)
  const [openfolderid, setOpenfolderid] = useState(false)
  const [updatedfolderid, setUpdatedfolderid] = useState('')

  const [renamefile, setRenamefile] = useState('')

  const [folderid, setFolderid] = useState(match.params.id)

  const [open, setOpen] = useState(false)
  const [openfolder, setOpenfolder] = useState(false)
  const [orgOutFolderid, setOrgOutFolderid] = useState("");
  const [currentOutFolderid, setCurrentOutFolderid] = useState(match.params.id);
  const [renameOutFile, setRenameOutFile] = useState("");
  const [removeOutFile, setRemoveOutFile] = useState("");
  const [moveOutFile, setMoveOutFile] = useState(false);
  const [currentOutfileid, setCurrentOutfileid] = useState("");
  const [openOutFilename, setOpenOutFilename] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [currentFolderName, setCurrentFolderName] = useState(folder_name);
  const [loading, setLoading] = useState(false);
  const [getFoldersList, setFoldersList] = useState([]);
  const [downloadOutFile, setDownloadOutFile] = useState(false);

  const [isNormalUser, setIsNormalUser] = useState(localStorage.getItem("isNormalUser"));
  const [userRole, setuserRole] = useState(localStorage.getItem('userRole'));
  const [hasAdminAccess, setHasAdminAccess] = useState(false);

  useEffect(() => {
    console.log('folderid', folderid)
    console.log('folder_name', folder_name)
    let deleteAccess = (isNormalUser!=true && isNormalUser!="true") ? 
                        true : (userRole=="admin") ? 
                        true : false;
    setHasAdminAccess(deleteAccess);
    getSharedFilesFolders(1)
    getAllFoldersList()
  }, [folderid])

  const getAllFoldersList = async() => {
    try {
      const { data } = await axios.get(
        BASE_URL + "getSharedFolders",
        getHeaders()
      );
      var folder_list = []
      console.log("getAllFoldersList data==>", data.data)
      var data_list = data.data
      console.log("jj==>", data_list)
      for(var i=0;i<data_list.length;i++) {
        console.log("kk")
        console.log("data_list[i]==>", data_list[i])
        folder_list.push({
          label:data_list[i]._id,
          value:data_list[i].folder_name,
        })
      }
      console.log("folder_list==>", folder_list)
      setFoldersList(folder_list);
    } catch (error) {
      console.log("getAllFoldersList error", error);
    }
  }

  const getSharedFilesFolders = async (page) => {
    try {
      setLoading(true)
      console.log("147 folderid", folderid)
      const { data } = await axios.get(
        BASE_URL + `getSharedFilesFolders/?folder_id=${folderid}`,
        getHeaders()
      );
      console.log("Folders data==>", data)
      if(data && data.data) {
        setNewfiles(data.data)
        setUserOptionVisibility(data.option_visibility_viewer)
        setMoveFilePermission(data.can_move_files)
        setRole(data.role)
      }
    } catch (error) {
      console.log("getSharedFilesFolders error", error);
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    setLoading(false)
  };

  const handleClosed = () => {
    console.log("handleClosed Called")
    setOpenOutFilename(false);
    setRemoveOutFile(false);
    setMoveOutFile(false);

    setCurrentOutfileid("");
    setOpenOutFilename("");
  };

  const handleGoBack = () => {
    console.log('handleGoBack')
    history.replace("/file-sharing", {
      onPageLoad: "folders",
    });
  }

  const renameFileHandler = (e) => {
    e.preventDefault();
    console.log("renameFileHandler - ", renameOutFile, currentOutfileid);
    updateOutFileName()
    handleClosed();
    setRenameOutFile("");
  };

  const updateOutFileName = async () => {
    setLoading(true)
    try {
      console.log("original_file_name==>", renameOutFile)
      console.log("file_id==>", currentOutfileid)
      const { data } = await axios.post(
        BASE_URL + "updateSharedFile/",
        {
          original_file_name: renameOutFile, 
          file_id: currentOutfileid,
          isRenamed:true
        },
        getHeaders()
      );

      console.log("updateOutFileName Saving==>", data)
      if(data.success) {
        getSharedFilesFolders(1)
        enqueueSnackbar(data.message, { variant: "success" })
      } else {
        enqueueSnackbar(data.message,{ variant: "error" });
      }
    } catch (error) {
      console.log("updateOutFileName error", error);
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    setLoading(false);
  };

  const deleteSharedFile = async (id) => {
    setLoading(true)
    try {
      const { data } = await axios.delete(
        BASE_URL + `deleteSharedFile/?id=${id}`,
        getHeaders()
      );

      console.log("deleteSharedFile Deleting==>", data)
      if(data.success) {
        enqueueSnackbar(data.message, { variant: "success" })
        getSharedFilesFolders(1)
      } else {
        enqueueSnackbar(data.message,{ variant: "error" });
      }
    } catch (error) {
      console.log("deleteSharedFile error", error);
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    handleClosed();
    setLoading(false);
  };

  const changeOutFolderHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("changeOutFolderHandler - currentOutFolderid==>", currentOutFolderid);
    console.log("changeOutFolderHandler - currentOutfileid==>", currentOutfileid);
    console.log("changeOutFolderHandler - orgOutFolderid==>", orgOutFolderid);
    try {
      const { data } = await axios.post(
        BASE_URL + "updateOutFileFolder/",
        {
          file_id: currentOutfileid, 
          folder_id: currentOutFolderid,
          remove_folder_id: orgOutFolderid 
        },
        getHeaders()
      );

      console.log("changeOutFolderHandler Saving==>", data)
      if(data.success) {
        enqueueSnackbar(data.message, { variant: "success" })
      } else {
        enqueueSnackbar(data.message,{ variant: "error" });
      }
    } catch (error) {
      console.log("changeOutFolderHandler error", error);
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    handleClosed();
    getSharedFilesFolders(1)
    setLoading(false);
  };

  const getFolderName = () => {
    if (orgOutFolderid) {
      console.log("currentOutFolderid", orgOutFolderid);
      let currentFolderName = null;
      if(getFoldersList) {
        console.log("getFolderName - getFoldersList==>", getFoldersList)
        getFoldersList.forEach((folder) => {
          if (folder.label === orgOutFolderid) {
            currentFolderName = folder.value;
          }
        });
      }
      if (currentFolderName) {
        return currentFolderName;
      }
      return "No Folder";
    }
  };

  const downloadDocument = async (id) => {
    console.log("Inside downloadDocument==>", id)
    console.log("downloadOutFile==>", downloadOutFile)
    try {
      const header = getHeaders()
      console.log("header==>", header.headers)
      
      axios({
        url: BASE_URL + `downloadDocument/?id=${id}`,
        method:  'GET',
        responseType: 'blob',
        headers: {
          "Authorization": header.headers.Authorization,
        },
      }).then((response)=> {
        console.log("response headers==>", response.request.getResponseHeader('x-file-name'));
        const file_name = response.request.getResponseHeader('x-file-name');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        enqueueSnackbar(
          "The File will download shortly",
          { variant: "success" }
        );
        link.setAttribute("download", file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    } catch (error) {
      console.log("downloadDocument error", error);
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    handleClosed();
  }

  return (
    <>
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />

              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-fmg">
                      <Sidebar />
                      {/* .nk-fmg-aside */}
                      {loading ? (
                        <div className={classes.rooot}>
                          <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                        </div>
                      ) : (
                        <div className="nk-fmg-body">
                          <div className="nk-fmg-body-content">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between position-relative">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                    {currentFolderName}
                                  </h3>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="nk-fmg-actions">
                                    <ul className="nk-block-tools g-3">
                                      <li>
                                        <button
                                          onClick={() =>handleGoBack()}
                                          className="btn btn-secondary text-white"
                                          data-toggle="dropdown"
                                          style={{ backgroundColor: 'rgb(20, 155, 227)'}}
                                        >
                                          <em className="icon ni ni-arrow-left" />{" "}
                                          <span>Back</span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="nk-fmg-listing nk-block">
                              <div
                                className="toggle-expand-content expanded"
                                data-content="recent-files"
                              >
                                <div className="nk-files nk-files-view-group">
                                  {/* .nk-files-head */}
                                  <div className="nk-files-group">
                                    <h6 className="title">Files</h6>
                                    {!loading ? (
                                    <div className="nk-files-list">
                                      {newfiles.length === 0 && <h5 style={{ marginLeft: "0.5rem", marginTop: "1rem", fontSize: "1rem", fontFamily: "Nunito" }}>There is no files in this Folder!</h5>}
                                      {newfiles?.length > 0 &&
                                        newfiles?.map((doc, index) => (
                                          <FileButton
                                            hasAdminAccess={hasAdminAccess}
                                            OptionVisibility={userOptionVisibility}
                                            moveFilePermission={moveFilePermission}
                                            role={role}
                                            files={doc}
                                            index={index}
                                            id={doc._id}
                                            folder_id={doc.folder_id}
                                            setOrgOutFolderid={setOrgOutFolderid}
                                            setCurrentOutFolderid={setCurrentOutFolderid}
                                            setCurrentOutfileid={setCurrentOutfileid}
                                            setOpenOutFilename={setOpenOutFilename}
                                            setRemoveOutFile={setRemoveOutFile}
                                            setRenameOutFile={setRenameOutFile}
                                            setMoveOutFile={setMoveOutFile}
                                            setDownloadOutFile={setDownloadOutFile}
                                            name={doc.original_file_name}
                                            downloadDocument={downloadDocument}
                                          />
                                        ))}
                                    </div>
                                    ) : <div><CircularProgress  style={{color:"rgb(20, 155, 227)"}}/></div>}
                                  </div>
                                </div>
                                {/* .nk-files */}
                              </div>
                            </div>
                            {/* .nk-block */}
                          </div>
                          {/* .nk-fmg-body-content */}
                        </div>
                      )}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}
        </div>
        {/* main @e */}
        {/* app-root @e */}
      </div>

      <Modal
        open={openOutFilename}
        onClose={handleClosed}
        aria-labelledby="simple-modal-title"
      >
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Rename File</h5>
            <IconButton
            onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form onSubmit={renameFileHandler}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  style={{ color: "black", width: "100%", marginTop: 9, outlineColor:"rgb(20, 155, 227) " }}
                  id="outlined-basic"
                  className={classes.customTextField}
                  size="small"
                  label="Rename File"
                  value={renameOutFile}
                  onChange={(e) => setRenameOutFile(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                style={{backgroundColor: "rgb(20, 155, 227)", color: "white"}}
                type="submit"
                disabled={renameOutFile === ""}
                style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
              >
                <div>Rename File</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        open={removeOutFile}
        onClose={handleClosed}
        aria-labelledby="simple-modal-title"
      >
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Delete Folder</h5>
            <IconButton
              onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form>
            <div className="col-md-12" style={{textAlign:"center"}}>
              <h6 style= {{fontFamily: "Nunito"}}>Are you sure want to delete the file?</h6>
              <span>Deleted file can not be recover</span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                style={{backgroundColor: '#dc3545', color: '#FFFFFF', fontWeight: 'bold'}}
                onClick={handleClosed}
              >
                <div>No</div>
              </Button>
              <Button
                variant="contained"
                style={{backgroundColor: 'rgb(20, 155, 227)', color: '#FFFFFF', fontWeight: 'bold'}}
                onClick={() => {deleteSharedFile(currentOutfileid)}}
              >
                <div>Yes</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        open={moveOutFile}
        onClose={handleClosed}
        aria-labelledby="simple-modal-title"
      >
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Update Folder</h5>
            <IconButton
            onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form onSubmit={changeOutFolderHandler}>
            <Grid container spacing={1}>
              <h5 style={{fontSize: "1rem",marginBottom: "1rem", fontFamily: "Nunito"}}>
                Current Folder - {getFolderName()}
              </h5>
              <Grid item xs={12}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                size="small"
                id="outlined-basic"
              >
              <InputLabel id="demo-simple-select-label1" 
                style={{
                  background:"white", paddingLeft:"5px", paddingRight:"5px"
                }}>
                Folder Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                value={currentOutFolderid}
                onChange={(e) => setCurrentOutFolderid(e.target.value)}
              >
                {getFoldersList && getFoldersList.length > 0 ? 
                  getFoldersList.map((folders) => (
                    <MenuItem value={folders.label}>
                      {folders.value}
                    </MenuItem>
                  ))
                  : null}
              </Select>
              </FormControl>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button
                id={currentOutFolderid}
                variant="contained"
                style={{backgroundColor: (
                  currentOutFolderid && currentOutFolderid!="") &&
                  currentOutFolderid!=orgOutFolderid ?
                  "rgb(20, 155, 227)": "",
                  color:(
                    currentOutFolderid && currentOutFolderid!="") &&
                    currentOutFolderid!=orgOutFolderid ?
                     "white":"#979797"
                }}
                type="submit"
                disabled={(
                  currentOutFolderid && currentOutFolderid!="") && 
                  currentOutFolderid!=orgOutFolderid ? 
                  "" : "disabled"}
              >
                <div>Update Folder</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default SharedFoldersfiles
