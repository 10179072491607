import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Link as Links } from "react-router-dom";

import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { register } from "../actions/userActions";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import logo from "./../assets/images/logo.jpeg";
import { useSnackbar } from "notistack";
import axios from "axios";
import { axiosConfig, ERROR, snackBarPosition } from "../constants/constants";
import { NEST_BACKEND_URL } from "../constants/apiconstant";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(NEST_BACKEND_URL + "auth/signup", { email, name }, axiosConfig);
      if (typeof data == "string") {
        enqueueSnackbar(data, {
          variant: ERROR,
          ...snackBarPosition,
        });
      } else {
        localStorage.setItem("email", data.email);
        history.push("/verify-otp");
      }
    } catch (err) {
      enqueueSnackbar("Something went wrong getting contacts", {
        variant: ERROR,
        ...snackBarPosition,
      });
    }
    setLoading(false);
  };

  if (message) {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <ToastContainer />
      <div className={classes.paper}>
        <img style={{ width: "7rem", borderRadius: "50%" }} alt="" src={logo} />
        {/* <Typography component='h1' variant='h5'>
          Sign up
        </Typography> */}
        <form className={classes.form} onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="name"
                type="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Name"
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                type="email"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
            className={classes.submit}
            disabled={loading}
          >
            {loading ? <CircularProgress style={{color:"white"}} size={24} /> : "Sign Up"}
          </Button>
          <div style={{textAlign:"end", marginTop:"-8px", fontSize:"11px"}}> <a href="https://ahrvo.com/data-privacy-policy/" style={{textDecoration:"underline", fontWeight:"600"}}>Privacy and Data Protection policy</a> and <a href="https://ahrvo.com/terms-of-service/" style={{textDecoration:"underline", fontWeight:"600"}}>Terms of Services</a></div>
          <Grid style={{ marginTop: 6 }} container justify="flex-end">
            <Grid item>
              <Links to="/">Already have an account? Sign in</Links>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
