import axios from 'axios'
import { BASE_URL } from '../../constants/apiconstant'
import { getHeaders } from '../../utils'

export const getDbCred = async (userId, setData, showError) => {
  const { data } = await axios.get(
    BASE_URL + 'get-db-cred?user_id_or_company_id=' + userId,
    getHeaders()
  )

  if (data.success === 'false' || data.success === false) {
    showError(data.message)
  } else {
    console.log('dataaa', data)
    setData(data.data)
  }
}

export const getApiKey = async (userId, setApikey, showError) => {
  try {
    const response = await axios.get(
      BASE_URL + 'get-api-key-by-user-id?user_id_or_company_id=' + userId,
      getHeaders()
    )
    console.log('response', response)
    console.log('data', response)
    if (response.data.apikey) {
      setApikey(response.data.apikey)
    }

    if (response.data.success === 'false' || response.data.success === false) {
      showError(response.data.message)
    }
    return false
  } catch (error) {
    console.log('error', error.response)
  }
}
