import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "../assets/css/dashlite.css";
import "../assets/css/theme.css";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import { useSnackbar } from "notistack";
import { BASE_URL } from "../constants/apiconstant";
import { getHeaders } from "../utils";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import Paginator from "../components/Pagination";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import AddEmailFromContacts from "./Shared/AddEmailFromContacts";

const CancelToken = axios.CancelToken;

const Contact = () => {
  const [searchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = React.useState(false);
  const [filter, setFilter] = React.useState(false);
  const [setting, setSetting] = React.useState(false);
  const [page, setPage] = useState(1);
  const [contacts, setContacts] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [csvGenerateLoading, setCsvGenerateLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [dropdown, setDropdown] = React.useState({
    number: "",
    selected: false,
  });
  const [allContacts, setAllContacts] = useState([]);
  const { number, selected } = dropdown;
  const [bodyWidth, setBodyWidth] = React.useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;


  const [order, setOrder] = useState("DESC");
  const [showExportDiv, setShowExportDiv] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] =
    useState("submitted_date");
  const [showFilterDiv, setShowFilterDiv] = useState(false);

  const sourceRef = useRef(null);

  useEffect(() => {
    getContacts(page, rowsPerPage, order, selectedSortColumn);
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const getSortOrder = (selected) => {
    let sortOrder = order;
    if (selectedSortColumn === selected) {
      if (order === "ASC") {
        sortOrder = "DESC";
      } else {
        sortOrder = "ASC";
      }
    } else {
      sortOrder = "DESC";
    }
    setOrder(sortOrder);
    return sortOrder;
  };
  const getContacts = async (
    _page,
    _rowsPerPage,
    sortOrder,
    sortColumn,
    searchText = "",
  ) => {
    setLoading(true);

    if (sourceRef.current) {
      sourceRef.current.cancel('Operation canceled by the user.');
    }
    
    sourceRef.current = CancelToken.source()
    

    try {
      if (_page !== page) setPage(_page);
      let url =
        BASE_URL +
        `get-contacts?page=${_page}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`;
      if (searchText) {
        url = url + "?name=" + searchText;
      }
      const { data } = await axios.get(url, {...getHeaders(),
        cancelToken: sourceRef.current.token
      });
      console.log("data", data);
      if (data.isFromMainApp) {
        const dataFromMainApp = JSON.parse(data.data);
        setContacts(dataFromMainApp.data);
        setPageCount(Math.ceil(dataFromMainApp.count / rowsPerPage));
      } else {
        setContacts(data.data);
        setPageCount(data.pages);
      }
    } catch (error) {
      console.log("error", error);
      if (!axios.isCancel(error)) {    
      enqueueSnackbar("Something went wrong getting contacts", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    }
    setLoading(false);
    
  };
  //
  const generateCsv = async () => {
    try {
      setExportLoading(true);
      const { data } = await axios.get(
        BASE_URL + "generate-contacts-csv",
        getHeaders()
      );

      console.log("data", data);
      axios({
        url: BASE_URL + "media/" + data.csvFileName, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "contacts.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went while generating csv file", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }

    setExportLoading(false);
  };
  const handleDelete = async (_id, index) => {
    setDeleteLoading(index);
    try {
      const { data } = await axios.delete(
        BASE_URL + "delete-contacts?contactId=" + _id,
        getHeaders()
      );

      getContacts(page, rowsPerPage, order, selectedSortColumn, searchText);
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong while deleting contacts", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    setDeleteLoading(false);
  };
  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };
  // Dummy data
  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"],
  ];

  //   Dropdown function
  const showDropdown = (e, index) => {
    if (index === number) {
      setDropdown({
        ...dropdown,
        number: "",
        selected: false,
      });
    } else {
      setDropdown({
        ...dropdown,
        number: index,
        selected: true,
      });
    }
  };
  return (
    <div className="p-2">
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />

              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div
                      className="nk-fmg"
                      style={bodyWidth ? { paddingLeft: 80 } : null}
                    >
                      <Sidebar page="contact" getState={sidebarState} />
                      {/* .nk-fmg-aside */}
                      <div className="nk-fmg-body">
                        <div className="nk-block">
                          <div className="my-3 row">
                            <h3 className="col-lg-8 nunito-font">Contacts</h3>
                            <div className="col-lg-4 text-right">
                              {!userInfo.result.companyId && (
                                <Link
                                  className="btn btn-primary text-white mr-1 nunito-font"
                                  style={{ backgroundColor: 'rgb(20, 155, 227)'}}
                                  to={"/import"}
                                >
                                  Import
                                </Link>
                              )}

                              <Link
                                className="btn btn-primary text-white mr-1 nunito-font"
                                style={{ backgroundColor: 'rgb(20, 155, 227)'}}
                                onClick={generateCsv}
                              >
                                {exportLoading ? (
                                  <CircularProgress  size={20} color="white" />
                                ) : (
                                  "Export"
                                )}
                              </Link>
                              <Link
                                to={"/dashboard"}
                                className="btn btn-outline-light text-white d-none d-sm-inline-flex nunito-font"
                                style={{ backgroundColor: 'rgb(20, 155, 227)'}}
                              >
                                <em className="icon ni ni-arrow-left"></em>
                                <span className="nunito-font">Back</span>
                              </Link>
                            </div>
                          </div>
                          <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                              <div className="card-inner position-relative card-tools-toggle">
                                <div
                                  class={
                                    "card-title-group " +
                                    (search ? "d-none" : null)
                                  }
                                >
                                  <div className="card-tools">
                                    {!userInfo.result.companyId && (
                                      <div className="form-inline flex-nowrap gx-3">
                                        <div className="form-wrap w-150px">
                                          <select
                                            className="form-control"
                                            data-search="off"
                                            data-placeholder="Bulk Action"
                                          >
                                            <option value="">
                                              Bulk Action
                                            </option>
                                            <option value="email">
                                              Send Email
                                            </option>
                                            <option value="group">
                                              Change Group
                                            </option>
                                            <option value="suspend">
                                              Suspend User
                                            </option>
                                            <option value="delete">
                                              Delete User
                                            </option>
                                          </select>
                                        </div>
                                        <div className="btn-wrap">
                                          <span className="d-none d-md-block">
                                            <button className="btn btn-dim btn-outline-light disabled">
                                              Apply
                                            </button>
                                          </span>
                                          <span className="d-md-none">
                                            <button className="btn btn-dim btn-outline-light btn-icon disabled">
                                              <em className="icon ni ni-arrow-right"></em>
                                            </button>
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="card-tools mr-n1">
                                    <ul className="btn-toolbar gx-1">
                                      <li>
                                        <a
                                          href="#"
                                          className="btn btn-icon search-toggle toggle-search"
                                          data-target="search"
                                          onClick={() => setSearch(true)}
                                        >
                                          <em className="icon ni ni-search"></em>
                                        </a>
                                      </li>
                                      <li className="btn-toolbar-sep"></li>
                                      <li>
                                        <div className="toggle-wrap d-block">
                                          <a
                                            href="#"
                                            className="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                          >
                                            <em className="icon ni ni-menu-right"></em>
                                          </a>
                                          <div
                                            className="toggle-content"
                                            data-content="cardTools"
                                          >
                                            <ul className="btn-toolbar gx-1">
                                              <li className="toggle-close">
                                                <a
                                                  href="#"
                                                  className="btn btn-icon btn-trigger toggle"
                                                  data-target="cardTools"
                                                >
                                                  <em className="icon ni ni-arrow-left"></em>
                                                </a>
                                              </li>
                                              {/*   <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() =>
                                                      setFilter(!filter)
                                                    }
                                                    data-toggle="dropdown"
                                                  >
                                                    <div className="dot dot-primary"></div>
                                                    <em className="icon ni ni-filter-alt"></em>
                                                  </a>
                                                  <div
                                                    class={
                                                      "filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right " +
                                                      (filter
                                                        ? "d-block"
                                                        : null)
                                                    }
                                                  >
                                                    <div className="dropdown-head">
                                                      <span className="sub-title dropdown-title">
                                                        Filter Users
                                                      </span>
                                                      <div className="dropdown">
                                                        <a
                                                          href="#"
                                                          className="btn btn-sm btn-icon"
                                                        >
                                                          <em className="icon ni ni-more-h"></em>
                                                        </a>
                                                      </div>
                                                    </div>
                                                    <div className="dropdown-body dropdown-body-rg">
                                                      <div className="row gx-6 gy-3">
                                                        <div className="col-6">
                                                          <div className="custom-control custom-control-sm custom-checkbox">
                                                            <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              id="hasBalance"
                                                            />
                                                            <label
                                                              className="custom-control-label"
                                                              for="hasBalance"
                                                            >
                                                              {" "}
                                                              Have Balance
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div className="col-6">
                                                          <div className="custom-control custom-control-sm custom-checkbox">
                                                            <input
                                                              type="checkbox"
                                                              className="custom-control-input"
                                                              id="hasKYC"
                                                            />
                                                            <label
                                                              className="custom-control-label"
                                                              for="hasKYC"
                                                            >
                                                              {" "}
                                                              KYC Verified
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div className="col-6">
                                                          <div className="form-group">
                                                            <label className="overline-title overline-title-alt">
                                                              Role
                                                            </label>
                                                            <select className="form-control">
                                                              <option value="any">
                                                                Any Role
                                                              </option>
                                                              <option value="investor">
                                                                Investor
                                                              </option>
                                                              <option value="seller">
                                                                Seller
                                                              </option>
                                                              <option value="buyer">
                                                                Buyer
                                                              </option>
                                                            </select>
                                                          </div>
                                                        </div>
                                                        <div className="col-6">
                                                          <div className="form-group">
                                                            <label className="overline-title overline-title-alt">
                                                              Status
                                                            </label>
                                                            <select className="form-control">
                                                              <option value="any">
                                                                Any Status
                                                              </option>
                                                              <option value="active">
                                                                Active
                                                              </option>
                                                              <option value="pending">
                                                                Pending
                                                              </option>
                                                              <option value="suspend">
                                                                Suspend
                                                              </option>
                                                              <option value="deleted">
                                                                Deleted
                                                              </option>
                                                            </select>
                                                          </div>
                                                        </div>
                                                        <div className="col-12">
                                                          <div className="form-group">
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary"
                                                            >
                                                              Filter
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="dropdown-foot between">
                                                      <a
                                                        className="clickable"
                                                        href="#"
                                                      >
                                                        Reset Filter
                                                      </a>
                                                      <a href="#">
                                                        Save Filter
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                             */}
                                              <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() =>
                                                      setSetting(!setting)
                                                    }
                                                    data-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-setting"></em>
                                                  </a>
                                                  <div
                                                    class={
                                                      "dropdown-menu dropdown-menu-xs dropdown-menu-right " +
                                                      (setting
                                                        ? "d-block"
                                                        : null)
                                                    }
                                                  >
                                                    <ul className="link-check">
                                                      <li>
                                                        <span>Show</span>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setRowsPerPage(20);
                                                          getContacts(
                                                            1,
                                                            20,
                                                            order,
                                                            selectedSortColumn
                                                          );
                                                        }}
                                                        class={
                                                          rowsPerPage === 20 &&
                                                          "active"
                                                        }
                                                      >
                                                        <a href="#">20</a>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setRowsPerPage(50);
                                                          getContacts(
                                                            1,
                                                            50,
                                                            order,
                                                            selectedSortColumn
                                                          );
                                                        }}
                                                        class={
                                                          rowsPerPage === 50 &&
                                                          "active"
                                                        }
                                                      >
                                                        <a href="#">50</a>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setRowsPerPage(100);
                                                          getContacts(
                                                            1,
                                                            100,
                                                            order,
                                                            selectedSortColumn
                                                          );
                                                        }}
                                                        class={
                                                          rowsPerPage === 100 &&
                                                          "active"
                                                        }
                                                      >
                                                        <a href="#">100</a>
                                                      </li>
                                                    </ul>
                                                    {/*    <ul className="link-check">
                                                      <li>
                                                        <span>Order</span>
                                                      </li>
                                                      <li className="active">
                                                        <a href="#">DESC</a>
                                                      </li>
                                                      <li>
                                                        <a href="#">ASC</a>
                                                      </li>
                                                    </ul> */}
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  class={
                                    "card-search search-wrap " +
                                    (search ? "active" : null)
                                  }
                                  data-search="search"
                                >
                                  <div className="card-body">
                                    <div className="search-content">
                                      <a
                                        href="#"
                                        className="search-back btn btn-icon toggle-search"
                                        onClick={() => {
                                          setSearch(false);
                                          getContacts(
                                            1,
                                            rowsPerPage,

                                            order,
                                            selectedSortColumn
                                          );
                                          setSearchText("");
                                        }}
                                        data-target="search"
                                      >
                                        <em className="icon ni ni-arrow-left"></em>
                                      </a>
                                      <input
                                        type="text"
                                        value={searchText}
                                        onChange={(e) => {
                                          getContacts(
                                            1,
                                            rowsPerPage,

                                            order,
                                            selectedSortColumn,
                                            e.target.value
                                          );
                                          setSearchText(e.target.value);
                                        }}
                                        className="form-control border-transparent form-focus-none"
                                        placeholder="Search by user or email"
                                      />
                                      <button className="search-submit btn btn-icon">
                                        <em className="icon ni ni-search"></em>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist is-compact">
                                  <div className="nk-tb-item nk-tb-head">
                                    {!userInfo.result.companyId && (
                                      <div className="nk-tb-col nk-tb-col-check">
                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="uid"
                                          />
                                          <label
                                            className="custom-control-label"
                                            for="uid"
                                          ></label>
                                        </div>
                                      </div>
                                    )}

                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="nk-tb-col p-3 tb-col-md"
                                      onClick={(e) => {
                                        getContacts(
                                          1,
                                          20,
                                          getSortOrder("first_name"),
                                          "first_name"
                                        );
                                        setSelectedSortColumn("first_name");
                                      }}
                                    >
                                      <span className="sub-text">
                                        First Name
                                        {selectedSortColumn === "first_name" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>

                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="nk-tb-col p-3 tb-col-md"
                                      onClick={(e) => {
                                        getContacts(
                                          1,
                                          20,
                                          getSortOrder("last_name"),
                                          "last_name"
                                        );
                                        setSelectedSortColumn("last_name");
                                      }}
                                    >
                                      <span className="sub-text">
                                        Last Name
                                        {selectedSortColumn === "last_name" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>

                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="nk-tb-col p-3 tb-col-md"
                                      onClick={(e) => {
                                        getContacts(
                                          1,
                                          20,
                                          getSortOrder("email"),
                                          "email"
                                        );
                                        setSelectedSortColumn("email");
                                      }}
                                    >
                                      <span className="sub-text">
                                        Email
                                        {selectedSortColumn === "email" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>

                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="nk-tb-col p-3 tb-col-md"
                                      onClick={(e) => {
                                        getContacts(
                                          1,
                                          20,
                                          getSortOrder("phone"),
                                          "phone"
                                        );
                                        setSelectedSortColumn("phone");
                                      }}
                                    >
                                      <span className="sub-text">
                                        Phone
                                        {selectedSortColumn === "phone" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>

                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="nk-tb-col p-3 tb-col-md"
                                      onClick={(e) => {
                                        getContacts(
                                          1,
                                          20,
                                          getSortOrder("address"),
                                          "address"
                                        );
                                        setSelectedSortColumn("address");
                                      }}
                                    >
                                      <span className="sub-text">
                                        Address
                                        {selectedSortColumn === "address" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>

                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="nk-tb-col p-3 tb-col-md"
                                      onClick={(e) => {
                                        getContacts(
                                          1,
                                          20,
                                          getSortOrder("country"),
                                          "country"
                                        );
                                        setSelectedSortColumn("country");
                                      }}
                                    >
                                      <span className="sub-text">
                                        Country
                                        {selectedSortColumn === "country" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>

                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="nk-tb-col p-3 tb-col-md"
                                      onClick={(e) => {
                                        getContacts(
                                          1,
                                          20,
                                          getSortOrder("active"),
                                          "active"
                                        );
                                        setSelectedSortColumn("active");
                                      }}
                                    >
                                      <span className="sub-text">
                                        Active
                                        {selectedSortColumn === "active" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>

                                    {/*   <div className="nk-tb-col p-3">
                                      <span className="sub-text">User</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-md">
                                      <span className="sub-text">Role</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-sm">
                                      <span className="sub-text">Email</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-md">
                                      <span className="sub-text">Phone</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-lg">
                                      <span className="sub-text">Company</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-lg">
                                      <span className="sub-text">Verified</span>
                                    </div>
                                    <div className="nk-tb-col p-3 tb-col-lg">
                                      <span className="sub-text">Last Login</span>
                                    </div>
                                    <div className="nk-tb-col p-3">
                                      <span className="sub-text">Status</span>
                                    </div> */}
                                    <div className="nk-tb-col nk-tb-col-tools text-right"></div>
                                  </div>

                                  {contacts.length > 0 &&
                                    contacts.map((contact, index) => (
                                      <div className="nk-tb-item">
                                        {!userInfo.result.companyId && (
                                          <div className="nk-tb-col nk-tb-col-check">
                                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="uid1"
                                              />
                                              <label
                                                className="custom-control-label"
                                                for="uid1"
                                              ></label>
                                            </div>
                                          </div>
                                        )}

                                        <div className="nk-tb-col p-3 tb-col-sm">
                                          <span>{contact.first_name}</span>
                                        </div>
                                        <div className="nk-tb-col p-3 tb-col-sm">
                                          <span>{contact.last_name}</span>
                                        </div>

                                        <div className="nk-tb-col p-3 tb-col-sm">
                                          <span>{contact.email}</span>
                                        </div>
                                        <div className="nk-tb-col p-3 tb-col-sm">
                                          <span>{contact.phone}</span>
                                        </div>
                                        <div className="nk-tb-col p-3 tb-col-sm">
                                          <span>{contact.address}</span>
                                        </div>

                                        <div className="nk-tb-col p-3 tb-col-sm">
                                          <span>{contact.country}</span>
                                        </div>
                                        <div className="nk-tb-col p-3 tb-col-sm">
                                          <span>{contact.active}</span>
                                        </div>

                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-2">
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#"
                                                  className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
                                                  data-toggle="dropdown"
                                                  onClick={(e, ind) =>
                                                    showDropdown(e, index)
                                                  }
                                                >
                                                  <em className="icon ni ni-more-h"></em>
                                                </a>
                                                <div
                                                  class={
                                                    "dropdown-menu dropdown-menu-right " +
                                                    (number == index && selected
                                                      ? "d-block"
                                                      : "none")
                                                  }
                                                >
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        onClick={() =>
                                                          localStorage.setItem(
                                                            "contact",
                                                            JSON.stringify(
                                                              contact
                                                            )
                                                          )
                                                        }
                                                        to={"/contact-details"}
                                                      >
                                                        <em className="icon ni ni-eye"></em>
                                                        <span>
                                                          View Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    {!userInfo.result
                                                      .companyId && (
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleDelete(
                                                            contact._id,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <a>
                                                          <em className="icon ni ni-repeat"></em>
                                                          <span>
                                                            {deleteLoading ===
                                                            index ? (
                                                              <CircularProgress
                                                              style={{color:"rgb(20, 155, 227)"}}
                                                                size={20}
                                                              />
                                                            ) : (
                                                              "Delete User"
                                                            )}
                                                          </span>
                                                        </a>
                                                      </li>
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                              {loading && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "1rem",
                                  }}
                                >
                                  <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={30} />
                                </div>
                              )}
                              {contacts.length === 0 && !loading && (
                                <p
                                  style={{
                                    padding: "1rem",
                                    borderBottom: "1px solid #dbdfea",
                                    width: "100%",
                                  }}
                                >
                                  No Information Found
                                </p>
                              )}

                              <div className="card-inner">
                                <Paginator
                                  pageCount={pageCount}
                                  onChange={(_page) =>
                                    getContacts(
                                      _page,
                                      rowsPerPage,
                                      order,
                                      selectedSortColumn
                                    )
                                  }
                                />
                              </div>
                              {/*   <div className="card-inner">
                                <ul className="pagination justify-content-center justify-content-md-start">
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      Prev
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      1
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      2
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <span className="page-link">
                                      <em className="icon ni ni-more-h"></em>
                                    </span>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      6
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      7
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}
        </div>
      </div>
    </div>
  );
};
export default Contact;
