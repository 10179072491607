import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Link as Links, useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { register } from "../actions/userActions";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import logo from "./../assets/images/logo.jpeg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { NEST_BACKEND_URL } from "../constants/apiconstant";
import { useSnackbar } from "notistack";
import { USER_LOGIN_SUCCESS } from "../constants/userConstants";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInFromRedirect() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageType, setPageType] = useState("info");
  const [otp, setOtp] = useState("");
  const [showCompanyField, setShowCompanyField] = useState(true);
  const [token, setToken] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!params.token) history.push("/");

   
    const interval  =setInterval(()=>{
      let tempData = localStorage.getItem("tempData");
      if (tempData) {
        tempData = JSON.parse(tempData);
        console.log("ttt", tempData)
        setFirstName(tempData.first_name);
        setLastName(tempData.last_name);
        setCompanyName(tempData.company_name);
        setEmail(tempData.email);
        if (tempData.role == "member") {
          setShowCompanyField(false);
        }
        clearInterval(interval)
      }
    })
 
  }, []);

  const classes = useStyles();

  const submit = async (e) => {
    e.preventDefault();
    if (pageType === "info") await submitDetail();
    if (pageType === "otp") await verifyOtp();
    setLoading(false);
  };
  const verifyOtp = async () => {
    try {
      setLoading(true);

      const { data } = await axios.post(NEST_BACKEND_URL + "auth/verify-otp", {
        token,
        otp,
      });
      console.log("data", data);
      if (data.success) {
        localStorage.setItem("userInfo", JSON.stringify({ result: data.data }));
        localStorage.setItem("token", data.data.token);
        if (data.data.role == "member") {
          localStorage.setItem("isNormalUser", true);
        }
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: { result: data.data },
        });
        history.push("/");
      } else {
        enqueueSnackbar(data.message || "Something went wrong, please try again later", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again later", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  const submitDetail = async () => {
    try {
      setLoading(true);

      const { data } = await axios.post(NEST_BACKEND_URL + "auth/sign-up-from-redirect", {
        tokenFromRedirect: params.token,
        name: firstName + " " + lastName,
        email,
        companyName,
      });
      console.log("data", data);
      if (data.success) {
        setPageType("otp");
        setToken(data.token);
        enqueueSnackbar("A otp has been sent on your email address", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        enqueueSnackbar(data.message || "Something went wrong, please try again later", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again later", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <ToastContainer />
      <div className={classes.paper}>
        <img style={{ width: "7rem", borderRadius: "50%" }} alt="" src={logo} />
        {/*  <Typography component="h5" variant="h6"></Typography> */}
        {pageType === "info" ? (
          <form className={classes.form} onSubmit={submit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Last Name"
                  autoFocus
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              {showCompanyField && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Company Name"
                    autoFocus
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Email"
                  autoFocus
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {loading ? <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={24} /> : "Sign Up"}
            </Button>
          </form>
        ) : (
          <form className={classes.form} onSubmit={submit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="One Time Password"
                  autoFocus
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{backgroundColor: 'rgb(20, 155, 227)'}}
              className={classes.submit}
              disabled={loading}
            >
              {loading ? <CircularProgress style={{color:"white"}} size={24} /> : "Verify Otp"}
            </Button>
          </form>
        )}
      </div>
    </Container>
  );
}
