import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

const SharedFoldersFilesMenu = (props) => {
  console.log("SharedFoldersFilesMenu props==>", props)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const deleteOutFile = (id) => {
    console.log('SharedFolderFiles deleteOutFile', id)
    props.setCurrentOutfileid(id)
    props.setRemoveOutFile(true)
  }
  const renameOutFile = (id, name) => {
    console.log("SharedFolderFiles renameOutFile id==>", id)
    console.log("SharedFolderFiles renameOutFile props==>", props)
    props.setCurrentOutfileid(id)
    props.setRenameOutFile(name)
    props.setOpenOutFilename(true)
  }
  const moveOutFile = (file_id, folder_id) => {
    console.log("SharedFolderFiles moveOutFile file_id==>", file_id)
    console.log("SharedFolderFiles moveOutFile folder_id==>", folder_id)
    props.setCurrentOutfileid(file_id)
    props.setCurrentOutFolderid(folder_id)
    props.setOrgOutFolderid(folder_id)
    props.setMoveOutFile(true)
  }
  const downloadOutFile = (id) => {
    console.log('SharedFolderFiles downloadOutFile', id)
    props.setCurrentOutfileid(id)
    props.setDownloadOutFile(true)
    props.downloadDocument(id);
  }
  return (
    <>
      <IconButton
        color='inherit'
        aria-label='open drawer'
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreHorizIcon style={{ color: 'grey' }} />
      </IconButton>
      <Menu
        elevation={0}
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            window.open(props.fileUrl, "_blank")
            console.log('Shared Outgoing dot Menu-Download-curr id==>', props?.id) // should be each individual id, but here always "test3"
          }}
        >
          Download{' '}
        </MenuItem>
       {props.moveFilePermission && <MenuItem
          onClick={() => {
            setAnchorEl(null)
            moveOutFile(props?.id, props?.folder_id)
            console.log('SharedFolderFiles dot Menu-Move-curr id==>', props?.id) // should be each individual id, but here always "test3"
          }}
        >
          Move{' '}
        </MenuItem>}
        {props && (props.role==="editor" || props.role === "admin") ? (
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            renameOutFile(props?.id, props?.name)
            console.log('SharedFolderFiles dot Menu-Rename-curr id==>', props?.id) // should be each individual id, but here always "test3"
          }}
        >
          Rename{' '}
        </MenuItem>
        ) : "" }
        {props && props.role === "admin" ? (
        <MenuItem
          onClick={() => {
            deleteOutFile(props?.id)
            setAnchorEl(null)
            console.log('SharedFolderFiles dot Menu-Delete-curr id==>', props?.id) // should be each individual id, but here always "test3"
          }}
        >
          {' '}
          Delete
        </MenuItem>
        ) : "" }
      </Menu>
    </>
  )
}

export default SharedFoldersFilesMenu
