import { Button, LinearProgress, Modal } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { BASE_URL } from "../../constants/apiconstant";
import { getHeaders } from "../../utils";
import Checkbox from "@mui/material/Checkbox";
import { ArrowBack } from "@material-ui/icons";
import Swal from "sweetalert2";

const getContacts = async (setContacts) => {
  try {
    const { data } = await axios.get(BASE_URL + `getAllContacts`, { ...getHeaders() });
    const emailList = data?.data?.map((item) => item.email);
    setContacts(emailList);
  } catch (error) {
    if (!axios.isCancel(error)) {
    }
  }
};
const updateShareFolderPermissions = async (
  setLoading,
  setFolders,
  folder_data,
  editor_share_and_change_permissions = null,
  option_visibility_viewer = null,
  general_access = null,
  public_access_permission = null,
  rename_folder = null,
  move_files = null,
  change_user_role = null
) => {
  try {
    setLoading(true);
    const { data } = await axios.put(
      BASE_URL + "updateShareFolderPermissions/",
      {
        folder_id: folder_data._id,
        editor_share_and_change_permissions: editor_share_and_change_permissions,
        option_visibility_viewer: option_visibility_viewer,
        general_access: general_access,
        public_access_permission: public_access_permission,
        rename_folder: rename_folder,
        move_files: move_files,
        change_user_role: change_user_role,
      },
      getHeaders()
    );
    setLoading(false);
    if (data.success) {
      const newData = {
        editor_share_and_change_permissions: data.data.editor_share_and_change_permissions,
        general_access: data.data.general_access,
        option_visibility_viewer: data.data.option_visibility_viewer,
        public_access_permission: data.data.public_access_permission,
      };
      setFolders((prevDataState) => ({
        ...prevDataState,
        data: prevDataState.data.map((element) => {
          if (element._id === folder_data._id) {
            return {
              ...element,
              ...newData,
            };
          }
          return element;
        }),
      }));
      folder_data = {
        ...folder_data,
        ...newData,
      };
    }
    console.log("updated data folder single", folder_data);
  } catch (error) {
    if (error.response.status == 409) {
    }
  }
};
const copyToClipboard = (shareableLink) => {
  try {
    navigator.clipboard.writeText(shareableLink);

    const Toast = Swal.mixin({
      toast: true,
      position: "top-right",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "success",
      title: "Only users with access will be able to use this link.",
      timer: 2000,
    });
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
};
function getRandomRgbColor() {
  const r = Math.floor(Math.random() * 256); // Random value between 0 and 255 for red
  const g = Math.floor(Math.random() * 256); // Random value between 0 and 255 for green
  const b = Math.floor(Math.random() * 256); // Random value between 0 and 255 for blue
  return `rgb(${r}, ${g}, ${b})`;
}

const Permission = ({ loading, mail, variant = "standard", permision, setPermission }) => {
  const menuItemStyles = {
    fontFamily: "Nunito, sans-serif",
  };
  return (
    <FormControl sx={{ m: 1, minWidth: permision === "commenter" ? 140 : 100 }} size="small" variant={variant}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={permision}
        disabled={loading}
        style={menuItemStyles}
        label="permision"
        autoWidth
        onChange={(event) => {
          event.preventDefault();
          setPermission(event.target.value);
        }}
      >
        <MenuItem style={menuItemStyles} value={"viewer"}>
          Viewer
        </MenuItem>
        <MenuItem style={menuItemStyles} value={"commenter"}>
          Commenter
        </MenuItem>
        <MenuItem style={menuItemStyles} value={"editor"}>
          Editor
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const GeneralAccess = ({ loading, setLoading, folder_data, setFolders }) => {
  const [generalAccess, setGeneralAccess] = useState(folder_data.general_access);
  const [permision, setPermission] = useState(folder_data.public_access_permission);
  const menuItemStyles = {
    fontFamily: "Nunito, sans-serif",
  };
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            backgroundColor: generalAccess === "public" ? "rgb(230,244,234)" : "#e3e3e3",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "30px",
            height: "30px",
            borderRadius: "20px",
            margin: "15px 7px 7px 7px",
          }}
        >
          {generalAccess === "public" ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" viewBox="0 0 512 512">
              <path
                fill="currentColor"
                d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
              />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" height="1.1em" viewBox="0 0 448 512" width="1em" color="red">
              <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
            </svg>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <div>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small" variant="standard">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={generalAccess}
                style={menuItemStyles}
                disabled={loading || folder_data.role !== "admin"}
                label="access"
                onChange={(event) => {
                  setGeneralAccess(event.target.value);
                  updateShareFolderPermissions(
                    setLoading,
                    setFolders,
                    folder_data,
                    null,
                    null,
                    event.target.value,
                    null,
                    null,
                    null,
                    null
                  );
                }}
              >
                <MenuItem style={menuItemStyles} value={"public"}>
                  Anyone with the link
                </MenuItem>
                <MenuItem style={menuItemStyles} value={"private"}>
                  Restricted
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              margin: "-8px 0 0 5px",
              fontSize: ".75rem",
              color: loading || folder_data.role !== "admin" ? "gray" : "black",
            }}
          >
            {generalAccess === "private"
              ? "Only people with access can open with the link"
              : "Anyone on the internet with the link can view"}
          </div>
        </div>
      </div>
      {generalAccess === "public" && (
        <div style={{ marginTop: "17px" }}>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small" variant="standard">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={permision}
              disabled={loading || folder_data.role !== "admin"}
              label="permision"
              onChange={(event) => {
                setPermission(event.target.value);
                updateShareFolderPermissions(
                  setLoading,
                  setFolders,
                  folder_data,
                  null,
                  null,
                  null,
                  event.target.value,
                  null,
                  null,
                  null
                );
              }}
            >
              <MenuItem style={menuItemStyles} value={"viewer"}>
                Viewer
              </MenuItem>
              <MenuItem style={menuItemStyles} value={"commenter"}>
                Commenter
              </MenuItem>
              <MenuItem style={menuItemStyles} value={"editor"}>
                Editor
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

const AccessUser = ({ folder_data, user, handleRemoveAccess, setUserPermission, setCurrentUser }) => {
  const [permission, setPermission] = useState(user.permission);
  const menuItemStyles = {
    fontFamily: "Nunito, sans-serif",
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-between", margin: "8px 0" }}>
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            width: "50px",
            height: "50px",
            textAlign: "center",
            borderRadius: "50%",
            backgroundColor: "rgb(20, 150, 227)",
            paddingTop: "5px",
            color: "white",
            fontSize: "27px",
            fontWeight: "bold",
          }}
        >
          {user.email[0].toUpperCase()}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ fontWeight: "600", color: "black" }}>{user.email}</div>
          <div style={{ marginTop: "-5px", fontSize: ".75rem", color: "black" }}>{user.email}</div>
        </div>
      </div>
      <FormControl sx={{ m: 1, minWidth: permission === "commenter" ? 140 : 100 }} size="small" variant={"standard"}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={permission}
          style={menuItemStyles}
          disabled={folder_data.role === "editor" && !folder_data.editor_can_change_user_role}
          label="permision"
          autoWidth
          onChange={(event) => {
            event.preventDefault();
            setPermission(event.target.value);
            setUserPermission(event.target.value);
            setCurrentUser({ ...user, permission: event.target.value });
          }}
        >
          <MenuItem style={menuItemStyles} value={"viewer"}>
            Viewer
          </MenuItem>
          <MenuItem style={menuItemStyles} value={"commenter"}>
            Commenter
          </MenuItem>
          <MenuItem style={menuItemStyles} value={"editor"}>
            Editor
          </MenuItem>
          <div style={{ display: "flex", flexDirection: "column", fontSize: "1rem", boxShadow: "-2px -8px 5px -9px" }}>
            <Button
              onClick={() => {
                handleRemoveAccess(user);
                // setPermission(permission);
              }}
              style={{
                outline: "none",
                fontSize: "1rem",
                color: "black",
                textAlign: "left",
                textTransform: "none",
                fontWeight: 400,
              }}
            >
              Remove access
            </Button>
            {/* <Button
              onClick={() => {
                handleRemoveAccess(user);
              }}
              style={{
                outline: "none",
                fontSize: "1rem",
                color: "black",
                textAlign: "left",
                textTransform: "none",
                fontWeight: 400,
              }}
            >
              Transfer Ownership
            </Button> */}
          </div>
        </Select>
      </FormControl>
    </div>
  );
};

const AutoCompleteInput = ({ loading, contacts, setTab, emails, setEmails }) => {
  const [inputValue, setInputValue] = useState("");

  const handleOptionSelect = (event, newValue) => {
    setEmails(newValue);
  };
  useEffect(() => {
    if (emails.length > 0) {
      setTab("mail");
    } else {
      setTab("main");
    }
  }, [emails]);

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        multiple
        disabled={loading}
        value={emails}
        inputValue={inputValue}
        onChange={handleOptionSelect}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="multiselect-input"
        options={contacts}
        freeSolo
        renderInput={(params) => (
          <TextField disabled={loading} {...params} label="Select or Enter" variant="outlined" fullWidth />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
        }
      />
    </div>
  );
};

const addBulkFolderParticipants = async (
  setLoading,
  setFolders,
  participants,
  permission,
  folder_id,
  notify,
  message,
  setParticipants,
  setEmails,
  setShareFolderToContacts,
  ShowSuccessMessage
) => {
  try {
    setLoading(true);
    const { data } = await axios.post(
      BASE_URL + "addParticipantsToSharedFolder/",
      {
        folder_id: folder_id,
        participants: participants,
        permission: permission,
        notify: notify,
        message: message,
      },
      getHeaders()
    );
    setFolders((prevDataState) => ({
      ...prevDataState,
      data: prevDataState.data.map((element) => {
        if (element._id === folder_id) {
          return {
            ...element,
            participants: data.participants,
          };
        }
        return element;
      }),
    }));
    setParticipants(data.participants);
    setEmails([]);
    setLoading(false);
    ShowSuccessMessage("Participants added successfully", "success");
    setShareFolderToContacts({ share: false, folderName: "" });
  } catch (error) {
    setEmails([]);
    setLoading(false);
    setShareFolderToContacts({ share: false, folderName: "" });
    ShowSuccessMessage("Error while adding Participants", "error");
    if (error.response.status == 409) {
    }
  }
};

const ShareFolderModal = ({
  ShowSuccessMessage,
  setFolders,
  setShareFolderToContacts,
  shareFolderToContacts,
  folder_data,
  currentOutFolderid,
}) => {
  const [contacts, setContacts] = useState([]);
  const [emails, setEmails] = useState([]);
  const [tab, setTab] = useState("main");
  const [permission, setPermission] = useState("viewer");
  const [emailMessage, setEmailMessage] = useState("");
  const [userPermission, setUserPermission] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const existingParticipants = folder_data.participants;
  const [participants, setParticipants] = useState(existingParticipants);
  const [changedParticipants, setChangedParticipants] = useState([]);
  const [deletedParticipants, setDeletedParticipants] = useState([]);
  const [editorPermission, setEditorPermission] = useState(folder_data.editor_share_and_change_permissions);
  const [editorChangeRole, setEditorChangeRole] = useState(folder_data.editor_can_change_user_role);
  const [editorRenamePermission, setEditorRenamePermission] = useState(folder_data.editor_can_rename);
  const [editorCanMoveFiles, setEditorCanMoveFiles] = useState(folder_data.editor_can_move_files);
  const [viewerVisibility, setViewerVisibility] = useState(folder_data.option_visibility_viewer);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (currentUser !== "" && userPermission !== "") {
      let exist =
        existingParticipants.find((participant) => participant._id === currentUser._id).permission ===
        currentUser.permission;

      const deleted =
        changedParticipants.length > 0
          ? changedParticipants.filter((participant) => participant._id !== currentUser._id)
          : [];

      if (!exist) {
        deleted.push(currentUser);
      }
      exist = false;
      setChangedParticipants(deleted);
      setUserPermission("");
    }
  }, [userPermission]);
  useEffect(() => {
    if (contacts.length === 0) {
      getContacts(setContacts);
    }
  }, []);
  const handleRemoveAccess = (user) => {
    let deleted = participants.length > 0 ? participants.filter((participant) => participant._id !== user._id) : [];

    setParticipants([...deleted]);
    deleted =
      changedParticipants.length > 0 ? changedParticipants.filter((participant) => participant._id !== user._id) : [];
    setChangedParticipants(deleted);
    const data = deletedParticipants;
    data.push(user);
    setDeletedParticipants(data);
  };
  const handleUpdateParticipants = async () => {
    if (deletedParticipants.length > 0 || changedParticipants.length > 0) {
      try {
        setLoading(true);
        const { data } = await axios.put(
          BASE_URL + "updateSharedFolderParticipants/",
          {
            folder_id: folder_data._id,
            delete_participants: deletedParticipants,
            update_participants: changedParticipants,
          },
          getHeaders()
        );
        setLoading(false);
        if (data.success) {
          ShowSuccessMessage("Participants updated successfully", "success");
          setChangedParticipants([]);
          setDeletedParticipants([]);
          setParticipants(data.updated_participants);
          setFolders((prevDataState) => ({
            ...prevDataState,
            data: prevDataState.data.map((element) => {
              if (element._id === folder_data._id) {
                return {
                  ...element,
                  participants: data.updated_participants,
                };
              }
              return element;
            }),
          }));
          setShareFolderToContacts({ share: false, folderName: "" });
        }
      } catch (error) {
        setChangedParticipants([]);
        setDeletedParticipants([]);
        setParticipants(existingParticipants);
        setLoading(false);
        setShareFolderToContacts({ share: false, folderName: "" });
        ShowSuccessMessage("Error while updating Participants", "error");
        console.error(error);
      }
    } else {
      setShareFolderToContacts({ share: false, folderName: "" });
    }
  };
  const [notify, setNotify] = useState(true);
  const body = (
    <div
      style={{
        backgroundColor: "white",
        width: "500px",
        borderRadius: "5px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "25px",
        font: "Roboto",
        overflow: "hidden",
      }}
    >
      {loading && (
        <div style={{ margin: "-26px 0 22px -25px", width: "111%" }}>
          <LinearProgress />
        </div>
      )}
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "22px", fontWeight: "600", color: "black", marginBottom: "8px" }}>
            {tab === "settings" && (
              <ArrowBack style={{ cursor: "pointer", margin: "0 4px 6px 0" }} onClick={() => setTab("main")} />
            )}
            {tab !== "settings" ? "Share" : "Settings for"} " {shareFolderToContacts.folderName} "
          </div>
          {tab !== "settings" && folder_data.role === "admin" && (
            <div style={{ display: "flex" }}>
              <div>
                <a class="btn btn-trigger btn-icon dropdown-toggle">
                  <em class="icon ni ni-help"></em>
                </a>
              </div>
              <div>
                <a class="btn btn-trigger btn-icon dropdown-toggle" onClick={() => setTab("settings")}>
                  <em class="icon ni ni-setting"></em>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {tab !== "settings" &&
        ((folder_data.role === "editor" && folder_data.editor_share_and_change_permissions) ||
          folder_data.role === "admin") && (
          <div style={{ display: "flex" }}>
            <AutoCompleteInput
              loading={loading}
              emails={emails}
              setEmails={setEmails}
              contacts={contacts}
              setTab={setTab}
            />
            {tab === "mail" && <Permission loading={loading} permision={permission} setPermission={setPermission} />}
          </div>
        )}
      {tab === "mail" && (
        <div>
          <div style={{ display: "flex", alignItems: "center", margin: "5px 0 5px 0" }}>
            <Checkbox
              disabled={loading}
              onChange={() => {
                setNotify(!notify);
              }}
              checked={notify}
            />
            <div>Notify people</div>
          </div>
          {notify && (
            <TextField
              multiline
              disabled={loading}
              onChange={(e) => {
                setEmailMessage(e.target.value);
              }}
              value={emailMessage}
              minRows={4}
              maxRows={4}
              fullWidth
              id="message"
              label="Message"
              variant="outlined"
            />
          )}
          <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0 10px 0" }}>
            <Button
              variant="contained"
              style={{
                borderRadius: "25px",
                color: "rgb(20 155 227)",
                backgroundColor: "white",
                fontWeight: "bold",
                border: "none",
                outline: "none",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="1.5em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M12.586 4.586a2 2 0 1 1 2.828 2.828l-3 3a2 2 0 0 1-2.828 0a1 1 0 0 0-1.414 1.414a4 4 0 0 0 5.656 0l3-3a4 4 0 0 0-5.656-5.656l-1.5 1.5a1 1 0 1 0 1.414 1.414l1.5-1.5Zm-5 5a2 2 0 0 1 2.828 0a1 1 0 1 0 1.414-1.414a4 4 0 0 0-5.656 0l-3 3a4 4 0 1 0 5.656 5.656l1.5-1.5a1 1 0 1 0-1.414-1.414l-1.5 1.5a2 2 0 1 1-2.828-2.828l3-3Z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                style={{
                  borderRadius: "25px",
                  color: "rgb(20 155 227)",
                  backgroundColor: "white",
                  fontWeight: "bold",
                  border: "none",
                  outline: "none",
                }}
              >
                <div>Cancel</div>
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  addBulkFolderParticipants(
                    setLoading,
                    setFolders,
                    emails,
                    permission,
                    currentOutFolderid,
                    notify,
                    emailMessage,
                    setParticipants,
                    setEmails,
                    setShareFolderToContacts,
                    ShowSuccessMessage
                  );
                }}
                style={{
                  borderRadius: "25px",
                  backgroundColor: "rgb(20 155 227)",
                  color: "white",
                  fontWeight: "bold",
                  border: "none",
                  outline: "none",
                }}
              >
                <div>Done</div>
              </Button>
            </div>
          </div>
        </div>
      )}
      {tab === "main" && (
        <div>
          <div>
            <div style={{ fontSize: "22px", margin: "15px 0", fontWeight: "600", color: "black" }}>
              People with Access
            </div>
            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "8px 0" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      textAlign: "center",
                      borderRadius: "50%",
                      backgroundColor: "green",
                      paddingTop: "5px",
                      color: "white",
                      fontSize: "27px",
                      fontWeight: "bold",
                    }}
                  >
                    {folder_data.owner_email[0]?.toUpperCase()}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ fontWeight: "600", color: "black" }}>{folder_data.owner_email}</div>
                    <div style={{ marginTop: "-5px", fontSize: ".75rem", color: "black" }}>
                      {folder_data.owner_email}
                    </div>
                  </div>
                </div>
                <div style={{ margin: " 15px 10px 0 0", fontSize: "16px" }}>Owner</div>
              </div>
              {participants.length > 0 &&
                participants.map((user) => (
                  <AccessUser
                    folder_data={folder_data}
                    key={user._id}
                    user={user}
                    handleRemoveAccess={handleRemoveAccess}
                    setUserPermission={setUserPermission}
                    setCurrentUser={setCurrentUser}
                  />
                ))}
            </div>
          </div>
          <div>
            <div style={{ fontSize: "22px", margin: "15px 0", fontWeight: "600", color: "black" }}>General access</div>
            <GeneralAccess
              loading={loading}
              setLoading={setLoading}
              folder_data={folder_data}
              setFolders={setFolders}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0 10px 0" }}>
            <Button
              variant="contained"
              onClick={() => {
                copyToClipboard(folder_data.shareable_link);
              }}
              style={{
                borderRadius: "25px",
                color: "rgb(20 155 227)",
                backgroundColor: "white",
                fontWeight: "bold",
                border: "none",
                outline: "none",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="1.5em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M12.586 4.586a2 2 0 1 1 2.828 2.828l-3 3a2 2 0 0 1-2.828 0a1 1 0 0 0-1.414 1.414a4 4 0 0 0 5.656 0l3-3a4 4 0 0 0-5.656-5.656l-1.5 1.5a1 1 0 1 0 1.414 1.414l1.5-1.5Zm-5 5a2 2 0 0 1 2.828 0a1 1 0 1 0 1.414-1.414a4 4 0 0 0-5.656 0l-3 3a4 4 0 1 0 5.656 5.656l1.5-1.5a1 1 0 1 0-1.414-1.414l-1.5 1.5a2 2 0 1 1-2.828-2.828l3-3Z"
                  clipRule="evenodd"
                />
              </svg>
              <div>Copy Link</div>
            </Button>
            <div style={{ display: "flex", gap: "10px" }}>
              {(deletedParticipants.length > 0 || changedParticipants.length > 0) && (
                <div style={{ padding: "3px", color: loading ? "gray" : "black", fontStyle: "initial" }}>
                  Pending changes
                </div>
              )}
              <Button
                variant="contained"
                style={{
                  borderRadius: "25px",
                  backgroundColor: loading ? "#e1e1e1" : "rgb(20 155 227)",
                  color: "white",
                  fontWeight: "bold",
                  border: "none",
                  outline: "none",
                }}
                disabled={loading}
                onClick={handleUpdateParticipants}
              >
                <div>{deletedParticipants.length > 0 || changedParticipants.length > 0 ? "Save" : "Done"}</div>
              </Button>
            </div>
          </div>
        </div>
      )}
      {tab === "settings" && (
        <div style={{ color: loading ? "gray" : "black" }}>
          {folder_data.role !== "editor" && (
            <div style={{ display: "flex", alignItems: "center", margin: "4px 0 4px 0" }}>
              <Checkbox
                disabled={loading}
                onChange={() => {
                  setEditorPermission(!editorPermission);
                  updateShareFolderPermissions(
                    setLoading,
                    setFolders,
                    folder_data,
                    !editorPermission,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  );
                }}
                checked={editorPermission}
              />
              <div>Editors can share the folder.</div>
            </div>
          )}
          {/* allow editor to rename folder */}
          <div style={{ display: "flex", alignItems: "center", margin: "4px 0 4px 0" }}>
            <Checkbox
              disabled={loading}
              onChange={() => {
                setEditorRenamePermission(!editorRenamePermission);
                updateShareFolderPermissions(
                  setLoading,
                  setFolders,
                  folder_data,
                  null,
                  null,
                  null,
                  null,
                  !editorRenamePermission,
                  null,
                  null
                );
              }}
              checked={editorRenamePermission}
            />
            <div>Editors can rename folder.</div>
          </div>
          {/* allow editor to move files */}
          <div style={{ display: "flex", alignItems: "center", margin: "4px 0 4px 0" }}>
            <Checkbox
              disabled={loading}
              onChange={() => {
                setEditorCanMoveFiles(!editorCanMoveFiles);
                updateShareFolderPermissions(
                  setLoading,
                  setFolders,
                  folder_data,
                  null,
                  null,
                  null,
                  null,
                  null,
                  !editorCanMoveFiles,
                  null
                );
              }}
              checked={editorCanMoveFiles}
            />
            <div>Editors can move folder files.</div>
          </div>
          {/* change user role permission to editor */}
          <div style={{ display: "flex", alignItems: "center", margin: "4px 0 4px 0" }}>
            <Checkbox
              disabled={loading}
              onChange={() => {
                setEditorChangeRole(!editorChangeRole);
                updateShareFolderPermissions(
                  setLoading,
                  setFolders,
                  folder_data,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  !editorChangeRole
                );
              }}
              checked={editorChangeRole}
            />
            <div>Editors can change user role.</div>
          </div>
          <div style={{ display: "flex", alignItems: "center", margin: "4px 0 4px 0" }}>
            <Checkbox
              disabled={loading}
              onChange={() => {
                setViewerVisibility(!viewerVisibility);
                updateShareFolderPermissions(setLoading, setFolders, folder_data, null, !viewerVisibility, null, null);
              }}
              checked={viewerVisibility}
            />
            <div>Viewers and commenters can see the option to download, print, and copy.</div>
          </div>
        </div>
      )}
    </div>
  );
  return (
    <Modal
      disableEnforceFocus
      open={shareFolderToContacts.share}
      style={{ zIndex: 800 }}
      onClose={() => {
        setChangedParticipants([]);
        setParticipants((prev) => [...prev, ...deletedParticipants]);
        setDeletedParticipants([]);
        setTab("main");
        setShareFolderToContacts({ share: false, folderName: "" });
      }}
      aria-labelledby="simple-modal-title"
    >
      {body}
    </Modal>
  );
};

export default ShareFolderModal;
