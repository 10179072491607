import {
  GET_FILE_FAIL,
  GET_FILE_REQUEST,
  GET_FILE_SUCCESS,
  GET_FOLDER_FAIL,
  GET_FOLDER_REQUEST,
  GET_FOLDER_SUCCESS,
  GET_OCR_DOCUMENT_REQUEST,
  GET_OCR_DOCUMENT_SUCCESS,
  GET_OCR_DOCUMENT_FAIL,
  REMOVE_FILE_FAIL,
  REMOVE_FILE_REQUEST,
  REMOVE_FILE_SUCCESS,
  REMOVE_FOLDER_FAIL,
  REMOVE_FOLDER_REQUEST,
  REMOVE_FOLDER_SUCCESS,
  UPDATE_FOLDER_REQUEST,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAIL,
  CREATE_FILE_FAIL,
  CREATE_FILE_REQUEST,
  CREATE_FILE_SUCCESS,
  CREATE_FOLDER_FAIL,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  UPDATE_FILE_FAIL,
  UPDATE_FILE_REQUEST,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILENAME_FAIL,
  UPDATE_FILENAME_REQUEST,
  UPDATE_FILENAME_SUCCESS,
  UPDATE_FOLDERID_FAIL,
  UPDATE_FOLDERID_SUCCESS,
  UPDATE_FOLDERID_REQUEST,
  UPDATE_OCR_SUCCESS,
  UPDATE_OCR_FAIL,
  REMOVE_OCR_DOCUMENT_SUCCESS,
  REMOVE_OCR_DOCUMENT_FAIL
} from '../constants/assetsConstants';

export const getAssetsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FILE_REQUEST:
      return {...state, loading: true}
    case GET_FILE_SUCCESS:
      console.log('action.payload', action.payload)
      return {...state, loading: false, file: action.payload}
    case GET_FILE_FAIL:
      return {loading: false, error: action.payload}
    case GET_OCR_DOCUMENT_REQUEST:
      return {...state, loading: true}
    case GET_OCR_DOCUMENT_SUCCESS:
      console.log('action.payload ocr', action.payload)
      return {...state, loading: false, ocr: action.payload}
    case GET_OCR_DOCUMENT_FAIL:
      return {loading: false, error: action.payload}
    case GET_FOLDER_SUCCESS:
      console.log('action.payload', action.payload)
      return {...state, loading: false, folder: action.payload}
    case GET_FOLDER_FAIL:
      return {loading: false, error: action.payload}
    case REMOVE_OCR_DOCUMENT_SUCCESS:
      console.log('file id', action.payload)
      let updatedocrarray = state.ocr.filter(
        (files) => files._id != action.payload
      )
      console.log('updatedfilearray', updatedocrarray)

      return {
        ...state,
        loading: false,
        ocr: updatedocrarray,
      }
    case REMOVE_OCR_DOCUMENT_FAIL:
      return {loading: false, error: action.payload}
    case REMOVE_FILE_SUCCESS:
      console.log('file id', action.payload)
      let updatedfilearray = state.file.filter(
        (files) => files._id != action.payload
      )
      console.log('updatedfilearray', updatedfilearray)

      return {
        ...state,
        loading: false,
        file: updatedfilearray,
      }
    case REMOVE_FILE_FAIL:
      return {loading: false, error: action.payload}
    case REMOVE_FOLDER_SUCCESS:
      console.log('folder id', action.payload)
      let updatedfolderarray = state.folder.filter(
        (folders) => folders._id != action.payload
      )
      console.log('updatedfolderarray', updatedfolderarray)

      return {
        ...state,
        loading: false,
        folder: updatedfolderarray,
      }
    case REMOVE_FOLDER_FAIL:
      return {loading: false, error: action.payload}
    case CREATE_FILE_SUCCESS:
      return {
        ...state,
        file: [...state.file, action.payload],
        loading: false,
      }
    case CREATE_FILE_FAIL:
      return {loading: false, error: action.payload}
    case CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        folder: [...state.folder, action.payload],
        loading: false,
      }
    case CREATE_FOLDER_FAIL:
      return {loading: false, error: action.payload}
    default:
      return state
    case UPDATE_FILE_SUCCESS:
      const indexFile = state.file.findIndex(
        (files) => files._id === action.payload._id
      )
      let newfiles = state.file
      console.log('indexFile', indexFile)
      console.log('newfiles1', newfiles)

      newfiles[indexFile] = action.payload
      console.log('newfiles2', newfiles)
      return {
        ...state,
        loading: false,
        file: [...newfiles],
      }
    case UPDATE_FOLDER_SUCCESS:
      const indexFolder = state.folder.findIndex(
        (folders) => folders._id === action.payload._id
      )
      let newfolders = state.folder
      console.log('indexFolder', indexFolder)
      console.log('newfolder1', newfolders)

      newfolders[indexFolder] = action.payload
      console.log('newfolders2', newfolders)
      return {
        ...state,
        loading: false,
        folder: [...newfolders],
      }
    case UPDATE_FILENAME_SUCCESS:
      const indexFiles = state.file.findIndex(
        (files) => files._id === action.payload._id
      )
      let newfile = state.file
      console.log('indexFile', indexFiles)
      console.log('newfiles1', newfile)

      newfile[indexFiles] = action.payload
      console.log('newfiles2', newfile)
      return {
        ...state,
        loading: false,
        file: [...newfile],
      }
    case UPDATE_FOLDERID_SUCCESS:
      const indexFiless = state.file.findIndex(
        (files) => files._id === action.payload._id
      )
      let newfiless = state.file
      console.log('indexFile', indexFiless)
      console.log('newfiles1', newfiless)

      newfiless[indexFiless] = action.payload
      console.log('newfiles2', newfiless)
      return {
        ...state,
        loading: false,
        file: [...newfiless],
      }
    case UPDATE_OCR_SUCCESS:
      console.log("state.ocr = ", state.ocr)
    // const indexFiless = state.file.findIndex(
    //   (files) => files._id === action.payload._id
    // )
    // let newfiless = state.file
    // console.log('indexFile', indexFiless)
    // console.log('newfiles1', newfiless)

    // newfiless[indexFiless] = action.payload
    // console.log('newfiles2', newfiless)
    // return {
    //   ...state,
    //   loading: false,
    //   file: [...newfiless],
    // }
  }
}
