import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "../assets/css/dashlite.css";
import "../assets/css/theme.css";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import { BASE_URL } from "../constants/apiconstant";
import { getHeaders } from "../utils";
import { useSnackbar } from "notistack";
import moment from "moment";
import Paginator from "../components/Pagination";
import { CircularProgress } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

const NotificationPage = () => {
  const [search, setSearch] = React.useState(false);
  const [filter, setFilter] = React.useState(false);
  const [setting, setSetting] = React.useState(false);
  const [page, setPage] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [csvGenerateLoading, setCsvGenerateLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dropdown, setDropdown] = React.useState({
    number: "",
    selected: false,
  });
  const { number, selected } = dropdown;
  const [bodyWidth, setBodyWidth] = React.useState(false);

  const [order, setOrder] = useState("DESC");
  const [showExportDiv, setShowExportDiv] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("created_at");
  const [showFilterDiv, setShowFilterDiv] = useState(false);
  useEffect(() => {
    getNotifications(page, rowsPerPage, order, selectedSortColumn);
  }, []);
  const { enqueueSnackbar } = useSnackbar();

  const getNotifications = async (
    _page,
    _rowsPerPage,
    sortOrder,
    sortColumn
  ) => {
    try {
      if (_page !== page) setPage(_page);
      const { data } = await axios.get(
        BASE_URL +
          `get-notifications?page=${_page}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`,
        getHeaders()
      );

      setNotifications(data.data);
      setPageCount(data.count);
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong getting notifications", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  //
  const generateCsv = async () => {
    try {
      setCsvGenerateLoading(true);
      const { data } = await axios.get(
        BASE_URL + "generate-csv-for-notifications",
        getHeaders()
      );

      console.log("data", data);
      axios({
        url: BASE_URL + "media/" + data.csvFileName, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "notifications.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went while generating csv file", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    setCsvGenerateLoading(false);
  };
  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };
  // Dummy data
  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"],
  ];

  //   Dropdown function
  const showDropdown = (e, index) => {
    if (index === number) {
      setDropdown({
        ...dropdown,
        number: "",
        selected: false,
      });
    } else {
      setDropdown({
        ...dropdown,
        number: 0,
        selected: true,
      });
    }
  };
  const getSortOrder = (selected) => {
    let sortOrder = order;
    if (selectedSortColumn === selected) {
      if (order === "ASC") {
        sortOrder = "DESC";
      } else {
        sortOrder = "ASC";
      }
    } else {
      sortOrder = "DESC";
    }
    setOrder(sortOrder);
    return sortOrder;
  };
  return (
    <div className="p-2">
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />
              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div
                      className="nk-fmg"
                      style={bodyWidth ? { paddingLeft: 80 } : null}
                    >
                      <Sidebar getState={sidebarState} />
                      {/* .nk-fmg-aside */}
                      <div className="nk-fmg-body">
                        <div className="nk-block">
                          <div className="my-3 row">
                            <h3 className="col-lg-8 nunito-font">
                              Notifications
                            </h3>
                            <div className="col-lg-4 text-right">
                              <Link
                                to={"/dashboard"}
                                className="btn btn-outline-light text-white d-none d-sm-inline-flex"
                                style={{ backgroundColor: 'rgb(20, 155, 227)'}}
                              >
                                <em className="icon ni ni-arrow-left"></em>
                                <span>Back</span>
                              </Link>
                            </div>
                          </div>
                          <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                              <div className="card-inner position-relative card-tools-toggle">
                                <div
                                  className={
                                    "card-title-group " +
                                    (search ? "d-none" : null)
                                  }
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <div className="card-tools mr-n1">
                                    <ul className="btn-toolbar gx-1">
                                      <li>
                                        <div className="toggle-wrap d-block">
                                          <a
                                            href="#"
                                            className="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                          >
                                            <em className="icon ni ni-menu-right"></em>
                                          </a>
                                          <div
                                            className="toggle-content"
                                            data-content="cardTools"
                                          >
                                            <ul className="btn-toolbar gx-1">
                                              <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() =>
                                                      setShowExportDiv(
                                                        !showExportDiv
                                                      )
                                                    }
                                                    data-toggle="dropdown11"
                                                  >
                                                    <em className="icon ni ni-filter-alt"></em>
                                                  </a>
                                                  <div
                                                    style={{
                                                      display: showExportDiv
                                                        ? "block"
                                                        : "none",
                                                    }}
                                                    className={
                                                      "filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right " +
                                                      (filter
                                                        ? "d-block"
                                                        : null)
                                                    }
                                                  >
                                                    <div className="dropdown-head">
                                                      {csvGenerateLoading ? (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <CircularProgress
                                                            style={{color:"rgb(20, 155, 227)"}}
                                                            size={20}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <span
                                                          onClick={generateCsv}
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="sub-title dropdown-title"
                                                        >
                                                          Export CSV
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() =>
                                                      setShowFilterDiv(
                                                        !showFilterDiv
                                                      )
                                                    }
                                                    data-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-setting"></em>
                                                  </a>
                                                  <div
                                                    style={{
                                                      display: showFilterDiv
                                                        ? "block"
                                                        : "none",
                                                      minWidth: "auto",
                                                    }}
                                                    className={
                                                      "filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right " +
                                                      (filter
                                                        ? "d-block"
                                                        : null)
                                                    }
                                                  >
                                                    <div className="dropdown-head">
                                                      <span className="sub-title dropdown-title">
                                                        Filter
                                                      </span>
                                                      <div className="dropdown">
                                                        <a
                                                          href="#"
                                                          className="btn btn-sm btn-icon"
                                                        >
                                                          {/*   <em className="icon ni ni-more-h"></em> */}
                                                        </a>
                                                      </div>
                                                    </div>
                                                    <div className="dropdown-body dropdown-body-rg">
                                                      <div className="form-group">
                                                        <label className="overline-title overline-title-alt">
                                                          Size
                                                        </label>
                                                        <ul className="link-check">
                                                          <li
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            className={
                                                              rowsPerPage ===
                                                                20 && "active"
                                                            }
                                                            onClick={() => {
                                                              setRowsPerPage(
                                                                20
                                                              );
                                                              getNotifications(
                                                                1,
                                                                20,
                                                                order,
                                                                selectedSortColumn
                                                              );
                                                            }}
                                                          >
                                                            <a
                                                              style={{
                                                                paddingLeft: 0,
                                                              }}
                                                            >
                                                              20
                                                            </a>
                                                          </li>
                                                          <li
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              setRowsPerPage(
                                                                50
                                                              );
                                                              getNotifications(
                                                                1,
                                                                50,
                                                                order,
                                                                selectedSortColumn
                                                              );
                                                            }}
                                                            className={
                                                              rowsPerPage ===
                                                                50 && "active"
                                                            }
                                                          >
                                                            <a
                                                              style={{
                                                                paddingLeft: 0,
                                                              }}
                                                            >
                                                              50
                                                            </a>
                                                          </li>
                                                          <li
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              getNotifications(
                                                                1,
                                                                100,
                                                                order,
                                                                selectedSortColumn
                                                              );
                                                              setRowsPerPage(
                                                                100
                                                              );
                                                            }}
                                                            className={
                                                              rowsPerPage ===
                                                                100 && "active"
                                                            }
                                                          >
                                                            <a
                                                              style={{
                                                                paddingLeft: 0,
                                                              }}
                                                            >
                                                              {" "}
                                                              100
                                                            </a>
                                                          </li>
                                                        </ul>

                                                        {/*   <div className="col-12">
                                                          <div className="form-group">
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary"
                                                            >
                                                              Filter
                                                            </button>
                                                          </div>
                                                        </div> */}
                                                      </div>
                                                    </div>
                                                    {/*   <div className="dropdown-foot between">
                                                      <a
                                                        className="clickable"
                                                        href="#"
                                                      >
                                                        Reset Filter
                                                      </a>
                                                      <a href="#">
                                                        Save Filter
                                                      </a>
                                                    </div> */}
                                                  </div>
                                                </div>

                                                {/*       <div className="dropdown"> */}
                                                {/*  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() =>
                                                      setSetting(!setting)
                                                    }
                                                    data-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-setting"></em>
                                                  </a> */}

                                                {/*     <div
                                                    className={
                                                      "dropdown-menu dropdown-menu-xs dropdown-menu-right " +
                                                      (setting
                                                        ? "d-block"
                                                        : null)
                                                    }
                                                  >
                                                    <ul className="link-check">
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className={
                                                          rowsPerPage === 20 &&
                                                          "active"
                                                        }
                                                        onClick={() => {
                                                          setRowsPerPage(20);
                                                          getInfo(1, 20);
                                                        }}
                                                      >
                                                        <a>20</a>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setRowsPerPage(50);
                                                          getInfo(1, 50);
                                                        }}
                                                        className={
                                                          rowsPerPage === 50 &&
                                                          "active"
                                                        }
                                                      >
                                                        <a>50</a>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          getInfo(1, 100);
                                                          setRowsPerPage(100);
                                                        }}
                                                        className={
                                                          rowsPerPage === 100 &&
                                                          "active"
                                                        }
                                                      >
                                                        <a>100</a>
                                                      </li>


                                                    </ul>
                                                  </div>
                                               */}
                                                {/*  </div> */}
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className={
                                    "card-search search-wrap " +
                                    (search ? "active" : null)
                                  }
                                  data-search="search"
                                >
                                  <div className="card-body">
                                    <div className="search-content">
                                      <a
                                        href="#"
                                        className="search-back btn btn-icon toggle-search"
                                        onClick={() => setSearch(false)}
                                        data-target="search"
                                      >
                                        <em className="icon ni ni-arrow-left"></em>
                                      </a>
                                      <input
                                        type="text"
                                        className="form-control border-transparent form-focus-none"
                                        placeholder="Search by user or email"
                                      />
                                      <button className="search-submit btn btn-icon">
                                        <em className="icon ni ni-search"></em>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist is-compact">
                                  <div className="nk-tb-item nk-tb-head">
                                    <div
                                      onClick={(e) => {
                                        getNotifications(
                                          1,
                                          20,
                                          getSortOrder("type"),
                                          "type"
                                        );
                                        setSelectedSortColumn("type");
                                      }}
                                      className="nk-tb-col tb-col-md"
                                      style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span className="sub-text">
                                        Notification Type{" "}
                                        {selectedSortColumn === "type" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>
                                    <div
                                      className="nk-tb-col tb-col-md"
                                      style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        getNotifications(
                                          1,
                                          20,
                                          getSortOrder("document"),
                                          "document"
                                        );
                                        setSelectedSortColumn("document");
                                      }}
                                    >
                                      <span className="sub-text">
                                        Document Name{" "}
                                        {selectedSortColumn === "document" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>
                                    <div
                                      className="nk-tb-col tb-col-lg"
                                      style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        getNotifications(
                                          1,
                                          20,
                                          getSortOrder("created_at"),
                                          "created_at"
                                        );
                                        setSelectedSortColumn("created_at");
                                      }}
                                    >
                                      <span className="sub-text">
                                        Date{" "}
                                        {selectedSortColumn === "created_at" ? (
                                          order === "ASC" ? (
                                            <ArrowUpward />
                                          ) : (
                                            <ArrowDownward />
                                          )
                                        ) : null}
                                      </span>
                                    </div>

                                    {/*  <div className="nk-tb-col nk-tb-col-tools text-right"></div> */}
                                  </div>
                                  {notifications.length > 0 ? (
                                    notifications.map((notification, index) => (
                                      <div className="nk-tb-item">
                                        <div className="nk-tb-col tb-col-md">
                                          <span>{notification.type}</span>
                                        </div>

                                        <div className="nk-tb-col tb-col-md">
                                          <span>
                                            {
                                              notification?.document
                                                ?.originalFilename || notification?.shared_file?.original_file_name
                                            }
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span>
                                            {moment(
                                              notification.created_at
                                            ).format("MMM-DD-YYYY hh:mma")}
                                          </span>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <p
                                      style={{
                                        margin: "1rem",
                                        textAlign: "center",
                                        width: "100%",
                                      }}
                                    >
                                      No Information Found
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="card-inner">
                                <Paginator
                                  pageCount={pageCount}
                                  onChange={(_page) =>
                                    getNotifications(
                                      _page,
                                      rowsPerPage,
                                      order,
                                      selectedSortColumn
                                    )
                                  }
                                />
                                {/*   <ul className="pagination justify-content-center justify-content-md-start">
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      Prev
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      1
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      2
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <span className="page-link">
                                      <em className="icon ni ni-more-h"></em>
                                    </span>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      6
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      7
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              
                               */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}
        </div>
      </div>
    </div>
  );
};
export default NotificationPage;
