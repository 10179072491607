import { CircularProgress, TextField } from "@material-ui/core";
import { Contactless, PermContactCalendar } from "@material-ui/icons";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../constants/apiconstant";
import { getHeaders } from "../../utils";
import { Modal } from "../configuration/components/Modal";

export default function DeviceAndLocationModal({ onClose, documentId }) {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      const { data } = await axios.get(
        BASE_URL + "get-device-info-by-document-id?document_id=" + documentId,
        getHeaders()
      );

      setInfo(data.data);
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong while getting the info", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    setLoading(false);
  };
  return (
    <Modal
      cancelButtonVariant={"contained"}
      loading={loading}
      onClose={onClose}
      showConfirmButton={false}
      cancelButtonText={"Close"}
      isOpen={true}
      modalTitle="Document Audit Trail"
      width="80%"
      modalContent={
        <div>
          <div className="card-inner p-0">
            <div className="nk-tb-list nk-tb-ulist is-compact">
              <div className="nk-tb-item nk-tb-head">
                <div className="nk-tb-col p-3 tb-col-md">
                  <span className="sub-text">DOCUMENT NAME</span>
                </div>
                <div className="nk-tb-col p-3 tb-col-sm">
                  <span className="sub-text">IP</span>
                </div>
                <div className="nk-tb-col p-3 tb-col-md">
                  <span className="sub-text">COUNTRY</span>
                </div>

                <div className="nk-tb-col p-3 tb-col-md">
                  <span className="sub-text">REGION</span>
                </div>

                <div className="nk-tb-col p-3 tb-col-md">
                  <span className="sub-text">CITY</span>
                </div>
                <div className="nk-tb-col p-3 tb-col-md">
                  <span className="sub-text">OPERATING SYSTEM</span>
                </div>
                <div className="nk-tb-col p-3 tb-col-md">
                  <span className="sub-text">DEVICE TYPE</span>
                </div>
                <div className="nk-tb-col p-3 tb-col-md">
                  <span className="sub-text">BROWSER</span>
                </div>
                <div className="nk-tb-col p-3 tb-col-md">
                  <span className="sub-text">DATE</span>
                </div>
                <div className="nk-tb-col nk-tb-col-tools text-right"></div>
              </div>
              {loading ? (
                <div>
                  <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                </div>
              ) : (
                info.map((i, index) => (
                  <div className="nk-tb-item">
                    <div className="nk-tb-col tb-col-md">
                      <span>{i.document.originalFilename || "-"}</span>
                    </div>

                    <div className="nk-tb-col tb-col-md">
                      <span>{i.ip || "-"}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{i.country || "-"}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{i.region || "-"}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{i.city || "-"}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{i.os_name || "-"}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{i.device_type || "-"}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{i.browser || "-"}</span>
                    </div>

                    <div className="nk-tb-col tb-col-lg">
                      <span>
                        {moment(i.created_at).format("MMM-DD-YYYY hh:mma")}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          {info.length === 0 && (
            <div>
              <p
                style={{
                  margin: "1rem",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                The document has not been opened
              </p>
            </div>
          )}
        </div>
      }
    />
  );
}
