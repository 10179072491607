import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { userLoginReducer, userRegisterReducer } from './reducers/userReducers'
import { getAssetsReducer } from './reducers/assetsReducer'
const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  getAssets: getAssetsReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null
const fileFromStorage = localStorage.getItem('getfile')
  ? JSON.parse(localStorage.getItem('getfile'))
  : null
const folderFromStorage = localStorage.getItem('getfolder')
  ? JSON.parse(localStorage.getItem('getfolder'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  getAssets: {
    file: fileFromStorage,
    folder: folderFromStorage,
  },
}
const middleware = [thunk]
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)
export default store
