import React, { useEffect, useLayoutEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import "../assets/css/dashlite.css";
import "../assets/css/theme.css";
import "./dashboard.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Line, Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";
import { BASE_URL } from "../constants/apiconstant";
import { getHeaders, titleCase } from "../utils";
import { useSnackbar } from "notistack";
import moment from "moment";

import { CircularProgress } from "@material-ui/core";
import { countryShortCodes, ERROR, snackBarPosition } from "../constants/constants";
import Tour from "../components/Tour";

const steps = [
  {
    element: ".step1",
    intro: "Provides access to different sections of the application",
  },
  {
    element: ".step2",
    intro: "Navigate back to the main application.",
  },
  {
    element: ".step3",
    intro: "This will only sign you out of the Document Management System.",
  },
  {
    element: ".step4",
    intro: "Shows the count of completed electronic signature documents.",
  },
  {
    element: ".step5",
    intro: "Shows the count of pending electronic signature documents.",
  },
  {
    element: ".step6",
    intro: "Shows the count of incoming files received from file sharing.",
  },
  {
    element: ".step7",
    intro: "Shows the count of outgoing files shared through file sharing.",
  },
  {
    element: ".step8",
    intro: "Provides a visual representation of the sessions by device type.",
  },
  {
    element: ".step9",
    intro:
      "Visual representation of all user locations by country. This includes file sharing, folder sharing, and electronic signature.",
  },
  {
    element: ".step10",
    intro: "Displays recent notifications for all key file sharing and electronic signature actions.",
  },
  {
    element: ".step11",
    intro:
      "Summarizes contact information with a link to view all contacts. Note: If you are using the main application and document management system (DMS), you will need to add users to the main application for them to show in contacts. If you are only using the DMS, you may add users directly.",
  },
];

const state = {
  labels: ["January", "February", "March", "April", "May"],
  datasets: [
    {
      label: "Rainfall",
      fill: false,
      lineTension: 0.5,
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 2,
      data: [65, 59, 80, 81, 56],
    },
  ],
};

const Dashboard = ({ history }) => {
  const [showTour, setShowTour] = useState(false);
  const [bodyWidth, setBodyWidth] = React.useState(false);
  const [notifications, setNotifications] = useState([]);
  const [dropdown, setDropdown] = React.useState({
    show: false,
    number: 0,
  });
  const [incomingOutgoingFilesCount, setIncominOutgoingFilesCount] = useState({
    incomingFileCount: 0,
    outgoingFileCount: 0,
  });
  const [contacts, setContacts] = useState([]);
  const [esigCount, setEsigCount] = useState({ pending: 0, completed: 0 });
  const [loading, setLoading] = useState(true);
  const [sessionInfo, setSessionInfo] = useState({
    desktop: 0,
    mobile: 0,
    tablet: 0,
  });
  const [countryData, setCountryData] = useState([]);
  const { show, number } = dropdown;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getEsigCounts();
    await getNotifications();
    await getSessionByDevice();
    await getIncomingOutgoingFileData();
    const _countryDataArray = await getCountryCount();
    await getContacts();
    await getDeviceAndLocationInfo();
    initializeChart(_countryDataArray);
    setLoading(false);
  };
  const getNotifications = async () => {
    try {
      const { data } = await axios.get(
        BASE_URL + "get-notifications?page=1&rowsPerPage=10&sortOrder=DESC&sortColumn=created_at",
        getHeaders()
      );

      setNotifications(data.data);
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong getting notifications", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const getIncomingOutgoingFileData = async () => {
    try {
      const { data } = await axios.get(BASE_URL + `getIncomingOutgoingFileData`, getHeaders());

      setIncominOutgoingFilesCount({
        incomingFileCount: data.incomingFileCount,
        outgoingFileCount: data.outgoingFileCount,
      });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong getting Shared Files Count", {
        variant: ERROR,
        ...snackBarPosition,
      });
    }
  };

  const getContacts = async () => {
    try {
      const { data } = await axios.get(
        BASE_URL + `get-contacts?page=${1}&rowsPerPage=${7}&sortOrder=desc&sortColumn=submitted_date`,
        getHeaders()
      );

      if (data.isFromMainApp) {
        const dataFromMainApp = JSON.parse(data.data);
        setContacts(dataFromMainApp.data);
      } else {
        setContacts(data.data);
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong getting contacts", {
        variant: ERROR,
        ...snackBarPosition,
      });
    }
  };

  const getEsigCounts = async () => {
    try {
      const { data } = await axios.get(BASE_URL + "getEsignCount", getHeaders());
      console.log("data", data);
      setEsigCount({
        completed: data.data.complete,
        pending: data.data.pending,
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong getting esign document count", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const getSessionByDevice = async () => {
    try {
      const { data } = await axios.get(BASE_URL + "get-session-by-device", getHeaders());
      setSessionInfo({
        desktop: data.desktop,
        mobile: data.mobile,
        tablet: data.tablet,
      });
      setShowTour(!data.introjs_completed);
    } catch (error) {
      enqueueSnackbar("Something went wrong getting esign document count", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const getDeviceAndLocationInfo = async () => {
    try {
      const { data } = await axios.get(
        BASE_URL + "get-device-info?page=" + 1 + "&rowsPerPage=5" + "&sortOrder=DESC&sortColumn=created_at",
        getHeaders()
      );
      console.log("dataaaa", data);
      setCountryData(data.data);
    } catch (error) {
      enqueueSnackbar("Something went wrong getting esign document count", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  const getCountryCount = async () => {
    try {
      const { data } = await axios.get(BASE_URL + "get-country-count", getHeaders());
      console.log("data country coun", data);
      const _countryData = data.data;
      let totalCount = 0;
      Object.keys(_countryData).forEach((key) => {
        totalCount = totalCount + Number(_countryData[key]);
      });
      const _countryDataArray = Object.keys(_countryData).map((key) => {
        return {
          countryName: key,
          count: _countryData[key],
          percentage: percentage(_countryData[key], totalCount),
        };
      });

      // setCountryData(_countryDataArray);
      return _countryDataArray;
      /* setEsigCount({
          completed: data.data.complete,
          pending: data.data.pending,
        }); */
      /*   setSessionInfo({
          desktop: data.desktop,
          mobile: data.mobile,
          tablet: data.tablet,
        }); */
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong getting esign document count", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  am4core.useTheme(am4themes_animated);

  const displayDropdown = (e, index) => {
    if (show === true && number === index) {
      setDropdown({
        ...dropdown,
        show: false,
        number: index,
      });
    } else {
      setDropdown({
        ...dropdown,
        show: true,
        number: index,
      });
    }
  };

  const initializeChart = (_countryDataArray) => {
    const interval = setInterval(() => {
      const isDocumentMounted = document.getElementById("chartdivm");
      if (isDocumentMounted) {
        clearInterval(interval);
        let chart = am4core.create("chartdivm", am4maps.MapChart);

        // Set map definition
        chart.geodata = am4geodata_worldLow;

        // Set projection
        chart.projection = new am4maps.projections.Miller();

        // Series for World map
        let worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
        worldSeries.exclude = ["AQ"];

        worldSeries.useGeodata = true;

        let polygonTemplate = worldSeries.mapPolygons.template;
        console.log("country data", countryData);
        // console.log(
        //   _countryDataArray
        //     .map((country) => {
        //       if (!countryShortCodes[country.countryName]) return null;
        //       return {
        //         id: countryShortCodes[country.countryName].countryCode,
        //         name: countryShortCodes[country.countryName].amchartCountryName,
        //         value: country.count || 0,
        //       };
        //     })
        //     .filter((item) => item != null)
        // );
        /*  worldSeries.data = _countryDataArray
          .map((country) => {
            if (!country.countryName) return null;
            return {
              ...countryShortCodes[country.countryName],
              count: country.count || 0,
            };
          })
          .filter((item) => item != null); */

        worldSeries.data = Object.keys(countryShortCodes).map((key) => {
          let countryFromDb = null;
          _countryDataArray.forEach((country) => {
            if (country.countryName === key) {
              countryFromDb = country;
            }
          });
          if (countryFromDb) {
            return {
              id: countryShortCodes[key].countryCode,
              name: countryShortCodes[key].amchartCountryName,
              value: countryFromDb.count,
            };
          }
          return {
            id: countryShortCodes[key].countryCode,
            name: countryShortCodes[key].amchartCountryName,
            value: 0,
          };
        });

        /*   _countryDataArray
          .map((country) => {
            if (!countryShortCodes[country.countryName]) return null;
            return {
              id: countryShortCodes[country.countryName].countryCode,
              name: countryShortCodes[country.countryName].amchartCountryName,
              value: country.count || 0,
            };
          })
          .filter((item) => item != null); */
        polygonTemplate.tooltipText = "{name}:{value}";
        polygonTemplate.fill = chart.colors.getIndex(0);
        polygonTemplate.nonScalingStroke = true;

        // Hover state
        let hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#367B25");

        // Series for United States map
        /*  let usaSeries = chart.series.push(new am4maps.MapPolygonSeries());
    usaSeries.geodata = am4geodata_usaLow; */

        /* let usPolygonTemplate = usaSeries.mapPolygons.template;
    usPolygonTemplate.tooltipText = "{name}";
    usPolygonTemplate.fill = chart.colors.getIndex(1);
    usPolygonTemplate.nonScalingStroke = true; */

        // Hover state
        /* let hss = usPolygonTemplate.states.create("hover");
    hss.properties.fill = am4core.color("#367B25"); */
      }
    }, 100);
  };

  /*   const getCountryCodeByName = (countryName, count) => {
   let dataToReturn = null
    countryShortCodes.forEach((countryWithShortCode) => {
      if (countryWithShortCode.ip2locationCountryName === countryName)
       return {
          id: countryWithShortCode.countryCode
              name: countryWithShortCode.amchartCountryName,
              value: country.count,
       } shortCode = countryWithShortCode.code;
    });
    return shortCode;
  }; */

  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };

  const plugins = [
    {
      afterDraw: function (chart) {
        console.log(chart);
        if (chart.data.datasets[0].data.length < 1) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "30px Arial";
          ctx.fillText("No data to display", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];
  return (
    <div>
      {!loading && showTour && <Tour steps={steps} page_name={"esign_dashboard"} isEnabled={true} />}
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />
              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className={"nk-fmg "} style={bodyWidth ? { paddingLeft: 80 } : null}>
                      <Sidebar page="dashboard" getState={sidebarState} />
                      {/* .nk-fmg-aside */}
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                          }}
                        >
                          <CircularProgress style={{ color: "rgb(20, 155, 227)" }} />
                        </div>
                      ) : (
                        <>
                          <div className=" p-3 row m-0 ">
                            <div className="col-lg-6 pl-0 step4">
                              <div className="card round">
                                <div className="nk-ecwg nk-ecwg6">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <Link className="text-black" to={"/eSign-documents"}>
                                          <h3 className="nunito-font">Completed Documents</h3>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="data">
                                      <div className="data-group my-3 d-flex justify-content-between">
                                        <div className="amount align-self-center title">{esigCount.completed}</div>
                                        <div className="nk-ecwg6-ck align-self-center w-50">
                                          {/*     <div
                                        id="chartdiv"
                                        style={{ height: 100 }}
                                      >
                                        <Line
                                          data={state}
                                          width={100 + "%"}
                                          height={50 + "px"}
                                          options={{
                                            responsive: true,
                                            maintainAspectRatio: false,

                                            scales: {
                                              x: {
                                                display: false,
                                              },
                                              y: {
                                                display: false,
                                              },
                                            },
                                            plugins: {
                                              legend: {
                                                display: false,
                                              },
                                            },
                                            tooltips: {
                                              intersect: false,
                                            },
                                            elements: {
                                              point: {
                                                radius: 0,
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                   */}
                                        </div>
                                      </div>
                                      {/*  <div className="info">
                                    <span className="change up text-danger">
                                      <em className="icon ni ni-arrow-long-up nunito-font"></em>
                                      4.63%
                                    </span>
                                    <span className="nunito-font">
                                      {" "}
                                      vs. last week
                                    </span>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 p-0 step5">
                              <div className="card m-0 round">
                                <div className="nk-ecwg nk-ecwg6">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <Link className="text-black" to={"/eSign-documents"}>
                                          <h3 className="nunito-font">Pending Documents</h3>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="data">
                                      <div className="data-group my-3 d-flex justify-content-between">
                                        <div className="amount align-self-center title">{esigCount.pending}</div>
                                        <div className="nk-ecwg6-ck align-self-center w-50">
                                          {/*      <div
                                        id="chartdivy"
                                        style={{ height: 100 }}
                                      >
                                        <Line
                                          data={state}
                                          height={50 + "px"}
                                          width={100 + "%"}
                                          options={{
                                            responsive: true,
                                            maintainAspectRatio: false,

                                            scales: {
                                              x: {
                                                display: false,
                                              },
                                              y: {
                                                display: false,
                                              },
                                            },
                                            plugins: {
                                              legend: {
                                                display: false,
                                              },
                                            },
                                            tooltips: {
                                              intersect: false,
                                            },
                                            elements: {
                                              point: {
                                                radius: 0,
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                  */}
                                        </div>
                                      </div>
                                      {/*  <div className="info">
                                    <span className="change up text-danger">
                                      <em className="icon ni ni-arrow-long-up nunito-font"></em>
                                      4.63%
                                    </span>
                                    <span className="nunito-font">
                                      {" "}
                                      vs. last week
                                    </span>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 pl-0 pt-2 step6">
                              <div className="card m-0 round">
                                <div className="nk-ecwg nk-ecwg6">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <Link className="text-black" to={"/file-sharing"}>
                                          <h3 className="nunito-font">Incoming Files</h3>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="data">
                                      <div className="data-group my-3 d-flex justify-content-between">
                                        <div className="amount align-self-center title">
                                          {incomingOutgoingFilesCount.incomingFileCount}
                                        </div>
                                        <div className="nk-ecwg6-ck align-self-center w-50">
                                          {/*      <div
                                        id="chartdivy"
                                        style={{ height: 100 }}
                                      >
                                        <Line
                                          data={state}
                                          height={50 + "px"}
                                          width={100 + "%"}
                                          options={{
                                            responsive: true,
                                            maintainAspectRatio: false,

                                            scales: {
                                              x: {
                                                display: false,
                                              },
                                              y: {
                                                display: false,
                                              },
                                            },
                                            plugins: {
                                              legend: {
                                                display: false,
                                              },
                                            },
                                            tooltips: {
                                              intersect: false,
                                            },
                                            elements: {
                                              point: {
                                                radius: 0,
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                  */}
                                        </div>
                                      </div>
                                      {/*  <div className="info">
                                    <span className="change up text-danger">
                                      <em className="icon ni ni-arrow-long-up nunito-font"></em>
                                      4.63%
                                    </span>
                                    <span className="nunito-font">
                                      {" "}
                                      vs. last week
                                    </span>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 p-0 pt-2 step7">
                              <div className="card m-0 round">
                                <div className="nk-ecwg nk-ecwg6">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <Link className="text-black" to={"/file-sharing"}>
                                          <h3 className="nunito-font">Outgoing Files</h3>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="data">
                                      <div className="data-group my-3 d-flex justify-content-between">
                                        <div className="amount align-self-center title">
                                          {incomingOutgoingFilesCount.outgoingFileCount}
                                        </div>
                                        <div className="nk-ecwg6-ck align-self-center w-50">
                                          {/*      <div
                                        id="chartdivy"
                                        style={{ height: 100 }}
                                      >
                                        <Line
                                          data={state}
                                          height={50 + "px"}
                                          width={100 + "%"}
                                          options={{
                                            responsive: true,
                                            maintainAspectRatio: false,

                                            scales: {
                                              x: {
                                                display: false,
                                              },
                                              y: {
                                                display: false,
                                              },
                                            },
                                            plugins: {
                                              legend: {
                                                display: false,
                                              },
                                            },
                                            tooltips: {
                                              intersect: false,
                                            },
                                            elements: {
                                              point: {
                                                radius: 0,
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                  */}
                                        </div>
                                      </div>
                                      {/*  <div className="info">
                                    <span className="change up text-danger">
                                      <em className="icon ni ni-arrow-long-up nunito-font"></em>
                                      4.63%
                                    </span>
                                    <span className="nunito-font">
                                      {" "}
                                      vs. last week
                                    </span>
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="p-3 row m-0">
                            <div className="col-lg-6 pl-0 step8">
                              <div className="bg-white p-3 round">
                                <div className="d-flex justify-content-between">
                                  <h3 className="nunito-font">Sessions by device</h3>
                                  <div className="card-tools">
                                    <Link
                                      to="/esign-document-device-info"
                                      onClick={() => history.push("/esign-document-device-info")}
                                    >
                                      View All
                                    </Link>
                                  </div>
                                  {/* <div> */}
                                  {/* <Select
                                  value="1"
                                  placeholder="Time"
                                  className="nunito-font"
                                >
                                  <MenuItem value={1} className="nunito-font">
                                    1 month
                                  </MenuItem>
                                  <MenuItem value={2} className="nunito-font">
                                    3 month
                                  </MenuItem>
                                  <MenuItem value={6} className="nunito-font">
                                    6 month
                                  </MenuItem>
                                  <MenuItem value={3} className="nunito-font">
                                    1 year
                                  </MenuItem>
                                </Select> */}
                                  {/*  </div> */}
                                </div>

                                <div
                                  id="chartdivP"
                                  className="my-2"
                                  style={{
                                    height: 505,
                                    display:
                                      sessionInfo.desktop === 0 && sessionInfo.mobile === 0 && sessionInfo.tablet === 0
                                        ? "flex"
                                        : "",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {sessionInfo.desktop === 0 && sessionInfo.mobile === 0 && sessionInfo.tablet === 0 ? (
                                    <p
                                      className="Nunito font"
                                      style={{
                                        textAlign: "center",
                                        margin: "1rem",
                                      }}
                                    >
                                      Data will begin to populate once your first document is signed.
                                    </p>
                                  ) : (
                                    <Doughnut
                                      plugins={plugins}
                                      sessionInfo={sessionInfo}
                                      className="mx-auto"
                                      data={{
                                        labels: ["Desktop", "Mobile", "Tablet"],
                                        datasets: [
                                          {
                                            label: "Session",
                                            backgroundColor: ["#B21F00", "#C9DE00", "#2FDE00"],
                                            hoverBackgroundColor: ["#501800", "#4B5000", "#175000"],
                                            data: [sessionInfo.desktop, sessionInfo.mobile, sessionInfo.tablet],
                                          },
                                        ],
                                      }}
                                      height={500}
                                      options={{
                                        responsive: false,
                                        title: {
                                          display: true,
                                          text: "Average Rainfall per month",
                                          fontSize: 20,
                                        },
                                        legend: {
                                          display: true,
                                          position: "right",
                                          labels: {
                                            fontFamily: "Nunito",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 p-0 step9">
                              <div className="card round h-100">
                                <div className="card-inner">
                                  <div className="card-title-group">
                                    <div className="card-title card-title-sm">
                                      <h3 className="nunito-font">Users by Country</h3>
                                    </div>
                                    <div className="card-tools">
                                      <Link
                                        to="/esign-document-device-info"
                                        onClick={() => history.push("/esign-document-device-info")}
                                      >
                                        View All
                                      </Link>
                                    </div>
                                    {/*  <div className="card-tools">
                                  <div className="drodown">
                                    <div>
                                      <Select
                                        className="nunito-font"
                                        value="1"
                                        placeholder="Time"
                                      >
                                        <MenuItem
                                          className="nunito-font"
                                          value={1}
                                        >
                                          1 month
                                        </MenuItem>
                                        <MenuItem
                                          className="nunito-font"
                                          value={2}
                                        >
                                          3 month
                                        </MenuItem>
                                        <MenuItem
                                          className="nunito-font"
                                          value={6}
                                        >
                                          6 month
                                        </MenuItem>
                                        <MenuItem
                                          className="nunito-font"
                                          value={3}
                                        >
                                          1 year
                                        </MenuItem>
                                      </Select>
                                    </div>
                                  </div>
                                </div> */}
                                  </div>
                                  <div className="analytics-map">
                                    <div id="chartdivm" style={{ width: 100 + "%", height: 300 }}></div>

                                    <table className="analytics-map-data-list">
                                      <tbody>
                                        <tr
                                          className="analytics-map-data"
                                          style={{
                                            borderBottom: "1px solid #eee",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <td className="nunito-font">IP</td>
                                          <td className="nunito-font">COUNTRY</td>
                                          <td className="nunito-font">REGION</td>
                                          <td className="nunito-font">CITY</td>
                                        </tr>
                                        {countryData.map((cd) => (
                                          <tr className="analytics-map-data">
                                            <td className="nunito-font">{cd.ip}</td>
                                            <td className="nunito-font">{titleCase(cd.country)}</td>
                                            <td className="nunito-font">{cd.region}</td>
                                            <td className="nunito-font">{cd.city}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                    {countryData.length === 0 && (
                                      <p
                                        className="Nunito font"
                                        style={{
                                          textAlign: "center",
                                          margin: "1rem",
                                        }}
                                      >
                                        No Information Found
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row m-0 my-3">
                            <div className=" col-lg-6 step10">
                              <div className="">
                                <div>
                                  <div className="card round ">
                                    <div className="card-inner border-bottom">
                                      <div className="card-title-group">
                                        <div className="card-title">
                                          <h3 className="nunito-font"> Notifications</h3>
                                        </div>
                                        <div className="card-tools">
                                          <Link to="/notifications" onClick={() => history.push("/notifications")}>
                                            View All
                                          </Link>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="card-inner"
                                      style={{
                                        height: 515,
                                        overflowY: "scroll",
                                        display: notifications.length === 0 ? "flex" : "",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="timeline">
                                        {/*  <h6 className="timeline-head nunito-font">
                                      November, 2019
                                    </h6> */}

                                        {loading ? (
                                          <CircularProgress style={{ color: "rgb(20, 155, 227)" }} />
                                        ) : (
                                          <>
                                            <ul className="timeline-list">
                                              {notifications.map((notification) => (
                                                <li className="timeline-item">
                                                  <div className="timeline-status bg-primary is-outline"></div>
                                                  <div className="timeline-date nunito-font">
                                                    {moment(notification.created_at).format("DD MMM")}
                                                    <em className="icon ni ni-alarm-alt"></em>
                                                  </div>
                                                  <div className="timeline-data">
                                                    <h6 className="timeline-title nunito-font">{notification.type}</h6>
                                                    <div className="timeline-des">
                                                      <p className="nunito-font">
                                                        {notification?.document?.originalFilename}
                                                        {notification?.shared_file?.original_file_name}
                                                      </p>
                                                      <span className="time nunito-font">
                                                        {moment(notification.created_at).format("hh:mma")}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </li>
                                              ))}
                                            </ul>
                                            {notifications.length === 0 && (
                                              <p className="nunito-font">Notification will show up here</p>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 pl-0 step11">
                              <div className="card round card-full">
                                <div className="card-inner-group">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <h3 className="nunito-font">Contacts</h3>
                                      </div>
                                      <div className="card-tools">
                                        <Link to={"/contact"}>View All</Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      height: 515,
                                      overflowY: "scroll",
                                      display: contacts.length === 0 ? "flex" : "",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {contacts.map((contact, index) => {
                                      return (
                                        <div className="card-inner card-inner-md">
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary-dim">
                                              <span className="nunito-font">
                                                {contact.first_name && contact.first_name[0]}
                                                {contact.last_name && contact.last_name[0]}
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span className="lead-text nunito-font">
                                                {contact.first_name} {contact.last_name}
                                              </span>
                                              <span className="sub-text nunito-font">
                                                {contact.country || "No Username"} - {contact.phone}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                    {contacts.length === 0 && (
                                      <p
                                        className="Nunito font"
                                        style={{
                                          textAlign: "center",
                                          margin: "1rem",
                                        }}
                                      >
                                        Your imported contacts or onboarded users will show up here
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
