import { IconButton } from '@material-ui/core'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import ReactTimeAgo from 'react-time-ago'
import SharedFoldersFilesMenu from "./SharedFoldersFilesMenu";
import { PictureAsPdf } from '@material-ui/icons'
import { getCSVSVG, getDocSVG, getFileSVG, getFolderSVG, getHeaders, 
          getMediaSVG, getPDFSVG, getZipSVG } from "../../utils";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const FileButton = (props) => {
  const contentType = props.files?.file_extension?.toLowerCase();
  const history = useHistory();
  return (
    <div className="nk-file-item nk-file">
      <div className="nk-file-info">
        <div
          style={{ cursor: 'pointer' }}
          className="nk-file-title"
          onClick={() => {
           if(contentType === "jpg" || contentType === "png" || contentType === "jpeg" || contentType === "pdf" || contentType === "mp3"){
            localStorage.setItem(
              "viewerFileName",
              props.files?.file_name
            );
            localStorage.setItem(
              "viewerFileUrl",
              props.files?.fileurl
            );
            localStorage.setItem(
              "contentType",
              contentType
            );
            history.push(
              "/view-file"
            );
          }
          }}
        >
          <div className="nk-file-icon">
            <span className="nk-file-icon-type">
              {props.files?.file_extension.toLowerCase()=="pdf" ?
                getPDFSVG()
              : props.files?.file_extension.toLowerCase()=="csv" ? 
                getCSVSVG()
              : props.files?.file_extension.toLowerCase()=="doc"||props.files?.file_extension.toLowerCase()=="docx" ? 
                getDocSVG()
              : props.files?.file_extension.toLowerCase()=="png"||
                props.files?.file_extension.toLowerCase()=="jpg"||
                props.files?.file_extension.toLowerCase()=="jpeg"||
                props.files?.file_extension.toLowerCase()=="gif" ? 
                getMediaSVG()
              : props.files?.file_extension.toLowerCase()=="zip" ? 
                getZipSVG()
              :
                getFileSVG()
              }
            </span>
          </div>
          <div className="nk-file-name">
            <div
              className="nk-file-name-text"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 240,
              }}
            >
              {props.files?.original_file_name}
            </div>
          </div>
        </div>
        <ul className="nk-file-desc">
          <li className="date">
            Created:{' '}
            <ReactTimeAgo date={props.files?.created_at} locale="en-US" />
          </li>
        </ul>
      </div>
      <div className="nk-file-actions">
        <div className="dropdown">
        {props.OptionVisibility && <SharedFoldersFilesMenu
          hasAdminAccess={props.hasAdminAccess}
          moveFilePermission={props.moveFilePermission}
          role={props.role}
          id={props?.id}
          folder_id={props?.folder_id}
          setOrgOutFolderid={props.setOrgOutFolderid}
          setCurrentOutFolderid={props.setCurrentOutFolderid}
          setCurrentOutfileid={props.setCurrentOutfileid}
          setOpenOutFilename={props.setOpenOutFilename}
          setRemoveOutFile={props.setRemoveOutFile}
          setRenameOutFile={props.setRenameOutFile}
          setMoveOutFile={props.setMoveOutFile}
          setDownloadOutFile={props.setDownloadOutFile}
          name={props.files?.original_file_name}
          downloadDocument={props.downloadDocument}
          fileUrl ={props.files?.fileurl || props.files?.storjFileurl}
        />}
        </div>
      </div>
    </div>
  )
}

export default FileButton
