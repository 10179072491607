import {
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ShowChart,
} from "@material-ui/icons";
import React, { useState } from "react";
import { fontsArray } from "../Shared/constant";
import { ToolbarCustomButton } from "../Shared/EditPdf";
import { getRemainingFieldCount } from "./utils";

export default function Header({
  loading,
  nodes,
  data,
  isUnderLine,
  setIsUnderLine,
  isBold,
  setIsBold,
  setZoomTop,
  isItalic,
  setIsItalic,
  fontSize,
  setFontSize,
  fontFamily,
  setFontFamily,
  activeField,
  setActiveField,
  activeFieldRef,
  permission
}) {
  const [showDropDown, setShowDropDown] = useState(false);
  console.log("data", data);
  console.log("nodes", nodes());
  const nodesArray = nodes();

  const clickHandler = () => {
    setShowDropDown(!showDropDown);
    if (showDropDown){
      setZoomTop("100px")
    } else {
      setZoomTop("235px")
    }
  };
  if (loading) return null;
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid #000",
        borderRadius: "5px",
        boxShadow: "0 0 3px #000",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "45px",
          backgroundColor: "white",
        }}
      >
        <div style={{ display: "flex" }}>
        {permission=="contributor" && <ToolbarCustomButton
            title={"Text"}
            buttonText="I"
            active={activeField === "AText"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="1.60em"
                height="2em"
                color="black"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M432 416h-23.41L277.88 53.69A32 32 0 0 0 247.58 32h-47.16a32 32 0 0 0-30.3 21.69L39.41 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-19.58l23.3-64h152.56l23.3 64H304a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM176.85 272L224 142.51L271.15 272z"
                />
              </svg>
            }
            onClick={() => {
              if (activeField === "AText") {
                setActiveField(null);
                activeFieldRef.current = null;
              } else {
                activeFieldRef.current = "AText";
                setActiveField("AText");
              }
            }}
          />}
          <ToolbarCustomButton
            buttonText="I"
            title={"Italic"}
            active={isItalic}
            onClick={() => {
              setIsItalic(!isItalic);
            }}
          />
          <ToolbarCustomButton
            active={isUnderLine}
            title={"UnderLine"}
            onClick={() => {
              setIsUnderLine(!isUnderLine);
            }}
            buttonText="U"
          />
          <ToolbarCustomButton
            buttonText="B"
            title={"Bold"}
            active={isBold}
            onClick={() => {
              setIsBold(!isBold);
            }}
          />
          <ToolbarCustomButton
            buttonText={fontSize}
            title={"Font Size"}
            onClick={(value) => setFontSize(value)}
            type="select"
            selectOptions={[
              4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32,
            ]}
          />
          <ToolbarCustomButton
          title={"Font Family"}
            onClick={(value) => setFontFamily(value)}
            buttonText={fontFamily}
            type="select"
            selectOptions={fontsArray}
          />
        </div>
        <div>
          {showDropDown ? (
            <ArrowDropUp
              onClick={clickHandler}
              color="black"
              size={30}
              style={{ color: "black", fontSize: "60px", cursor: "pointer" }}
            />
          ) : (
            <ArrowDropDown
              onClick={clickHandler}
              color="black"
              size={30}
              style={{ color: "black", fontSize: "60px", cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      {showDropDown && (
        <div
          style={{ background: "white", padding: "1rem", paddingLeft: "3rem" }}
        >
          <div style={{ display: "flex" }}>
            <p style={{ width: "8rem", marginBottom: "0.3rem" }}>To</p>
            <p style={{ flex: 4, fontWeight: "bold" }}>{data.email}</p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ width: "8rem", marginBottom: "0.3rem" }}>From</p>
            <p style={{ fontWeight: "bold" }}>{data.creatorEmail}</p>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ width: "8rem", marginBottom: "0.3rem" }}>Message</p>
            <p style={{ fontWeight: "bold" }}>{data.emailMessage}</p>
          </div>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              marginTop: "1rem",
              width: "max-content",
              color: "black",
            }}
          >
            {/* Comment due to unsufficent node error, will do cater this later */}
            {/* {getRemainingFieldCount(nodesArray, data.email)} fields remaining */}
          </p>
        </div>
      )}
    </div>
  );
}
