import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { FileDeletion, FolderDeletion } from '../actions/assetsAction'
import { useDispatch, useSelector } from 'react-redux'
import './filemenu.css'
const FileMenu = (props) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const getAssets = useSelector((state) => state.getAssets)
  const { loading, error, file, folder } = getAssets

  const deletefile = (id) => {
    // setAnchorElfile(null)

    console.log('deletefile', id)
    dispatch(FileDeletion(id))
  }
  const renamefile = (id, name) => {
    // setAnchorElfile(null)
    props.setCurrentfileid(id)
    props.setOpenfilename(true)
    console.log('filename', id, name)
  }
  const movefolder = (id) => {
    // setAnchorElfile(null)
    props.setCurrentfileid(id)
    props.setOpenfolderid(true)
    console.log('foldername', id)
  }

  return (
    <>
      <IconButton
        color='inherit'
        aria-label='open drawer'
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreHorizIcon style={{ color: 'grey' }} />
      </IconButton>
      <Menu
        elevation={0}
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        {/* <MenuItem
        //   onClick={() => {
        //     deletefolder(props?.id)
        //     setAnchorEl(null)
        //     console.log('curr id', props?.id) // should be each individual id, but here always "test3"
        //   }}
        >
          Download{' '}
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            movefolder(props?.id)
            setAnchorEl(null)
            console.log('curr id', props?.id) // should be each individual id, but here always "test3"
          }}
        >
          Move{' '}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            renamefile(props?.id, props?.name)
          }}
        >
          Rename{' '}
        </MenuItem>
        <MenuItem
          onClick={() => {
            deletefile(props?.id)
            setAnchorEl(null)
            console.log('curr id', props?.id) // should be each individual id, but here always "test3"
          }}
        >
          {' '}
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default FileMenu
