import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Link as Links } from "react-router-dom";

import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { register } from "../actions/userActions";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import logo from "./../assets/images/logo.jpeg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { NEST_BACKEND_URL } from "../constants/apiconstant";
import { useSnackbar } from "notistack";
import { USER_LOGIN_SUCCESS } from "../constants/userConstants";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AuthenticateFromEmail() {
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (!token) {
      setMessage("Valid token not provided");
      setLoading(false);
      return;
    }
    verifyOtp(token);
  }, []);

  const classes = useStyles();

  const verifyOtp = async (token) => {
    try {
      setLoading(true);

      const { data } = await axios.post(NEST_BACKEND_URL + "auth/verify-otp", {
        token,
      });
      if (data.success) {
        localStorage.setItem("userInfo", JSON.stringify({ result: data.data }));
        localStorage.setItem("token", data.data.token);

        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: { result: data.data },
        });
        history.push("/");
      } else {
        setMessage(
          data.message || "Something went wrong, please try again later"
        );
      }
    } catch (error) {
      setMessage("Something went wrong, please try again later");
    }
    setLoading(false);
  };
  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
      </div>
    );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <ToastContainer />
      <div className={classes.paper}>
        <img style={{ width: "7rem", borderRadius: "50%" }} alt="" src={logo} />
        {message && (
          <Typography component="h5" variant="h6">
            {message}
          </Typography>
        )}
      </div>
    </Container>
  );
}
