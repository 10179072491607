import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory  } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const UserPrivateRoute = ({ component: Component, ...rest }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const isNormalUser = localStorage.getItem("isNormalUser");
  const location = useLocation();
  const [token, setToken] = useState(null);

  useEffect(() => {
    console.log(location)
    const searchParams = new URLSearchParams(location.search);
    if (location.pathname.startsWith("/sharedfoldersfiles/")){
      const newURL = location.pathname + location.search ;

      localStorage.setItem('redirectUrl', newURL);
      window.history.replaceState(null, null, newURL);
    }
    const tokenParam = searchParams.get('token');
    if (tokenParam) {
      setToken(tokenParam);

      searchParams.delete('token');
      const newURL = location.pathname + '?token=' + tokenParam;

      localStorage.setItem('redirectUrl', newURL);
      window.history.replaceState(null, null, newURL);
    }
  }, [location]);
  const history = useHistory();

  useEffect(() => {}, []);

  const isExpired = () => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("expiry") < new Date().valueOf()) {
        localStorage.clear();
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={(props) => (!isExpired() ? <Component {...props} /> : <Redirect to="/user-signup" />)} />
  );
};

export default UserPrivateRoute;
