import Swal from "sweetalert2"
import { getHeaders } from "../../utils"
import axios from "axios"
import { BASE_URL } from "../../constants/apiconstant"

const createFolder = async (createFolderAPIRequest, foldername, folders, setFolders) => {
        try {
            const { data } = await createFolderAPIRequest(foldername)
            Swal.fire(
                'Folder Created!',
                ' ',
                'success'
              )
            setFolders({...folders, data:[...folders.data, data]})
        } catch (error) {
          Swal.fire(
            'Error Creating Folder!',
            ' ',
            'error'
          )
        }
    }
const folderCreateModalSwal = async (createFolderAPIRequest, setShowCreateFolderModal, setFolders, folders) =>{
        const { value: formValues } = await Swal.fire({
          title: 'Create Folder',
          confirmButtonColor:'rgb(20, 155, 227)',
          html:
            '<input id="swal-input1" class="swal2-input">',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById('swal-input1').value,
            ]
          }
        })
        if (formValues && formValues[0] !== "") {
          createFolder(createFolderAPIRequest, formValues[0], folders, setFolders);
        }
        setShowCreateFolderModal(false);
      }

export default folderCreateModalSwal;