import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {CardActionArea} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import {useDropzone} from 'react-dropzone';
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import ReactCrop from 'react-image-crop';

import {BASE_URL} from "../constants/apiconstant";
import {getHeaders, getMultipartHeaders} from "../utils";

import 'react-image-crop/dist/ReactCrop.css';

export const OcrTemplateDialog = (props) => {
  const {onClose, templateId, open} = props;
  const [ocrTemplate, setOcrTemplate] = useState(false)
  const [isNew, setIsNew] = useState(!templateId);
  const [crop, setCrop] = useState({unit: 'px'});
  const [upImg, setUpImg] = useState(null);
  const [upFile, setUpFile] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [currentFieldName, setCurrentFieldName] = useState(null);
  const [currentTemplateName, setCurrentTemplateName] = useState(null);
  const [hasCrop, setHasCrop] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file) => {
      setUpFile(file);
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        setUpImg(reader.result);
      }
      reader.readAsDataURL(file);
    });
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  useEffect(() => {
    if (isNew) {
      // props.reset();
    } else {
      getOcrTemplate(templateId)
    }
  }, []);

  const handleClose = () => {
    onClose(templateId);
  };

  const onCropChange = crop => {
    setCrop(crop);
    setHasCrop(crop.width > 0);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
    // setCrop({ width: img.width, height: img.height } );
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const handleClickDeleteField = async (fieldId) => {
    axios.delete(BASE_URL + "api/ocr-template-data/" + fieldId + '/', getHeaders())
    .then(function (response) {
      console.log('ocr-template-data deleted', response.data);
      getOcrTemplate(ocrTemplate.id);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const handleClickAddFieldValue = async () => {
    axios.post(BASE_URL + "api/ocr-template-data/",
      {
        name: currentFieldName,
        x: parseInt(completedCrop.x),
        y: parseInt(completedCrop.y),
        width: parseInt(completedCrop.width),
        height: parseInt(completedCrop.height),
        ocrtemplate: ocrTemplate.id,
      },
      getHeaders())
    .then(function (response) {
      console.log('ocr-template-data:', response.data);
      getOcrTemplate(ocrTemplate.id);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const getOcrTemplate = async (templateId) => {
    setCrop({unit: 'px'});
    axios.get(BASE_URL + "api/ocr-templates/" + templateId, getHeaders())
    .then(function (response) {
      console.log('ocr-template', response.data);
      setOcrTemplate(response.data);
      setCurrentTemplateName(response.data.name);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const saveOcrTemplate = async () => {
    if (isNew) {
      const formData = new FormData();
      formData.append("name", currentTemplateName);
      formData.append("image", upFile);
      axios.post(BASE_URL + "api/ocr-templates/", formData,
        getMultipartHeaders())
      .then(function (response) {
        console.log('ocr-template:', response.data);
        setIsNew(false);
        setOcrTemplate(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      const formData = new FormData();
      formData.append("id", ocrTemplate.id);
      formData.append("name", currentTemplateName);
      console.log(formData);
      axios.put(BASE_URL + "api/ocr-templates/" + templateId + "/", formData,
        getMultipartHeaders())
      .then(function (response) {
        console.log('ocr-template:', response.data);
        setOcrTemplate(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  };

  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <DialogTitle>{isNew ? 'Create' : 'Edit'} OCR template</DialogTitle>
      <DialogContent>
        {isNew && (
          <div>
            <div className="upload-zone small bg-lighter dropzone dz-clickable" {...getRootProps()}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Drag 'n' drop some files here, or click to select files</p>
              }
            </div>
            {upImg && (
              <ReactCrop onImageLoaded={onLoad} src={upImg} crop={crop} onChange={onCropChange}
                         onComplete={(c) => setCompletedCrop(c)}/>
            )}
            <TextField
              required
              id="name"
              label="Template Name"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(event) => setCurrentTemplateName(event.target.value)}
            />
            <Divider/>
            <Button onClick={saveOcrTemplate} variant="contained" size="large" style={{backgroundColor:'rgb(20, 155, 227)'}} endIcon={<SaveIcon/>}>
              Save
            </Button>
          </div>
        )}
        {ocrTemplate && (
          <Card>
            <CardActionArea>
              <CardContent>
                <ReactCrop imageStyle={{ width: "100%" }} onImageLoaded={onLoad} src={ocrTemplate.image} crop={crop} onChange={onCropChange}
                           onComplete={(c) => setCompletedCrop(c)}/>
                <TextField
                  id="name"
                  label="Template Name"
                  type="text"
                  variant="outlined"
                  defaultValue={ocrTemplate.name}
                  onChange={(event) => setCurrentTemplateName(event.target.value)}
                  required
                  fullWidth
                />
                <Button onClick={saveOcrTemplate} variant="contained" size="large" endIcon={<SaveIcon/>}>
                  Update
                </Button>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Divider textAlign="left">
              <Chip label="Template Fields"/>
            </Divider>
            {hasCrop && (
              <Box sx={{display: 'inline-flex', justifyContent: 'space-evenly'}}>
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                  }}
                />
                <TextField
                  required
                  id="field_name"
                  label="Field Name"
                  type="text"
                  variant="outlined"
                  onChange={(event) => setCurrentFieldName(event.target.value)}
                />
                <Button disabled={isNew} onClick={handleClickAddFieldValue} variant="contained" color="primary"
                        startIcon={<AddCircleRoundedIcon/>}>Add</Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={4}>
            {ocrTemplate && (
              <div>
                <Alert severity="info">{ocrTemplate.data.length} fields found</Alert>
                <List>
                  {ocrTemplate.data.map((item) => (
                    <ListItem
                      key={item.id}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => {
                          handleClickDeleteField(item.id);
                        }}>
                          <DeleteIcon/>
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon/>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.name}
                        secondary={'x=' + item.x + ', y=' + item.y + ', width=' + item.width + ', height=' + item.height}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{m:2}}>
        <Button variant="outlined" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default OcrTemplateDialog;