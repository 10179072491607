import React, { useEffect, useRef, useState } from "react";

import "../../assets/css/dashlite.css";
import "../../assets/css/theme.css";
import "../../assets/css/custom-shared-docs.css";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Grid, Container, Modal, TextField, Fab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CreateFolder from "./CreateFolder";

import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { html } from "../Html";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import FileButton from "../../components/FileButton";
import "../homepage.css";
import axios from "axios";
import { getHeaders } from "../../utils";
import { BASE_URL } from "../../constants/apiconstant";
import { useSnackbar } from "notistack";
import Paginator from "../../components/Pagination";
import { removeEsignDOcuments } from "../../actions/assetsAction";
import RenameFile from "./RenameFile";
import Moment from "moment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { Loading } from "../configuration/components/Loading";

const UploadProcess = (props) => {
  const location = useLocation();
  console.log("location", location);
  const [files, setFiles] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const { innerWidth, innerHeight } = window;
  const history = useHistory();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      borderRadius: "11px",
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: innerWidth > 600 ? 500 : "100%",

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto !important",
      top: "50%",
      fontFamily: "Nunito, sans-serif !important",
    },
    multilineColor: {
      color: "white",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    flex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "1rem",
      flex: 1,
    },
    rooot: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fieldroot: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [bodyWidth, setBodyWidth] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const options = [
    { label: "User 1", value: "user1" },
    { label: "User 2", value: "user2" },
  ];

  const [showWarningAlert, setShowWarningAlert] = useState(true);
  const [showPrimaryAlert, setShowPrimaryAlert] = useState(true);
  const [open, setOpen] = useState(false);
  const [totalBytes, setTotalBytes] = useState(0);
  const [selected, setSelected] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [titleUpdated, setTitleUpdated] = useState(false);
  const [expDateUpdated, setExpDateUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  //For Contacts
  const [page, setPage] = useState(1);
  const [contacts, setContacts] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState("DESC");
  const [selectedSortColumn, setSelectedSortColumn] = useState("submitted_date");
  const [isNormalUser, setIsNormalUser] = useState(localStorage.getItem("isNormalUser"));
  const [userRole, setuserRole] = React.useState(localStorage.getItem("userRole"));

  const [hasMultiSelect, setHasMultiSelect] = useState(true);

  const sidebarState = (state) => {
    console.log("sidebarState==>", state);
    setBodyWidth(state);
  };
  useEffect(() => {

    var prev_files_array = location.state.files;

    let new_array = [];
    for (var j = 0; j < prev_files_array.length; j++) {
      var file = { id: "", org_file_name: "", name: "", size: "" };
      file.id = prev_files_array[j].id;
      file.org_file_name = prev_files_array[j].name.split(".").slice(0, -1).join(".");
      file.name = prev_files_array[j].name.split(".").slice(0, -1).join(".");
      file.size = prev_files_array[j].size;

      new_array.push(file);
    }
    setFiles(new_array);


    getContacts(1, 20, "DESC", "submitted_date");

    // getContacts(1, 20, "DESC", "submitted_date");
  }, []);

  const getContacts = async (searchText, shouldSetAllContacts = false) => {
    setLoading(true)
    try {
      const { data } = await axios.get(BASE_URL + `getAllContacts`, { ...getHeaders() });

      setContacts(data.data.map((item) => ({ label: item.first_name + " " + item.last_name, email: item.email })));
      // if (shouldSetAllContacts) setAllContacts(data.data);
    } catch (error) {
      if (!axios.isCancel(error)) {
        enqueueSnackbar("Something went wrong getting notifications", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    }
    setLoading(false);
  };


  /* const getUserContacts = async () => {
    setLoading(true);
    try {
      console.log("getUserContacts Try");
      let url =
        BASE_URL +
        // `getUserContacts?user_id=${_page}`;
        `getUserContacts`;
      const { data } = await axios.get(url, getHeaders());
      console.log("getUserContacts data==>", data);
      if (userInfo.result.role == "member" && userInfo.result.companyId) {
        var contact_list = [];
        data.data.forEach((item) => {
          contact_list.push({
            label: item.name,
            value: item._id,
            email: item.email,
          });
        });

        setContacts(contact_list);
      } else {
        let user_id_or_company_id = "";

        for (var i = 0; i < data.data.length; i++) {
          console.log("getUserContacts Inside FOR==>", data.data[i]);
          user_id_or_company_id = data.data[i].user_id_or_company_id;
          // if(data.data[i].user_id_or_company_id) {
          //   contact_list.push({
          //     label:data.data[i].first_name+" "+data.data[i].last_name,
          //     value:data.data[i].id ? data.data[i].id : data.data[i]._id,
          //     email:data.data[i].email
          //   })
          // }
        }
        console.log("user_id_or_company_id==>", user_id_or_company_id);
        getAdminInfo(user_id_or_company_id);
      }
    } catch (error) {
      console.log("getUserContacts error", error);
      enqueueSnackbar("Something went wrong getting contacts", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    console.log("All Contacts for Select==>", contacts);
    setLoading(false);
  }; */

  const getAdminInfo = async (user_id_or_company_id) => {
    try {
      console.log("Inside getAdminInfo", user_id_or_company_id);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "https://sign.kycaml.systems/auth/user-info",
        { user_id_or_company_id },
        config
      );

      console.log("getAdminInfo==>", data);
      if (data && data.result) {
        var contact_list = [];
        contact_list.push({
          label: data.result.name,
          value: data.result._id ? data.result._id : "",
          email: data.result.email,
        });
        setContacts(contact_list);
      }
    } catch (error) {
      console.log("getAdminInfo error", error);
      enqueueSnackbar("Something went wrong getting contacts", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  /*   const getContacts = async (_page, _rowsPerPage, sortOrder, sortColumn, searchText = "") => {
    setLoading(true);

    try {
      if (_page !== page) setPage(_page);
      console.log("getContacts Try");
      let url =
        BASE_URL +
        `getContactsForFileSharing?page=${_page}&rowsPerPage=${rowsPerPage}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`;
      if (searchText) {
        url = url + "?name=" + searchText;
      }
      const { data } = await axios.get(url, getHeaders());
      console.log("getContacts data==>", data);
      if (data.isFromMainApp) {
        console.log("Inside If isFromMainApp==>", data);
        const dataFromMainApp = JSON.parse(data.data);
        // setContacts(dataFromMainApp.data);
        var contact_list = [];
        for (var i = 0; i < dataFromMainApp.data.length; i++) {
          if (dataFromMainApp.data[i].email) {
            contact_list.push({
              label: dataFromMainApp.data[i].first_name + " " + dataFromMainApp.data[i].last_name,
              value: dataFromMainApp.data[i].id ? dataFromMainApp.data[i].id : dataFromMainApp.data[i]._id,
              email: dataFromMainApp.data[i].email,
            });
          }
        }
        setContacts(contact_list);
      } else {
        console.log("Inside ELSE isFromMainApp==>", data);
        var contact_list = [];
        for (var i = 0; i < data.data.length; i++) {
          console.log("Inside FOR==>", data.data[i]);
          if (data.data[i].email) {
            contact_list.push({
              label: data.data[i].first_name
                ? data.data[i].first_name + " " + data.data[i].last_name
                : data.data[i].name,
              value: data.data[i].id ? data.data[i].id : data.data[i]._id,
              email: data.data[i].email,
            });
          }
        }
        console.log("contact_list==>", contact_list);
        setContacts(contact_list);
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar("Something went wrong getting contacts", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    console.log("All Contacts for Select==>", contacts);
    setLoading(false);
  }; */

  const handleChangeTitle = (index) => (e) => {
    console.log("Inside handleChangeTitle==>", e.target);
    console.log("Target ID==>", e.target.id);
    console.log("Target Value==>", e.target.value);

    let newArr = [...files]; // copying the old datas array
    // newArr[index].id = e.target.id;
    newArr[index].name = e.target.value;

    newArr.map((file, index) => {
      console.log("MAP File==>", file);
      if (file.id == e.target.id) {
        setTitleUpdated(file.org_file_name != e.target.value);
      }
    });
    setFiles(newArr);
  };

  const handleChangeExpDate = (index) => (e) => {
    console.log("Inside handleChangeExpDate==>", e.target);
    console.log("handleChangeExpDate Target ID==>", e.target.id);
    console.log("handleChangeExpDate Target Value==>", e.target.value);

    let newArr = [...files]; // copying the old datas array
    newArr[index].exp_date = e.target.value;

    newArr.map((file, index) => {
      console.log("handleChangeExpDate MAP File==>", file);
      if (file.id == e.target.id) {
        setTitleUpdated(e.target.value != "");
      }
    });
    setFiles(newArr);
  };

  const handleContacts = (index, file_id) => (e) => {
    console.log("Inside handleContacts index==>", e);
    console.log("Inside handleContacts file_id==>", file_id);

    let newArr = [...files]; // copying the old datas array
    console.log("handleContacts newArr=>", newArr);
    newArr[index].contact_list = e;

    newArr.map((file, index) => {
      console.log("handleContacts MAP File==>", file);
      if (file.id == file_id) {
        setTitleUpdated(e != "");
      }
    });
    setFiles(newArr);
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log("Inside handleSubmit==>", selected);
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("handleSubmit userInfo==>", userInfo);
    if (files && files.length > 0) {
      if (userInfo.result.name && userInfo.result.name != "") {
        if (titleUpdated) {
          console.log("Updating Titles...");
          console.log("Is Title Updated==>", titleUpdated);
          let isAllFilesAdded = true;
          for (var i = 0; i < files.length; i++) {
            try {
              const { data } = await axios.post(
                BASE_URL + "updateSharedFile/",
                {
                  original_file_name: files[i].name,
                  file_id: files[i].id,
                  file_contacts: files[i].contact_list ? files[i].contact_list : [],
                  expiration_date: files[i].exp_date ? files[i].exp_date : "",
                  shared_by: userInfo.result.name,
                },
                getHeaders()
              );
              console.log("handleSubmit Saving==>", data);
              if (data.success) {
                console.log("File " + files[i].name + " uploaded successfully");
              } else {
                isAllFilesAdded = false;
                enqueueSnackbar(data.message, { variant: "error" });
              }
            } catch (error) {
              isAllFilesAdded = false;
              console.log("handleSubmit error", error);
              enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
            }
          }
          setLoading(false);
          if (isAllFilesAdded) {
            history.push("/file-sharing", {
              onPageLoad: "outcoming",
            });
          }
        } else {
          enqueueSnackbar("Files are uploaded successfully", { variant: "success" });
          setLoading(false);
          history.push("/file-sharing", {
            onPageLoad: "outcoming",
          });
        }
      } else {
        enqueueSnackbar("Something went wrong! Please try again", { variant: "error" });
      }
    } else {
      enqueueSnackbar("You must select at least one file to upload.", { variant: "error" });
    }
    setLoading(false);
  };

  const handleAssign = (index, file_id) => (e) => {
    const toSearch = { value: "*" };
    let isSelectAll = false;
    let selectAllFilter = e.filter((e) => e.value == "*");
    isSelectAll = selectAllFilter.length == 1;

 

    let newArr = [...files]; // copying the old datas array
    newArr[index].contact_list = isSelectAll ? contacts : e;

    newArr.map((file, index) => {
      if (file.id == file_id) {
        setTitleUpdated(e != "");
      }
    });
    setFiles(newArr);
  };

  // const onChange = (value, event) => {
  //   console.log("value==>", value)
  //   console.log("event==>", event)
  //   if (event.action === "select-option" && event.option.value === "*") {
  //     let newArr = [...files]; // copying the old datas array
  //     console.log("handleContacts newArr=>", newArr)
  //     newArr[index].contact_list = contacts;

  //     newArr.map((file, index) => {
  //       console.log("handleContacts MAP File==>", file);
  //       if(file.id==file_id) {
  //         setTitleUpdated(value!="")
  //       }
  //     })
  //     setFiles(newArr)
  //   }
  // }

  return (
    <>
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />
              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-fmg" style={bodyWidth ? { paddingLeft: 80 } : null}>
                      <Sidebar page="file-sharing" getState={sidebarState} />

                      <div className="nk-fmg-body">
                        <div className="nk-fmg-body-content">
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between position-relative">
                              <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Upload Files</h3>
                                <h5 style={{ marginTop: "20px", fontSize: "17px" }}>FILES READY TO UPLOAD</h5>
                              </div>
                            </div>
                          </div>
                          {/* .nk-block-head */}
                          <Stack>
                            <p>
                              Please complete the following information to finish the uploading process. Remember that
                              "Title" is a required field.
                            </p>
                            <p>
                              <strong>Note</strong>: You can skip assigning if you want. The files are retained and you
                              may add them to clients or groups later.
                            </p>
                          </Stack>
                          {files && files.length > 0
                            ? files.map((file, index) => (
                                <div style={{ marginTop: "20px" }}>
                                  <span
                                    style={{
                                      wordBreak: "break-all",
                                      padding: "10px",
                                      background: "#232835",
                                      color: "white",
                                      fontSize: "18px",
                                      display: "inline-block",
                                      borderRadius: "5px 5px 0 0",
                                    }}
                                  >
                                    {file.name}
                                  </span>
                                  <div className="container-fluid border">
                                    <div className="row" style={{ marginTop: "20px", marginBottom: "20px" }}>
                                      <div className="col-sm-4">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <h3
                                              style={{
                                                fontSize: "17px",
                                                fontWeight: "900",
                                                textTransform: "uppercase",
                                                color: "#666",
                                              }}
                                            >
                                              FILE INFORMATION
                                            </h3>
                                            <div className="form-group">
                                              <label
                                                style={{ marginBottom: ".5rem", color: "#666", fontWeight: "bold" }}
                                              >
                                                <span style={{ color: "red" }}>* </span>Title
                                              </label>
                                              <input
                                                type="text"
                                                id={file.id}
                                                value={file.name}
                                                className="form-control file_title"
                                                onChange={handleChangeTitle(index)}
                                                placeholder="Enter here the required file title."
                                                required
                                              />
                                              <span style={{ fontSize: "11px", color: "#666" }}>
                                                Enter title without file extension
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-4">
                                        <h3
                                          style={{
                                            fontSize: "17px",
                                            fontWeight: "900",
                                            textTransform: "uppercase",
                                            color: "#666",
                                          }}
                                        >
                                          EXPIRATION DATE
                                        </h3>
                                        <div className="form-group">
                                          <label style={{ marginBottom: ".5rem", color: "#666", fontWeight: "bold" }}>
                                            Select a date
                                          </label>
                                          <input
                                            type="date"
                                            id={"exp-" + file.id}
                                            name="exp_date"
                                            className="form-control"
                                            onChange={handleChangeExpDate(index)}
                                          />
                                        </div>
                                        {/* <div className="custom-control custom-checkbox custom-control-sm">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        <label className="custom-control-label" for="customCheck1">File Expires</label>
                                      </div> */}
                                        {/* <h3 style={{
                                          marginTop:"40px",
                                          fontSize:"17px", fontWeight:"900", 
                                          textTransform:"uppercase", color:"#666"
                                        }}
                                      >
                                        PUBLIC DOWNLOADING
                                      </h3>
                                      <div className="custom-control custom-checkbox custom-control-sm">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        <label className="custom-control-label" for="customCheck1">Allow public downloading of this file.</label>
                                      </div> */}
                                      </div>
                                      <div className="col-sm-4">
                                        <h3
                                          style={{
                                            fontSize: "17px",
                                            fontWeight: "900",
                                            textTransform: "uppercase",
                                            color: "#666",
                                          }}
                                        >
                                          ASSIGNATIONS
                                        </h3>
                                        <div className="form-group">
                                          <label style={{ marginBottom: ".5rem", color: "#666", fontWeight: "bold" }}>
                                            Assign this file to:
                                          </label>
                                          {/* <MultiSelect id={"contact-"+file.id}
                                          options={contacts}
                                          value={file.contact_list ? file.contact_list : selected}
                                          // onChange={setSelected}
                                          onChange={handleContacts(index, file.id)}
                                          labelledBy="Select Contact"
                                          showCheckbox={false}
                                          // hasSelectAll={false}
                                        /> */}
                                        {loading ? <Loading style={{height:"1rem"}} /> :   <Select
                                            isMulti
                                            isSelectAll
                                            name="assignations"
                                            options={
                                              contacts.map(contact => ({label:contact.label, value:contact.email}))
                                            }
                                            value={file.contact_list ? file.contact_list : []}
                                            onChange={handleAssign(index, file.id)}
                                          />}
                                        
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : ""}
                          <div className="container" style={{ textAlign: "center", marginTop: "40px" }}>
                            <button
                              className="btn btn-primary"
                              style={{ backgroundColor: "rgb(20, 155, 227)" }}
                              onClick={() => handleSubmit()}
                            >
                              Share
                            </button>
                          </div>
                        </div>
                        {/* .nk-fmg-body-content */}
                      </div>
                      {/* .nk-fmg-body */}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}
        </div>
      </div>
    </>
  );
};

export default UploadProcess;
