import { TablePagination, makeStyles } from '@material-ui/core';
import React from 'react'
import Pagination from '@material-ui/lab/Pagination';
const useStyles = makeStyles(() => ({
    ul: {
      "& .MuiPaginationItem-textPrimary.Mui-selected": {
        backgroundColor:"rgb(20, 155, 227)"
      }
    }
  }));
export default function Paginator({ pageCount = 2, onChange }) {
    const classes = useStyles();
    return (
        <div style={{ display: "flex", marginTop: "1rem", justifyContent: "center" }}>
            <Pagination count={pageCount} color="primary" classes={{ ul: classes.ul }} onChange={(e, page) => onChange(page)} />
        </div>
    )
}
