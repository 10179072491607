import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router';
import {Grid, IconButton, Modal, TextField} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {makeStyles,} from '@material-ui/core/styles';
import { red } from '@mui/material/colors';
import '../../../assets/css/dashlite.css';
import '../../../assets/css/theme.css';
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import {Clear} from '@material-ui/icons';
import {Loading} from './Loading';


const useStyles = makeStyles((theme) => ({
  palette: {
    primary: {
      main: 'rgb(20, 155, 227)',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  paper: {
    position: 'absolute',
    width: window.innerWidth > 600 ? 500 : '100%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto !important',
    top: '50%',
    fontFamily: 'Nunito, sans-serif !important',
  },
  multilineColor: {
    color: 'white',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  rooot: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  fieldroot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

export const Layout = ({loading, navbar = <Navbar/>, sidebar = <Sidebar/>, pageHeader, content}) => {

  const [modalStyle] = useState(getModalStyle)
  const [bodyWidth, setBodyWidth] = React.useState(false)


  const {innerWidth, innerHeight} = window
  const history = useHistory()

  function getModalStyle() {
    const {innerWidth, innerHeight} = window

    let top = 50,
      left = innerWidth < 600 ? 0 : 50
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
    }
  }


  const classes = useStyles()


  const userLogin = useSelector((state) => state.userLogin)
  const {userInfo} = userLogin


  const [open, setOpen] = useState(false)


  const handleOpen = () => {
    setOpen(true)

  }

  const sidebarState = (state) => {
    console.log(state)
    setBodyWidth(state)
  }


  return (
    <>
      <div className='nk-body npc-apps apps-only has-apps-sidebar npc-apps-files'>
        <div className='nk-app-root'>
          <div className='nk-main '>

            <div className='nk-wrap '>

              {navbar}


              <div className='nk-content p-0'>
                <div className='nk-content-inner'>
                  <div className='nk-content-body'>
                    <div className='nk-fmg' style={bodyWidth ? {paddingLeft: 80} : null}>
                      {/* {sidebar} */}
                      <Sidebar page='home' getState={sidebarState}/>

                      {loading ? (
                        <Loading/>
                      ) : (
                        <div className='nk-fmg-body'>
                          <div className='nk-fmg-body-content'>
                            {pageHeader}

                            {content}
                          </div>

                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
      <Modal
        open={open}
        onClose={() => {
        }}
        aria-labelledby='simple-modal-title'
      >
        <div style={modalStyle} className={classes.paper}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h5 id='simple-modal-title'>Create Folder</h5>
            <IconButton

              color='inherit'
              aria-label='open drawer'
              style={{marginTop: -10, marginBottom: 10}}
            >
              <Clear style={{color: 'grey'}}/>
            </IconButton>
          </div>


          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                style={{color: 'black', width: '100%', marginTop: 9}}
                id='outlined-basic'
                size='small'
                label='Folder Name'

                variant='outlined'
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              marginTop: 13,
              justifyContent: 'space-around',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              type='submit'

            >
              <div>Create Folder</div>
            </Button>
          </div>

        </div>
      </Modal>


    </>
  )
}
