import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import { NEST_BACKEND_URL } from "../constants/apiconstant";
import { Link as Links } from 'react-router-dom'
import { useHistory, withRouter, useParams } from "react-router-dom";
import axios from "axios"
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { CircularProgress } from '@material-ui/core';
import logo from './../assets/images/logo.jpeg'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';




const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }))

export default function TokenExpired(props) {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    console.log("ss", params);
    useEffect(() => {
      
        if (params.token && params.user_id) {
           
        }
        else {
            setError("Token or User ID missing")
        }

    });
    const resentInvitationLink = async () => {
        setLoading(true)
        try {
            const { data } = await axios.post(`${NEST_BACKEND_URL}auth/resent_invitation_email`, {
            expired_token:params.token,
            user_id: params.user_id
        });
        if (data.success) {
            setSuccess(data.message)
        } else {
            setError(data.message)
        }
    }catch(error) {
        setError("An error occurred, please try again or contact support")
    }
        setLoading(false);

    };
    const classes = useStyles()

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <ToastContainer />

            <div className={classes.paper}>

               
                <Card sx={{ maxWidth: 345 }}>
     
      <CardContent>
      <div style={{display:"flex", justifyContent:"center"}}>
      <img style={{ width: "10rem", borderRadius: "50%" }} alt="" src={logo} />
      
      </div>
        {error ? (
                    <Typography style={{color:"red", fontSize:"1.2rem", textAlign:"center"}} component='h1' variant='h5'>
                        {error}
                    </Typography>
                ) : (
                    <>
                        {success ?
                            <Typography style={{ fontSize:"1.2rem", textAlign:"center"}} component='h1' variant='h5'>
                               {success}

                            </Typography>
                            : (
                                <>
                                    <Typography style={{ fontSize:"1.2rem", textAlign:"center"}} component='h1' variant='h5'>
                                        Invitation link has expired. Please click the button below to resend invitation link

                                    </Typography>
                                    <Button
                                        type='button'
                                        fullWidth
                                        onClick={() => resentInvitationLink()}
                                        variant='contained'
                                        style={{ backgroundColor: "rgb(20, 155, 227)"}}
                                        className={classes.submit}
                                        disabled={loading}
                                    >
                                        {
                                            loading
                                                ? <CircularProgress style={{color:"white"}} size={24} />
                                                : 'Resend Invitation Link'
                                        }

                                    </Button>
                                </>
                            )
                        }

                    </>
                )}




          
            </CardContent>
     
     </Card>
     </div>
        </Container>
    );
}
