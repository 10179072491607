import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router'
import './../App.css'
import Editor from 'react-medium-editor'
import { useReactToPrint } from 'react-to-print'
import { Fab } from '@material-ui/core'

import PrintIcon from '@material-ui/icons/Print'
import SaveIcon from '@material-ui/icons/Save'
import SendIcon from '@material-ui/icons/Send'
import FontNameForm from '../medium-ext/FontName'
import FontSizeForm from '../medium-ext/FontSize'
import { useDispatch, useSelector } from 'react-redux'
import { FileUpdate, OcrUpdate } from '../actions/assetsAction'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ExportModal } from './OcrDocument'

require('medium-editor/dist/css/medium-editor.css')
require('medium-editor/dist/css/themes/default.css')

const EditOcrDocument = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showocr, setShowocr] = useState('')
  const [showExportModal, setShowExportModal] = useState(false)

  const componentRef = useRef()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const location = useLocation()

  useEffect(() => {
    console.log('OCRDATA', localStorage.getItem('ocrData'))
    const savedData = JSON.parse(localStorage.getItem('ocrData'))
    savedData.data = JSON.parse(savedData.data)
    // console.log("savedData = ",JSON.parse(savedData.data))
    setShowocr(savedData)
  }, [])

  const handleEditorChange = (text, index) => {
    console.log('text = ', text)
    console.log('index = ', index)
    showocr.data[index].text = text
    setShowocr(showocr)
  }
  const handleSave = () => {
    console.log('showocr = ', showocr)
    // const html = document.querySelectorAll('samp')[0].innerHTML
    const fileid = match.params.id
    dispatch(OcrUpdate(fileid, showocr))
    // history.push('/homepage')
    history.goBack()
  }
  const handleSendClick = () => {
    if (location.state.canExport) {
      setShowExportModal(true)
    }
  }
  return (
    <div>
      <div className="header-bar"></div>
      <div>
        {showocr.data?.length > 0
          ? showocr.data?.map((ocrtext, index) => (
              <div className="editor">
                <Editor
                  ref={componentRef}
                  tag="samp"
                  text={ocrtext?.text}
                  onChange={(event) => handleEditorChange(event, index)}
                  options={{
                    imageDragging: true,
                    placeholder: false,
                    anchorPreview: true,
                    toolbar: false,
                    extensions: {
                      fontname: new FontNameForm(),
                      fontsize: new FontSizeForm(),
                    },
                  }}
                />
              </div>
            ))
          : null}

        {/* {daf?.length <= 0 ? (
            <>
            </>
        ) : (
            <>
            <Draggable>
                <img src={daf} style={{ position: 'absolute' }}></img>
            </Draggable>
            </>
        )}{' '} */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            bottom: 20,
            right: 32,
          }}
        >
          <Fab
            onClick={handleSave}
            style={{ marginTop: 10, backgroundColor: '#000' }}
            color="primary"
          >
            <SaveIcon />
          </Fab>
          <Fab
            onClick={handleSendClick}
            style={{ marginTop: 10, backgroundColor: '#000' }}
            color="primary"
          >
            <SendIcon />
          </Fab>
          {/* <Fab
            style={{ marginTop: 10, backgroundColor: '#000' }}
            color='primary'
            onClick={handlePrint}
            >
            <PrintIcon />
            </Fab> */}
        </div>
      </div>
      {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          userInfo={userInfo}
          apikey={location.state.apikey}
          taskIds={location.state.task_id}
        />
      )}
    </div>
  )
}

export default EditOcrDocument
