import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  MenuItem,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../constants/apiconstant";
import { getHeaders } from "../../utils";

export default function MoveInFolder({
  onClose,
  folders,
  fileToMoveId,
  onMoved,
  showMoveToRootFolder = false,
}) {
  const [modalStyle] = useState(getModalStyle);
  const [folderId, setFolderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const { innerWidth, innerHeight } = window;
  useEffect(() => {
    //setFolderId(folders[0]._id);
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
    };
  }

  const moveFiles = async () => {
    setLoading(true);
    try {
      const data = await axios.post(
        BASE_URL + "moveEsigFilesIntoFolder/",
        { fileToMoveId, folderId },
        getHeaders()
      );
      console.log("data", data);
      setIsCreated(true);
      enqueueSnackbar("Files has been moved", { variant: "success" });
      onMoved();
      onClose();
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    setLoading(false);
  };

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="simple-modal-title">
      <div
        style={{
          position: "absolute",
          width: innerWidth > 600 ? 500 : "100%",

          backgroundColor: theme.palette.background.paper,
          // border: '2px solid #000',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto !important",
          top: "50%",
          borderRadius: "11px",
          ...modalStyle,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5 id="simple-modal-title">Create Folder</h5>
          <IconButton
            onClick={onClose}
            color="inherit"
            aria-label="open drawer"
            style={{ marginTop: -10, marginBottom: 10 }}
          >
            <ClearIcon style={{ color: "grey" }} />
          </IconButton>
        </div>

        <Grid item xs={12}>
          <FormControl
            style={{ width: "100%" }}
            variant="outlined"
            size="small"
          >
            <InputLabel id="demo-simple-select-label">Folder Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={folderId}
              onChange={(e) => setFolderId(e.target.value)}
            >
              {showMoveToRootFolder && (
                <MenuItem value={"moveToRoot"}>Move to parent</MenuItem>
              )}
              {folders?.length > 0
                ? folders?.map((folder) => {
                    if (localStorage.getItem("id") == folder._id) return null;
                    return (
                      <MenuItem value={folder._id}>
                        {folder.foldername}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: 13,
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={moveFiles}
            disabled={isCreated}
            style={{backgroundColor: "rgb(20, 155, 227)", color: "white"}}
          >
            <div>{loading ? <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={20} /> : "Move"} </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
