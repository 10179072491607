import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import ReactTimeAgo from 'react-time-ago'
import FolderMenu from './FolderMenu'

const FolderButton = (props) => {
  return (
    <div className='nk-file-item nk-file'>
      <div className='nk-file-info'>
        <div className='nk-file-title'>
          <div className='nk-file-icon'>
            <span className='nk-file-icon-type'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                viewBox='0 0 72 72'
              >
                <path
                  fill='#6C87FE'
                  d='M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
C60,29.9,58.9,31,57.5,31z'
                />
                <path
                  fill='rgb(20, 155, 227)'
                  d='M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z'
                />
                <path
                  display='none'
                  fill='rgb(20, 155, 227)'
                  d='M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z'
                />
                <path
                  fill='#798BFF'
                  d='M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z'
                />
              </svg>
            </span>
          </div>
          <Link to={`/foldersfiles/${props.folders?._id}`}>
            <div className='nk-file-name'>
              <div className='nk-file-name-text'>
                {props.folders?.folderName}
              </div>
            </div>
          </Link>
        </div>
        <ul className='nk-file-desc'>
          <li className='date'>
            Last updated: <ReactTimeAgo date={props.folders?.updatedAt} locale="en-US" />
          </li>
        </ul>
      </div>
      <div className='nk-file-actions'>
        <div className='dropdown'>
          {/* <IconButton
      color='inherit'
      aria-label='open drawer'
      onClick={() =>
        deletefolder(
          props.folders?._id,
          index
        )
      }
    >
      <DeleteIcon
        style={{ color: 'grey' }}
      />
    </IconButton> */}
          <FolderMenu
            id={props.folders?._id}
            setCurrentfolderid={props.setCurrentfolderid}
            setOpenfoldername={props.setOpenfoldername}
            name={props.name}
          />
        </div>
      </div>
    </div>
  )
}

export default FolderButton
