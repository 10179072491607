import React, { useEffect, useState } from "react";

import "../../assets/css/dashlite.css";
import "../../assets/css/theme.css";
import "../../assets/css/custom-shared-docs.css";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Grid, Modal, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CreateFolder from "./CreateFolder";

import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import "../homepage.css";
import axios from "axios";
import {
  getCSVSVG,
  getDocSVG,
  getFileSVG,
  getFolderSVG,
  getHeaders,
  getMediaSVG,
  getPDFSVG,
  getZipSVG,
} from "../../utils";
import { BASE_URL } from "../../constants/apiconstant";
import { useSnackbar } from "notistack";
import RenameFile from "./RenameFile";
import Moment from "moment";

import SharedFolderMenu from "./SharedFolderMenu";
import SharedOutFileMenu from "./SharedOutFileMenu";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import Paginator from "../../components/Pagination";
import Swal from "sweetalert2";
import ShareFolderModal from "./ShareFolderModal";
import StorageType from "../../components/StorageType";
import Tour from "../../components/Tour";

const steps = [
  {
    element: ".step-fs-1",
    intro:
      "Allows users to upload new files for sharing. Clicking this button opens the file upload dialog where users can select files to upload.",
  },
  {
    element: ".step-fs-2",
    intro:
      "Allows users to create a new folder for organizing shared files. Clicking this button opens an interface for naming and creating a new folder.",
  },
  {
    element: ".step-esign-3",
    intro:
      "Toggle the switch to choose between different storage options for saving files. Users can switch between AWS and Storj storage solutions based on their preferences.",
  },
  {
    element: ".step-fs-4",
    intro:
      "Allows users to search for files and folders. This feature helps users quickly locate specific files or folders within the file-sharing section.",
  },
  {
    element: ".step-fs-5",
    intro:
      "Provides additional filtering options to narrow down the list of files based on specific criteria. This might include file types, date ranges, or other attributes.",
  },
];

const SharedDocuments = () => {
  const location = useLocation();

  const [showCreateFolderModal, setShowClearFolderModal] = useState(false);
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const { innerWidth, innerHeight } = window;
  const history = useHistory();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      borderRadius: "11px",
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: innerWidth > 600 ? 500 : "100%",

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto !important",
      top: "50%",
      fontFamily: "Nunito, sans-serif !important",
    },
    multilineColor: {
      color: "white",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    flex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "1rem",
      flex: 1,
    },
    rooot: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fieldroot: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const classes = useStyles();

  const [incomingDocuments, setIncomingDocuments] = useState({
    activePage: 1,
    pages: 1,
    data: [],
    active: true,
  });

  const [outgoingDocuments, setOutgoingDocuments] = useState({
    activePage: 1,
    pages: 1,
    data: [],
    active: false,
  });

  const [folders, setFolders] = useState({
    activePage: 1,
    pages: 1,
    data: [],
    active: false,
  });

  const [loading, setLoading] = useState({
    incoming: false,
    outgoing: false,
    folder: false,
  });

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [fileToMoveId, setFileToMoveId] = useState([]);
  const [showMoveInFolderModal, setShowMoveInFolderModal] = useState(false);
  const [bodyWidth, setBodyWidth] = React.useState(false);
  const [folderClicked, setFolderClicked] = useState(null);
  const [folderFilesGetLoading, setFolderFilesGetLoading] = useState(false);
  const [folderFiles, setFolderFiles] = useState([]);
  const [showMoveButton, setShowMoveButton] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [originamFilename, setOriginalFilname] = useState("");
  const [showInfoModel, setShowInfoModel] = useState(false);
  const [currentfolderid, setCurrentfolderid] = useState("");
  const [openfoldername, setOpenfoldername] = useState("");
  const [renamefolder, setRenamefolder] = useState("");
  const [removefolder, setRemovefolder] = useState("");
  const [showExpiredFiles, setShowExpiredFiles] = useState(false);
  const [showDeletedFiles, setShowDeletedFiles] = useState(false);

  const [showExpiredFilesIncoming, setShowExpiredFilesIncoming] = useState(false);
  const [showDeletedFilesIncoming, setShowDeletedFilesIncoming] = useState(false);

  const [currentOutfileid, setCurrentOutfileid] = useState("");
  const [openOutFilename, setOpenOutFilename] = useState("");
  const [orgOutFolderid, setOrgOutFolderid] = useState("");
  const [currentOutFolderid, setCurrentOutFolderid] = useState("");
  const [renameOutFile, setRenameOutFile] = useState("");
  const [removeOutFile, setRemoveOutFile] = useState("");
  const [moveOutFile, setMoveOutFile] = useState(false);
  const [downloadOutFile, setDownloadOutFile] = useState(false);

  const [getFoldersList, setFoldersList] = useState([]);

  const [isNormalUser, setIsNormalUser] = useState(localStorage.getItem("isNormalUser"));
  const [userRole, setuserRole] = useState(localStorage.getItem("userRole"));
  const [getSharedContacts, setSharedContacts] = useState([]);
  const [showSharedContacts, setShowSharedContacts] = useState(false);
  const [hasAdminAccess, setHasAdminAccess] = useState(false);

  const [selectedSortColumnIncoming, setSelectedSortColumnIncoming] = useState("created_at");
  const [selectedSortColumnOutgoing, setSelectedSortColumnOutgoing] = useState("created_at");
  const [selectedSortColumnFolders, setSelectedSortColumnFolders] = useState("created_at");

  const [orderIncoming, setOrderIncoming] = useState("DESC");
  const [orderOutgoing, setOrderOutgoing] = useState("DESC");
  const [orderFolders, setOrderFolders] = useState("DESC");

  const [search, setSearch] = useState(false);
  const [setting, setSetting] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState([]);

  const [currentPageIncoming, setCurrentPageIncoming] = useState(1);
  const [currentPageOutgoing, setCurrentPageOutgoing] = useState(1);
  const [currentPageFolders, setCurrentPageFolders] = useState(1);

  const [sharedRemove, setSharedRemove] = useState(false);
  const [shareFolderToContacts, setShareFolderToContacts] = useState({ share: false, folderName: "" });
  const [showTour, setShowTour] = useState(false);
  const [tourLoading, setTourLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const sidebarState = (state) => {
    setBodyWidth(state);
  };
  useEffect(() => {
    let deleteAccess = isNormalUser != true && isNormalUser != "true" ? true : userRole == "admin" ? true : false;
    setHasAdminAccess(deleteAccess);
    var onPageLoad = "";
    try {
      onPageLoad = location.state.onPageLoad;
    } catch (error) {
      console.log("useEffect onPageLoad State==>", error);
    }
    getDocuments(onPageLoad);
  }, []);

  const getDocuments = async (onPageLoad) => {
    if (onPageLoad && onPageLoad == "outcoming") {
      await getOutgoingDocuments(
        currentPageOutgoing,
        searchText,
        rowsPerPage,
        selectedSortColumnOutgoing,
        orderOutgoing,
        showDeletedFiles,
        showExpiredFiles
      );
    } else if (onPageLoad && onPageLoad == "folders") {
      await getFolders(currentPageFolders, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders);
    } else {
      await getIncomingDocuments(
        currentPageIncoming,
        searchText,
        rowsPerPage,
        selectedSortColumnIncoming,
        orderIncoming,
        showDeletedFilesIncoming,
        showExpiredFilesIncoming
      );
    }

    await get_introjs_page_completed();
    setLoading({
      incoming: false,
      outgoing: false,
      folder: false,
    });
  };

  const get_introjs_page_completed = async () => {
    let url = BASE_URL + `get_intro_js_page?page_completed=esign_file_sharing`;
    const { data } = await axios.get(url, getHeaders());
    setShowTour(!data.introjs_completed);
    setTourLoading(false);
  };
  const getIncomingDocuments = async (
    page,
    searchString = "",
    rowsPerPage = 20,
    sortColumn = "file_name",
    sortOrder = "DESC",
    showDelFiles = false,
    showExpFalse = false
  ) => {
    setSearchText(searchString);
    setLoading({ ...loading, incoming: true });
    setIncomingDocuments({
      active: true,
    });
    setOutgoingDocuments({
      active: false,
    });
    setFolders({
      active: false,
    });
    try {
      let url =
        BASE_URL +
        `getIncomingFiles?page=${page}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&rowsPerPage=${rowsPerPage}&showDeletedFiles=${showDelFiles}&showExpiredFiles=${showExpFalse}`;
      if (searchString) {
        url = url + "&searchText=" + searchString;
      }
      const { data } = await axios.get(url, getHeaders());

      setIncomingDocuments({
        ...incomingDocuments,
        pages: data.pages,
        data: [...data.data],
        loading: false,
        active: true,
      });
      setCurrentPageIncoming(page);

      getAllFoldersList();
    } catch (error) {
      console.log("getIncomingDocuments error", error);
    }
    setLoading({ ...loading, incoming: false });
  };

  const getOutgoingDocuments = async (
    page,
    searchString = "",
    rowsPerPage = 20,
    sortColumn = "file_name",
    sortOrder = "DESC",
    showDeletedFiles = false,
    showExpiredFiles = false
  ) => {
    setSearchText(searchString);
    setLoading({ ...loading, outgoing: true });
    setIncomingDocuments({
      active: false,
    });
    setOutgoingDocuments({
      active: true,
    });
    setFolders({
      active: false,
    });
    try {
      let url =
        BASE_URL +
        `getOutgoingFiles?page=${page}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&rowsPerPage=${rowsPerPage}&showDeletedFiles=${showDeletedFiles}&showExpiredFiles=${showExpiredFiles}`;
      if (searchString) {
        url = url + "&searchText=" + searchString;
      }
      const { data } = await axios.get(url, getHeaders());

      setOutgoingDocuments({
        ...outgoingDocuments,
        pages: data.pages,
        data: [...data.data],
        loading: false,
        active: true,
      });
      setCurrentPageOutgoing(page);

      getAllFoldersList();

      const { data1 } = await axios.get(BASE_URL + "getSharedFolders?page=" + page, getHeaders());
    } catch (error) {
      console.log("getOutgoingDocuments error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    setLoading({ ...loading, outgoing: false });
    if (setShowClearFolderModal) {
      setShowClearFolderModal(false);
    }
  };

  const getAllFoldersList = async () => {
    try {
      const { data } = await axios.get(BASE_URL + "getSharedFolders", getHeaders());
      var folder_list = [];

      var data_list = data.data;

      for (var i = 0; i < data_list.length; i++) {
        folder_list.push({
          label: data_list[i]._id,
          value: data_list[i].folder_name,
        });
      }

      setFoldersList(folder_list);
    } catch (error) {
      console.log("getAllFoldersList error", error);
    }
  };

  const getFolders = async (
    page,
    searchString = "",
    rowsPerPage = 20,
    sortColumn = "folder_name",
    sortOrder = "DESC"
  ) => {
    setSearchText(searchString);
    setLoading({ ...loading, folder: true });
    setIncomingDocuments({
      active: false,
    });
    setOutgoingDocuments({
      active: false,
    });
    setFolders({
      active: true,
    });
    try {
      let url =
        BASE_URL +
        `getSharedFolders?page=${page}&sortOrder=${sortOrder}&sortColumn=${sortColumn}&rowsPerPage=${rowsPerPage}`;
      if (searchString) {
        url = url + "&searchText=" + searchString;
      }
      const { data } = await axios.get(url, getHeaders());

      setFolders({
        ...folders,
        pages: data.pages,
        data: [...data.data],
        loading: false,
        active: true,
      });
      setCurrentPageFolders(page);
    } catch (error) {
      console.log("getLinkDocuments error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    setLoading({ ...loading, folder: false });
    if (setShowClearFolderModal) {
      setShowClearFolderModal(false);
    }
  };

  const handleClosed = () => {
    setOpenfoldername(false);
    setOpenOutFilename(false);
    setRemovefolder(false);
    setRemoveOutFile(false);
    setMoveOutFile(false);

    setCurrentOutfileid("");
    setOpenOutFilename("");
    setOrgOutFolderid("");
    setCurrentOutFolderid("");

    setShowSharedContacts(false);
    setSharedRemove(false);
  };

  const renamefolderHandler = (e) => {
    e.preventDefault();

    updateFolderName();
    handleClosed();
    setRenamefolder("");
  };

  const updateFolderName = async (name) => {
    setLoading({ ...loading, folder: true });
    try {
      const { data } = await axios.post(
        BASE_URL + "updateSharedFolder/",
        { folder_name: name, folder_id: currentfolderid },
        getHeaders()
      );

      if (data.success) {
        Swal.fire("Folder Renamed", " ", "success");
        await getFolders(currentPageFolders, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders);
      } else {
        Swal.fire("Error Renaming Folder!", " ", "error");
      }
    } catch (error) {
      console.log("updateFolderName error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    setLoading({ ...loading, folder: false });
  };

  const deleteSharedFolder = async (id) => {
    setLoading({ ...loading, folder: true });
    try {
      const { data } = await axios.delete(BASE_URL + `deleteSharedFolder/?id=${id}`, getHeaders());

      if (data.success) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        await getFolders(currentPageFolders, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders);
      } else {
        Swal.fire("Error While Deleting!", "", "error");
      }
    } catch (error) {
      console.log("deleteSharedFolder error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    handleClosed();
    setLoading({ ...loading, folder: false });
  };

  const renameOutFileHandler = (e) => {
    e.preventDefault();

    updateOutFileName();
    handleClosed();
    setRenameOutFile("");
  };

  const updateOutFileName = async () => {
    setLoading({ ...loading, folder: true });
    try {
      const { data } = await axios.post(
        BASE_URL + "updateSharedFile/",
        {
          original_file_name: renameOutFile,
          file_id: currentOutfileid,
          isRenamed: true,
        },
        getHeaders()
      );

      if (data.success) {
        enqueueSnackbar(data.message, { variant: "success" });
        if (outgoingDocuments.active) {
          await getOutgoingDocuments(
            currentPageOutgoing,
            searchText,
            rowsPerPage,
            selectedSortColumnOutgoing,
            orderOutgoing,
            showDeletedFiles,
            showExpiredFiles
          );
        }
        if (incomingDocuments.active) {
          await getIncomingDocuments(
            currentPageIncoming,
            searchText,
            rowsPerPage,
            selectedSortColumnIncoming,
            orderIncoming,
            showDeletedFilesIncoming,
            showExpiredFilesIncoming
          );
        }
      } else {
        enqueueSnackbar(data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    setLoading({ ...loading, outgoing: false });
  };

  function formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const deleteSharedFile = async (id) => {
    setLoading({ ...loading, folder: true });
    try {
      const { data } = await axios.delete(BASE_URL + `deleteSharedFile/?id=${id}`, getHeaders());

      if (data.success) {
        enqueueSnackbar(data.message, { variant: "success" });
        if (outgoingDocuments.active) {
          getOutgoingDocuments(
            currentPageOutgoing,
            searchText,
            rowsPerPage,
            selectedSortColumnOutgoing,
            orderOutgoing,
            showDeletedFiles,
            showExpiredFiles
          );
        }
        if (incomingDocuments.active) {
          getIncomingDocuments(
            currentPageIncoming,
            searchText,
            rowsPerPage,
            selectedSortColumnIncoming,
            orderIncoming,
            showDeletedFilesIncoming,
            showExpiredFilesIncoming
          );
        }
      } else {
        enqueueSnackbar(data.message, { variant: "error" });
      }
    } catch (error) {
      console.log("deleteSharedFile error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    handleClosed();
    setLoading({ ...loading, folder: false });
  };

  const getFolderName = () => {
    if (orgOutFolderid) {
      let selectedFolderArray = orgOutFolderid.split(",");

      let currentFolderName = null;
      if (getFoldersList) {
        getFoldersList.forEach((folder) => {
          if (folder.label === orgOutFolderid) {
            currentFolderName = folder.value;
          }
        });
      }
      if (currentFolderName) {
        return currentFolderName;
      }
      return "No Folder";
    }
  };

  const changeOutFolderHandler = async (e) => {
    e.preventDefault();
    setLoading({ ...loading, outgoing: true });

    try {
      const { data } = await axios.post(
        BASE_URL + "updateOutFileFolder/",
        {
          file_id: currentOutfileid,
          folder_id: currentOutFolderid,
          remove_folder_id: orgOutFolderid,
        },
        getHeaders()
      );

      if (data.success) {
        enqueueSnackbar(data.message, { variant: "success" });
      } else {
        enqueueSnackbar(data.message, { variant: "error" });
      }
    } catch (error) {
      console.log("changeOutFolderHandler error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    handleClosed();
    if (outgoingDocuments.active) {
      await getOutgoingDocuments(
        currentPageOutgoing,
        searchText,
        rowsPerPage,
        selectedSortColumnOutgoing,
        orderOutgoing,
        showDeletedFiles,
        showExpiredFiles
      );
    }
    if (incomingDocuments.active) {
      await getIncomingDocuments(
        currentPageIncoming,
        searchText,
        rowsPerPage,
        selectedSortColumnIncoming,
        orderIncoming,
        showDeletedFilesIncoming,
        showExpiredFilesIncoming
      );
    }
    setLoading({ ...loading, outgoing: false });
  };

  const getSharedContactsList = async (file_id) => {
    try {
      const { data } = await axios.get(BASE_URL + `getSharedContacts/?file_id=${file_id}`, getHeaders());

      if (data.success) {
        if (data.isContactModel) {
          setSharedContacts(data.data);
          if (data && data.data) {
            setShowSharedContacts(true);
          } else {
            setShowSharedContacts(false);
            enqueueSnackbar("This file is not shared with any contacts!", { variant: "error" });
          }
        } else {
          const contact_email = data.contact_email_list;

          if (contact_email.length > 0) {
            getSharedAdminContactsList(contact_email[0]);
          } else {
            setShowSharedContacts(false);
            enqueueSnackbar("This file is not shared with any contacts!", { variant: "error" });
          }
        }
      } else {
        enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
      }
    } catch (error) {
      console.log("getSharedContactsList error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
  };

  const getSharedAdminContactsList = async (email) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post("https://sign.kycaml.systems/auth/user-info", { email }, config);

      if (data && data.result) {
        var contact_list = [];
        contact_list.push({
          contact_email: data.result.email,
          contact_name: data.result.name,
        });
        setSharedContacts(contact_list);
        setShowSharedContacts(true);
      } else {
        setShowSharedContacts(false);
        enqueueSnackbar("This file is not shared with any contacts!", { variant: "error" });
      }
    } catch (error) {
      console.log("getSharedAdminContactsList error", error);
      enqueueSnackbar("Something went wrong getting contacts", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const showSharedFolderFiles = async (folder_id, folder_name) => {
    history.push("/sharedfoldersfiles/" + folder_id + "?folder_name=" + folder_name);
  };

  const downloadDocument = async (id) => {
    try {
      const header = getHeaders();

      axios({
        url: BASE_URL + `downloadDocument/?id=${id}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: header.headers.Authorization,
        },
      })
        .then((response) => {
          const file_name = response.request.getResponseHeader("x-file-name");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          enqueueSnackbar("The File will download shortly", { variant: "success" });
          link.setAttribute("download", file_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log("ERR=>", error);
          enqueueSnackbar("Download Failed, please try again after some time", { variant: "error" });
        });
    } catch (error) {
      console.log("downloadDocument error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    handleClosed();
  };

  const handleSearchClear = async () => {
    if (incomingDocuments.active) {
      await getIncomingDocuments(
        currentPageIncoming,
        "",
        rowsPerPage,
        selectedSortColumnIncoming,
        orderIncoming,
        showDeletedFilesIncoming,
        showExpiredFilesIncoming
      );
    } else if (outgoingDocuments.active) {
      await getOutgoingDocuments(
        currentPageOutgoing,
        "",
        rowsPerPage,
        selectedSortColumnOutgoing,
        orderOutgoing,
        showDeletedFiles,
        showExpiredFiles
      );
    } else if (folders.active) {
      await getFolders(currentPageFolders, "", rowsPerPage, selectedSortColumnFolders, orderFolders);
    } else {
    }
  };

  const handleSearchOption = async (searchText) => {
    if (incomingDocuments.active) {
      await getIncomingDocuments(
        currentPageIncoming,
        searchText,
        rowsPerPage,
        selectedSortColumnIncoming,
        orderIncoming,
        showDeletedFilesIncoming,
        showExpiredFilesIncoming
      );
    } else if (outgoingDocuments.active) {
      await getOutgoingDocuments(
        currentPageOutgoing,
        searchText,
        rowsPerPage,
        selectedSortColumnOutgoing,
        orderOutgoing,
        showDeletedFiles,
        showExpiredFiles
      );
    } else if (folders.active) {
      await getFolders(currentPageFolders, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders);
    } else {
    }
  };

  const handleRowsPerPage = async (rowsPerPage) => {
    if (incomingDocuments.active) {
      await getIncomingDocuments(
        currentPageIncoming,
        searchText,
        rowsPerPage,
        selectedSortColumnIncoming,
        orderIncoming,
        showDeletedFilesIncoming,
        showExpiredFilesIncoming
      );
    } else if (outgoingDocuments.active) {
      await getOutgoingDocuments(
        currentPageOutgoing,
        searchText,
        rowsPerPage,
        selectedSortColumnOutgoing,
        orderOutgoing,
        showDeletedFiles,
        showExpiredFiles
      );
    } else if (folders.active) {
      await getFolders(currentPageFolders, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders);
    } else {
    }
  };

  const handlePageChange = async (_page) => {
    if (incomingDocuments.active) {
      await getIncomingDocuments(
        _page,
        searchText,
        rowsPerPage,
        selectedSortColumnIncoming,
        orderIncoming,
        showDeletedFilesIncoming,
        showExpiredFilesIncoming
      );
    } else if (outgoingDocuments.active) {
      await getOutgoingDocuments(
        _page,
        searchText,
        rowsPerPage,
        selectedSortColumnOutgoing,
        orderOutgoing,
        showDeletedFiles,
        showExpiredFiles
      );
    } else if (folders.active) {
      await getFolders(_page, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders);
    } else {
    }
  };

  const sharedRemoveFile = async (id) => {
    setLoading({ ...loading, outgoing: true });
    try {
      const { data } = await axios.delete(BASE_URL + `sharedRemoveFile/?id=${id}`, getHeaders());

      if (data.success) {
        enqueueSnackbar(data.message, { variant: "success" });
        if (outgoingDocuments.active) {
          getOutgoingDocuments(
            currentPageOutgoing,
            searchText,
            rowsPerPage,
            selectedSortColumnOutgoing,
            orderOutgoing,
            showDeletedFiles,
            showExpiredFiles
          );
        }
      } else {
        enqueueSnackbar(data.message, { variant: "error" });
      }
    } catch (error) {
      console.log("sharedRemoveFile error", error);
      enqueueSnackbar("Something went wrong, please try again after some time", { variant: "error" });
    }
    handleClosed();
    setLoading({ ...loading, folder: false });
  };
  const createFolder = async (foldername) => {
    try {
      const { data } = await axios.post(BASE_URL + "createSharedFolders/", { foldername }, getHeaders());
      Swal.fire("Folder Created!", " ", "success");
      getFolders(currentPageFolders, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders);
      setShowClearFolderModal(false);
    } catch (error) {
      Swal.fire("Error Creating Folder!", " ", "error");
    }
  };
  const folderCreateModalSwal = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Create Folder",
      confirmButtonColor: "rgb(20, 155, 227)",
      html: '<input id="swal-input1" class="swal2-input">',
      focusConfirm: false,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value];
      },
    });
    if (formValues && formValues[0] !== "") {
      createFolder(formValues[0]);
    }
    setShowClearFolderModal(false);
  };
  useEffect(() => {
    if (showCreateFolderModal) {
      folderCreateModalSwal();
    }
  }, [showCreateFolderModal]);
  const ShowSuccessMessage = (message, type) => {
    Swal.fire(message, " ", type);
  };
  const updateFolderNameHandler = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Rename Folder",
      confirmButtonColor: "rgb(20, 155, 227)",
      html: '<input id="swal-input1" class="swal2-input">',
      focusConfirm: false,
      preConfirm: () => {
        return [document.getElementById("swal-input1").value];
      },
    });
    if (formValues && formValues[0] !== "") {
      updateFolderName(formValues[0]);
    }
  };
  useEffect(() => {
    if (openfoldername) {
      updateFolderNameHandler();
      handleClosed();
    }
  }, [openfoldername]);
  const removeFolderHandler = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(20, 155, 227)",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSharedFolder(currentfolderid);
      }
    });
    setRemovefolder(false);
  };
  useEffect(() => {
    if (removefolder) {
      removeFolderHandler();
    }
  }, [removefolder]);
  return (
    <>
      {!tourLoading && showTour && <Tour steps={steps} isEnabled={showTour} page_name="esign_file_sharing" />}
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />
              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-fmg" style={bodyWidth ? { paddingLeft: 80 } : null}>
                      <Sidebar page="file-sharing" getState={sidebarState} />

                      <div className="nk-fmg-body">
                        <div className="nk-fmg-body-content">
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between position-relative">
                              <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">File Sharing</h3>
                              </div>
                              <div className="nk-block-head-content">
                                <div className="nk-fmg-actions">
                                  <ul className="nk-block-tools g-3">
                                    <Link to={"/upload-files"}>
                                      <li className="step-fs-1">
                                        <button
                                          className="btn btn-primary"
                                          style={{ backgroundColor: "rgb(20, 155, 227)" }}
                                          data-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-upload-cloud" /> <span>Upload</span>
                                        </button>
                                      </li>
                                    </Link>
                                    <li className="step-fs-2">
                                      <button
                                        onClick={() => setShowClearFolderModal(true)}
                                        className="btn btn-primary"
                                        style={{ backgroundColor: "rgb(20, 155, 227)" }}
                                        data-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-upload" /> <span>Create Folder</span>
                                      </button>
                                    </li>
                                    <StorageType requestType={"filesharing"} />
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* .nk-block-head */}

                          <div className="card card-bordered card-stretch">
                            <div className="card-inner-group">
                              <div
                                className="card-inner position-relative card-tools-toggle"
                                style={{ padding: "1.50rem" }}
                              >
                                <div class={"card-title-group " + (search ? "d-none" : null)}>
                                  <div className="card-tools"></div>
                                  <div className="card-tools mr-n1">
                                    <ul className="btn-toolbar gx-1">
                                      <li className="step-fs-4">
                                        <a
                                          href="#"
                                          className="btn btn-icon search-toggle toggle-search"
                                          data-target="search"
                                          onClick={() => setSearch(true)}
                                        >
                                          <em className="icon ni ni-search"></em>
                                        </a>
                                      </li>
                                      <li className="btn-toolbar-sep"></li>
                                      <li className="step-fs-5">
                                        <div className="toggle-wrap d-block">
                                          <a
                                            href="#"
                                            className="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                          >
                                            <em className="icon ni ni-menu-right"></em>
                                          </a>
                                          <div className="toggle-content" data-content="cardTools">
                                            <ul className="btn-toolbar gx-1">
                                              <li className="toggle-close">
                                                <a
                                                  href="#"
                                                  className="btn btn-icon btn-trigger toggle"
                                                  data-target="cardTools"
                                                >
                                                  <em className="icon ni ni-arrow-left"></em>
                                                </a>
                                              </li>
                                              <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    onClick={() => setSetting(!setting)}
                                                    data-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-setting"></em>
                                                  </a>
                                                  <div
                                                    class={
                                                      "dropdown-menu dropdown-menu-xs dropdown-menu-right " +
                                                      (setting ? "d-block" : null)
                                                    }
                                                  >
                                                    <ul className="link-check">
                                                      <li>
                                                        <span>Show</span>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setRowsPerPage(20);
                                                          handleRowsPerPage(20);
                                                        }}
                                                        class={rowsPerPage === 20 && "active"}
                                                      >
                                                        <a href="#">20</a>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setRowsPerPage(50);
                                                          handleRowsPerPage(50);
                                                        }}
                                                        class={rowsPerPage === 50 && "active"}
                                                      >
                                                        <a href="#">50</a>
                                                      </li>
                                                      <li
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setRowsPerPage(100);
                                                          handleRowsPerPage(100);
                                                        }}
                                                        class={rowsPerPage === 100 && "active"}
                                                      >
                                                        <a href="#">100</a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  class={"card-search search-wrap " + (search ? "active" : null)}
                                  data-search="search"
                                >
                                  <div className="card-body">
                                    <div className="search-content">
                                      <a
                                        href="#"
                                        className="search-back btn btn-icon toggle-search"
                                        onClick={() => {
                                          setSearch(false);
                                          setSearchText("");
                                          handleSearchClear();
                                        }}
                                        data-target="search"
                                      >
                                        <em className="icon ni ni-arrow-left"></em>
                                      </a>
                                      <input
                                        type="text"
                                        value={searchText}
                                        onChange={(e) => {
                                          setSearchText(e.target.value);
                                          handleSearchOption(e.target.value);
                                        }}
                                        className="form-control border-transparent form-focus-none"
                                        placeholder={
                                          incomingDocuments.active || outgoingDocuments.active
                                            ? "Search by file name"
                                            : folders.active
                                            ? "Search by folder name"
                                            : ""
                                        }
                                      />
                                      <button className="search-submit btn btn-icon">
                                        <em className="icon ni ni-search"></em>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <ul className="nk-nav nav nav-tabs" style={{ marginTop: "0rem", paddingLeft: "15px" }}>
                                <li
                                  className={incomingDocuments.active ? "nav-item active" : "nav-item"}
                                  onClick={(e) =>
                                    !incomingDocuments.active && !loading.outgoing && !loading.folder
                                      ? getIncomingDocuments(
                                          1,
                                          searchText,
                                          rowsPerPage,
                                          selectedSortColumnIncoming,
                                          orderIncoming,
                                          showDeletedFilesIncoming,
                                          showExpiredFilesIncoming
                                        )
                                      : e.preventDefault()
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <a className="nav-link">Incoming</a>
                                </li>
                                <li
                                  className={outgoingDocuments.active ? "nav-item active" : "nav-item"}
                                  onClick={(e) =>
                                    !outgoingDocuments.active && !loading.incoming && !loading.folder
                                      ? getOutgoingDocuments(
                                          1,
                                          searchText,
                                          rowsPerPage,
                                          selectedSortColumnOutgoing,
                                          orderOutgoing,
                                          showDeletedFiles,
                                          showExpiredFiles
                                        )
                                      : e.preventDefault()
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <a className="nav-link">Outgoing</a>
                                </li>
                                <li
                                  className={folders.active ? "nav-item active" : "nav-item"}
                                  onClick={(e) =>
                                    !folders.active && !loading.incoming && !loading.outgoing
                                      ? getFolders(1, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders)
                                      : e.preventDefault()
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <a className="nav-link">Folders</a>
                                </li>
                              </ul>
                              {/* .nk-nav */}
                              {incomingDocuments.active ? (
                                !loading.incoming ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        padding: "1rem",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div class="custom-control custom-checkbox" style={{ marginRight: "1rem" }}>
                                        <input
                                          checked={showDeletedFilesIncoming}
                                          onClick={() => {
                                            getIncomingDocuments(
                                              1,
                                              searchText,
                                              rowsPerPage,
                                              selectedSortColumnIncoming,
                                              orderIncoming,
                                              !showDeletedFilesIncoming,
                                              showExpiredFilesIncoming
                                            );
                                            setShowDeletedFilesIncoming(!showDeletedFilesIncoming);
                                          }}
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="customCheck1"
                                        />
                                        <label class="custom-control-label" for="customCheck1">
                                          Show Deleted Files
                                        </label>
                                      </div>

                                      <div class="custom-control custom-checkbox" style={{ marginRight: "1rem" }}>
                                        <input
                                          checked={showExpiredFilesIncoming}
                                          onClick={() => {
                                            getIncomingDocuments(
                                              1,
                                              searchText,
                                              rowsPerPage,
                                              selectedSortColumnIncoming,
                                              orderIncoming,
                                              showDeletedFilesIncoming,
                                              !showExpiredFilesIncoming
                                            );
                                            setShowExpiredFilesIncoming(!showExpiredFilesIncoming);
                                          }}
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="customCheck2"
                                        />
                                        <label class="custom-control-label" for="customCheck2">
                                          Show Expired Files
                                        </label>
                                      </div>
                                    </div>
                                    {incomingDocuments.data && incomingDocuments.data.length > 0 ? (
                                      <div className="nk-block nk-block-xs">
                                        <div className="nk-files nk-files-view-list">
                                          <div className="nk-files-head">
                                            <div className="nk-file-item">
                                              <div
                                                className="nk-file-info"
                                                style={{ cursor: "pointer", paddingLeft: "15px" }}
                                                onClick={(e) => {
                                                  setSelectedSortColumnIncoming("file_name");
                                                  setOrderIncoming(orderIncoming == "DESC" ? "ASC" : "DESC");
                                                  getIncomingDocuments(
                                                    currentPageIncoming,
                                                    searchText,
                                                    rowsPerPage,
                                                    "file_name",
                                                    orderIncoming == "DESC" ? "ASC" : "DESC",
                                                    showDeletedFilesIncoming,
                                                    showExpiredFilesIncoming
                                                  );
                                                }}
                                              >
                                                <div className="tb-head">
                                                  Name
                                                  {selectedSortColumnIncoming === "file_name" ? (
                                                    orderIncoming === "ASC" ? (
                                                      <ArrowUpward />
                                                    ) : (
                                                      <ArrowDownward />
                                                    )
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div
                                                className="nk-file-shared-on"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  setSelectedSortColumnIncoming("created_at");
                                                  setOrderIncoming(orderIncoming == "DESC" ? "ASC" : "DESC");
                                                  getIncomingDocuments(
                                                    currentPageIncoming,
                                                    searchText,
                                                    rowsPerPage,
                                                    "created_at",
                                                    orderIncoming == "DESC" ? "ASC" : "DESC",
                                                    showDeletedFilesIncoming,
                                                    showExpiredFilesIncoming
                                                  );
                                                }}
                                              >
                                                <div className="tb-head">
                                                  Shared On
                                                  {selectedSortColumnIncoming === "created_at" ? (
                                                    orderIncoming === "ASC" ? (
                                                      <ArrowUpward />
                                                    ) : (
                                                      <ArrowDownward />
                                                    )
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div
                                                className="nk-file-shared-by"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  setSelectedSortColumnIncoming("shared_by");
                                                  setOrderIncoming(orderIncoming == "DESC" ? "ASC" : "DESC");
                                                  getIncomingDocuments(
                                                    currentPageIncoming,
                                                    searchText,
                                                    rowsPerPage,
                                                    "shared_by",
                                                    orderIncoming == "DESC" ? "ASC" : "DESC",
                                                    showDeletedFilesIncoming,
                                                    showExpiredFilesIncoming
                                                  );
                                                }}
                                              >
                                                <div className="tb-head">
                                                  Shared By
                                                  {selectedSortColumnIncoming === "shared_by" ? (
                                                    orderIncoming === "ASC" ? (
                                                      <ArrowUpward />
                                                    ) : (
                                                      <ArrowDownward />
                                                    )
                                                  ) : null}
                                                </div>
                                              </div>
                                              <div className="nk-file-actions">&nbsp;</div>
                                            </div>
                                          </div>
                                          <div className="nk-files-list">
                                            {incomingDocuments.data
                                              ? incomingDocuments.data.map((doc, index) => (
                                                  <div className="nk-file-item nk-file">
                                                    <div className="nk-file-info">
                                                      <a href="#" className="nk-file-link">
                                                        <div className="nk-file-title">
                                                          <div className="nk-file-icon">
                                                            <span className="nk-file-icon-type">
                                                              {doc.file_extension.toLowerCase() == "pdf"
                                                                ? getPDFSVG()
                                                                : doc.file_extension.toLowerCase() == "csv"
                                                                ? getCSVSVG()
                                                                : doc.file_extension.toLowerCase() == "doc" ||
                                                                  doc.file_extension.toLowerCase() == "docx"
                                                                ? getDocSVG()
                                                                : doc.file_extension.toLowerCase() == "png" ||
                                                                  doc.file_extension.toLowerCase() == "jpg" ||
                                                                  doc.file_extension.toLowerCase() == "jpeg" ||
                                                                  doc.file_extension.toLowerCase() == "gif"
                                                                ? getMediaSVG()
                                                                : doc.file_extension.toLowerCase() == "zip"
                                                                ? getZipSVG()
                                                                : getFileSVG()}
                                                            </span>
                                                          </div>
                                                          <div className="nk-file-name">
                                                            <div className="nk-file-name-text">
                                                              <span style={{ fontWeight: "bold", color: "#364a63" }}>
                                                                {doc.original_file_name}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </a>
                                                    </div>
                                                    <div className="nk-file-meta">
                                                      <div className="tb-lead">
                                                        {Moment(doc.created_at).format("DD/MM/YYYY HH:mm A")}
                                                      </div>
                                                    </div>
                                                    <div className="nk-file-size">
                                                      <div className="tb-lead">{doc.shared_by}</div>
                                                    </div>
                                                    <div className="nk-file-actions">
                                                      <div className="dropdown">
                                                        <SharedOutFileMenu
                                                          hasAdminAccess={hasAdminAccess}
                                                          menuFor={"incoming"}
                                                          id={doc._id}
                                                          folder_id={doc.folder_id}
                                                          setOrgOutFolderid={setOrgOutFolderid}
                                                          setCurrentOutFolderid={setCurrentOutFolderid}
                                                          setCurrentOutfileid={setCurrentOutfileid}
                                                          setOpenOutFilename={setOpenOutFilename}
                                                          setRemoveOutFile={setRemoveOutFile}
                                                          setRenameOutFile={setRenameOutFile}
                                                          setMoveOutFile={setMoveOutFile}
                                                          setDownloadOutFile={setDownloadOutFile}
                                                          name={doc.original_file_name}
                                                          downloadDocument={downloadDocument}
                                                          fileurl={doc?.fileurl}
                                                          setSharedRemove={setSharedRemove}
                                                          storjFileurl={doc?.storjFileurl}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div style={{ padding: "10px", textAlign: "center" }}>
                                        {searchText && searchText != ""
                                          ? "No file Found"
                                          : "Incoming files will show up here"}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className={classes.flex}>
                                    <CircularProgress style={{ color: "rgb(20, 155, 227)" }} />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                              {incomingDocuments.active ? (
                                <div className="card-inner">
                                  <Paginator
                                    pageCount={incomingDocuments.pages}
                                    onChange={(_page) => handlePageChange(_page)}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {outgoingDocuments.active ? (
                                !loading.outgoing ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        padding: "1rem",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div class="custom-control custom-checkbox" style={{ marginRight: "1rem" }}>
                                        <input
                                          checked={showDeletedFiles}
                                          onClick={() => {
                                            getOutgoingDocuments(
                                              1,
                                              searchText,
                                              rowsPerPage,
                                              selectedSortColumnOutgoing,
                                              orderOutgoing,
                                              !showDeletedFiles,
                                              showExpiredFiles
                                            );
                                            setShowDeletedFiles(!showDeletedFiles);
                                          }}
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="customCheck1"
                                        />
                                        <label class="custom-control-label" for="customCheck1">
                                          Show Deleted Files
                                        </label>
                                      </div>

                                      <div class="custom-control custom-checkbox" style={{ marginRight: "1rem" }}>
                                        <input
                                          checked={showExpiredFiles}
                                          onClick={() => {
                                            getOutgoingDocuments(
                                              1,
                                              searchText,
                                              rowsPerPage,
                                              selectedSortColumnOutgoing,
                                              orderOutgoing,
                                              showDeletedFiles,
                                              !showExpiredFiles
                                            );
                                            setShowExpiredFiles(!showExpiredFiles);
                                          }}
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="customCheck2"
                                        />
                                        <label class="custom-control-label" for="customCheck2">
                                          Show Expired Files
                                        </label>
                                      </div>
                                    </div>
                                    {outgoingDocuments.data && outgoingDocuments.data.length > 0 ? (
                                      <div className="nk-block nk-block-xs">
                                        <div className="nk-files nk-files-view-list">
                                          <div className="nk-files-head">
                                            <div className="nk-file-item">
                                              <div
                                                className="nk-file-info"
                                                style={{ cursor: "pointer", paddingLeft: "15px" }}
                                                onClick={(e) => {
                                                  setSelectedSortColumnOutgoing("file_name");
                                                  setOrderOutgoing(orderOutgoing == "DESC" ? "ASC" : "DESC");
                                                  getOutgoingDocuments(
                                                    currentPageOutgoing,
                                                    searchText,
                                                    rowsPerPage,
                                                    "file_name",
                                                    orderOutgoing == "DESC" ? "ASC" : "DESC",
                                                    showDeletedFiles,
                                                    showExpiredFiles
                                                  );
                                                }}
                                              >
                                                <div className="tb-head">
                                                  Name
                                                  {selectedSortColumnOutgoing === "file_name" ? (
                                                    orderOutgoing === "ASC" ? (
                                                      <ArrowUpward />
                                                    ) : (
                                                      <ArrowDownward />
                                                    )
                                                  ) : null}
                                                </div>
                                                {/* <div className="tb-head">Name</div> */}
                                              </div>
                                              <div
                                                className="nk-file-size"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  setSelectedSortColumnOutgoing("file_size");
                                                  setOrderOutgoing(orderOutgoing == "DESC" ? "ASC" : "DESC");
                                                  getOutgoingDocuments(
                                                    currentPageOutgoing,
                                                    searchText,
                                                    rowsPerPage,
                                                    "file_size",
                                                    orderOutgoing == "DESC" ? "ASC" : "DESC",
                                                    showDeletedFiles,
                                                    showExpiredFiles
                                                  );
                                                }}
                                              >
                                                <div className="tb-head">
                                                  Size
                                                  {selectedSortColumnOutgoing === "file_size" ? (
                                                    orderOutgoing === "ASC" ? (
                                                      <ArrowUpward />
                                                    ) : (
                                                      <ArrowDownward />
                                                    )
                                                  ) : null}
                                                </div>
                                                {/* <div className="tb-head">Size</div> */}
                                              </div>
                                              <div
                                                className="nk-file-meta"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  setSelectedSortColumnOutgoing("created_at");
                                                  setOrderOutgoing(orderOutgoing == "DESC" ? "ASC" : "DESC");
                                                  getOutgoingDocuments(
                                                    currentPageOutgoing,
                                                    searchText,
                                                    rowsPerPage,
                                                    "created_at",
                                                    orderOutgoing == "DESC" ? "ASC" : "DESC",
                                                    showDeletedFiles,
                                                    showExpiredFiles
                                                  );
                                                }}
                                              >
                                                <div className="tb-head">
                                                  Shared On
                                                  {selectedSortColumnOutgoing === "created_at" ? (
                                                    orderOutgoing === "ASC" ? (
                                                      <ArrowUpward />
                                                    ) : (
                                                      <ArrowDownward />
                                                    )
                                                  ) : null}
                                                </div>
                                                {/* <div className="tb-head">Shared On</div> */}
                                              </div>
                                              <div className="nk-file-meta">
                                                <div className="tb-head">Shared With</div>
                                              </div>
                                              <div className="nk-file-actions">&nbsp;</div>
                                            </div>
                                          </div>
                                          <div className="nk-files-list">
                                            {outgoingDocuments.data
                                              ? outgoingDocuments.data.map((doc, index) => (
                                                  <div className="nk-file-item nk-file">
                                                    <div className="nk-file-info">
                                                      <a href="#" className="nk-file-link">
                                                        <div className="nk-file-title">
                                                          <div className="nk-file-icon">
                                                            <span className="nk-file-icon-type">
                                                              {doc.file_extension.toLowerCase() == "pdf"
                                                                ? getPDFSVG()
                                                                : doc.file_extension.toLowerCase() == "csv"
                                                                ? getCSVSVG()
                                                                : doc.file_extension.toLowerCase() == "doc" ||
                                                                  doc.file_extension.toLowerCase() == "docx"
                                                                ? getDocSVG()
                                                                : doc.file_extension.toLowerCase() == "png" ||
                                                                  doc.file_extension.toLowerCase() == "jpg" ||
                                                                  doc.file_extension.toLowerCase() == "jpeg" ||
                                                                  doc.file_extension.toLowerCase() == "gif"
                                                                ? getMediaSVG()
                                                                : doc.file_extension.toLowerCase() == "zip"
                                                                ? getZipSVG()
                                                                : getFileSVG()}
                                                            </span>
                                                          </div>
                                                          <div className="nk-file-name">
                                                            <div className="nk-file-name-text">
                                                              <span style={{ fontWeight: "bold", color: "#364a63" }}>
                                                                {doc.original_file_name}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </a>
                                                    </div>
                                                    <div className="nk-file-size">
                                                      <div className="tb-lead">{formatFileSize(doc.file_size, 2)}</div>
                                                    </div>
                                                    <div className="nk-file-meta">
                                                      <div className="tb-lead">
                                                        {Moment(doc.created_at).format("DD/MM/YYYY HH:mm A")}
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="nk-file-shared"
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => getSharedContactsList(doc._id)}
                                                    >
                                                      <div className="tb-lead">
                                                        <em
                                                          className="icon ni ni-eye-alt-fill"
                                                          style={{ verticalAlign: "middle" }}
                                                        ></em>
                                                        <span className="nk-fmg-menu-text"> Click to view</span>
                                                      </div>
                                                    </div>
                                                    <div className="nk-file-actions">
                                                      <div className="dropdown">
                                                        <SharedOutFileMenu
                                                          showDeletedFiles={showDeletedFiles}
                                                          showExpiredFiles={showExpiredFiles}
                                                          isNormalUser={isNormalUser}
                                                          userRole={userRole}
                                                          menuFor={"outgoing"}
                                                          id={doc._id}
                                                          folder_id={doc.folder_id}
                                                          setOrgOutFolderid={setOrgOutFolderid}
                                                          setCurrentOutFolderid={setCurrentOutFolderid}
                                                          setCurrentOutfileid={setCurrentOutfileid}
                                                          setOpenOutFilename={setOpenOutFilename}
                                                          setRemoveOutFile={setRemoveOutFile}
                                                          setRenameOutFile={setRenameOutFile}
                                                          setMoveOutFile={setMoveOutFile}
                                                          setDownloadOutFile={setDownloadOutFile}
                                                          name={doc.original_file_name}
                                                          downloadDocument={downloadDocument}
                                                          setSharedRemove={setSharedRemove}
                                                          fileurl={doc?.fileurl}
                                                          storjFileurl={doc?.storjFileurl}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div style={{ padding: "10px", textAlign: "center" }}>
                                        Outgoing files will show up here
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className={classes.flex}>
                                    <CircularProgress style={{ color: "rgb(20, 155, 227)" }} />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                              {outgoingDocuments.active ? (
                                <div className="card-inner">
                                  <Paginator
                                    pageCount={outgoingDocuments.pages}
                                    onChange={(_page) => handlePageChange(_page)}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {folders.active ? (
                                !loading.folder ? (
                                  folders.data && folders.data.length > 0 ? (
                                    <div className="nk-block nk-block-xs">
                                      <div className="nk-files nk-files-view-list">
                                        <div className="nk-files-head">
                                          <div className="nk-file-item">
                                            <div
                                              className="nk-file-info"
                                              style={{ cursor: "pointer", paddingLeft: "15px" }}
                                              onClick={(e) => {
                                                setSelectedSortColumnFolders("folder_name");
                                                setOrderFolders(orderFolders == "DESC" ? "ASC" : "DESC");
                                                getFolders(
                                                  currentPageFolders,
                                                  searchText,
                                                  rowsPerPage,
                                                  "folder_name",
                                                  orderFolders == "DESC" ? "ASC" : "DESC"
                                                );
                                              }}
                                            >
                                              <div className="tb-head">
                                                Name
                                                {selectedSortColumnFolders === "folder_name" ? (
                                                  orderFolders === "ASC" ? (
                                                    <ArrowUpward />
                                                  ) : (
                                                    <ArrowDownward />
                                                  )
                                                ) : null}
                                              </div>
                                              {/* <div className="tb-head">Name</div> */}
                                            </div>
                                            <div
                                              className="nk-file-meta"
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                setSelectedSortColumnFolders("created_at");
                                                setOrderFolders(orderFolders == "DESC" ? "ASC" : "DESC");
                                                getFolders(
                                                  currentPageFolders,
                                                  searchText,
                                                  rowsPerPage,
                                                  "created_at",
                                                  orderFolders == "DESC" ? "ASC" : "DESC"
                                                );
                                              }}
                                            >
                                              <div className="tb-head">
                                                Created On
                                                {selectedSortColumnFolders === "created_at" ? (
                                                  orderFolders === "ASC" ? (
                                                    <ArrowUpward />
                                                  ) : (
                                                    <ArrowDownward />
                                                  )
                                                ) : null}
                                              </div>
                                              {/* <div className="tb-head">Created On</div> */}
                                            </div>
                                            <div className="nk-file-actions">&nbsp;</div>
                                          </div>
                                        </div>
                                        <div className="nk-files-list">
                                          {folders.data
                                            ? folders.data.map((doc, index) => (
                                                <div className="nk-file-item nk-file">
                                                  <div
                                                    className="nk-file-info"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => showSharedFolderFiles(doc._id, doc.folder_name)}
                                                  >
                                                    <div className="nk-file-title">
                                                      <div className="nk-file-icon">
                                                        <span className="nk-file-icon-type">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            viewBox="0 0 72 72"
                                                          >
                                                            <path
                                                              fill="#6C87FE"
                                                              d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10
                                                    C60,29.9,58.9,31,57.5,31z"
                                                            />
                                                            <path
                                                              fill="rgb(20, 155, 227)"
                                                              d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8
                                                    c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"
                                                            />
                                                            <path
                                                              display="none"
                                                              fill="rgb(20, 155, 227)"
                                                              d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3
                                                    c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"
                                                            />
                                                            <path
                                                              fill="#798BFF"
                                                              d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"
                                                            />
                                                          </svg>
                                                        </span>
                                                      </div>
                                                      <div className="nk-file-name">
                                                        <div className="nk-file-name-text">
                                                          <span style={{ fontWeight: "bold", color: "#364a63" }}>
                                                            {doc.folder_name}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="nk-file-meta">
                                                    <div className="tb-lead">
                                                      {Moment(doc.created_at).format("DD/MM/YYYY HH:mm A")}
                                                    </div>
                                                  </div>
                                                  <div className="nk-file-actions">
                                                    <div className="dropdown">
                                                      <SharedFolderMenu
                                                        id={doc._id}
                                                        setCurrentfolderid={setCurrentfolderid}
                                                        setOpenfoldername={setOpenfoldername}
                                                        setRemovefolder={setRemovefolder}
                                                        setShareFolderToContacts={setShareFolderToContacts}
                                                        name={doc.folder_name}
                                                        doc={doc}
                                                      />
                                                      {currentfolderid === doc._id && shareFolderToContacts && (
                                                        <ShareFolderModal
                                                          setShareFolderToContacts={setShareFolderToContacts}
                                                          shareFolderToContacts={shareFolderToContacts}
                                                          ShowSuccessMessage={ShowSuccessMessage}
                                                          folder_data={doc}
                                                          folders={folders}
                                                          setFolders={setFolders}
                                                          currentOutFolderid={currentfolderid}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div style={{ padding: "10px", textAlign: "center" }}>
                                      Created folders will show up here
                                    </div>
                                  )
                                ) : (
                                  <div className={classes.flex}>
                                    <CircularProgress style={{ color: "rgb(20, 155, 227)" }} />
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                              {folders.active ? (
                                <div className="card-inner">
                                  <Paginator pageCount={folders.pages} onChange={(_page) => handlePageChange(_page)} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        {/* .nk-fmg-body-content */}
                      </div>
                      {/* .nk-fmg-body */}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}

          {showRenameModal && (
            <RenameFile
              existingName={originamFilename}
              id={showRenameModal}
              onClose={() => setShowRenameModal(false)}
            />
          )}
          {/* {showCreateFolderModal && (
            <CreateFolder
              onCreated={(created) =>
                getFolders(currentPageFolders, searchText, rowsPerPage, selectedSortColumnFolders, orderFolders)
              }
              onClose={() => setShowClearFolderModal(false)}
            />
          )} */}
        </div>
      </div>
      {/* <Modal open={openfoldername} onClose={handleClosed} aria-labelledby="simple-modal-title">
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Rename Folder</h5>
            <IconButton
              onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form onSubmit={renamefolderHandler}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  style={{ color: "black", width: "100%", marginTop: 9 }}
                  id="outlined-basic"
                  size="small"
                  label="Rename Folder"
                  value={renamefolder}
                  onChange={(e) => setRenamefolder(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button variant="contained" color="primary" type="submit" disabled={renamefolder === ""}>
                <div>Rename Folder</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal> */}
      {/* <Modal open={removefolder} onClose={handleClosed} aria-labelledby="simple-modal-title">
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Delete Folder</h5>
            <IconButton
              onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form>
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <h6>Are you sure want to delete?</h6>
              <span>Deleted items can not be recover</span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#dc3545", color: "#FFFFFF", fontWeight: "bold" }}
                onClick={handleClosed}
              >
                <div>No</div>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#12824C", color: "#FFFFFF", fontWeight: "bold" }}
                onClick={() => {
                  deleteSharedFolder(currentfolderid);
                }}
              >
                <div>Yes</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal> */}
      <Modal open={openOutFilename} onClose={handleClosed} aria-labelledby="simple-modal-title">
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Rename File</h5>
            <IconButton
              onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form onSubmit={renameOutFileHandler}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  style={{ color: "black", width: "100%", marginTop: 9 }}
                  id="outlined-basic"
                  size="small"
                  label="Rename File"
                  value={renameOutFile}
                  onChange={(e) => setRenameOutFile(e.target.value)}
                  variant="outlined"
                />
                <p>
                  <span style={{ color: "red" }}>*</span> Enter filename without .extension
                </p>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={renameOutFile === ""}
                style={{ backgroundColor: "rgb(20, 155, 227)", color: "white" }}
              >
                <div>Rename File</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal open={removeOutFile} onClose={handleClosed} aria-labelledby="simple-modal-title">
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Delete Folder</h5>
            <IconButton
              onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form>
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <h6>Are you sure want to delete the file?</h6>
              <span>Deleted file can not be recover</span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#dc3545", color: "#FFFFFF", fontWeight: "bold" }}
                onClick={handleClosed}
              >
                <div>No</div>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "rgb(20, 155, 227)", color: "#FFFFFF", fontWeight: "bold" }}
                onClick={() => {
                  deleteSharedFile(currentOutfileid);
                }}
              >
                <div>Yes</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal open={moveOutFile} onClose={handleClosed} aria-labelledby="simple-modal-title">
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Update Folder</h5>
            <IconButton
              onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form onSubmit={changeOutFolderHandler}>
            <Grid container spacing={1}>
              <h5 style={{ fontSize: "1rem", marginBottom: "1rem" }}>Current Folder - {getFolderName()}</h5>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} variant="outlined" size="small" id="outlined-basic">
                  <InputLabel
                    id="demo-simple-select-label1"
                    style={{
                      background: "white",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    Folder Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label1"
                    value={currentOutFolderid}
                    onChange={(e) => setCurrentOutFolderid(e.target.value)}
                  >
                    {getFoldersList && getFoldersList.length > 0
                      ? getFoldersList.map((folders) => <MenuItem value={folders.label}>{folders.value}</MenuItem>)
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button
                id={currentOutFolderid}
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  currentOutFolderid && currentOutFolderid != "" && currentOutFolderid != orgOutFolderid
                    ? ""
                    : "disabled"
                }
                style={{ backgroundColor: "rgb(20, 155, 227)", color: "white" }}
              >
                <div>Update Folder</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal open={showSharedContacts} onClose={handleClosed} aria-labelledby="simple-modal-title">
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Shared With Contacts:</h5>
            <IconButton
              onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>
          {/* <Grid container spacing={1}>
            <div className="col-md-12">Email</div>
          </Grid> */}
          {/* <hr className="col-md-12"
              style={{
                  color: "red",
                  backgroundColor: "red",
                  height: 1
              }}
          /> */}
          {getSharedContacts && getSharedContacts.length > 0
            ? getSharedContacts.map((contacts, index) => (
                <Grid container spacing={1}>
                  <div className="col-md-12" style={{ color: "black" }}>
                    {index + 1}. <b>{contacts.contact_name}</b> <i>({contacts.contact_email})</i>
                  </div>
                </Grid>
              ))
            : ""}
        </div>
      </Modal>
      <Modal open={sharedRemove} onClose={handleClosed} aria-labelledby="simple-modal-title">
        <div style={modalStyle} className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 id="simple-modal-title">Shared Remove</h5>
            <IconButton
              onClick={handleClosed}
              color="inherit"
              aria-label="open drawer"
              style={{ marginTop: -10, marginBottom: 10 }}
            >
              <ClearIcon style={{ color: "grey" }} />
            </IconButton>
          </div>

          <form>
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <h6>Are you sure you want to remove all shared users?</h6>
              <span>Once access is removed, it can not be recovered</span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 13,
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#dc3545", color: "#FFFFFF", fontWeight: "bold" }}
                onClick={handleClosed}
              >
                <div>No</div>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#12824C", color: "#FFFFFF", fontWeight: "bold" }}
                onClick={() => {
                  sharedRemoveFile(currentOutfileid);
                }}
              >
                <div>Yes</div>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default SharedDocuments;
