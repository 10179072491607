import { CircularProgress, Fab, MenuItem, Menu, Tooltip } from "@material-ui/core";
import { ArrowBack, CalendarToday, DeleteOutline, Save, TextFieldsOutlined } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "../configuration/components/Modal";
import SideBar from "./SideBar";
import { printPdf, renderPdf } from "./utils";
import SignatureImage from "../../assets/signature.png";
import textBoxImage from "../../assets/text_box.png";
import SendIcon from "@material-ui/icons/Send";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getHeaders } from "../../utils";
import axios from "axios";
import { useSnackbar } from "notistack";
import PrepareSend from "./PrepareSend";
import { v4 as uuidv4 } from "uuid";
import LeftSidebarImage from "./LeftSidebarImage";
import { BASE_URL } from "../../constants/apiconstant";
import ReactDOM from "react-dom";
import { fontsArray } from "./constant";
import useStateRef from "react-usestateref";
import AddEmailFromContacts from "./AddEmailFromContacts";
import lock_open from "../../assets/lock_image_open.png";
import lock_close from "../../assets/lock_image_close.png";
import drawerImage from "../../assets/drawer.png";
import zoomIn from "../../assets/icons/zoom-in.png";
import zoomOut from "../../assets/icons/zoom-out.png";
import Undo from "../../assets/icons/undo.png";
import Redo from "../../assets/icons/redo.png";
import { Print } from "@material-ui/icons";
import Swal from "sweetalert2";

import "./EditPdf.css";
import Tour from "../../components/Tour";

const CancelToken = axios.CancelToken;

var text1 = window.fabric.util.createClass(window.fabric.IText, {
  initialize: function (text, options) {
    this.callSuper("initialize", text, options);
  },
  render: function (ctx) {
    this.clearContextTop();
    this.callSuper("render", ctx);
    this.cursorOffsetCache = {};
    this.renderCursorOrSelection();
    /*var bound = this.getBoundingRect();
            ctx.strokeStyle = this.fill;  
            ctx.strokeRect(
          bound.left + 0.5,
          bound.top + 0.5,
          bound.width,
          bound.height
            );*/
    ctx.strokeStyle = this.fill;
    let coords = this.calcCoords();
    ctx.beginPath();
    ctx.moveTo(coords.tl.x, coords.tl.y);
    ctx.lineTo(coords.tr.x, coords.tr.y);
    ctx.lineTo(coords.br.x, coords.br.y);
    ctx.lineTo(coords.bl.x, coords.bl.y);
    ctx.closePath();
    ctx.stroke();

    /*ctx.rect(this.left , this.top , this.width*this.scaleX , this.height*this.scaleY); 
            ctx.stroke();*/
  },
  /**
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   */
  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});
function mergeData(firstArray, secondArray) {
  const secondArrayMap = secondArray.reduce((map, obj) => {
    map[obj.fieldId] = obj;
    return map;
  }, {});
  const mergedArray = firstArray.filter((obj) => secondArrayMap[obj.fieldId]);
  mergedArray.forEach((obj) => {
    if (secondArrayMap[obj.fieldId]) {
      obj.data = { ...obj.data, ...secondArrayMap[obj.fieldId].data };
    }
  });
  return mergedArray;
}
var onDeleteClick = () => {};
var onChangeReqired = () => {};
var currentState = [];
const stateHistory = [];
var forwardHistory = [];
var maxStateValue = -1;
var currentStateValue = -1;
let typedarray = null;

const steps = [
  {
    element: ".step-esign-pdf-1",
    intro: "Located at the top of the editor, this toolbar provides various tools for editing the PDF document.",
  },
  {
    element: ".step-esign-pdf-2",
    intro: "Displays the current assignee of the document and provides options to assign the document to a user.",
  },
  {
    element: ".step-esign-pdf-3",
    intro: "Opens the contacts list to assign the document to a user.",
  },
  {
    element: ".step-esign-pdf-4",
    intro:
      "Located on the right side of the editor, this toolbar provides additional options for document actions for printing, downloading, sending, and saving the document.",
  },
];

export default function EditPdf() {
  /*   const [isEditActive, setIsEditActive] = useState(false) */
  const [documentId, setDocumentId] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [renderingPdfLoading, setRenderingPdfLoading] = useState(false);
  const [showPrepareSignModal, setShowPrepareSignModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [fieldNodes, setFieldNodes] = useState([]);
  const canvasArrayRef = useRef(null);
  const pdfRef = useRef(null);
  const fieldNodesRef = useRef([]);
  const activeFieldRef = useRef(null);
  const disbaleClickRef = useRef(false);
  const showSidebarRef = useRef(false);
  const activeFormFieldRef = useRef(null);
  const [count, setCount] = useState(1);
  const [activeNodes, setActiveNodes] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [newParticipant, setNewParticipant] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [canvasArray, setCanvasArray] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [textStyle, setTextStyle, textStyleRef] = useStateRef(null);
  const [isBold, setIsBold, isBoldRef] = useStateRef(false);
  const [isUnderLine, setIsUnderLine, isUnderlineRef] = useStateRef(false);
  const [isItalic, setIsItalic, isItalicRef] = useStateRef(false);
  const [fontSize, setFontSize, fontSizeRef] = useStateRef(16);
  const [fontFamily, setFontFamily, fontFamilyRef] = useStateRef(fontsArray[0]);
  const [contacts, setContacts] = useState([]);
  const [allContacts, setAllContacts] = useState([]);

  const [zoom, setZoom] = useState(1);
  const [Margin, setMargin] = useState("0px");
  const [contactsLoading, setContactLoading] = useState(true);
  const [showFromCOntactsAddModal, setShowFromContactsAddModal] = useState(false);
  const sourceRef = useRef(null);
  const [showPages, setShowPages] = useState(true);
  const [zoomLeft, setZoomLeft] = useState("400px");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const originalFileName = useRef(null);
  const { fileName, fileUrl } = location.state;
  const [attatchmentLoading, setAttatchmentLoading] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const [shareableLink, setShareableLink] = useState(null);
  const deleteShareableLink = async () => {
    try {
      const requestData = {
        fileName: fileName,
      };
      const { data } = await axios.post(BASE_URL + "deleteShareAbleLink/", requestData, getHeaders());
      if (data.success) {
        setShareableLink(null);
        Swal.fire("Success!", "Shareable Link Removed", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (renderingPdfLoading || attatchmentLoading) {
      Swal.fire({
        title: "Getting Document",
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [renderingPdfLoading, attatchmentLoading]);
  useEffect(() => {
    showPages ? setZoomLeft("400px") : setZoomLeft("400px");
  }, [showPages]);

  useEffect(() => {
    currentState = mergeData(currentState, fieldNodesRef.current);

    if (currentState.length !== 0) {
      stateHistory.push(currentState);
      ++currentStateValue;
    }
  }, [fieldNodesRef.current]);

  useEffect(() => {
    let margin = "";
    if (zoom >= 1.5 && zoom < 2.25) {
      margin = "30% 0 0 0";
    } else if (zoom >= 2.25 && zoom < 3.375) {
      margin = "57% 0 0 24%";
    } else if (zoom >= 3.375 && zoom < 5.0625) {
      margin = "79% 0 0 57%";
    } else if (zoom >= 5.0625 && zoom < 7.59375) {
      margin = "25.5% 0 0 1723px";
    } else if (zoom >= 7.59375 && zoom < 11.3906) {
      margin = "17.5% 0 0 204rem";
    } else if (zoom >= 11.3906) {
      margin = "11.96% 0 0 349rem";
    }
    setMargin(margin);
  }, [zoom]);

  useEffect(() => {
    activeFieldRef.current = selectedBox;
  }, [selectedBox]);

  useEffect(() => {
    setRenderingPdfLoading(true);
    initializePdf(fileName);
  }, []);

  useEffect(() => {
    getContacts(null, true);
  }, []);

  const getContacts = async (searchText, shouldSetAllContacts = false) => {
    setContactLoading(true);

    if (sourceRef.current) {
      sourceRef.current.cancel("Operation canceled by the user.");
    }

    sourceRef.current = CancelToken.source();

    try {
      const { data } = await axios.get(
        searchText ? BASE_URL + `getAllContacts?name=` + searchText : BASE_URL + `getAllContacts`,
        { ...getHeaders(), cancelToken: sourceRef.current.token }
      );

      setContacts(data.data);
      if (shouldSetAllContacts) setAllContacts(data.data);
    } catch (error) {
      if (!axios.isCancel(error)) {
        enqueueSnackbar("Something went wrong getting notifications", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    }
    setContactLoading(false);
  };
  const initializePdf = async () => {
    /*   */

    try {
      const { data } = await axios.get(BASE_URL + "getEsignParticipants?filename=" + fileName, getHeaders());
      setDocumentId(data.data.documents._id);
      const result = await renderPdf(fileUrl);
      originalFileName.current = data.data.documents.originalFilename;
      canvasArrayRef.current = result.canvasArray;
      pdfRef.current = result.pdf;
      setShareableLink(data.data.documents.shareable_link);
      setCanvasArray(result.canvasArray);
      let nodes = [];
      if (data.data.documents.nodes) {
        nodes = JSON.parse(data.data.documents.nodes).map((node) => {
          if (node.fieldType === "date") {
            return {
              ...node,
              text: (node.text = "" ? "Enter Date" : node.text),
              isComplete: true,
            };
          }
          return {
            ...node,
            text: node.text === "" ? "Enter Text" : node.text,
          };
        });
        fieldNodesRef.current = nodes;
      }
      attachClickHandler();
      setParticipants([...data.data.participants]);
      await get_introjs_page_completed();
    } catch (error) {}
    setRenderingPdfLoading(false);
  };

  const get_introjs_page_completed = async () => {
    let url = BASE_URL + `get_intro_js_page?page_completed=esign_pdf`;
    const { data } = await axios.get(url, getHeaders());
    setShowTour(!data.introjs_completed);
  };
  const getCanvasObjectFromPage = (pageNumber) => {
    let canvasId = null;
    let canvasToReturn = null;
    canvasArrayRef.current.forEach(({ page, canvas, id }) => {
      if (page === pageNumber) {
        canvasToReturn = canvas;
        canvasId = id;
      }
    });

    return { canvas: canvasToReturn, canvasId };
  };

  const attachClickHandler = (nodes) => {
    canvasArrayRef.current.forEach(({ page, canvas }) => {
      canvas.on("text:changed", function (e) {
        const _modifiedFieldNodesRef = fieldNodesRef.current.map((node) => {
          if (node.fieldId === e.target.fieldId) {
            setActiveNodes((prev) => ({
              ...prev,
              ...node,
              text: e.target.text,
              width: e.target.width,
              height: e.target.height,
              minHeight: 500,
            }));

            return {
              ...node,
              text: e.target.text,
              width: e.target.width,
              height: e.target.height,
            };
          }

          return node;
        });

        fieldNodesRef.current = _modifiedFieldNodesRef;
        // currentState = fieldNodesRef.current?.map((node) => node)
        // stateHistory.push(currentState);
      });
      canvas.on("mouse:up", function (e) {
        if (e.target) {
          fieldNodesRef.current.map((node) => {
            if (node.fieldId === e.target.fieldId) {
              setActiveNodes({ ...node, minHeight: 500 });
            }
          });
          disbaleClickRef.current = true;
        } else {
          disbaleClickRef.current = false;
        }
        if (activeFieldRef.current && !disbaleClickRef.current) addRectangleNew(canvas, e.pointer.x, e.pointer.y, page);
      });

      canvas.on("object:modified", function (options) {
        const left = options.target.left;
        const top = options.target.top;
        const _modifiedFieldNodesRef = fieldNodesRef.current.map((node) => {
          if (node.fieldId === options.target.fieldId) {
            setActiveNodes((prev) => ({
              ...prev,
              ...node,
              left,
              top,
              minHeight: 500,
            }));
            return {
              ...node,
              left,
              top,
              minHeight: 500,
            };
          }
          return node;
        });

        fieldNodesRef.current = _modifiedFieldNodesRef;

        /*   modifyPdf(0, options.target.left, options.target.top,
                                              width, height) */
      });
      canvas.on("object:scaling", function (options) {
        const width = options.target.width * options.target.scaleX;
        const height = options.target.height * options.target.scaleY;
        const _modifiedFieldNodesRef = fieldNodesRef.current.map((node) => {
          if (node.fieldId === options.target.fieldId) {
            setActiveNodes((prev) => ({
              ...prev,
              ...node,
              width,
              height,
              minHeight: 500,
            }));
            return {
              ...node,
              width,
              height,
              minHeight: 500,
            };
          }
          return node;
        });
        fieldNodesRef.current = _modifiedFieldNodesRef;

        /* modifyPdf(0, x, y, width, height) */
      });
    });
    let temp = [];
    fieldNodesRef.current.forEach((node) => {
      let canvas = getCanvasObjectFromPage(node.page);

      const text = new text1(node.text, {
        ...node,
      });
      temp.push({
        ...node,

        width: text.width,
        height: text.height,
      });

      canvas.canvas.add(text);
    });
    fieldNodesRef.current = [...temp];
  };

  const addRectangle = (canvas, left, top, page) => {
    setActiveNodes(false);
    disbaleClickRef.current = false;
    // Render the Textbox on Canvas

    const fieldId = uuidv4();
    const textToShow =
      activeFieldRef.current === "sign"
        ? "Sign here"
        : activeFieldRef.current === "initials"
        ? "Initial Here"
        : activeFieldRef.current === "text"
        ? ""
        : activeFieldRef.current === "text-field"
        ? ""
        : "Date";
    let textProperties = {
      left: left,
      top: top,
      width: 200,
      maxWidth: 200,
      height: 400,
      minHeight: 400,
      fontSize: fontSizeRef.current,
      borderStyle: "solid",
      borderColor: "`black`",
      fieldId: fieldId,
      fieldAlias:
        activeFieldRef.current === "sign"
          ? "Sign Box "
          : activeFieldRef.current === "initials"
          ? "Initial Field"
          : activeFieldRef.current === "text"
          ? "Text"
          : activeFieldRef.current === "text-field"
          ? "Para"
          : "Date Box",
      fieldType: activeFieldRef.current,
      assigned: null,
      text: textToShow,
      page,
      public: false,
      required: false,
      fontFamily: fontFamilyRef.current,
      underline: isUnderlineRef.current,
    };
    if (isBoldRef.current) {
      textProperties.fontWeight = "bold";
    }
    if (isItalicRef.current) {
      textProperties.fontStyle = "italic";
    }

    const text = new text1(textToShow, textProperties);
    if (activeFieldRef.current == "text-field") {
      text.width = 200;
      text.height = 50;
    }
    fieldNodesRef.current = [
      ...fieldNodesRef.current,
      {
        ...textProperties,
        width: text.width,
        height: text.height,
      },
    ];
    const fieldAliasContent = textProperties.fieldAlias;
    const fieldAliasFontSize = 16;
    const fieldAliasFontFamily = "Arial";

    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.font = `${fieldAliasFontSize}px ${fieldAliasFontFamily}`;
    const fieldAliasWidth = tempCtx.measureText(fieldAliasContent).width;

    const minimumFieldAliasWidth = 100;

    const maxWidth = Math.max(fieldAliasWidth, minimumFieldAliasWidth);

    const updatedFieldNodes = fieldNodesRef.current.map((fieldNode) => {
      if (fieldNode.fieldId === fieldId) {
        return {
          ...fieldNode,
          width: maxWidth,
          minWidth: 200,
        };
      }
      return fieldNode;
    });
    fieldNodesRef.current = updatedFieldNodes;

    const textBoundingBox = text.getBoundingRect();

    const fieldAliasLeft = textBoundingBox.left + (textBoundingBox.width - maxWidth) / 2;

    const fieldAliasTop = textBoundingBox.top + (textBoundingBox.height - fieldAliasFontSize) / 2;

    text.set({
      left: fieldAliasLeft,
      top: fieldAliasTop,
    });

    const updatedFieldNodesWithPositions = fieldNodesRef.current.map((fieldNode) => {
      if (fieldNode.fieldId === fieldId) {
        return {
          ...fieldNode,
          minWidth: maxWidth,

          left: fieldAliasLeft,
          top: fieldAliasTop,
        };
      }
      return fieldNode;
    });
    fieldNodesRef.current = updatedFieldNodesWithPositions;

    canvas.add(text);
    currentState.push(text);
    currentStateValue++;
    // maxStateValue++;
    stateHistory.push([...currentState]);
    forwardHistory = [];
  };

  const addRectangleNew = (canvas, left, top, page) => {
    setActiveNodes(false);
    disbaleClickRef.current = false;

    const fieldId = uuidv4();

    const textToShow =
      activeFieldRef.current === "sign"
        ? "Sign here"
        : activeFieldRef.current == "text" || activeFieldRef.current == "AText"
        ? ""
        : activeFieldRef.current === "text-field"
        ? ""
        : activeFieldRef.current === "initials"
        ? "Initial"
        : "Date";

    let textProperties = {
      left: left,
      top: top,
      width: 200, // Set an initial width value
      height: 200,
      minHeight: 400,
      fontSize: fontSizeRef.current,
      borderStyle: "solid",
      borderColor: "black", // Fix the borderColor value
      fieldId: fieldId,
      fieldAlias:
        activeFieldRef.current === "sign"
          ? "Sign Box"
          : activeFieldRef.current === "initials"
          ? "Initial Field"
          : activeFieldRef.current === "text"
          ? "Text Box"
          : activeFieldRef.current === "AText"
          ? "Enter Text"
          : activeFieldRef.current === "text-field"
          ? "Enter Text"
          : "Date Box",
      fieldType: activeFieldRef.current,
      assigned: null,
      public: activeFieldRef.current === "AText" ? true : false,
      required: false,
      text: textToShow,
      page,
      fontFamily: fontFamilyRef.current,
      underline: isUnderlineRef.current,
    };

    if (isBoldRef.current) {
      textProperties.fontWeight = "bold";
    }
    if (isItalicRef.current) {
      textProperties.fontStyle = "italic";
    }

    const text = new text1(textToShow, textProperties);
    if (activeFieldRef.current === "text-field") {
      text.width = 200;
      text.height = 40;
    }

    if (activeFieldRef.current === "initials") {
      text.width = 40;
      text.height = 20;
    }
    const textBoundingBox = text.getBoundingRect();

    const fieldAliasContent = textProperties.fieldAlias;
    const fieldAliasFontSize = 16;
    const fieldAliasFontFamily = "Arial";

    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.font = `${fieldAliasFontSize}px ${fieldAliasFontFamily}`;
    const fieldAliasWidth = tempCtx.measureText(fieldAliasContent).width;

    // Calculate the width as the maximum between text content width, fieldAlias width, and minimum width
    const minWidth = 100;
    const maxWidth = Math.max(textBoundingBox.width, fieldAliasWidth, minWidth);

    // Adjust the width property based on the calculated maxWidth
    text.set({
      width: activeFieldRef.current === "initials" ? 40 : maxWidth,
    });

    fieldNodesRef.current = [
      ...fieldNodesRef.current,
      {
        ...textProperties,
        width: activeFieldRef.current === "initials" ? 40 : maxWidth, // Update width to the calculated maxWidth
        height: textBoundingBox.height,
      },
    ];

    canvas.add(text);
    currentState.push(text);
    currentStateValue++;
    stateHistory.push([...currentState]);
    forwardHistory = [];
  };

  const buttonStyle = {
    position: "static",
    background: "white",
    marginRight: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  };

  const activeStyle = {
    ...buttonStyle,
    border: "2px solid blue",
    color: "blue",
  };

  onDeleteClick = () => {
    const _modifiedFieldNodesRef = [];
    fieldNodesRef.current.map((node) => {
      if (node.fieldId !== activeNodes.fieldId) {
        _modifiedFieldNodesRef.push(node);
      }
      let { canvas } = getCanvasObjectFromPage(node.page);

      canvas.remove(canvas.getActiveObject());
    });
    fieldNodesRef.current = _modifiedFieldNodesRef;
    setActiveNodes(false);
    disbaleClickRef.current = false;
  };
  onChangeReqired = () => {
    const _modifiedFieldNodesRef = [];
    fieldNodesRef.current.map((node) => {
      if (node.fieldId !== activeNodes.fieldId) {
        _modifiedFieldNodesRef.push(node);
      } else if (node.fieldId === activeNodes.fieldId) {
        const data = { ...node, required: !node.required };
        setActiveNodes((prev) => ({
          ...prev,
          ...node,
          required: !node.required,
        }));
        _modifiedFieldNodesRef.push(data);
      }
    });
    fieldNodesRef.current = _modifiedFieldNodesRef;
  };
  useEffect(() => {
    updatePropertiesOnCanvas({ fontWeight: isBold ? "bold" : "normal" });
  }, [isBold]);

  useEffect(() => {
    updatePropertiesOnCanvas({ fontWeight: isItalic ? "italic" : "normal" });
  }, [isItalic]);
  useEffect(() => {
    updatePropertiesOnCanvas({ underline: isUnderLine });
  }, [isUnderLine]);
  useEffect(() => {
    updatePropertiesOnCanvas({ fontSize: fontSize });
  }, [fontSize]);
  useEffect(() => {
    updatePropertiesOnCanvas({ fontFamily: fontFamily });
  }, [fontFamily]);

  const updateActiveNode = (value) => {
    const _modifiedFieldNodesRef = fieldNodesRef.current.map((node) => {
      if (node.fieldId === activeNodes.fieldId) {
        return {
          ...node,
          ...value,
        };
      }

      return node;
    });

    fieldNodesRef.current = _modifiedFieldNodesRef;
    setActiveNodes((prev) => ({ ...prev, ...value, minHeight: 500 }));
  };

  const updatePropertiesOnCanvas = (property) => {
    if (activeNodes) {
      let { canvas } = getCanvasObjectFromPage(activeNodes.page);
      let activeObject = null;
      let objectsArray = canvas.getObjects().filter((item) => {
        return item.fieldId === activeNodes.fieldId;
      });

      if (objectsArray.length > 0) {
        activeObject = objectsArray[0];

        activeObject.set(property);

        canvas.renderAll();

        const width = activeObject.width * activeObject.scaleX;
        const height = activeObject.height * activeObject.scaleY;
        updateActiveNode({ ...property, width, height, minHeight: 1000 });
      }
    } else {
    }
  };

  const updateAssignedUser = (email) => {
    const _modifiedFieldNodesRef = fieldNodesRef.current.map((node) => {
      if (node.fieldId === activeNodes.fieldId && node.assigned === email) {
        email = null;
      }
      if (node.fieldId === activeNodes.fieldId) {
        return {
          ...node,
          assigned: email,
        };
      }
      return node;
    });

    fieldNodesRef.current = _modifiedFieldNodesRef;
    setActiveNodes((prev) => ({ ...prev, assigned: email, minHeight: 500 }));
  };
  const shareDocAsAttatchment = async (subject, message, email) => {
    setAttatchmentLoading(true);
    try {
      const { data } = await axios.post(
        BASE_URL + "shareDocumentAsEmailAttatchment/",
        {
          email: email,
          subject: subject,
          message: message,
          fileName: originalFileName.current,
          url: fileUrl,
        },
        getHeaders()
      );
      if (data.success) {
        Swal.fire("Success!", "Attatchment Sent!", "success");
        setAttatchmentLoading(false);
        setShowPrepareSignModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onSendClick = async (subject, message) => {
    setLoading(true);
    let hasUnassignedNode = 0;
    let existAssignedNode = false;
    fieldNodesRef.current.forEach((node) => {
      if (!node.assigned && !node.public) {
        hasUnassignedNode += 1;
      }
      if (!node.public) existAssignedNode = true;
    });
    if (!existAssignedNode) {
      enqueueSnackbar("No Sign node is Added!", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    } else if (hasUnassignedNode > 0) {
      enqueueSnackbar(
        `There ${hasUnassignedNode > 1 ? "are" : "is an"} unassigned node${
          hasUnassignedNode > 1 ? "s" : ""
        }, please assign
          the required Field or delete ${hasUnassignedNode > 1 ? "them" : "it"} to move forward.`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    } else {
      try {
        const participantsToSendEmail = [];
        fieldNodesRef.current.forEach((node) => {
          if (node.assigned && participantsToSendEmail.indexOf(node.assigned) < 0) {
            participantsToSendEmail.push(node.assigned);
          }
        });
        const { data } = await axios.post(
          BASE_URL + "updateEsignature/",
          {
            nodes: fieldNodesRef.current.length > 0 ? JSON.stringify(fieldNodesRef.current) : "",
            newParticipants: newParticipant,
            fileName: fileName,
            isEditing: false,
            subject,
            message,
            participantsToSendEmail,
          },
          getHeaders()
        );
        // setShowSuccessModal(true);
        setShowPrepareSignModal(false);
        Swal.fire("Email Sent!", " ", "success");
        history.goBack();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log("err", error);
      }
    }
    setLoading(false);
  };

  const getEmailsWithFieldCount = () => {
    let emailWithFieldCount = [];
    let emailFields = [];

    fieldNodesRef.current.forEach((node) => {
      if (node.assigned) {
        if (emailFields.indexOf(node.assigned) > -1) {
          emailWithFieldCount[emailFields.indexOf(node.assigned)].fieldCount =
            emailWithFieldCount[emailFields.indexOf(node.assigned)].fieldCount + 1;
        } else {
          emailWithFieldCount.push({ email: node.assigned, fieldCount: 1 });
          emailFields.push(node.assigned);
        }
      }
    });

    return emailWithFieldCount;
  };

  const onSaveClick = async () => {
    setRenderingPdfLoading(true);
    try {
      const { data } = await axios.post(
        BASE_URL + "updateEsignature/",
        {
          nodes: fieldNodesRef.current.length > 0 ? JSON.stringify(fieldNodesRef.current) : "",
          newParticipants: newParticipant,
          fileName: fileName,
          isEditing: true,
        },
        getHeaders()
      );
      Swal.fire({
        icon: "success",
        title: "Saved Successfully!",
        text: " Redirecting you back to Document Hub page.",
      });
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log("err", error);
    }
  };

  const onSendButtonClick = () => {
    let noSignerPresent = true;

    setShowPrepareSignModal(true);
  };

  const initialSvgPath = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width="2.3em"
      height="2.3em"
      color="black"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 20 20"
      className="iconify iconify--custom"
    >
      <path
        d="M20 17v1H0v-1h20zM18.12 5.94c.27-.4.48-.38.68-.04c.13.22.2.52.2.74c0 .32-.07.74-.29 1.24c.56.07.86.36.99.58c.17.3.07.5-.28.5l-.1-.02c-.32-.03-.79 0-1.19.06l-.62 1.07l-.45.8l-.46.84c-.3.56-.58 1.11-.8 1.6a4.27 4.27 0 0 0-.28.87c-.03.17-.05.34-.05.49c0 .54.18.92.49.92c.7 0 1.63-1.14 2.26-2.49c.2-.43.62-.47.74.02c.1.38.02.65-.18 1.06c-.25.54-1.46 2.09-2.97 2.09a1.7 1.7 0 0 1-1.39-.71c-.57.45-1.27.76-1.88.76c-1.21 0-1.73-.6-1.73-1.54c0-.85.63-2.23 1.26-3.3l.27-.45l.3-.47l.1-.2l.1-.16c.07-.14.1-.23.04-.23c-.04 0-.1.02-.2.07l-.15.08l-.2.13c-.38.25-.91.7-1.49 1.34c-.77.82-1.44 2.09-2.05 3.38l-.48 1.05c-.13.29-.36.4-.65.1a1.3 1.3 0 0 1-.4-.98a3.3 3.3 0 0 1 .36-1.43l.18-.4l.23-.46l.25-.48l.28-.52l.3-.53l.3-.54l.32-.54l.32-.52l.16-.25l.3-.47c.24-.34.51-.33.7 0c.15.27.23.56.23.84c0 .13 0 .26-.02.36c.77-.72 1.64-1.31 2.28-1.31c.53 0 1.01.63 1.01 1.31c0 .17-.06.38-.17.63l-.13.28l-.17.31l-.3.55l-.13.21l-.25.41c-.49.84-.94 1.8-.94 2.37c0 .37.13.6.36.6c.37 0 .85-.41 1.32-1a3.94 3.94 0 0 1 .34-1.8l.09-.21l.2-.48l.13-.26l.26-.55l.29-.59l.3-.6l.33-.6l.16-.31c-.55-.07-.88-.27-1.02-.49c-.25-.37-.07-.55.34-.54c.41.02.9 0 1.3-.03l.31-.54l.28-.47l.26-.42l.25-.37l.25-.36zM11.29 2.2c.2-.29.4-.24.53.02c.31.64.29 1.7-.66 3.36l-.99 1.72l-.68 1.2l-.72 1.3l-.5.9l-.49.93l-.48.94l-.48.94l-.46.94l-.44.92l-.2.45c-.1.22-.18.24-.36.05c-.31-.33-.58-1.1-.1-2.44A47.7 47.7 0 0 1 7.9 7.87L8.37 7l.24-.43l.48-.84l.48-.82l.46-.77l.45-.72l.8-1.22zM3.5 11.4l.7.7l-1.4 1.4l1.4 1.4l-.7.7l-1.4-1.4l-1.4 1.4l-.7-.7l1.4-1.4L0 12.1l.7-.7l1.4 1.4l1.4-1.4z"
        fill="currentColor"
      />
    </svg>
  );

  const undo = () => {
    setActiveNodes(false);
    if (currentStateValue >= 0) {
      currentState.map((node) => {
        let { canvas } = getCanvasObjectFromPage(node.page);
        canvas.remove(node);
      });
      if (stateHistory.length > 0) {
        forwardHistory.push(stateHistory.pop());
      }

      // if (currentStateValue > 0) {
      const desiredKeys = [
        "assigned",
        "borderColor",
        "borderStyle",
        "fieldAlias",
        "fieldId",
        "fieldType",
        "fontFamily",
        "fontSize",
        "height",
        "left",
        "maxWidth",
        "minHeight",
        "minWidth",
        "page",
        "text",
        "top",
        "underline",
        "width",
      ];
      const newArray =
        stateHistory.length > 0
          ? stateHistory[stateHistory?.length - 1].map((originalObj) => {
              const newObj = {};
              desiredKeys.forEach((key) => {
                newObj[key] = originalObj[key];
              });
              return newObj;
            })
          : [];
      fieldNodesRef.current = newArray;
      if (stateHistory.length > 0) {
        stateHistory[stateHistory?.length - 1].map((node) => {
          let { canvas } = getCanvasObjectFromPage(node.page);
          canvas.add(node);
        });
      }
      const lastElementsArray =
        stateHistory.length > 0 ? stateHistory[stateHistory?.length - 1].map((node) => node) : [];
      currentState = lastElementsArray;
      --currentStateValue;
      // }
    }
  };
  const redo = () => {
    if (forwardHistory.length > 1) {
      setActiveNodes(false);
      disbaleClickRef.current = false;
      currentState.map((node) => {
        let { canvas } = getCanvasObjectFromPage(node.page);
        canvas.remove(node);
      });
      const desiredKeys = [
        "assigned",
        "borderColor",
        "borderStyle",
        "fieldAlias",
        "fieldId",
        "fieldType",
        "fontFamily",
        "fontSize",
        "height",
        "left",
        "maxWidth",
        "minHeight",
        "minWidth",
        "page",
        "text",
        "top",
        "underline",
        "width",
      ];
      const newArray =
        forwardHistory.length > 0
          ? forwardHistory[forwardHistory?.length - 1].map((originalObj) => {
              const newObj = {};
              desiredKeys.forEach((key) => {
                newObj[key] = originalObj[key];
              });
              return newObj;
            })
          : [];
      fieldNodesRef.current = newArray;
      if (forwardHistory.length > 0) {
        forwardHistory[forwardHistory?.length - 1].map((node) => {
          let { canvas } = getCanvasObjectFromPage(node.page);
          canvas.add(node);
        });
      }
      const lastElementsArray =
        forwardHistory.length > 0 ? forwardHistory[forwardHistory?.length - 1].map((node) => node) : [];
      currentState = lastElementsArray;
      ++currentStateValue;
      stateHistory.push(forwardHistory.pop());
    }
  };

  const addBulkEsignUsers = async (param) => {
    try {
      const { data } = await axios.post(
        BASE_URL + "createBulkEsignatureParticipant/",
        {
          fileName: fileName,
          param,
        },
        getHeaders()
      );
      setParticipants(data.participants);
    } catch (error) {
      if (error.response.status == 409) {
        enqueueSnackbar("Error while Adding Users!", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    }
  };

  return (
    <>
      {!renderingPdfLoading && showTour && <Tour steps={steps} isEnabled={showTour} page_name="esign_pdf" />}
      {showFromCOntactsAddModal && (
        <AddEmailFromContacts
          getContacts={getContacts}
          participants={participants}
          newParticipant={newParticipant}
          onClose={() => {
            setContacts([...allContacts]);
            setShowFromContactsAddModal(false);
          }}
          loading={contactsLoading}
          contacts={contacts}
          onAddClick={(data) => {
            setContacts([...allContacts]);
            addBulkEsignUsers(data);
            setShowFromContactsAddModal(false);
          }}
        />
      )}

      {showPrepareSignModal && !attatchmentLoading && (
        <PrepareSend
          shareableLink={shareableLink}
          setShareableLink={setShareableLink}
          deleteShareableLink={deleteShareableLink}
          loading={loading}
          participants={participants}
          setParticipants={setParticipants}
          attatchmentLoading={attatchmentLoading}
          shareDocAsAttatchment={shareDocAsAttatchment}
          fileName={fileName}
          fieldNodesRef={fieldNodesRef}
          setShowPrepareSignModal={setShowPrepareSignModal}
          showSuccessModal={showSuccessModal}
          onConfirm={onSendClick}
          emailsWithFieldCount={getEmailsWithFieldCount()}
          onClose={() => {
            // if (showSuccessModal) {
            //   history.push("/homepage");
            // } else {
            if (showSuccessModal) {
              history.goBack();
            }
            setShowPrepareSignModal(false);
            //  }
          }}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          bottom: 20,
          right: 32,
          height: "auto",
          zIndex: 90999999,
        }}
        className="step-esign-pdf-4"
      >
        {" "}
        <Fab
          onClick={() => printPdf(canvasArrayRef.current)}
          style={{ marginTop: 10, backgroundColor: "#000" }}
          color="primary"
        >
          <Print />
        </Fab>
        <Fab onClick={onSendButtonClick} style={{ marginTop: 10, backgroundColor: "#000" }} color="primary">
          <SendIcon />
        </Fab>
        <Fab style={{ marginTop: 10, backgroundColor: "#000" }} color="primary" onClick={onSaveClick}>
          <Save />
        </Fab>
        <Fab
          onClick={() => history.push("/eSign-documents")}
          style={{ marginTop: 10, backgroundColor: "#000" }}
          color="primary"
        >
          <ArrowBack />
        </Fab>
      </div>
      <div>
        <div
          className="step-esign-pdf-1"
          style={{
            // background: "black",
            // backgroundColor: "#242424",
            display: "flex",
            // background: "linear-gradient(to bottom, #242424, rgba(36, 36, 36, 0.75))",
            border: "1px solid rgba(226, 226, 226, 0.87)",
            borderRadius: "5px",
            boxShadow: "0 -3px 5px #000",
            zIndex: 999,
          }}
        >
          <ToolbarCustomButton
            onClick={() => {
              setShowPages(!showPages);
            }}
            title={"Show Pages"}
            icon={
              <img src={drawerImage} alt="" color="white" style={{ color: "white", width: "35px", height: "30px" }} />
            }
          />
          <ToolbarCustomButton
            title={"Undo"}
            Styles={{ padding: "9px 13px" }}
            onClick={() => {
              if (stateHistory.length > 0) {
                setActiveNodes(false);
                disbaleClickRef.current = false;
                const counterX = stateHistory[stateHistory?.length - 1]?.length || 0;

                if (counterX == 1) {
                  while (stateHistory.length != 0) {
                    undo();
                  }
                } else {
                  while (stateHistory[stateHistory?.length - 1]?.length != counterX - 1) {
                    undo();
                  }
                }
              }
            }}
            icon={<img src={Undo} alt="" color="white" style={{ color: "white", width: "28px", height: "22px" }} />}
          />
          <ToolbarCustomButton
            title={"Redo"}
            Styles={{ padding: "9px 13px" }}
            onClick={() => {
              if (forwardHistory.length > 0) {
                const counterX = forwardHistory[forwardHistory?.length - 1]?.length;

                if (counterX == 0) {
                  while (forwardHistory.length != 1) {
                    redo();
                  }
                } else if (forwardHistory[forwardHistory.length - 1].length == forwardHistory[0].length) {
                  while (forwardHistory.length != 0) {
                    redo();
                    if (forwardHistory.length == 1) {
                      forwardHistory = [];
                    }
                  }
                } else {
                  while (forwardHistory[forwardHistory?.length - 1]?.length == counterX) {
                    redo();
                  }
                }
              }
            }}
            icon={<img src={Redo} alt="" color="white" style={{ color: "white", width: "28px", height: "22px" }} />}
          />
          <ToolbarCustomButton
            title={"Text"}
            buttonText="I"
            active={activeField === "AText"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="1.60em"
                height="2em"
                color="black"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M432 416h-23.41L277.88 53.69A32 32 0 0 0 247.58 32h-47.16a32 32 0 0 0-30.3 21.69L39.41 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-19.58l23.3-64h152.56l23.3 64H304a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM176.85 272L224 142.51L271.15 272z"
                />
              </svg>
            }
            onClick={() => {
              if (activeField === "AText") {
                setActiveField(null);
                activeFieldRef.current = null;
              } else {
                activeFieldRef.current = "AText";
                setActiveField("AText");
              }
            }}
          />
          <ToolbarCustomButton
            title={"Text Field"}
            onClick={() => {
              if (activeField === "text") {
                setActiveField(null);
                activeFieldRef.current = null;
              } else {
                activeFieldRef.current = "text";
                setActiveField("text");
              }
            }}
            active={activeField === "text"}
            icon={<TextFieldsOutlined color="black" style={{ color: "black" }} />}
          />
          <ToolbarCustomButton
            title={"Paragraph Field"}
            onClick={() => {
              if (activeField === "text-field") {
                setActiveField(null);
                activeFieldRef.current = null;
              } else {
                activeFieldRef.current = "text-field";
                setActiveField("text-field");
              }
            }}
            active={activeField === "text-field"}
            icon={
              <img
                src={textBoxImage}
                alt=""
                color="black"
                style={{ color: "white", width: "28px", height: "22px", filter: "invert(1)" }}
              />
            }
          />
          <ToolbarCustomButton
            title={"Date Field"}
            onClick={() => {
              if (activeField === "date") {
                activeFieldRef.current = null;
                setActiveField(null);
              } else {
                activeFieldRef.current = "date";
                setActiveField("date");
              }
            }}
            active={activeField === "date"}
            icon={<CalendarToday color="black" style={{ color: "black", width: "20px" }} />}
          />

          <ToolbarCustomButton
            title={"Signature Field"}
            onClick={() => {
              if (activeField === "sign") {
                setActiveField(null);
                activeFieldRef.current = null;
              } else {
                activeFieldRef.current = "sign";
                setActiveField("sign");
              }
            }}
            active={activeField === "sign"}
            icon={<img src={SignatureImage} alt="" style={{ width: "20px", height: "18px", filter: "invert(1)" }} />}
          />

          <ToolbarCustomButton
            title={"Initials Field"}
            onClick={() => {
              if (activeField === "initials") {
                setActiveField(null);
                activeFieldRef.current = null;
              } else {
                activeFieldRef.current = "initials";
                setActiveField("initials");
              }
            }}
            active={activeField === "initials"}
            icon={initialSvgPath}
          />
          <ToolbarCustomButton
            title={"Italic"}
            buttonText="I"
            active={isItalic}
            onClick={() => {
              setIsItalic(!isItalic);
            }}
          />
          <ToolbarCustomButton
            title={"UnderLine"}
            active={isUnderLine}
            onClick={() => {
              setIsUnderLine(!isUnderLine);
            }}
            buttonText="U"
          />
          <ToolbarCustomButton
            title={"Bold"}
            buttonText="B"
            active={isBold}
            onClick={() => {
              setIsBold(!isBold);
            }}
          />
          <ToolbarCustomButton
            title={"Font Size"}
            buttonText={fontSize}
            onClick={(value) => setFontSize(value)}
            type="select"
            selectOptions={[4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32]}
          />
          <ToolbarCustomButton
            title={"Font Family"}
            onClick={(value) => setFontFamily(value)}
            buttonText={fontFamily}
            type="select"
            selectOptions={fontsArray}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxHeight: "calc(100vh - 54px)",
            backgroundColor: "rgb(243 244 246/var(--tw-bg-opacity))",
          }}
        >
          {showPages && <LeftSidebarImage canvasArray={canvasArray} />}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "33px",
              height: "max-content",
              alignItems: "center",
              position: "fixed",
              top: "85px",
              right: zoomLeft,
              zIndex: 999,
              border: "1px solid black",
              borderRadius: "5px",
            }}
          >
            <div
              onClick={() => {
                zoom <= 11 ? setZoom(zoom + zoom / 2) : setZoom(zoom);
              }}
              className="zoom"
              style={{ cursor: "pointer", borderBottom: "1px solid black", width: "100%", textAlign: "center" }}
            >
              <img style={{ width: "75%", margin: "3px" }} className="imgZoom" src={zoomIn} />
            </div>
            <div
              onClick={() => {
                zoom >= 0.1 ? setZoom(zoom - zoom / 3) : setZoom(zoom);
              }}
              className="zoom"
              style={{ cursor: "pointer", width: "100%", textAlign: "center" }}
            >
              <img style={{ width: "75%", margin: "3px" }} className="imgZoom" src={zoomOut} />
            </div>
          </div>
          <div
            style={{
              overflowY: "auto",
              display: "flex",
              justifyContent: "center",
              width: showPages ? "72%" : "81%",
              maxWidth: showPages ? "72%" : "81%",
            }}
          >
            <div style={{ display: "flex", paddingLeft: "4rem" }}>
              <div id="pdf-viewer" style={{ transform: `scale(${zoom})`, margin: `${Margin}` }}></div>
            </div>
          </div>
          <SideBar
            isDisabled={activeNodes?.fieldId ? true : false}
            onAddFromContactClicked={() => setShowFromContactsAddModal(true)}
            activeNode={activeNodes}
            setParticipants={setParticipants}
            fileName={fileName}
            onDeleteClick={onDeleteClick}
            updateAssignedUser={updateAssignedUser}
            participants={participants}
            newParticipant={newParticipant}
            setNewParticipant={setNewParticipant}
            updateActiveNode={updateActiveNode}
          />
        </div>
        {activeNodes && (
          <ShowAbsoluteButton
            activeNode={activeNodes}
            pageWidth={canvasArrayRef.current.length > 0 ? canvasArrayRef.current[0].width : 0}
            onDeleteClick={onDeleteClick}
            onChangeReqired={onChangeReqired}
            showDeleteIcon
            showLockIcon
          ></ShowAbsoluteButton>
        )}
        {/*   </div> */}
      </div>
    </>
  );
}

export const ToolbarCustomButton = ({
  MarginRigth = "0px",
  buttonText,
  icon,
  onClick,
  active,
  text,
  type = "button",
  selectOptions,
  Styles,
  title,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = (selected) => {
    if (typeof selected === "string" || typeof selected === "number") {
      onClick(selected);
    }
    setAnchorEl(null);
  };

  const style = {
    borderBottomLeftRadius: "5px",
    borderTopLeftRadius: "5px",
    // backgroundColor: "#242424",
    // background: "linear-gradient(rgb(234, 233, 233), rgba(112, 112, 112,0.89))",
    border: "0",
    borderRight: "0px solid #000",
    borderLeft: "0px solid rgba(255, 255, 255, 0.1)",
    boxShadow: "0 2px 2px rgb(0 0 0 / 30%)",
    color: "#fff",
    height: "45px",
    minWidth: "45px",
    width: title === "Font Family" ? "auto" : "45px",
    padding: title === "Font Family" ? "0px 6px 0px 6px " : "0",
    transition: "background-color .2s ease-in",
    boxSizing: "border-box",
    cursor: "pointer",
    display: "block",
    fontSize: "10px",
    lineHeight: "1.33",
    margin: "2px",
    marginRight: MarginRigth,
    textDecoration: "none",
  };
  const content = (
    <div id="buttonContainer">
      <button
        id="buttonC"
        onClick={(event) => {
          if (type === "button") onClick();
          else {
            setAnchorEl(event.currentTarget);
          }
        }}
        style={
          active
            ? {
                ...style,
                backgroundColor: "#000",
                background: "linear-gradient(rgb(234, 233, 233), rgba(112, 112, 112,0.89))",
              }
            : style
        }
      >
        {icon ? (
          icon
        ) : (
          <p
            style={{
              color: "black",
              height: "100%",
              fontWeight: "500",
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {buttonText}
          </p>
        )}
      </button>
      {type === "select" && (
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {selectOptions.map((option) => (
            <MenuItem onClick={() => handleClose(option)}>{option}</MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
  return (
    <Tooltip title={<div style={{ fontSize: "14px", fontWeight: "bold" }}>{title}</div>} arrow placement="top">
      {content}
    </Tooltip>
  );
};

export const ShowAbsoluteButton = ({
  activeNode,
  pageWidth,
  onDeleteClick,
  showDeleteIcon,
  showLockIcon,
  onChangeReqired,
}) => {
  const [leftValue, setLeftValue] = useState(5);
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const divWidth = divRef.current.clientWidth;
      const halfWidth = divWidth / 2;
      setLeftValue(halfWidth);
    }
  }, [activeNode.fieldAlias, activeNode.width]);
  return ReactDOM.createPortal(
    <div
      ref={divRef}
      className="AbsoluteButton"
      style={{
        fontFamily: "Nunito",
        width: "auto",
        fontSize: "1rem",
        height: "40px",
        minWidth: activeNode.width > 100 ? activeNode.width + "px" : "100px",
        maxWidth: "608px",
        position: "absolute",
        top: activeNode.top - 45 + "px",
        left:
          activeNode.left - leftValue + activeNode.width / 2 < 0
            ? "1px"
            : activeNode.left - leftValue + activeNode.width / 2 + "px",
        display: "flex",
        justifyContent: "center",
        overflow: "visible",
      }}
    >
      <h6
        style={{
          padding: "3px 7px",
          fontFamily: "Nunito",
          color: "white",
          fontSize: "0.9rem",
          width: "max-content",
          borderRadius: "5px",
          height: "40px",
          background: "rgb(20 155 227)",
          minWidth: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "no-break",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
            width: "100%",
            minHeight: "50%",
            textAlign: "center",
            marginTop: "5px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {activeNode.fieldAlias}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "auto" }}>
            <Tooltip title="Make Field Required" arrow placement="top">
              <img
                src={activeNode.required ? lock_close : lock_open}
                onClick={onChangeReqired}
                width={"14px"}
                style={{ filter: "invert(1)", display: showLockIcon ? "block" : "none", cursor: "pointer" }}
              />
            </Tooltip>
          </p>
          <p
            onClick={onDeleteClick}
            style={{
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Delete Field" arrow placement="top">
              <DeleteOutline style={{ color: "white", width: "18px", display: showDeleteIcon ? "block" : "none" }} />
            </Tooltip>
          </p>{" "}
        </div>
      </h6>
    </div>,
    document.getElementById("pagec" + activeNode.page)
  );
};
