import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {CardActionArea} from '@mui/material';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {useHistory} from "react-router";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stack from '@mui/material/Stack';
import {green, blue} from '@mui/material/colors';
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Modal, TextField, useTheme} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

import OcrUpload from "./OcrUpload";
import "../assets/css/dashlite.css";
import "../assets/css/theme.css";
import {FolderAdd, OcrDocumentfetch, OcrUpdate,} from "../actions/assetsAction";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import OcrButton from "../components/OcrButton";
import {getApiKey, getDbCred} from "./configuration/api";
import {useSnackBar} from "./configuration/utils";
import {Modal as CustomModal} from "./configuration/components/Modal";
import {BASE_URL} from "../constants/apiconstant";
import {getHeaders} from "../utils";
import {FolderList} from "./Shared/FolderList";
import OcrTemplateDialog from "./OcrTemplateDialog";
import SmartOcrDialog from "./SmartOcrDialog";
import folderCreateModalSwal from "./Shared/createFolderSwal";


const OcrDocument = () => {
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const [showSendMultipleCheckbox, setShowSendMultipleCheckbox] = useState(false);
  const {innerWidth, innerHeight} = window;
  const history = useHistory();
  const [exportTaskId, setExportTaskId] = useState([]);

  function getModalStyle() {
    const {innerWidth, innerHeight} = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      borderRadius: "11px",
    };
  }

  const useStyles = makeStyles((theme) => ({
    // formControl: {
    //   margin: theme.spacing(1),
    //   minWidth: 120,
    // },
    // selectEmpty: {
    //   marginTop: theme.spacing(2),
    // },
    paper: {
      position: "absolute",
      width: innerWidth > 600 ? 500 : "100%",

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto !important",
      top: "50%",
    },
    multilineColor: {
      color: "white",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    rooot: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fieldroot: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const classes = useStyles();

  const getAssets = useSelector((state) => state.getAssets);
  const {loading, error, file, folder, ocr} = getAssets;
  const userLogin = useSelector((state) => state.userLogin);
  const {userInfo} = userLogin;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [fileName, setFileName] = useState("");
  const [foldername, setFoldername] = useState("");
  const [renameocrfile, setRenameocrfile] = useState("");
  const [openfolderid, setOpenfolderid] = useState(false);

  const [openocrfile, setOpenocrfile] = useState(false);
  const [currentOcrFile, setCurrentOcrFile] = useState("");
  const [updatedfolderid, setUpdatedfolderid] = useState("");
  const [apikey, setApikey] = useState(null);
  const [apikeyLoading, setApiKeyLoading] = useState(true);
  const [folderid, setFolderid] = useState("");
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openfolder, setOpenfolder] = useState(false);
  const [bodyWidth, setBodyWidth] = React.useState(false);

  const [showClearFolderModal, setShowClearFolderModal] = useState(false);
  const [showMoveInFolderModal, setShowMoveInFolderModal] = useState(false);
  const [fileToMoveId, setFileToMoveId] = useState([]);
  const [showMoveButton, setShowMoveButton] = useState(false);
  const [folders, setFolders] = useState({data: []});
  const [folderLoadLoading, setFolderLoadLoading] = useState(false);
  const {errorSnackBar, enqueueSnackbar} = useSnackBar();
  const [deleteFolderLoading, setDeleteFolderLoading] = useState(false);

  const [ocrTemplates, setOcrTemplates] = useState([]);
  const [ocrTemplateDialogOpen, setOcrTemplateDialogOpen] = useState(false);
  const [smartOcrDialogOpen, setSmartOcrDialogOpen] = useState(false);
  const [currentTemplateId, setCurrentTemplateId] = useState(null);

  const handleClickOpenSOD = () => {
    setSmartOcrDialogOpen(true);
  };

  const handleClickOpenOcrTemplate = () => {
    setCurrentTemplateId(null);
    setOcrTemplateDialogOpen(true);
  };

  const handleClickOpenSODValue = (value) => () => {
    setCurrentTemplateId(value);
    setOcrTemplateDialogOpen(true);
  };


  const callGetApiKey = async () => {
    await getApiKey(userInfo?.result?._id, setApikey, errorSnackBar);
    setApiKeyLoading(false);
  };

  useEffect(() => {
    if (userInfo) {
      console.log("userInfo uuu = ", userInfo);
      callGetApiKey();
      dispatch(OcrDocumentfetch(userInfo?.result?._id));
      getFolders();
      getOcrTemplates();
    }
  }, []);

  const getOcrTemplates = async () => {
    axios.get(BASE_URL + "api/ocr-templates", getHeaders())
    .then(function (response) {
      console.log('ocr-templates', response.data);
      setOcrTemplates(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const getFolders = async () => {
    try {
      const {data} = await axios.get(
        BASE_URL + "get-ocr-folders",
        getHeaders()
      );
      console.log('folders:', data);

      setFolders({
        ...folders,
        pages: data.pages,
        data: [...data.data],
        loading: false,
      });
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        {variant: "error"}
      );
    }
    // setFolderLoadLoading(false);
  };

  const handleClosed = () => {
    setOpen(false);
    setOpenfolder(false);
    setOpenocrfile(false);
    setOpenfolderid(false);
  };

  const addOrRemoveTaskIdFromExport = (taskId) => {
    console.log("in add or removbe");
    const index = exportTaskId.indexOf(taskId);
    if (index >= 0) {
      exportTaskId.splice(index, 1);
    } else {
      exportTaskId.push(taskId);
    }
    setExportTaskId([...exportTaskId]);
  };

  const submitHandlers = (e) => {
    e.preventDefault();
    console.log("submits", foldername, userInfo?.result?._id);
    dispatch(FolderAdd(foldername, userInfo?.result?._id));
    handleClosed();
    setFoldername("");
  };

  const updatefolderidHandler = (e) => {
    e.preventDefault();
    // console.log('submittsfolderr', updatedfolderid, currentfileid)
    // dispatch(Updatefilefolderid(currentfileid, updatedfolderid))
    // handleClosed()
    // setUpdatedfolderid('')
  };

  useEffect(() => {
    console.log("user", userInfo);
  }, []);
  useEffect(() => {
    if (file) console.log("file", file);
  }, [file]);
  useEffect(() => {
    if (folder) console.log("folder", folder);
  }, [folder]);
  useEffect(() => {
    console.log("folderid", folderid);
  }, [folderid]);

  const saveOcrDocumentData = (data, id) => {
    console.log("dagergergta = ", data);
    localStorage.setItem("ocrData", JSON.stringify(data));
    console.log("bas");

    history.push(`/editocrdocument/${id}`, {
      canExport: data.task_id,
      apikey,
      task_id: data.task_id,
    });

    // setIsLoading(false)
  };

  const renameocrfileHandler = (e) => {
    e.preventDefault();
    console.log("submittsfile", currentOcrFile);
    // dispatch(UpdatefileName(currentfileid, renamefile))
    let ocrData = currentOcrFile;
    ocrData.name = renameocrfile;
    ocrData.data = JSON.parse(currentOcrFile.data);
    // try{
    //   ocrData.data = JSON.parse(currentOcrFile.data)
    // }catch(e){
    //   ocrData.data = currentOcrFile.data
    // }

    console.log("ocrData = ", ocrData);
    dispatch(OcrUpdate(ocrData._id, ocrData));
    handleClosed();
    setRenameocrfile({});
    ocrData.data = JSON.stringify(currentOcrFile.data);
  };

  console.log("file to move id", fileToMoveId);
  const createocrfilebody = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <h5 id="simple-modal-title">Rename File</h5>
        <IconButton
          onClick={handleClosed}
          color="inherit"
          aria-label="open drawer"
          style={{marginTop: -10, marginBottom: 10}}
        >
          <ClearIcon style={{color: "grey"}}/>
        </IconButton>
      </div>

      <form onSubmit={renameocrfileHandler}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              style={{color: "black", width: "100%", marginTop: 9}}
              id="outlined-basic"
              size="small"
              label="Rename File"
              value={renameocrfile}
              onChange={(e) => setRenameocrfile(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: 13,
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={renameocrfile === ""}
            style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
          >
            <div>Rename File</div>
          </Button>
        </div>
      </form>
    </div>
  );

  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };

  const handleFolderDelete = async (_id) => {
    setFolderLoadLoading(true);
    try {
      const {data} = await axios.delete(
        BASE_URL + "delete_ocr_folder?folderId=" + _id,
        getHeaders()
      );
      console.log("folder files", data);
      getFolders(1, false);
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        {variant: "error"}
      );
    }
    setFolderLoadLoading(false);
  };

  const onMoved = () => {
    getFolders(1);
  };
  const handleOcrDelete = (id, documentDeleted) => {
    dispatch(OcrDocumentfetch(userInfo?.result?._id));
  };
  // console.log("ocrrrrrrrrrrrrrrrrrrr", ocr);

  const handleClickDeleteTemplate = async (fieldId) => {
    axios.delete(BASE_URL + "api/ocr-templates/" + fieldId + '/', getHeaders())
    .then(function (response) {
      console.log('ocr-template deleted', response.data);
      getOcrTemplates();
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const handleCloseOcrTemplateDialog = () => {
    setOcrTemplateDialogOpen(false);
    getOcrTemplates();
  }

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: "rgb(20, 155, 227)",
    borderColor: "rgb(20, 155, 227)",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#3f54ff",
      borderColor: "#3249ff"
    },
  }));
  const createFolderAPIRequest = async (foldername) => {
    const { data }= await axios.post(BASE_URL + "createEsignFolders/", { foldername }, getHeaders())
    return data;
  }
  useEffect(() => {
    if(showClearFolderModal){
      folderCreateModalSwal(createFolderAPIRequest, setShowClearFolderModal, setFolders, folders)
    }
  }, [showClearFolderModal])
  return (
    <>
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar/>

              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div
                      className="nk-fmg"
                      style={bodyWidth ? {paddingLeft: 80} : null}
                    >
                      <Sidebar page="ocr" getState={sidebarState}/>

                      {/* .nk-fmg-aside */}
                      {loading || apikeyLoading ? (
                        <div className={classes.rooot}>
                          <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                        </div>
                      ) : (
                        <div className="nk-fmg-body">
                          <div className="nk-fmg-body-content">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between position-relative">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                    Document OCR
                                  </h3>
                                </div>
                                <div className="nk-block-head-content">
                                  <div className="nk-fmg-actions">
                                    <ul className="nk-block-tools g-3">
                                      <li>
                                        <ColorButton onClick={handleClickOpenSOD} variant="contained"style={{ backgroundColor: "rgb(20, 155, 227)" }}
                                                startIcon={<ArtTrackIcon/>}>
                                          Smart OCR
                                        </ColorButton>
                                      </li>
                                      <li>
                                        <ColorButton onClick={handleClickOpenOcrTemplate} variant="contained" color="primary"
                                                style={{ backgroundColor: "rgb(20, 155, 227)" }}
                                                startIcon={<AddCircleRoundedIcon/>}>
                                          OCR Template
                                        </ColorButton>
                                      </li>
                                      <li>
                                          <button
                                            onClick={() => {
                                              if (showSendMultipleCheckbox) {
                                                if (exportTaskId.length > 0)
                                                  setExportModalOpen(true);
                                                else
                                                  errorSnackBar(
                                                    "Please select at least one document for export"
                                                  );
                                              } else {
                                                setShowSendMultipleCheckbox(
                                                  true
                                                );
                                              }
                                            }}
                                            className="btn btn-primary"
                                            data-toggle="dropdown"
                                            style={{ backgroundColor: "rgb(20, 155, 227)" }}
                                          >
                                            <em className="icon ni ni-upload"/>{" "}
                                            <span>
                                              {showSendMultipleCheckbox
                                                ? "Click to Export"
                                                : "Export Multiple"}{" "}
                                            </span>
                                          </button>
                                      </li>
                                      <li>
                                        <OcrUpload apikey={apikey}/>
                                      </li>
                                      <li>
                                        <button
                                          onClick={() =>
                                            setShowClearFolderModal(true)
                                          }
                                          className="btn btn-primary"
                                          data-toggle="dropdown"
                                          style={{ backgroundColor: "rgb(20, 155, 227)" }}
                                        >
                                          <em className="icon ni ni-upload"/>{" "}
                                          <span>Create Folder</span>
                                        </button>
                                        {fileToMoveId.length > 0 &&
                                        folders.data.length > 0 && (
                                          <button
                                            onClick={() =>
                                              setShowMoveInFolderModal(true)
                                            }
                                            className="btn btn-primary"
                                            style={{backgroundColor: "rgb(20, 155, 227)"}}
                                            data-toggle="dropdown"
                                          >
                                            <em className="icon ni ni-upload"/>{" "}
                                            <span>Move</span>
                                          </button>
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className="search-wrap px-2 d-lg-none"
                                  data-search="search"
                                >
                                  <div className="search-content">
                                    <a
                                      href="#"
                                      className="search-back btn btn-icon toggle-search"
                                      data-target="search"
                                    >
                                      <em className="icon ni ni-arrow-left"/>
                                    </a>
                                    <input
                                      type="text"
                                      className="form-control border-transparent form-focus-none"
                                      placeholder="Search by user or message"
                                    />
                                    <button className="search-submit btn btn-icon">
                                      <em className="icon ni ni-search"/>
                                    </button>
                                  </div>
                                </div>
                                {/* .search-wrap */}
                              </div>
                            </div>

                            <div className="nk-fmg-listing nk-block">
                              <div
                                className="toggle-expand-content expanded"
                                data-content="recent-files"
                              >
                                <div className="nk-files nk-files-view-group">
                                  {/* .nk-files-head */}
                                  <div className="nk-files-group">
                                    <h6 className="title">OCR Folders</h6>
                                    <div className="nk-files-list">
                                      {folderLoadLoading ? (
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                                        </div>
                                      ) : folders.data.length > 0 ? (
                                        folders.data.map((document, index) => (
                                          <FolderList
                                            key={index}
                                            onDeleteClick={() => {
                                              handleFolderDelete(
                                                document._id,
                                                document
                                              );
                                            }}
                                            onClick={() => {
                                              localStorage.setItem(
                                                "id",
                                                document._id
                                              );
                                              localStorage.setItem(
                                                "foldername",
                                                document.foldername
                                              );

                                              history.push("/ocr-folder-files");
                                            }}
                                            foldername={document.foldername}
                                          />
                                        ))
                                      ) : (
                                        <p
                                          style={{
                                            marginLeft: "9px",
                                            textAlign: "center",
                                            width: "100%",
                                          }}
                                        >
                                          Folders you create will show up here
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="nk-files-group">
                                    <h6 className="title">OCR Templates</h6>
                                    <Stack direction="row" spacing={2}>
                                      {ocrTemplates.map((ocrTemplate, idx) => (
                                        <Card sx={{width: 350}} key={idx}>
                                          <CardHeader
                                            avatar={
                                              <Avatar sx={{bgcolor: "rgb(20, 155, 227)", width: 32, height: 32}} aria-label="template">
                                                T
                                              </Avatar>
                                            }
                                            action={
                                              <IconButton aria-label="Delete"
                                                          onClick={() => handleClickDeleteTemplate(ocrTemplate.id)}>
                                                <DeleteIcon/>
                                              </IconButton>
                                            }
                                            title={ocrTemplate.name}
                                            subheader={ocrTemplate.data.length + ' fields found'}
                                          />
                                          <CardActionArea onClick={handleClickOpenSODValue(ocrTemplate.id)}>
                                            <CardMedia
                                              component="img"
                                              height="140"
                                              image={ocrTemplate.image}
                                              alt="image"
                                            />
                                          </CardActionArea>
                                        </Card>
                                      ))}
                                    </Stack>
                                  </div>

                                  <div className="nk-files-group">
                                    <h6 className="title">OCR </h6>

                                    <div className="nk-files-list">
                                      {ocr?.length > 0 ? (
                                        ocr?.map((files, index) => (
                                          <OcrButton
                                            key={index}
                                            moveFolder={() =>
                                              setShowMoveButton(true)
                                            }
                                            ocrDeleted={() =>
                                              handleOcrDelete(files._id, files)
                                            }
                                            files={files}
                                            index={index}
                                            saveocrdata={saveOcrDocumentData}
                                            setRenameocrfile={setRenameocrfile}
                                            setCurrentOcrFile={
                                              setCurrentOcrFile
                                            }
                                            setOpenocrfile={setOpenocrfile}
                                            setOpenfolderid={setOpenfolderid}
                                            checkBoxClicked={(taskId, _id) => {
                                              if (showMoveButton) {
                                                setFileToMoveId((prev) => [
                                                  ...prev,
                                                  _id,
                                                ]);
                                              } else {
                                                addOrRemoveTaskIdFromExport(
                                                  taskId
                                                );
                                              }
                                            }}
                                            showCheckBox={
                                              showSendMultipleCheckbox ||
                                              showMoveButton
                                            }
                                          />
                                        ))
                                      ) : (
                                        <p
                                          style={{
                                            marginLeft: "9px",
                                            textAlign: "center",
                                            width: "100%",
                                          }}
                                        >
                                          Document that are uploaded to the OCR
                                          show up here
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {/* .nk-files */}
                              </div>
                            </div>
                            {/* .nk-block */}
                          </div>
                          {/* .nk-fmg-body-content */}
                        </div>
                      )}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}
        </div>
      </div>
      {/* {showClearFolderModal && (
        <CreateFolder
          onClose={() => setShowClearFolderModal(false)}
          onCreated={(created) =>
            setFolders((prev) => ({
              ...prev,
              data: [...prev.data, created],
            }))
          }
        />
      )} */}
      {showMoveInFolderModal && (
        <MoveInFolder
          onMoved={onMoved}
          onClose={() => setShowMoveInFolderModal(false)}
          folders={folders.data}
          fileToMoveId={fileToMoveId}
        />
      )}
      {exportModalOpen && (
        <ExportModal
          userInfo={userInfo}
          apikey={apikey}
          taskIds={exportTaskId}
          onClose={() => setExportModalOpen(false)}
        />
      )}
      <Modal
        open={openocrfile}
        onClose={handleClosed}
        aria-labelledby="simple-modal-title"
      >
        {createocrfilebody}
      </Modal>
      {smartOcrDialogOpen && (
        <SmartOcrDialog open={smartOcrDialogOpen} onClose={() => setSmartOcrDialogOpen(false)}/>
      )}
      {ocrTemplateDialogOpen && (
        <OcrTemplateDialog templateId={currentTemplateId} open={ocrTemplateDialogOpen}
                        onClose={handleCloseOcrTemplateDialog}/>
      )}
    </>
  );
};

export default OcrDocument;

export const ExportModal = ({userInfo, apikey, taskIds, onClose}) => {
  const [dbCreds, setDbCreds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const ref = useRef();
  const classes = makeStyles();

  useEffect(() => {
    callGetDbCredApi();
  }, []);
  const history = useHistory();

  const {errorSnackBar, successSnackBar} = useSnackBar();
  useEffect(() => {
    if (dbCreds.length > 0) {
      setSelectedConfig(dbCreds[0]._id);
    }
  }, [dbCreds]);
  const callGetDbCredApi = async () => {
    await getDbCred(userInfo?.result?._id, setDbCreds, errorSnackBar);

    setLoading(false);
  };
  const exportData = async () => {
    setSaveLoading(true);
    try {
      const {data} = await axios.get(
        BASE_URL +
        `get-data-by-task-id/?company_id=${userInfo?.result?._id}&task_id=${taskIds}&export_type=database&config_id=${selectedConfig}`,
        getHeaders()
      );
      if (data.success == "True" || data.success == true) {
        successSnackBar("Data has been exported to selected Database");
      } else {
        errorSnackBar(data.message);
      }
    } catch (error) {
      errorSnackBar();
    }
    setSaveLoading(false);
  };

  return (
    <CustomModal
      onClose={onClose}
      showConfirmButton={!loading && dbCreds.length > 0}
      showCancelButton={!loading && dbCreds.length > 0}
      loading={saveLoading}
      onConfirm={exportData}
      modalContent={
        loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
          </div>
        ) : !apikey ? (
          <p>
            You have not generated an API key. Please&nbsp;
            <span
              style={{color: "blue", cursor: "pointer"}}
              onClick={() => history.push("/configuration")}
            >
              click here&nbsp;
            </span>
            to generate one now.
          </p>
        ) : dbCreds.length === 0 ? (
          <p>
            You have not added any database configurations. Please&nbsp;
            <span
              onClick={() => history.push("/configuration")}
              style={{color: "blue", cursor: "pointer"}}
            >
              click here&nbsp;
            </span>{" "}
            to add Mongo DB or MySQL credentials now.
          </p>
        ) : (
          <div>
            <FormControl
              variant={"outlined"}
              className={classes.formControl}
              style={{width: "100% "}}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Db Config to export
              </InputLabel>
              <Select
                ref={ref}
                size="small"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedConfig || ""}
                onChange={(event) => setSelectedConfig(event.target.value)}
                label="Db Config"
              >
                {dbCreds.map((cred) => (
                  <MenuItem value={cred._id}>{cred.configName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )
      }
      modalTitle="Export"
    />
  );
};

function CreateFolder({onClose, onCreated}) {
  const [modalStyle] = useState(getModalStyle);
  const [foldername, setFoldername] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const {innerWidth, innerHeight} = window;

  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();

  function getModalStyle() {
    const {innerWidth, innerHeight} = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      borderRadius: "11px",
    };
  }

  const createFolder = async () => {
    setLoading(true);
    try {
      const {data} = await axios.post(
        BASE_URL + "create-ocr-folders/",
        {foldername},
        getHeaders()
      );
      enqueueSnackbar("Folder has been created", {variant: "success"});
      console.log("data", data);
      onCreated(data.data);
      setIsCreated(true);
      onClose();
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        {variant: "error"}
      );
    }
    setLoading(false);
  };

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="simple-modal-title">
      <div
        style={{
          position: "absolute",
          width: innerWidth > 600 ? 500 : "100%",

          backgroundColor: theme.palette.background.paper,
          // border: '2px solid #000',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto !important",
          top: "50%",
          ...modalStyle,
        }}
      >
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <h5 id="simple-modal-title">Create Folder</h5>
          <IconButton
            onClick={onClose}
            color="inherit"
            aria-label="open drawer"
            style={{marginTop: -10, marginBottom: 10}}
          >
            <ClearIcon style={{color: "grey"}}/>
          </IconButton>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              style={{color: "black", width: "100%", marginTop: 9}}
              id="outlined-basic"
              size="small"
              label="Folder Name"
              value={foldername}
              onChange={(e) => setFoldername(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: 13,
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={createFolder}
            disabled={foldername === "" || isCreated}
          >
            <div>
              {loading ? <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={20}/> : "Create Folder"}{" "}
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function MoveInFolder({
                               onClose,
                               folders,
                               fileToMoveId,
                               onMoved,
                               showMoveToRootFolder = false,
                             }) {
  const [modalStyle] = useState(getModalStyle);
  const [folderId, setFolderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const {innerWidth, innerHeight} = window;
  useEffect(() => {
  }, []);
  const {enqueueSnackbar} = useSnackbar();
  const theme = useTheme();

  function getModalStyle() {
    const {innerWidth, innerHeight} = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
    };
  }

  const moveFiles = async () => {
    setLoading(true);
    try {
      const data = await axios.post(
        BASE_URL + "move-ocr-document-into-folder/",
        {fileToMoveId, folderId},
        getHeaders()
      );
      console.log("data", data);
      setIsCreated(true);
      enqueueSnackbar("Files has been moved", {variant: "success"});
      onMoved();
      onClose();
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        {variant: "error"}
      );
    }
    setLoading(false);
  };

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="simple-modal-title">
      <div
        style={{
          position: "absolute",
          width: innerWidth > 600 ? 500 : "100%",

          backgroundColor: theme.palette.background.paper,
          // border: '2px solid #000',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto !important",
          top: "50%",
          ...modalStyle,
        }}
      >
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <h5 id="simple-modal-title">Create Folder</h5>
          <IconButton
            onClick={onClose}
            color="inherit"
            aria-label="open drawer"
            style={{marginTop: -10, marginBottom: 10}}
          >
            <ClearIcon style={{color: "grey"}}/>
          </IconButton>
        </div>

        <Grid item xs={12}>
          <FormControl
            style={{width: "100%"}}
            variant="outlined"
            size="small"
          >
            <InputLabel id="demo-simple-select-label">Folder Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={folderId}
              onChange={(e) => setFolderId(e.target.value)}
            >
              {showMoveToRootFolder && (
                <MenuItem value={"moveToRoot"}>Move to parent</MenuItem>
              )}
              {folders?.length > 0
                ? folders?.map((folder) => {
                  if (localStorage.getItem("id") == folder._id) return null;
                  return (
                    <MenuItem value={folder._id}>
                      {folder.foldername}
                    </MenuItem>
                  );
                })
                : null}
            </Select>
          </FormControl>
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: 13,
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={moveFiles}
            disabled={isCreated}
          >
            <div>{loading ? <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={20}/> : "Move"} </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
