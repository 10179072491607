import { IconButton } from '@material-ui/core'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import ReactTimeAgo from 'react-time-ago'
import FileMenu from './FileMenu'
import { PictureAsPdf } from '@material-ui/icons'

const FileButton = (props) => {
  const getIcon = () => {
    let splitted = props.files.fileName.split(".")
    console.log("splieedd", splitted)
    if (splitted.length > 1) {
      if (splitted[1] === "pdf") return <PictureAsPdf style={{ color: "rgb(20, 155, 227)" }} />
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
        <g>
          <path
            d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
            style={{
              fill: '#599def',
            }}
          />
          <path
            d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
            style={{
              fill: '#c2e1ff',
            }}
          />
          <rect
            x={27}
            y={31}
            width={18}
            height={2}
            rx={1}
            ry={1}
            style={{
              fill: '#fff',
            }}
          />
          <rect
            x={27}
            y={36}
            width={18}
            height={2}
            rx={1}
            ry={1}
            style={{
              fill: '#fff',
            }}
          />
          <rect
            x={27}
            y={41}
            width={18}
            height={2}
            rx={1}
            ry={1}
            style={{
              fill: '#fff',
            }}
          />
          <rect
            x={27}
            y={46}
            width={12}
            height={2}
            rx={1}
            ry={1}
            style={{
              fill: '#fff',
            }}
          />
        </g>
      </svg>
    )


  }
  return (
    <div className="nk-file-item nk-file">
      <div className="nk-file-info">
        <div
          style={{ cursor: 'pointer' }}
          className="nk-file-title"
          onClick={() =>
            props.savehtmldata(
              props.files?.html,
              props.files?.upload,
              props.files?._id
            )
          }
        >
          <div className="nk-file-icon">
            <span className="nk-file-icon-type">
              {getIcon()}
            </span>
          </div>
          <div className="nk-file-name">
            <div
              className="nk-file-name-text"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 240,
              }}
            >
              {props.files?.fileName}
            </div>
          </div>
        </div>
        <ul className="nk-file-desc">
          <li className="date">
            Last updated:{' '}
            <ReactTimeAgo date={props.files?.updatedAt} locale="en-US" />
          </li>
        </ul>
      </div>
      <div className="nk-file-actions">
        <div className="dropdown">
          {/* <IconButton
      color='inherit'
      aria-label='open drawer'
      onClick={() =>
        deletefile(
          props.files?._id,
          index
        )
      }
    >
      <DeleteIcon
        style={{ color: 'grey' }}
      />
    </IconButton> */}
          <FileMenu
            id={props.files?._id}
            name={props.files?.fileName}
            setCurrentfileid={props.setCurrentfileid}
            setOpenfilename={props.setOpenfilename}
            setOpenfolderid={props.setOpenfolderid}
          />
        </div>
      </div>
    </div>
  )
}

export default FileButton
