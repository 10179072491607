import React, {useEffect, useState, useCallback} from "react";
import axios from "axios";
import {useDropzone} from 'react-dropzone';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



import {BASE_URL} from "../constants/apiconstant";
import {getHeaders, getMultipartHeaders} from "../utils";

import 'react-image-crop/dist/ReactCrop.css';

export const SmartOcrDialog = (props) => {
  const {onClose, open} = props;
  const [ocrTemplates, setOcrTemplates] = useState([]);
  const [currentTemplateId, setCurrentTemplateId] = useState(null);
  const [upImg, setUpImg] = useState(null);
  const [ocrResult, setOcrResult] = useState(null);
  const [ocrRunning, setOcrRunning] = useState(false);
  const [roiExpanded, setRoiExpanded] = useState(true);
  const [resultExpanded, setResultExpanded] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        setUpImg(reader.result);
      }
      reader.readAsDataURL(file);
    });
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  useEffect(() => {
    getOcrTemplates();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleChangeTemplateId = (evt) => {
    setCurrentTemplateId(evt.target.value);
  }

  const getOcrTemplates = async () => {
    axios.get(BASE_URL + "api/ocr-templates", getHeaders())
    .then(function (response) {
      console.log('ocr-templates', response.data);
      setOcrTemplates(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const handleRunOcr = async () => {
    setOcrRunning(true);
    const formData = new FormData();
    formData.append("id", currentTemplateId);
    formData.append("image", upImg);
    console.log(formData);
    axios.post(BASE_URL + "api/ocr-templates/" + currentTemplateId + "/extract/", formData,
      getMultipartHeaders())
    .then(function (response) {
      console.log('ocr-response:', response.data);
      setOcrRunning(false);
      setOcrResult(response.data);
      setUpImg(response.data.roi);
      setResultExpanded(true);
      setRoiExpanded(false);
    })
    .catch(function (error) {
      console.log(error);
    });

  };

  return (
    <Dialog fullWidth="true" maxWidth="md" onClose={handleClose} open={open}>
      <DialogTitle>Smart OCR</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{minWidth: 120}}>
          <FormControl fullWidth>
            <InputLabel id="template-select-label">Select a Template</InputLabel>
            <Select
              labelId="template-select-label"
              id="template-select"
              value={currentTemplateId}
              label="Select Template"
              onChange={handleChangeTemplateId}>
              {ocrTemplates.map((ocrTemplate, idx) => (
                <MenuItem key={idx} value={ocrTemplate.id}>{ocrTemplate.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="upload-zone small bg-lighter dropzone dz-clickable mt-4" {...getRootProps()}>
            <input {...getInputProps()} />
            {
              isDragActive ?
                <p>Drop the files here ...</p> :
                <p>Drag 'n' drop some files here, or click to select files</p>
            }
          </div>
        </Box>
        <Accordion expanded={roiExpanded} onChange={() => setRoiExpanded(!roiExpanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="roi-content"
            id="roi-header">
            <Typography>Region Of Interest</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {upImg && (
              <img id="roi" src={upImg} alt="image"/>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={resultExpanded} onChange={() => setResultExpanded(!resultExpanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="result-content"
            id="result-header">
            <Typography>OCR Result</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ocrResult && (
              <pre>{ JSON.stringify(ocrResult, null, 4) }</pre>
            )}
          </AccordionDetails>
        </Accordion>

      </DialogContent>
      <DialogActions sx={{m:2}}>
        <Button disabled={ocrRunning} variant="contained"  style ={{backgroundColor:"rgb(20, 155, 227)"}} onClick={handleRunOcr}>Run OCR</Button>
        <Button variant="outlined"  style ={{color:"rgb(20, 155, 227)"}} onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SmartOcrDialog;