import { CircularProgress, Tooltip } from "@material-ui/core";
import { ArrowBack, PermContactCalendar } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Modal } from "../configuration/components/Modal";
import avatar from "../../assets/Avatar.jpg";
import cross from "../../assets/icons/cross.png";
import axios from "axios";
import { BASE_URL } from "../../constants/apiconstant";
import { getHeaders } from "../../utils";
import { useSnackbar } from "notistack";
import Swal from "sweetalert2";

export default function PrepareSend({
  onClose,
  onConfirm,
  emailsWithFieldCount,
  loading,
  showSuccessModal,
  participants,
  setParticipants,
  fileName,
  shareDocAsAttatchment,
  attatchmentLoading,
  fieldNodesRef,
  setShowPrepareSignModal,
  shareableLink,
  setShareableLink,
  deleteShareableLink
}) {
  const [emailSubject, setEmailSubject] = useState("Action needed");
  const [emailMessage, setEmailMessage] = useState("Please review, sign and complete this documents");
  const [emailValid, setEmailvalid] = useState(true);
  const [modal, setModal] = useState("sign-request");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [sendToEmail, setSendToEmail] = useState("");

  const [collab, setCollab] = useState({
    email: "",
    notify: false,
    permission: "viewer",
  });
  const updateEsignatureUser = async (participantEmail, permission) => {
    try {
      const { data } = await axios.put(BASE_URL + "updateEsignatureParticipant/", {
        ...getHeaders(),
        fileName,
        participantEmail,
        permission,
      });
      const updatedParticipants = participants.map((participant) =>
        participant.participantEmail === participantEmail ? { ...participant, permission: permission } : participant
      );
      setParticipants(updatedParticipants);
    } catch (error) {
      console.error(error);
    }
  };
  const validateEmail = (email) => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const onAddClick = async () => {
    // give the entered email permision of the document.
    var verified = validateEmail(collab.email);
    if (verified) {
      // add user to esignature
      try {
        collab.fileName = fileName;
        const { data } = await axios.post(
          BASE_URL + "createEsignatureParticipant/",
          {
            collab,
          },
          getHeaders()
        );
        console.log("aaaaaaaaaaaaaaaaaa", data);
        if (data.success) {
          const newData = {
            _id: data.Id,
            participantEmail: data.participantEmail,
            permission: data.permission,
          };
          setParticipants([...participants, newData]);
        }
      } catch (error) {
        if (error.response.status == 409) {
          enqueueSnackbar("User is already invited", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      }
    } else {
      setEmailvalid(verified);
    }
  };
  const onChangeCollab = (e) => {
    setEmailvalid(true);
    const name = e.target.name;
    if (name == "notify") {
      setCollab({ ...collab, [name]: e.target.checked });
    } else {
      setCollab({ ...collab, [name]: e.target.value });
    }
  };
  const onChangeParticipantPermission = async (e, mail) => {
    updateEsignatureUser(mail, e.target.value);
  };
  const deleteHandler = async (mail) => {
    try {
      const requestData = {
        mail: mail,
        fileName: fileName,
      };
      const { data } = await axios.delete(
        BASE_URL + "deleteEsignatureParticipant/",
        {
          data: requestData,
        },
        getHeaders()
      );

      if (data.success) {
        const updatedParticipants = participants.filter((participant) => participant.participantEmail !== mail);
        setParticipants(updatedParticipants);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getShareableLink = async () => {
    if(shareableLink === null){
    try {
      const requestData = {
        fileName: fileName,
      };
      const { data } = await axios.post(
        BASE_URL + "createShareAbleLink/",
        requestData,
        getHeaders()
      );
        if (data.success) {
          setShareableLink(data.link)
        }
    } catch (error) {
      console.log(error);
    }
  }
  };
  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(shareableLink);
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: 'success',
        title: 'Link Copied Successfully'
      })
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  const handleDeleteShareableLink = () => {
    onClose();
    Swal.fire({
      title: 'Are you sure?',
      text: "Anyone who visits the link will not be able to access the document. Other document permissions will not change.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(20, 155, 227)',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteShareableLink();
      }
    })
  }
  const onDeleteClick = (e, mail) => {
    var exists = false;
    fieldNodesRef.current.forEach((node) => {
      if (node.assigned === mail) {
        exists = true;
      }
    });
    if (!exists) {
      deleteHandler(mail);
    } else{
      setShowPrepareSignModal(false);
      Swal.fire({
        icon: 'error',
        title:'Can\'t Delete User',
        text: 'User has assigned Nodes!',
      })
    }
  };
  const [existParticipant, setExistParticipant ] = useState(false);
  useEffect(()=>{
    participants.map(participant => {
      if(participant.permission != "signer"){
        setExistParticipant(true);
      }else{
        setExistParticipant(false);
      }
    })
    console.log("Participants", existParticipant)
  }, [participants])
  return (
    <Modal
      cancelButtonVariant={showSuccessModal && "contained"}
      loading={loading}
      style={{zIndex: 800}}
      onClose={onClose}
      onConfirm={() => onConfirm(emailSubject, emailMessage)}
      cancelButtonText={showSuccessModal ? "Close" : "Cancel"}
      showCancelButton={modal == "sign-request" ? true : false}
      showConfirmButton={modal == "sign-request" ? true : false}
      confirmButtonText={modal == "sign-request" ? "Send Request" : modal == "attatchment" ? "Send Email" : "Send"}
      isOpen={true}
      width={700}
      modalTitle="Share With Others"
      modalContent={
        showSuccessModal ? (
          <p style={{ fontSize: "1rem" }}>Your email has been sent to necessary participants</p>
        ) : (
          <div>
            { !attatchmentLoading && (<div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginBottom: "20px",
                userSelect: "none",
              }}
            >
             {modal != "sign-request" && (
              <div
              onClick={() => {
                setModal("sign-request");
                setEmailSubject("Action needed");
                setEmailMessage("Please review, sign and complete this d  ocuments");
              }}
               style={{display:'flex', flexDirection:'column',  justifyContent:'center', alignSelf:'right'}}>
              <ArrowBack style={{ cursor: "pointer", margin:"0px 8px 3px 0px" }} />
              </div>)}
              <div
                style={{
                  display: "flex",
                  padding: "5px",
                  textAlign: "center",
                  color: modal==="attatchment" ? "rgb(255, 255, 255)":"rgb(20, 155, 227)",
                  backgroundColor: modal==="attatchment" ? "rgb(20, 155, 227)": "rgba(255,255,255)",
                  border: "2px solid rgba(0,0,0,0.2)",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setModal("attatchment");
                  setEmailSubject("Action needed");
                  setEmailMessage("Please review, sign and complete this documents");
                }}
              >
                <div style={{ margin: "0px 5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    width="1.5em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m3 8l7.89 5.26a2 2 0 0 0 2.22 0L21 8M5 19h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2Z"
                    />
                  </svg>
                </div>
                <div style={{ margin: "0px 8px" }}>Send as Attachment</div>
              </div>

              <div
                style={{
                  display: "flex",
                  padding: "5px",
                  textAlign: "center",
                  border: "2px solid rgba(0,0,0,0.2)",
                  borderRadius: "6px",
                  color: modal==="" ? "rgb(255, 255, 255)":"rgb(20, 155, 227)",
                  backgroundColor: modal==="" ? "rgb(20, 155, 227)": "rgba(255,255,255)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setModal("");
                }}
              >
                <div style={{ margin: "0px 5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1em"
                    viewBox="0 0 640 512">
                          <path
                          fill="currentColor"
                          d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
                    </svg>

                </div>
                <div style={{ margin: "0px 8px" }}>Add Participants</div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "4px",
                  textAlign: "center",
                  border: "2px solid rgba(0,0,0,0.2)",
                  borderRadius: "6px",
                  color: modal==="link" ? "rgb(255, 255, 255)":"rgb(20, 155, 227)",
                  backgroundColor: modal==="link" ? "rgb(20, 155, 227)": "rgba(255,255,255)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setModal("link");
                  getShareableLink();
                }}
              >
                <div style={{ margin: "0px 5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    width="1.5em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M12.586 4.586a2 2 0 1 1 2.828 2.828l-3 3a2 2 0 0 1-2.828 0a1 1 0 0 0-1.414 1.414a4 4 0 0 0 5.656 0l3-3a4 4 0 0 0-5.656-5.656l-1.5 1.5a1 1 0 1 0 1.414 1.414l1.5-1.5Zm-5 5a2 2 0 0 1 2.828 0a1 1 0 1 0 1.414-1.414a4 4 0 0 0-5.656 0l-3 3a4 4 0 1 0 5.656 5.656l1.5-1.5a1 1 0 1 0-1.414-1.414l-1.5 1.5a2 2 0 1 1-2.828-2.828l3-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div style={{ margin: "0px 8px" }}>Get Shareable Link</div>
              </div>

            </div>)}
             {modal === "link" && (
              <div style={{marginBottom:'10px',}}>
             <div style={{ display: "flex", flex: 1, border: '1px solid black',overflow:'hidden', borderRadius:'4px', height: '35px' }}>
            <div style={{ display: "flex", position: "relative", flex: 1 }}>
                <svg
                    style={{ position: "absolute", left: "6px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.1em"
                    viewBox="0 0 512 512"
                >
                    <path
                        fill="currentColor"
                        d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                    />
                </svg>
                <input style={{ paddingLeft: "28px",paddingRight:'4px', flex: 1, border: 'none' }} value={shareableLink} type="text" />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "28px",
                    borderLeft:'1px solid',
                     cursor:'pointer',
                }}
                onClick={copyToClipboard}
            >
              <Tooltip title="Copy Link" placement="top" arrow>
                <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      width="1.2em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                      class="iconify iconify--heroicons-outline h-4 w-4"
                  >
                      <path
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2M9 5a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2"
                      ></path>
                  </svg>
                </Tooltip>
            </div>
            </div>
              <div style={{color:'black', fontWeight:500, fontSize:'12px', margin:'5px 0 0 5px'}}>Anyone with this link can see this file. <span style={{color:"rgb(22, 155, 227)", cursor: 'pointer'}} onClick={handleDeleteShareableLink}>Remove Link</span></div>

            </div>
        )}

            {modal == "sign-request" && (
              <div>
                <div style={{ marginBottom: "4rem" }}>
                  <h5
                    style={{
                      borderBottom: "1px solid #ccc",
                      paddingBottom: "0.5rem",
                      fontFamily: "Nunito",
                    }}
                  >
                    Signers
                  </h5>
                  <div>
                    {emailsWithFieldCount.length === 0 && <p>No Signers have been added</p>}
                    {emailsWithFieldCount.map(({ email, fieldCount }) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <PermContactCalendar style={{ marginRight: "0.5rem" }} />{" "}
                          <p>
                            {email} : {fieldCount} fields assigned
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div>
                  <h5
                    style={{
                      borderBottom: "1px solid #ccc",
                      paddingBottom: "0.5rem",
                      fontFamily: "Nunito",
                    }}
                  >
                    Options
                  </h5>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <p style={{ marginBottom: 0, flex: 1 }}>Email Subject</p>
                      <input
                        value={emailSubject}
                        onChange={(e) => setEmailSubject(e.target.value)}
                        style={{
                          paddingLeft: "15px",
                          border: "1px solid #ccc",
                          width: "100%",
                          flex: 3,
                          borderRadius: "11px",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginBottom: 0, flex: 1 }}>Email Message</p>
                      <textarea
                        value={emailMessage}
                        rows="4"
                        onChange={(e) => setEmailMessage(e.target.value)}
                        style={{
                          paddingLeft: "15px",
                          border: "1px solid #ccc",
                          width: "100%",
                          flex: 3,
                          borderRadius: "11px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {modal == "" && (
              <div>
                <div style={{minHeight:"135px"}}>
                <h5
                    style={{
                      borderBottom: "1px solid #ccc",
                      paddingBottom: "0.5rem",
                      fontFamily: "Nunito",
                    }}
                  >
                    Participants
                  </h5>
                {!existParticipant && <p style={{height:"95px"}}>No Participants have been added</p>}
                  {participants.map((object) =>{ if(object.permission!=="signer") {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "8px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", color: "black", cursor: "pointer" }}>
                          <img width={"50px"} src={avatar} />
                          <div style={{ fontSize: "15px", marginLeft: "10px" }}>{object.participantEmail}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <select
                            style={{
                              height: "100%",
                              borderRadius: "4px",
                              backgroundColor: "transparent",
                              padding: "6px",
                              paddingLeft: "10px",
                            }}
                            onChange={(event) => {
                              onChangeParticipantPermission(event, object.participantEmail);
                            }}
                            name="permission"
                            id="permission"
                            defaultValue={object.permission}
                          >
                            <option value="viewer">Viewer</option>
                            <option value="contributor">Contributor</option>
                            {/* <option value="signer">Signer</option> */}
                          </select>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setExistParticipant(false);
                              onDeleteClick(e, object.participantEmail);
                            }}
                          >
                            <img width={"25px"} style={{ margin: "3px" }} src={cross} />
                          </div>
                        </div>
                      </div>
                    );
                  }})}
                </div>
                <h5 style={{ fontWeight: "500" }}>Add Collaborators</h5>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(0, 0, 0, 0.08)",
                    padding: "7px",
                    borderRadius: "6px",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid black",
                        borderRadius: "7px",
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "500",
                          fontSize: "0.875rem",
                          padding: "0.275rem",
                          color: "#4a5568",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="0.88em"
                          height="1em"
                          viewBox="0 0 448 512"
                          style={{ flexShrink: 0, width: "1.25rem", height: "1rem" }}
                        >
                          <path
                            fill="currentColor"
                            d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-68 304H48V48h320v416zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6c-10.8 0-18.7 8-44.8 8c-26.9 0-33.4-8-44.8-8c-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"
                          ></path>
                        </svg>
                      </div>
                      <div style={{ display: "flex", flexWrap: "nowrap" }}>
                        <input
                          onChange={onChangeCollab}
                          style={{
                            height: "100%",
                            width: "198px",
                            outline: "none",
                            width: "198px",
                            padding: "4px",
                            border: "1px solid white",
                            borderRight: "1px solid black",
                            borderLeft: "1px solid black",
                          }}
                          name="email"
                          type="email"
                          placeholder="emal@example.com"
                        />
                        <select
                          style={{
                            height: "100%",
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            padding: "6px",
                            paddingLeft: "10px",
                          }}
                          onChange={onChangeCollab}
                          name="permission"
                          id="permission"
                        >
                          <option value="viewer">Viewer</option>
                          <option value="contributor">Contributor</option>
                          {/* <option value="signer">Signer</option> */}
                        </select>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          padding: "5px",
                          borderRadius: "5px",
                          backgroundColor: "rgb(20, 155, 227)",
                          color: "white",
                          fontSize: "16px",
                          width: "80px",
                          textAlign: "center",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={onAddClick}
                      >
                        ADD
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          onChange={onChangeCollab}
                          type="checkbox"
                          name="notify"
                          style={{ width: "30px", height: "20px", marginLeft: "14px" }}
                        />
                        <div
                          style={{
                            fontSize: "13px",
                            fontWeight: "600",
                            cursor: "default",
                            marginTop: "3px",
                            color: "black",
                          }}
                        >
                          Notify People
                        </div>
                      </div>
                    </div>
                  </div>
                  {!emailValid && <div style={{ color: "red", margin: "5px" }}>Enter a Valid Email*.</div>}
                </div>
              </div>
            )}
             {modal == "attatchment" && attatchmentLoading && (
             <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
             <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
             </div>
             )}
            {modal == "attatchment" && !attatchmentLoading && (
              <div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <p style={{ marginBottom: 0, flex: 1 }}>To</p>
                    <input
                      placeholder="example@gmail.com"
                      type="email"
                      onChange={(e) => setSendToEmail(e.target.value)}
                      style={{
                        paddingLeft: "15px",
                        border: "1px solid #ccc",
                        width: "100%",
                        flex: 3,
                        borderRadius: "11px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <p style={{ marginBottom: 0, flex: 1 }}>Email Subject</p>
                    <input
                      value={emailSubject}
                      onChange={(e) => setEmailSubject(e.target.value)}
                      style={{
                        paddingLeft: "15px",
                        border: "1px solid #ccc",
                        width: "100%",
                        flex: 3,
                        borderRadius: "11px",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginBottom: 0, flex: 1 }}>Email Message</p>
                    <textarea
                      value={emailMessage}
                      rows="4"
                      onChange={(e) => setEmailMessage(e.target.value)}
                      style={{
                        paddingLeft: "15px",
                        border: "1px solid #ccc",
                        width: "100%",
                        flex: 3,
                        borderRadius: "11px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                      gap: "15px",
                    }}
                  >
                    <button onClick={onClose} className="MuiButtonBase-root MuiButton-root" tabindex="0" type="button">
                      <span className="MuiButton-label">Cancel</span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                    <button
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                      style={{ backgroundColor:'rgb(20, 155, 227)'}}
                      tabindex="0"
                      type="button"
                    >
                      <span
                        className="MuiButton-label"
                        onClick={() => {
                          if(sendToEmail ==""){
                            enqueueSnackbar(
                              "Email is Required",
                              {
                                variant: "error",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              }
                            );
                          } else if (validateEmail(sendToEmail)) {
                            shareDocAsAttatchment(emailSubject, emailMessage, sendToEmail);
                          } else {
                            enqueueSnackbar(
                              "Email is Invalid",
                              {
                                variant: "error",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              }
                            );
                          }
                        }}
                      >
                        Send Email
                      </span>
                      <span className="MuiTouchRipple-root"></span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      }
    />
  );
}
