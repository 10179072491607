import React, { useState } from 'react'

export default function ConfigDetails({ configData, mainText }) {
    const [showDetails, setShowDetails] = useState(false)
    return (
        <div>
            {!showDetails && (
                <ul className='nk-file-desc'>
                    <li className='date' onClick={(e) => {
                        e.stopPropagation()
                        setShowDetails(!showDetails)
                    }
                    }>
                        {mainText}
                    </li>


                </ul>
            )}

            <div style={{ marginTop: "1rem" }}>
                {showDetails && Object.keys(configData).map(key => {
                    return (
                        <p className='date' style={{ marginBottom: "0.3rem" }} onClick={(e) => {
                            e.stopPropagation()
                            setShowDetails(!showDetails)
                        }}>
                            {key}&nbsp; :&nbsp; {configData[key]}
                        </p>
                    )
                })}
            </div>
        </div>
    )
}
