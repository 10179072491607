import axios from "axios";
import { BASE_URL, NEST_BACKEND_URL } from "../constants/apiconstant";
import {
  GET_FILE_FAIL,
  GET_FILE_REQUEST,
  GET_FILE_SUCCESS,
  GET_FOLDER_FAIL,
  GET_FOLDER_REQUEST,
  GET_FOLDER_SUCCESS,
  GET_OCR_DOCUMENT_REQUEST,
  GET_OCR_DOCUMENT_SUCCESS,
  GET_OCR_DOCUMENT_FAIL,
  REMOVE_OCR_DOCUMENT_REQUEST,
  REMOVE_OCR_DOCUMENT_SUCCESS,
  REMOVE_OCR_DOCUMENT_FAIL,
  REMOVE_FOLDER_SUCCESS,
  REMOVE_FOLDER_REQUEST,
  REMOVE_FOLDER_FAIL,
  REMOVE_FILE_SUCCESS,
  UPDATE_FOLDER_REQUEST,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAIL,
  REMOVE_FILE_REQUEST,
  REMOVE_FILE_FAIL,
  CREATE_FILE_FAIL,
  CREATE_FILE_REQUEST,
  CREATE_FILE_SUCCESS,
  CREATE_FOLDER_FAIL,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  UPDATE_FILE_FAIL,
  UPDATE_FILE_REQUEST,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILENAME_SUCCESS,
  UPDATE_FILENAME_REQUEST,
  UPDATE_FILENAME_FAIL,
  UPDATE_FOLDERID_FAIL,
  UPDATE_FOLDERID_REQUEST,
  UPDATE_FOLDERID_SUCCESS,
  UPDATE_OCR_SUCCESS,
  UPDATE_OCR_FAIL,
} from "../constants/assetsConstants";
import { compare, getHeaders } from "../utils";
// import _ from "lodash";

export const Filefetch = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.post(
      NEST_BACKEND_URL + "file",
      {userId},
      config
    );

    dispatch({
      type: GET_FILE_SUCCESS,
      payload: data.sort(compare),
    });
  } catch (error) {
    dispatch({
      type: GET_FILE_FAIL,
      payload: error,
    });
  }
};

export const OcrDocumentfetch = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OCR_DOCUMENT_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log("userId OCR  == ", userId);
    const {data} = await axios.post(
      BASE_URL + "ocr/",
      {userId: userId},
      getHeaders()
    );
    console.log("actionpay ocr = ", data);
    dispatch({
      type: GET_OCR_DOCUMENT_SUCCESS,
      payload: data.sort(compare),
    });
  } catch (error) {
    dispatch({
      type: GET_OCR_DOCUMENT_FAIL,
      payload: error,
    });
  }
};

export const OcrFileDeletion = async (id) => {
  try {
    console.log("id = ", id);

    const {data} = await axios.delete(
      `${BASE_URL}remove-ocr/?id=${id}`,
      getHeaders()
    );
  } catch (error) {
  }
};

export const Folderfetch = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.post(
      NEST_BACKEND_URL + "folder",
      {userId},
      config
    );

    dispatch({
      type: GET_FOLDER_SUCCESS,
      payload: data.sort(compare),
    });
  } catch (error) {
    dispatch({
      type: GET_FOLDER_FAIL,
      payload: error,
    });
  }
};

export const FileDeletion = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const {data} = await axios.delete(
      `${NEST_BACKEND_URL}file/${id}`
    );

    dispatch({
      type: REMOVE_FILE_SUCCESS,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_FILE_FAIL,
      payload: error,
    });
  }
};

export const FolderDeletion = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const {data} = await axios.delete(
      `${NEST_BACKEND_URL}folder/${id}`
    );

    dispatch({
      type: REMOVE_FOLDER_SUCCESS,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_FOLDER_FAIL,
      payload: error,
    });
  }
};

export const FileAdd = (fileName, userId, folderId, html, upload, redirect) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.post(
      NEST_BACKEND_URL + "file/creation",
      {fileName, userId, folderId, html, upload},
      config
    );

    dispatch({
      type: CREATE_FILE_SUCCESS,
      payload: data,
    });

    redirect(data._id);
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: CREATE_FILE_FAIL,
      payload: error,
    });
  }
};

export const FolderAdd = (folderName, userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.post(
      NEST_BACKEND_URL + "folder/creation",
      {folderName, userId},
      config
    );

    dispatch({
      type: CREATE_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: CREATE_FOLDER_FAIL,
      payload: error,
    });
  }
};

export const FileUpdate = (_id, html) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.put(
      `${NEST_BACKEND_URL}file/${_id}`,
      {html},
      config
    );

    dispatch({
      type: UPDATE_FILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_FILE_FAIL,
      payload: error,
    });
  }
};

export const OcrUpdate = (_id, ocrdata) => async (dispatch, getState) => {
  try {
    // dispatch({
    //   type: GET_OCR_DOCUMENT_REQUEST,
    // })

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.post(
      BASE_URL + "updateocr/",
      {
        _id: ocrdata._id,
        name: ocrdata.name,
        user_id_or_company_id: ocrdata.user_id_or_company_id,
        data: ocrdata.data,
      },
      getHeaders()
    );

    dispatch({
      type: UPDATE_OCR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_OCR_FAIL,
      payload: error,
    });
  }
};

export const UpdatefileName = (_id, fileName) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.put(
      `${NEST_BACKEND_URL}file/update/${_id}`,
      {fileName},
      config
    );

    dispatch({
      type: UPDATE_FILENAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_FILENAME_FAIL,
      payload: error,
    });
  }
};

export const FolderUpdate = (_id, folderName) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.put(
      `${NEST_BACKEND_URL}folder/${_id}`,
      {folderName},
      config
    );

    dispatch({
      type: UPDATE_FOLDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_FOLDER_FAIL,
      payload: error,
    });
  }
};

export const Updatefilefolderid = (_id, folderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.put(
      `${NEST_BACKEND_URL}file/updatefolderid/${_id}`,
      {folderId},
      config
    );

    dispatch({
      type: UPDATE_FOLDERID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_FOLDERID_FAIL,
      payload: error,
    });
  }
};

export const removeEsignDOcuments = async (id) => {
  console.log("remmoving", id);
  try {
    await axios.delete(
      BASE_URL + "removeEsignDocuments?id=" + id,
      getHeaders()
    );
    return true;
  } catch (error) {
    console.log("error", error);
    return false;
  }
};
