import React, { useState } from "react";
import { TextField, Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: "3rem", // Adjust font size as needed (1 rem = 16px)
    border: "1px solid #ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    cursor: "pointer", // Add cursor style to indicate clickability
    transition: "background-color 0.3s ease-in-out", // Add transition for smooth effect
  },
  selected: {
    backgroundColor: "#f0f0f0", // Change the background color for selected grid box
  },
  }));

// Add more font family in list and it will generate more pattern
const fonts = [
  "James_Fajardo",
  "JennaSue",
  "PWSignaturetwo",
  "daniel",
  "HoneyScript-Light",
  "Brush Script MT",
  "JPHSL",
  "James_Fajardo",
];

const InitialsGenerator = (props) => {
  const { setSelectedInitial, selectedInitial, setIsSigned, ...other } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [initial, setInitial] = useState(null);
  const [selectedFonts, setSelectedFonts] = useState([]);
  const [selectedFontIndex, setSelectedFontIndex] = useState({});


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const generateInitials = () => {

    const initials = inputValue && inputValue
      .trim()
      .split(" ")
      .map((name) => name[0].toUpperCase())
      .join("");
    setInitial(initials);
    setSelectedFonts([...selectedFonts, initials]);
    // setInputValue('');
  };

  const handleFontClick = (index) => {
    console.log("[handleFontClick]-index", index)
    setSelectedFontIndex(index);
    setIsSigned(true);
    setSelectedInitial({...index, text: selectedFonts[0]})
  };

  return (
    <div>
      <Grid container spacing={2}>
       <div  style={{display:"flex", flexDirection:"column", width:'100%'}} >
        <TextField label="Enter your name" value={inputValue} onChange={handleInputChange} variant="outlined"  />
        <div style={{width:"100%", display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
        <Button variant="contained"  onClick={generateInitials} style={{padding: "5px", backgroundColor:'rgb(20, 157, 227)', color:'white', outlineColor:"rgb(20, 157, 227)"}}>
          Generate Initials
        </Button>
        </div>
        </div>
        {initial &&
          fonts.map((font, index) => (
            <Grid item xs={6} key={index} onClick={() => handleFontClick({font, index})}>
              <Paper
                className={`${classes.paper} ${font} ${selectedFontIndex.index === index ? classes.selected : ""}`}
                style={{
                  fontFamily: font,
                }}
              >
                {selectedFonts[selectedFonts.length - 1]}
              </Paper>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default InitialsGenerator;
