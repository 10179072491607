import { useEffect, useState } from "react";

import axios from "axios";
import { useHistory, withRouter, useParams } from "react-router-dom";
import { NEST_BACKEND_URL } from "../constants/apiconstant";

import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { USER_LOGIN_SUCCESS } from "../constants/userConstants";
import Typography from "@material-ui/core/Typography";

export default function RedirectFromMainApp(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  console.log("ss", params);
  useEffect(() => {
    if (params.token && params.user_id) {
      loginUser(params.token);
    }
    /* if (companyId && userId) {
      loginUser(companyId, userId);
    } else {
      setLoading(false);
    } */
  });
  const loginUser = async (tokenFromRedirect) => {
    try {
      const { data } = await axios.post(`${NEST_BACKEND_URL}auth/login-from-redirect`, {
        tokenFromRedirect,
      });
      if (data.success) {
        console.log("fewlfewlflewfeklwflewfe", data.data.role);
        localStorage.setItem("userRole", data.data.role);
        localStorage.setItem("userInfo", JSON.stringify({ result: data.data }));
        localStorage.setItem("token", data.data.token);
        if (data.data.role == "member") {
          localStorage.setItem("isNormalUser", true);
        }
        localStorage.setItem("expiry", new Date(new Date().getTime() + 24 * 60 * 60 * 1000).valueOf());
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: { result: data.data },
        });
        history.push(data.data.role == "member" ? "/file-sharing" : "/dashboard");
      } else {
        if (data.tokenExpired) {
          history.push("/token_expired/" + params.token + "/" + params.user_id);
        } else {
          setError("An error occurred, please try again or contact support");
        }
      }
    } catch (error) {
      console.log("eee", error);
      setError("An error occurred, please try again or contact support");
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {error ? (
        <Typography style={{ color: "red" }} component="h1" variant="h5">
          {error}
        </Typography>
      ) : (
        <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
      )}
    </div>
  );
}
