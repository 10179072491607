import React from 'react'

export const PageHeader = ({ pageTitle, navItems }) => {
    return (
        <div className='nk-block-head nk-block-head-sm'>
            <div className='nk-block-between position-relative'>
                <div className='nk-block-head-content'>
                    <h3 className='nk-block-title page-title'>
                        {pageTitle}
                    </h3>
                </div>
                <div className='nk-block-head-content'>
                    <div
                        className='nk-fmg-actions'

                    >
                        <ul className='nk-block-tools g-3'>
                            {navItems.map((navItem => (
                                <NavItem
                                    navItem={navItem}

                                />
                            )))}

                        </ul>
                    </div>
                </div>

                {/* .search-wrap */}
            </div>
        </div>
    )
}

const NavItem = ({ navItem: { text, onClick } }) => (
    <li>
        <div className='dropdown'>
            <button
                style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
                onClick={onClick}

                className='btn btn-light'
                data-toggle='dropdown'
            >
                <em className='icon ni ni-plus' />{' '}
                <span>{text}</span>
            </button>

        </div>
    </li>
)