import React from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const ContactDetail = () => {
  const [bodyWidth, setBodyWidth] = React.useState(false);

  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const contactString = localStorage.getItem("contact");
  const contact = JSON.parse(contactString);
  return (
    <div>
      <div className="p-2">
        <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
          <div className="nk-app-root">
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                {/* main header @s */}
                <Navbar />
                {/* main header @e */}

                <div className="nk-content p-0">
                  <div className="nk-content-inner">
                    <div className="nk-content-body">
                      <div
                        className="nk-fmg"
                        style={bodyWidth ? { paddingLeft: 80 } : null}
                      >
                        <Sidebar page="contact" getState={sidebarState} />
                        {/* .nk-fmg-aside */}
                        <div className="nk-fmg-body">
                          <div className="nk-block">
                            <div>
                              <div className="nk-content ">
                                <div className="container-fluid">
                                  <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                      <div className="nk-block-head nk-block-head-sm">
                                        <div className="nk-block-between g-3">
                                          <div className="nk-block-head-content">
                                            <h3 className="nk-block-title page-title">
                                              {/*  KYCs /{" "} */}
                                              <strong className="text-primary small">
                                                {contact.first_name +
                                                  " " +
                                                  contact.last_name}
                                              </strong>
                                            </h3>
                                            <div className="nk-block-des text-soft">
                                              <ul className="list-inline">
                                                <li>
                                                  User ID:{" "}
                                                  <span className="text-base">
                                                    {contact.id}
                                                  </span>
                                                </li>
                                                <li>
                                                  Submited At:{" "}
                                                  <span className="text-base">
                                                    {contact.submitted_date}
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="nk-block-head-content">
                                            <Link
                                              to={"/contact"}
                                              className="btn btn-outline-light text-white d-none d-sm-inline-flex"
                                              style={{ backgroundColor: 'rgb(20, 155, 227)'}}
                                            >
                                              <em className="icon ni ni-arrow-left"></em>
                                              <span>Back</span>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="nk-block">
                                        <div className="row gy-5">
                                          <div className="col-lg-7">
                                            <div className="nk-block-head">
                                              <div className="nk-block-head-content">
                                                <h5 className="nk-block-title title">
                                                  Applicant Information
                                                </h5>
                                                <p>
                                                  Basic info, like name, phone,
                                                  address, country etc.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="card card-bordered">
                                              <ul className="data-list is-compact">
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Username
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.username}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Email
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.email}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Phone
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.phone}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Address
                                                    </div>
                                                    <div className="data-value text-soft">
                                                      {contact.address}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      First Name
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.first_name}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Last Name
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.last_name}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Name
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.name}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Country
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.country}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Active
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.active}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Role
                                                    </div>
                                                    <div className="data-value text-break">
                                                      {contact.role}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Doc Type
                                                    </div>
                                                    <div className="data-value">
                                                      {contact.doc_type}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="data-item">
                                                  <div className="data-col">
                                                    <div className="data-label">
                                                      Submitted Date
                                                    </div>
                                                    <div className="data-value text-break">
                                                      {contact.submitted_date}
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* .nk-fmg */}
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactDetail;
