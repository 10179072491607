import React, { useState } from 'react'

export default function LeftSidebarImage({ canvasArray, permission }) {
    const [activePage, setActivePage] = useState(1)
    const height = permission === "viewer" ? "100vh" : "calc(100vh - 53px )"
    return (
        <div
            id="image-container"
            style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '200px',
                alignItems: 'center',
                height: height, overflowY: "auto",
                padding: "2rem", paddingTop: "5px",
                boxShadow:" rgb(136, 136, 136) 10px 0px 25px -6px"


            }}
        >
            {canvasArray.map((canvasBg) => {
                return <img alt="" onClick={() => {
                    let elem = document.getElementById("page" + canvasBg.id)
                    elem.scrollIntoView()
                    setActivePage(canvasBg.page)
                }} src={canvasBg.bg} id={"image" + canvasBg.page + " " + activePage} style={{
                    maxWidth: "100px",
                    border: activePage === canvasBg.page ? "3px solid blue" : "1px solid black",
                    marginBottom: "2rem",
                    marginTop: "3px",
                    height: "130px",
                    borderRadius:"7px"
                }} />
            })}
        </div>
    )
}
