import React from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
// import { FileDeletion, FolderDeletion } from '../actions/assetsAction'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

const SharedFolderMenu = (props) => {
  console.log("SharedFolderMenu Props==>", props)
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const getAssets = useSelector((state) => state.getAssets)
  const { loading, error, file, folder } = getAssets

  const deletefolder = (id) => {
    console.log('deletefolder', id)
    props.setCurrentfolderid(id)
    props.setRemovefolder(true)
  }
  const renamefolder = (id, name) => {
    console.log("renamefolder id==>", id)
    console.log("renamefolder props==>", props)
    props.setCurrentfolderid(id)
    props.setOpenfoldername(true)
  }
  const shareFolder = (id, name) => {
    props.setCurrentfolderid(id)
    props.setShareFolderToContacts({share: true, folderName: name})
  }
  return (
    <>
      <IconButton
        color='inherit'
        aria-label='open drawer'
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreHorizIcon style={{ color: 'grey' }} />
      </IconButton>
      <Menu
        elevation={0}
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        {((props.doc.role === "editor" && props.doc.editor_can_rename ) || props.doc.role === "admin" ) && <MenuItem
          onClick={() => {
            setAnchorEl(null)
            renamefolder(props?.id, props?.name)
            console.log('Shared dot Menu-Rename-curr id==>', props?.id) // should be each individual id, but here always "test3"
          }}
        >
          Rename{' '}
        </MenuItem>}
        {(props.doc.role === "admin" )&&<MenuItem
          onClick={() => {
            deletefolder(props?.id)
            setAnchorEl(null)
            console.log('Shared dot Menu-Delete-curr id==>', props?.id) // should be each individual id, but here always "test3"
          }}
        >
          {' '}
          Delete
        </MenuItem>}
        {<MenuItem
          onClick={() => {
            if((props.doc.role === "editor" && props.doc.editor_share_and_change_permissions) || props.doc.role === "admin" ){

              shareFolder(props?.id, props.name)
              setAnchorEl(null)
              console.log('Shared dot Menu-Share-curr id==>', props?.id) // should be each individual id, but here always "test3"
            } else{
              setAnchorEl(null)
              Swal.fire(
                'Not Allowed',
                ' You do not have the ability to share this folder. Please reach out to the person that shared this folder with you.',
                'error'
              )
            }
          }}
        >
          {' '}
          Share
        </MenuItem>}
      </Menu>
    </>
  )
}

export default SharedFolderMenu
