import React from "react";
import {
  Grid,
  Container,
  Modal as ReactModal,
  TextField,
  Fab,
  IconButton,
  DialogActions,
  DialogContent,
  makeStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";

import Clear from "@material-ui/icons/Clear";

function getModalStyle() {
  const { innerWidth, innerHeight } = window;

  let top = 50,
    left = innerWidth < 600 ? 0 : 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
    borderRadius: "11px",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: (props) => props.width,

    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "calc(100vh - 100px)",
    overflow: "auto !important",
    top: "50%",
    fontFamily: "Nunito, sans-serif !important",
  },
  multilineColor: {
    color: "white",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  rooot: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  fieldroot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export const Modal = ({
  modalContent,
  showCancelButton = true,
  showConfirmButton = true,
  cancelButtonText = "cancel",
  confirmButtonText = "confirm",
  modalTitle,
  modalSubTitle,
  onClose,
  onConfirm,
  loading,
  cancelButtonVariant,
  width = null,
  style={}
}) => {
  const modalStyle = getModalStyle();
  const classes = useStyles({
    width: width ? width : window.innerWidth > 600 ? 500 : "100%",
  });
  return (
    <ReactModal
      open={true}
      style={style}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      size="lg"
    >
      <div style={modalStyle} className={classes.paper}>
        <div style={{ display: "flex", justifyContent: "right", height:"20px"}}>
          {/* <h5 id="simple-modal-title" style={{marginLeft:"25px"}}>
            {modalTitle} 
            { modalSubTitle ? <> <br/> <small class="font-medium text-gray-500">Initials you create with eSign are legally valid.</small></> : "" }
          </h5> */}
          <IconButton
            onClick={onClose}
            color="inherit"
            aria-label="open drawer"
            style={{ marginTop: -4, marginRight:"-28px" }}
          >
            <Clear style={{ color: "grey" }} />
          </IconButton>
        </div>
        <DialogContent>{modalContent}</DialogContent>
        {(showCancelButton || showConfirmButton ) && <DialogActions style={{ marginTop: 13 }}>
          {showCancelButton && (
            <Button
              onClick={onClose}
              color="primary"
              style={{backgroundColor:"rgb(20, 155, 227)", color: "white" }}
              variant={cancelButtonVariant}
            >
              {cancelButtonText}
            </Button>
          )}
          {showConfirmButton && (
            <Button
              onClick={onConfirm}
              variant="contained"
              style={{backgroundColor:'rgb(20, 155, 227)', outline:'none', color:'white '}}
              autoFocus
            >
              {loading ? (
                <CircularProgress color="#fff" size={20} />
              ) : (
                confirmButtonText
              )}
            </Button>
          )}
        </DialogActions>}
      </div>
    </ReactModal>
  );
};
