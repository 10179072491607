import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import { Link as Links } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import { CircularProgress } from '@material-ui/core';
import logo from './../assets/images/logo.jpeg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [ showMessage, setShowMessage] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const submitHandler = async (e) => {
    setShowMessage(true);
    e.preventDefault()
    dispatch(login(email, password, true))
    }
    if (userInfo == 'User Doesnot Exist' && !loading && showMessage) {
      setShowMessage(false);
      toast.error(userInfo, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setPassword(false)
    } else if (userInfo == 'Wrong Password' && !loading && showMessage) {
      setShowMessage(false);
      toast.error('Wrong Password', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else if (userInfo?.email == email) {
      localStorage.setItem("email", email);
      history.push("/verify-otp");
    }
  const classes = useStyles()

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <ToastContainer />

      <div className={classes.paper}>

        <img style={{ width: "7rem", borderRadius: "50%" }} alt="" src={logo} />

        {/*  <Typography component='h1' variant='h5'>
          Sign in
        </Typography> */}
        <form className={classes.form} onSubmit={submitHandler}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            type='email'
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <TextField
            variant='outlined'
            required
            margin='normal'
            fullWidth
            name='password'
            label='Enter Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
          {/* <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember me'
          /> */}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            style={{backgroundColor:"rgb(20, 155, 227)", color:"white"}}
            className={classes.submit}
            disabled={loading}
          >
            {
              loading
                ? <CircularProgress  style={{color:"white"}} size={24} />
                : 'Sign In'
            }

          </Button>
          <div style={{textAlign:"end", marginTop:"-8px", fontSize:"11px"}}> <a href="https://ahrvo.com/data-privacy-policy/" style={{textDecoration:"underline", fontWeight:"600"}}>Privacy and Data Protection policy</a> and <a href="https://ahrvo.com/terms-of-service/" style={{textDecoration:"underline", fontWeight:"600"}}>Terms of Services</a></div>
          <Grid style={{ marginTop: 6 }} container justify='flex-end'>
            <Grid item>
              <Links to='/user-signup'>{"Don't have an account? Sign Up"}</Links>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}
