import axios from "axios";
import { BASE_URL } from "../constants/apiconstant";

export const uploadService = {
  uploadFile,
  uploadOcrDoc,
};

const getCsrf = () => {
  const result =
    document.cookie &&
    document.cookie
      .split("; ")
      .reduce(
        (acc, cookie) =>
          acc ||
          (cookie.substring(0, "csrftoken".length + 1) === "csrftoken=" &&
            decodeURIComponent(cookie.substring("csrftoken=".length))),
        null
      );
  return result;
};

export { getCsrf };

function uploadFile(data) {
  // return axios.post(`https://signapi.kycaml.systems/upload_pdf`, data, {});
  /* return axios.post(`https://signapi.kycaml.systems/upload_pdf/`, data, {}) */
  return axios.post(BASE_URL + "upload_pdf/", data, {});
}

function uploadOcrDoc(data) {
  /* return axios.post(`https://signapi.kycaml.systems/upload_ocr/`, data, {}) */
  return axios.post(BASE_URL + `upload_ocr/`, data, getHeaders());
}

export function getHeaders() {
  console.log("token", localStorage.getItem("token"));
  let token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: token,
    },
  };
}

export function getMultipartHeaders() {
  let token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  };
}

export function compare(a, b) {
  if (new Date(a.updatedAt) > new Date(b.updatedAt)) {
    return -1;
  }
  if (new Date(a.updatedAt) < new Date(b.updatedAt)) {
    return 1;
  }
  return 0;
}

export function titleCase(str) {
  if (str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
  return "";
}


export function getPDFSVG() {
  console.log("Inside getPDFSVG");
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{fill:"#f26b6b"}}></path><path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{fill:"#f4c9c9"}}></path><path d="M46.3342,44.5381a4.326,4.326,0,0,0-2.5278-1.4289,22.436,22.436,0,0,0-4.5619-.3828A19.3561,19.3561,0,0,1,35.82,37.9536a56.5075,56.5075,0,0,0,1.3745-6.0858,2.339,2.339,0,0,0-.4613-1.8444,1.9429,1.9429,0,0,0-1.5162-.753h-.0014a1.6846,1.6846,0,0,0-1.3893.6966c-1.1493,1.5257-.3638,5.219-.1941,5.9457a12.6118,12.6118,0,0,0,.7236,2.1477,33.3221,33.3221,0,0,1-2.49,6.1052,20.3467,20.3467,0,0,0-5.9787,3.4413,2.5681,2.5681,0,0,0-.8861,1.8265,1.8025,1.8025,0,0,0,.6345,1.3056,2.0613,2.0613,0,0,0,1.3942.5313,2.2436,2.2436,0,0,0,1.4592-.5459,20.0678,20.0678,0,0,0,4.2893-5.3578,20.8384,20.8384,0,0,1,5.939-1.1858A33.75,33.75,0,0,0,42.96,47.7858,2.6392,2.6392,0,0,0,46.376,47.55,2.08,2.08,0,0,0,46.3342,44.5381ZM27.6194,49.6234a.8344.8344,0,0,1-1.0847.0413.4208.4208,0,0,1-.1666-.2695c-.0018-.0657.0271-.3147.4408-.736a18.0382,18.0382,0,0,1,3.7608-2.368A17.26,17.26,0,0,1,27.6194,49.6234ZM34.9023,30.848a.343.343,0,0,1,.3144-.1514.6008.6008,0,0,1,.4649.2389.853.853,0,0,1,.1683.6722v0c-.1638.92-.4235,2.381-.8523,4.1168-.0125-.05-.0249-.1-.037-.1506C34.6053,34.0508,34.3523,31.5779,34.9023,30.848ZM33.7231,43.5507a34.9732,34.9732,0,0,0,1.52-3.7664,21.2484,21.2484,0,0,0,2.2242,3.05A21.8571,21.8571,0,0,0,33.7231,43.5507Zm11.7054,2.97a1.3085,1.3085,0,0,1-1.6943.0887,33.2027,33.2027,0,0,1-3.0038-2.43,20.9677,20.9677,0,0,1,2.8346.3335,2.97,2.97,0,0,1,1.7406.9647C45.8377,46.1115,45.6013,46.3483,45.4285,46.5212Z" style={{fill:"#fff"}}></path></svg>
}

export function getFolderSVG() {
  console.log("Inside getFolderSVG");
  return <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 72 72"><path fill="#6C87FE" d="M57.5,31h-23c-1.4,0-2.5-1.1-2.5-2.5v-10c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v10	C60,29.9,58.9,31,57.5,31z"></path><path fill="rgb(20, 155, 227)" d="M59.8,61H12.2C8.8,61,6,58,6,54.4V17.6C6,14,8.8,11,12.2,11h18.5c1.7,0,3.3,1,4.1,2.6L38,24h21.8	c3.4,0,6.2,2.4,6.2,6v24.4C66,58,63.2,61,59.8,61z"></path><path display="none" fill="rgb(20, 155, 227)" d="M62.1,61H9.9C7.8,61,6,59.2,6,57c0,0,0-31.5,0-42c0-2.2,1.8-4,3.9-4h19.3	c1.6,0,3.2,0.2,3.9,2.3l2.7,6.8c0.5,1.1,1.6,1.9,2.8,1.9h23.5c2.2,0,3.9,1.8,3.9,4v31C66,59.2,64.2,61,62.1,61z"></path><path fill="#798BFF" d="M7.7,59c2.2,2.4,4.7,2,6.3,2h45c1.1,0,3.2,0.1,5.3-2H7.7z"></path></svg>
}

export function getCSVSVG() {
  console.log("Inside getCSVSVG");
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{fill:"#36c684"}}></path><path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{fill:"#95e5bd"}}></path><path d="M42,31H30a3.0033,3.0033,0,0,0-3,3V45a3.0033,3.0033,0,0,0,3,3H42a3.0033,3.0033,0,0,0,3-3V34A3.0033,3.0033,0,0,0,42,31ZM29,38h6v3H29Zm8,0h6v3H37Zm6-4v2H37V33h5A1.001,1.001,0,0,1,43,34ZM30,33h5v3H29V34A1.001,1.001,0,0,1,30,33ZM29,45V43h6v3H30A1.001,1.001,0,0,1,29,45Zm13,1H37V43h6v2A1.001,1.001,0,0,1,42,46Z" style={{fill:"#fff"}}></path></svg>
}

export function getDocSVG() {
  console.log("Inside getDocSVG");
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><g><path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{fill:"#599def"}}></path><path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{fill:"#c2e1ff"}}></path><rect x="27" y="31" width="18" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="27" y="36" width="18" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="27" y="41" width="18" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="27" y="46" width="12" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect></g></svg>
}

export function getFileSVG() {
  console.log("Inside getFileSVG");
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{fill:"#7e95c4"}}></path><path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{fill:"#b7ccea"}}></path><rect x="27" y="31" width="18" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="27" y="35" width="18" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="27" y="39" width="18" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="27" y="43" width="14" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="27" y="47" width="8" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect></svg>
}

export function getMediaSVG() {
  console.log("Inside getMediaSVG");
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><path d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z" style={{fill:"#755de0"}}></path><path d="M27.2223,43H44.7086s2.325-.2815.7357-1.897l-5.6034-5.4985s-1.5115-1.7913-3.3357.7933L33.56,40.4707a.6887.6887,0,0,1-1.0186.0486l-1.9-1.6393s-1.3291-1.5866-2.4758,0c-.6561.9079-2.0261,2.8489-2.0261,2.8489S25.4268,43,27.2223,43Z" style={{fill:"#fff"}}></path><path d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z" style={{fill:"#b5b3ff"}}></path></svg>
}

export function getZipSVG() {
  console.log("Inside getZipSVG");
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"><g><rect x="16" y="14" width="40" height="44" rx="6" ry="6" style={{fill:"#7e95c4"}}></rect><rect x="32" y="17" width="8" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="32" y="22" width="8" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="32" y="27" width="8" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="32" y="32" width="8" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><rect x="32" y="37" width="8" height="2" rx="1" ry="1" style={{fill:"#fff"}}></rect><path d="M35,14h2a0,0,0,0,1,0,0V43a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V14A0,0,0,0,1,35,14Z" style={{fill:"#fff"}}></path><path d="M38.0024,42H33.9976A1.9976,1.9976,0,0,0,32,43.9976v2.0047A1.9976,1.9976,0,0,0,33.9976,48h4.0047A1.9976,1.9976,0,0,0,40,46.0024V43.9976A1.9976,1.9976,0,0,0,38.0024,42Zm-.0053,4H34V44h4Z" style={{fill:"#fff"}}></path></g></svg>
}
