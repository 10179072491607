import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getHeaders } from "../../utils";
import { Modal } from "../configuration/components/Modal";
import { CircularProgress, Fab } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { printPdf, renderPdf } from "../Shared/utils";
import SignaturePad from "signature_pad";
import Header from "./Header";
import Resizer from "react-image-file-resizer";
import { Print, TramRounded } from "@material-ui/icons";
import { getRemainingFieldCount } from "./utils";
import LeftSidebarImage from "../Shared/LeftSidebarImage";
import { jsPDF } from "jspdf";
import { ShowAbsoluteButton } from "../Shared/EditPdf";
import { BASE_URL } from "../../constants/apiconstant";
import Swal from "sweetalert2";

var text1 = window.fabric.util.createClass(window.fabric.IText, {
  render: function (ctx) {
    this.clearContextTop();
    this.callSuper("render", ctx);
    this.cursorOffsetCache = {};
    this.renderCursorOrSelection();
    /*var bound = this.getBoundingRect();
        ctx.strokeStyle = this.fill;  
        ctx.strokeRect(
      bound.left + 0.5,
      bound.top + 0.5,
      bound.width,
      bound.height
        );*/
    ctx.strokeStyle = this.fill;
    let coords = this.calcCoords();
    ctx.beginPath();
    ctx.moveTo(coords.tl.x, coords.tl.y);
    ctx.lineTo(coords.tr.x, coords.tr.y);
    ctx.lineTo(coords.br.x, coords.br.y);
    ctx.lineTo(coords.bl.x, coords.bl.y);
    ctx.closePath();
    ctx.stroke();

    /*ctx.rect(this.left , this.top , this.width*this.scaleX , this.height*this.scaleY); 
        ctx.stroke();*/
  },
  /**
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   */
  _render: function (ctx) {
    this.callSuper("_render", ctx);
  },
});

export default function ViewOnlyDocuments() {
  const [data, setData] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const nodesRef = useRef([]);
  const canvasArrayRef = useRef([]);
  const [canvasArray, setCanvasArray] = useState([]);

  const pdfRef = useRef(null);
  const [showSignPad, setShowSignPad] = useState(false);
  const activeNodeRef = useRef(null);
  const [activePage, setActivePage] = useState(1);
  const [activeNodes, setActiveNodes] = useState(false);

  useEffect(() => {
    initializePdf();
  }, []);
  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: 'Getting Document',
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [loading]);
  const initializePdf = async () => {
    try {
      let fileUrl;
      let nodes;
      const token = new URLSearchParams(window.location.search).get("token");
      if (token) {
        const { data } = await axios.get(
          BASE_URL + "decode-jwt?token=" + token,
          getHeaders()
        );
      
        fileUrl = data.data.fileUrl;
        nodes = data.data.nodes;
      } else {
     
        fileUrl = localStorage.getItem("pdfviewerFileUrl");
        nodes = localStorage.getItem("pdfViewerNodes");
        console.log("filename ", fileUrl, nodes)
      }

      console.log("nodes", nodes)
     
      const result = await renderPdf(fileUrl);
      canvasArrayRef.current = result.canvasArray;
      setCanvasArray(canvasArrayRef.current);
     
      pdfRef.current = result.pdf;
      attachClickHandler(JSON.parse(nodes));
    } catch (error) {
      console.log("errr", error);
      enqueueSnackbar("Something went wrong, please try again later", {
        variant: "error",
      });
    }
    setLoading(false);
  };
  
  const getCanvasObjectFromPage = (pageNumber) => {
    let canvasToReturn = null;

    canvasArrayRef.current.forEach(({ page, canvas }) => {
     
      if (page === pageNumber) {
        canvasToReturn = canvas;
      }
    });
    return canvasToReturn;
  };
  
  const attachClickHandler = (nodes) => {
    canvasArrayRef.current.forEach(({ page, canvas }) => {
      canvas.on("mouse:up", function (e) {
        if (e.target) {
          nodes.forEach((n) => {
            if (n.fieldId === e.target.fieldId) {
              setActiveNodes(n);
            }
          });
        }
      });
    });
    nodes.forEach((node) => {
      let canvas = getCanvasObjectFromPage(node.page);
     
      addRectangle(canvas, node);
    });

    /* modifyPdf(0, x, y, width, height) */
  };

  const addRectangle = (canvas, node) => {
    // Render the Textbox on Canvas

    let objToAdd = null;
    if (node.fieldType === "sign" || node.fieldType === "initials") {
      if (node.imageData) {
        window.fabric.Image.fromURL(
          node.imageData,
           function (myImg) {
             //i create an extra var for to change some image properties
             var img1 = myImg.set({
               left: node.left,
               top: node.top,
               width: node.width,
               height: node.height,
               selectable: false,
             });
             canvas.add(img1);
           },
           {
             crossOrigin: "Anonymous",
           }
         );
      }
      else {
        objToAdd = new text1(node.text || "Sign Here", {
          ...node,
          fontSize: 16,
          hasBorders: false,
          hasControls: false,
          hasRotatingPoint: false,
  
          margin: 10,
  
          selectable: false,
        });
      }
    
    }
    if (node.fieldType === "text") {
      let text = node.text;
      objToAdd = new text1(text, {
        ...node,
        fontSize: 16,
        hasBorders: false,
        hasControls: false,
        hasRotatingPoint: false,
        margin: 10,
        selectable: false,
      });
    }
    if (node.fieldType === "text-field" || node.fieldType === "AText") {
      let text = node.text;
      objToAdd = new text1(text, {
        ...node,
        fontSize: 16,
        hasBorders: false,
        hasControls: false,
        hasRotatingPoint: false,

        margin: 10,

        selectable: false,
      });
    }
    if (node.fieldType === "date") {
    
      let text = node.text;
      delete node.text;
      objToAdd = new text1(node.date || text, {
        ...node,
        fontSize: 16,
        hasBorders: false,
        hasControls: false,

        hasRotatingPoint: false,

        margin: 10,

        selectable: false,
      });
    }
   
    if (objToAdd) {
      canvas.add(objToAdd);
    }
  };
 
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          bottom: 20,
          right: 32,
          height: "auto",
          zIndex: 90999999,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            bottom: 20,
            right: 32,
            height: "auto",
            zIndex: 90999999,
          }}
        >
          <Fab
            onClick={() => printPdf(canvasArrayRef.current)}
            style={{ marginTop: 10, backgroundColor: "#000" }}
            color="primary"
          >
            <Print />
          </Fab>
        </div>
      </div>
      <div id="abcdef">
        {/*   <Header nodes={() => nodesRef.current} loading={loading} data={data} /> */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            marginTop: "13px",
            zIndex: loading ? 0 : 9999,
            marginLeft: "3rem",
          }}
        >
          <LeftSidebarImage canvasArray={canvasArray} />
        </div>
        <div
          style={{
            backgroundColor: "#9e9e9e",
            backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=")`,

            bottom: "0",
            right: "0",
            overflow: "auto",
            position: "absolute",
            top: "0",
            left: "0",
            margin: "0",
            border: "0",

            zIndex: loading ? 0 : 999,
          }}
        >
          <div id="pdf-viewer"></div>
        </div>
      </div>
      <div id="sign-pad-image"></div>

      {activeNodes && <ShowAbsoluteButton activeNode={activeNodes} />}
    </div>
  );
}
