export const GET_FILE_REQUEST = 'GET_FILE_REQUEST'
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS'
export const GET_FILE_FAIL = 'GET_FILE_FAIL'

export const GET_FOLDER_REQUEST = 'GET_FOLDER_REQUEST'
export const GET_FOLDER_SUCCESS = 'GET_FOLDER_SUCCESS'
export const GET_FOLDER_FAIL = 'GET_FOLDER_FAIL'

export const GET_OCR_DOCUMENT_REQUEST = 'GET_OCR_DOCUMENT_REQUEST'
export const GET_OCR_DOCUMENT_SUCCESS = 'GET_OCR_DOCUMENT_SUCCESS'
export const GET_OCR_DOCUMENT_FAIL = 'GET_OCR_DOCUMENT_FAIL'

export const UPDATE_OCR_SUCCESS = 'UPDATE_OCR_SUCCESS'
export const UPDATE_OCR_FAIL = 'UPDATE_OCR_FAIL'

export const REMOVE_OCR_DOCUMENT_REQUEST = 'REMOVE_OCR_DOCUMENT_REQUEST'
export const REMOVE_OCR_DOCUMENT_SUCCESS = 'REMOVE_OCR_DOCUMENT_SUCCESS'
export const REMOVE_OCR_DOCUMENT_FAIL = 'REMOVE_OCR_DOCUMENT_FAIL'


export const REMOVE_FILE_REQUEST = 'REMOVE_FILE_REQUEST'
export const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS'
export const REMOVE_FILE_FAIL = 'REMOVE_FILE_FAIL'
export const REMOVE_FOLDER_REQUEST = 'REMOVE_FOLDER_REQUEST'
export const REMOVE_FOLDER_SUCCESS = 'REMOVE_FOLDER_SUCCESS'
export const REMOVE_FOLDER_FAIL = 'REMOVE_FOLDER_FAIL'
export const CREATE_FILE_REQUEST = 'CREATE_FILE_REQUEST'
export const CREATE_FILE_SUCCESS = 'CREATE_FILE_SUCCESS'
export const CREATE_FILE_FAIL = 'CREATE_FILE_FAIL'
export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST'
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS'
export const CREATE_FOLDER_FAIL = 'CREATE_FOLDER_FAIL'
export const UPDATE_FILE_REQUEST = 'UPDATE_FILE_REQUEST'
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS'
export const UPDATE_FILE_FAIL = 'UPDATE_FILE_FAIL'
export const UPDATE_FOLDER_REQUEST = 'UPDATE_FOLDER_REQUEST'
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS'
export const UPDATE_FOLDER_FAIL = 'UPDATE_FOLDER_FAIL'
export const UPDATE_FILENAME_REQUEST = 'UPDATE_FILENAME_REQUEST'
export const UPDATE_FILENAME_SUCCESS = 'UPDATE_FILENAME_SUCCESS'
export const UPDATE_FILENAME_FAIL = 'UPDATE_FILENAME_FAIL'
export const UPDATE_FOLDERID_REQUEST = 'UPDATE_FOLDERID_REQUEST'
export const UPDATE_FOLDERID_SUCCESS = 'UPDATE_FOLDERID_SUCCESS'
export const UPDATE_FOLDERID_FAIL = 'UPDATE_FOLDERID_FAIL'
