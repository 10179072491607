import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  MenuItem,
  TextField,
  useTheme,
  FormControl,
  InputLabel,

  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../constants/apiconstant";
import { getHeaders } from "../../utils";
import "./AddEmailFromContacts.css";
import Select from "react-select";
export default function AddEmailFromContacts({
  onClose,
  onAddClick,
  contacts = [],
  loading,
  participants,
  newParticipant,
  getContacts,
}) {
  const [modalStyle] = useState(getModalStyle);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedEmailWithUserIdAndCompanyId, setSelectedEmailWithUserIdAndCompanyId] = useState([])
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = React.useState(false);
  const { innerWidth, innerHeight } = window;

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
    };
  }
  const handleCheckBoxClick = (email, userId, companyId) => {
    setSelectedEmails([...email])
  };
  const transformAndConvert = () => {
    const transformed = []

    selectedEmails.forEach(({label, value:email}) => {
      let isFound = false;

      participants.forEach((participant) => {
        if (participant.participantEmail == email) {
          isFound = true;
        }
      });
      newParticipant.forEach((participant) => {
        if (participant.participantEmail == email) {
          isFound = true;
        }
      });
      if (isFound) return

      const contact = contacts.filter(contact => contact.email == email)[0]

      transformed.push({
        participantEmail:email,
        userId:contact.id,
        companyId:contact.company_id
      })

  })

  onAddClick(transformed)

  }

  return (

    
    <Dialog
      style={{ borderRadius: "11px"}}
      open={true}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" style={{ fontFamily: "Nunito" }}>
        Add Email
      </DialogTitle>
      <DialogContent style={{ minWidth: "30rem" }} dividers={true}>
        
     
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
            <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={30} />
          </div>
        ) : (
          <>
            <div style={{ display: "flex", flexDirection: "column",minHeight:"250px"  }}>
          
                 
                   <Select
                   styles={{width:"100%"}}
                                            isMulti
                                            isSelectAll
                                            name="assignations"
                                            options={
                                              contacts.map(contact => ({label:contact.first_name + " " + contact.last_name, value:contact.email}))
                                            }
                                            value={selectedEmails}
                                            onChange={(e) =>handleCheckBoxClick(e)}
                                          />
            
                 
              
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button style={{ fontFamily: "Nunito" }} onClick={onClose}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ fontFamily: "Nunito" , color:"rgb(20, 155, 227)", color:"white"}}
          onClick={() => transformAndConvert()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
