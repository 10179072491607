import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, ...rest }) => {
  const isNormalUser = localStorage.getItem("isNormalUser");
  const isUserLoggedIn = () => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("expiry") < new Date().valueOf()) {
        localStorage.clear();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        console.log("uiss", isUserLoggedIn(), Component);
        return !isUserLoggedIn() ? (
          <Component {...props} />
        ) : isNormalUser == true || isNormalUser == "true" ? (
          <Redirect to="/file-sharing" />
        ) : (
          <Redirect to="/dashboard" />
        );
      }}
    />
  );
};

export default PublicRoute;
