import React from "react";
import {Checkbox} from "@material-ui/core";
import ReactTimeAgo from "react-time-ago";
import FileMenuOcr from "./FileMenuOcr";

const OcrButton = (props) => {
  return (
    <div className="nk-file-item nk-file">
      <div className="nk-file-info">
        <div
          style={{cursor: "pointer"}}
          className="nk-file-title"
          onClick={() => props.saveocrdata(props.files, props.files?._id)}
        >
          {props.showCheckBox && (
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                props.checkBoxClicked(props.files.task_id, props.files?._id);
              }}
            />
          )}

          <div className="nk-file-icon">
            <span className="nk-file-icon-type">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                <g>
                  <path
                    d="M50,61H22a6,6,0,0,1-6-6V22l9-11H50a6,6,0,0,1,6,6V55A6,6,0,0,1,50,61Z"
                    style={{
                      fill: "#599def",
                    }}
                  />
                  <path
                    d="M25,20.556A1.444,1.444,0,0,1,23.556,22H16l9-11h0Z"
                    style={{
                      fill: "#c2e1ff",
                    }}
                  />
                  <rect
                    x={27}
                    y={31}
                    width={18}
                    height={2}
                    rx={1}
                    ry={1}
                    style={{
                      fill: "#fff",
                    }}
                  />
                  <rect
                    x={27}
                    y={36}
                    width={18}
                    height={2}
                    rx={1}
                    ry={1}
                    style={{
                      fill: "#fff",
                    }}
                  />
                  <rect
                    x={27}
                    y={41}
                    width={18}
                    height={2}
                    rx={1}
                    ry={1}
                    style={{
                      fill: "#fff",
                    }}
                  />
                  <rect
                    x={27}
                    y={46}
                    width={12}
                    height={2}
                    rx={1}
                    ry={1}
                    style={{
                      fill: "#fff",
                    }}
                  />
                </g>
              </svg>
              {" "}
            </span>
          </div>
          <div className="nk-file-name">
            <div
              className="nk-file-name-text"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 240,
              }}
            >
              {props.files?.name}
            </div>
          </div>
        </div>
        <ul className="nk-file-desc">
          <li className="date">
            Last updated:{" "}
            <ReactTimeAgo date={props.files?.updated_at} locale="en-US"/>
          </li>
        </ul>
      </div>
      <div className="nk-file-actions">
        <div className="dropdown">
          {/* <IconButton
      color='inherit'
      aria-label='open drawer'
      onClick={() =>
        deletefile(
          props.files?._id,
          index
        )
      }
    >
      <DeleteIcon
        style={{ color: 'grey' }}
      />
    </IconButton> */}
          <FileMenuOcr
            moveFolder={props.moveFolder}
            files={props.files}
            ocrDeleted={props.ocrDeleted}
            name={props.files?.fileName}
            setRenameocrfile={props.setRenameocrfile}
            setCurrentOcrFile={props.setCurrentOcrFile}
            setOpenocrfile={props.setOpenocrfile}
            setOpenfolderid={props.setOpenfolderid}
          />
        </div>
      </div>
    </div>
  );
};

export default OcrButton;
