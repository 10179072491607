import React, { useEffect } from "react";
import IntroJs from "intro.js";

import axios from "axios";
import { getHeaders } from "../utils";
import { BASE_URL } from "../constants/apiconstant";

const Tour = ({ steps, page_name, isEnabled = false }) => {
  console.log("isss", isEnabled);
  useEffect(() => {
    // Initialize Intro.js only once
    const intro = IntroJs();

    // Set tour options
    intro.setOptions({
      steps: steps,
    });

    // Register event handlers
    intro.oncomplete(() => {
      updatePageCompleted();
    });

    intro.onexit(() => {
      updatePageCompleted();
    });

    // Start the tour
    if (isEnabled) intro.start();

    // Optionally clean up on unmount
    return () => {
      intro.exit();
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const updatePageCompleted = async () => {
    const { data } = await axios.post(BASE_URL + "update_intro_js_page/?page_completed=" + page_name, {}, getHeaders());

    console.log("rr", data);
  };

  return null;
};

export default Tour;
