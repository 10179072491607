import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import "./../App.css";
import Editor from "react-medium-editor";
import { useReactToPrint } from "react-to-print";
import { Fab } from "@material-ui/core";

import PrintIcon from "@material-ui/icons/Print";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";
import FontNameForm from "../medium-ext/FontName";
import FontSizeForm from "../medium-ext/FontSize";
import { useDispatch, useSelector } from "react-redux";
import { FileUpdate } from "../actions/assetsAction";
import "./edit.css";
import axios from "axios";

require("medium-editor/dist/css/medium-editor.css");
require("medium-editor/dist/css/themes/default.css");

const EditPage = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getAssets = useSelector((state) => state.getAssets);
  const { loading, error, file, folder } = getAssets;
  const [showhtml, setShowhtml] = useState("");
  const [counts, setCounts] = useState(1);

  const fileid = match.params.id;
  const componentRef = useRef();
  const prevPref = useRef();

  const [filteredfile, setFilteredfile] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => {
      setCounts(counts + 1);
      let htmls = document.querySelectorAll(".medium-editor-element")[0];
      // htmls.style.padding = '56px'
      // htmls.style.color = '#000'
      htmls.style.zoom = "2";
      // htmls.style.transform = 'scale(1.5)'
      console.log("counts", counts);
      prevPref.current = htmls;
      // let htmlss = document.querySelectorAll('.medium-editor-element')[0]
      // htmlss.style.padding = '56px'
      // htmls.style.fontSize = '35px'
      return prevPref.current;
    },
  });

  useEffect(() => {
    getData();

    // document.querySelector('#htmlCont').innerHTML = savedData
  }, []);

  const getData = async () => {
    const { data } = await axios.get(
      "https://sign.kycaml.systems/file/" + match.params.id
    );
    setShowhtml(data.html);
    console.log("dataaa", data);
  };
  useEffect(() => {
    const filess = file?.filter((xs) => xs._id === fileid)[0];
    console.log("filess", filess);
    setFilteredfile(filess);
  }, []);

  const handleEditorChange = (text) => {
    setShowhtml(text);
  };
  const handleSave = () => {
    const html = document.querySelectorAll(".medium-editor-element")[0]
      .innerHTML;

    dispatch(FileUpdate(fileid, html));
    history.push("/document-hub");
  };
  useEffect(() => {
    let html = document.querySelectorAll(".medium-editor-element")[0];
    // html.style.fontSize = '0.875rem'
    // componentRef.current = html

    html.style.padding = "56px";
    html.style.color = "#000";
    html.style.zoom = "1";
    console.log("componentRef", componentRef.current);
  }, [counts]);
  return (
    <div>
      <div className="editorBG">
        <Editor
          ref={componentRef}
          tag="div"
          text={filteredfile?.html}
          onChange={handleEditorChange}
          options={{
            imageDragging: true,
            placeholder: false,
            anchorPreview: true,
            toolbar: {
              buttons: [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "fontname",
                "fontsize",
              ],
              static: true,
              sticky: true,
              stickyTopOffset: 0,
            },
            extensions: {
              fontname: new FontNameForm(),
              fontsize: new FontSizeForm(),
            },
          }}
        />
      </div>
      {/* {daf?.length <= 0 ? (
        <>
        </>
      ) : (
        <>
          <Draggable>
            <img src={daf} style={{ position: 'absolute' }}></img>
          </Draggable>
        </>
      )}{' '} */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          bottom: 20,
          right: 32,
        }}
      >
        <Fab style={{ marginTop: 10, backgroundColor: "#000" }} color="primary">
          <SaveIcon onClick={handleSave} />
        </Fab>

        <Fab
          style={{ marginTop: 10, backgroundColor: "#000" }}
          color="primary"
          onClick={handlePrint}
        >
          <PrintIcon />
        </Fab>
      </div>
    </div>
  );
};

export default EditPage;
