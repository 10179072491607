import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  FileDeletion,
  FolderDeletion,
  OcrFileDeletion,
} from "../actions/assetsAction";
import { useDispatch, useSelector } from "react-redux";
import "./filemenu.css";
const FileMenuOcr = (props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const getAssets = useSelector((state) => state.getAssets);
  const { loading, error, ocr, folder } = getAssets;

  const deleteocrfile = async (id) => {
    // setAnchorElfile(null)

    console.log("deletefile", id);
    await OcrFileDeletion(id);
    if (props.ocrDeleted) props.ocrDeleted();
  };
  const renamefile = (file) => {
    // setAnchorElfile(null)
    console.log("file.name = ", file.name);
    props.setRenameocrfile(file?.files?.name);
    props.setCurrentOcrFile(file.files);
    props.setOpenocrfile(true);
    console.log("filename", file);
  };
  const movefolder = (id) => {
    if (props.moveFolder) props.moveFolder();
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreHorizIcon style={{ color: "grey" }} />
      </IconButton>
      <Menu
        elevation={0}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setAnchorEl(null)}
      >
        {/* <MenuItem
        //   onClick={() => {
        //     deletefolder(props?.id)
        //     setAnchorEl(null)
        //     console.log('curr id', props?.id) // should be each individual id, but here always "test3"
        //   }}
        >
          Download{' '}
        </MenuItem> */}
        {props.moveFolder && (
          <MenuItem
            onClick={() => {
              movefolder(props?.id);
              setAnchorEl(null);
              console.log("curr id", props?.id); // should be each individual id, but here always "test3"
            }}
          >
            Move{" "}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            renamefile(props);
          }}
        >
          Rename{" "}
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteocrfile(props?.files._id);
            setAnchorEl(null);
            console.log("curr id", props?.id); // should be each individual id, but here always "test3"
          }}
        >
          {" "}
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default FileMenuOcr;
