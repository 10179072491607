import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";

// import { AiOutlineCloudUpload } from 'react-icons/ai';
import Papa from "papaparse";
const dropzone = {
  width: "100%",
  padding: "25% !important",
};

function MyDropzone(props) {
  const [file, setFile] = useState([]);

  const handlePapa = (acceptedFiles) => {
    if (!acceptedFiles.length) return;
    setFile(acceptedFiles[0]);
    if (props.setFile) {
      props.setFile(acceptedFiles[0]);
    }
    props.setCsvFileName(acceptedFiles[0].name);
    props.setLoadingFile(true);
    Papa.parse(acceptedFiles[0], {
      header: true,
      complete: (result) => {
        props.setLoadingFile(false);
        const csvData = {
          fields: result.meta.fields,
          data: result.data,
        };
        props.setCsvData(csvData);
      },
    });
  };

  return (
    <>
      {props.loadingFile && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!props.loadingFile && (
        <Dropzone
          onDrop={(acceptedFiles) => handlePapa(acceptedFiles)}
          multiple={false}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onDropRejected={() => {
            alert("Please a drop CSV file only.");
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <div
                style={dropzone}
                {...getRootProps({ className: "dropzone" })}
              >
                <input {...getInputProps()} />
                <div style={{ textAlign: "center" }}>
                  <h4>Upload Schema From CSV</h4>
                  <p>
                    Drag 'n' drop your CSV file here, or click to select CSV
                    file
                  </p>
                  <em
                    className="icon ni ni-upload-cloud"
                    style={{ fontSize: 70 }}
                  ></em>{" "}
                </div>
              </div>
            </>
          )}
        </Dropzone>
      )}
    </>
  );
}

export default MyDropzone;
