import { useSnackbar } from "notistack"

export const useSnackBar = () => {
    const { enqueueSnackbar, } = useSnackbar()

    const successSnackBar = (msg) => {
        enqueueSnackbar(msg, { variant: "success" })
    }
    const errorSnackBar = (msg = "Something went wrong please try later") => {
        enqueueSnackbar(msg, { variant: "error" })
    }
    const infoSnackBar = (msg) => {
        enqueueSnackbar(msg, { variant: "info" })
    }
    return { successSnackBar, errorSnackBar, infoSnackBar }
}