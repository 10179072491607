import React, { useEffect, useRef, useState } from "react";

import "../../assets/css/dashlite.css";
import "../../assets/css/theme.css";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Grid, Container, Modal, TextField, Fab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CreateFolder from "./CreateFolder";

import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { html } from "../Html";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import FileButton from "../../components/FileButton";
import { FolderButton } from "./FolderButton";
import "../homepage.css";
import PrepareSend from "./PrepareSend";
import axios from "axios";
import { getHeaders } from "../../utils";
import { BASE_URL } from "../../constants/apiconstant";
import { FolderList } from "./FolderList";
import { useSnackbar } from "notistack";
import MoveInFolder from "./MoveFolderModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowBack } from "@material-ui/icons";
import { removeEsignDOcuments } from "../../actions/assetsAction";
import RenameFile from "./RenameFile";
import DeviceAndLocationModal from "./DeviceAndLocationModal";

const EsignFolderFiles = () => {
  const [showCreateFolderModal, setShowClearFolderModal] = useState(false);
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const { innerWidth, innerHeight } = window;
  const history = useHistory();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: innerWidth > 600 ? 500 : "100%",

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto !important",
      top: "50%",
      fontFamily: "Nunito, sans-serif !important",
    },
    multilineColor: {
      color: "white",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    rooot: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fieldroot: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const classes = useStyles();
  const [showInfoModel, setShowInfoModel] = useState(false);
  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [completedDocuments, setCompletedDocuments] = useState([]);
  const [uncompletedDocuments, setUnCompletedDocuments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;
  const [fileToMoveId, setFileToMoveId] = useState([]);
  const [showMoveInFolderModal, setShowMoveInFolderModal] = useState(false);
  const [bodyWidth, setBodyWidth] = React.useState(false);
  const [folderClicked, setFolderClicked] = useState(null);
  const [folderFilesGetLoading, setFolderFilesGetLoading] = useState(false);
  const [folderFiles, setFolderFiles] = useState([]);

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [originamFilename, setOriginalFilname] = useState("");
  const [showMoveButton, setShowMoveButton] = useState(false);

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };
  useEffect(() => {
    getFilesByFolderId(localStorage.getItem("id"));
    getFolders();
  }, []);

  const getFilesByFolderId = async (_id) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        BASE_URL + "get-files-by-folder-id?folderId=" + _id,
        getHeaders()
      );
      console.log("folder files", data);
      setFolderFiles(data.data);
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }

    setLoading(false);
  };

  const onMenuItemClick = async (type, id, documentType, _originalFilename) => {
    if (type === "delete") {
      await removeEsignDOcuments(id);
      getFilesByFolderId(localStorage.getItem("id"));
      return;
    }
    if (type === "move") {
      console.log("move click");
      setShowMoveButton(true);
      return;
    }
    if (type === "info") {
      setShowInfoModel(id);
      return;
    }
    if (type === "rename") {
      setOriginalFilname(_originalFilename);
      setShowRenameModal(id);
    }
  };
  const onRenamed = (created) => {
    folderFiles((prev) =>
      prev.data.map((item) =>
        item._id === showRenameModal
          ? { ...item, originalFilename: created.originalFilename }
          : item
      )
    );
  };
  const onMoved = () => {
    getFilesByFolderId(localStorage.getItem("id"));
  };
  const onCheckClick = (_id) => {
    if (fileToMoveId.indexOf(_id) > -1) {
      setFileToMoveId((prev) => prev.filter((id) => id !== _id));
    } else {
      setFileToMoveId([...fileToMoveId, _id]);
    }
  };
  console.log("show move ", showMoveButton);
  const getFolders = async () => {
    try {
      const { data } = await axios.get(
        BASE_URL + "getEsignFolders",
        getHeaders()
      );

      setFolders(data.data);
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        {
          variant: "error",
        }
      );
    }
  };
  console.log("erfg", fileToMoveId.length > 0 && folders.length > 0);
  return (
    <>
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />
              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div
                      className="nk-fmg"
                      style={bodyWidth ? { paddingLeft: 80 } : null}
                    >
                      <Sidebar page="shared" getState={sidebarState} />
                      {/* .nk-fmg-aside */}
                      {loading ? (
                        <div className={classes.rooot}>
                          <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
                        </div>
                      ) : (
                        <div className="nk-fmg-body">
                          <div className="nk-fmg-body-content">
                            <div className="nk-block-head nk-block-head-sm">
                              <div className="nk-block-between position-relative">
                                <div className="nk-block-head-content">
                                  <h3 className="nk-block-title page-title">
                                    <ArrowBack
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        history.push("/eSign-documents")
                                      }
                                    />
                                  </h3>
                                </div>
                                {fileToMoveId.length > 0 && folders.length > 0 && (
                                  <a
                                    style={{
                                      background: " rgb(20, 155, 227)",
                                      color: "white",
                                      marginLeft: "1rem",
                                    }}
                                    onClick={() =>
                                      setShowMoveInFolderModal(true)
                                    }
                                    className="btn btn-light"
                                    data-toggle="dropdown"
                                  >
                                    <span>Move</span>
                                  </a>
                                )}

                                <div
                                  className="search-wrap px-2 d-lg-none"
                                  data-search="search"
                                >
                                  <div className="search-content">
                                    <a
                                      href="#"
                                      className="search-back btn btn-icon toggle-search"
                                      data-target="search"
                                    >
                                      <em className="icon ni ni-arrow-left" />
                                    </a>
                                    <input
                                      type="text"
                                      className="form-control border-transparent form-focus-none"
                                      placeholder="Search by user or message"
                                    />
                                    <button className="search-submit btn btn-icon">
                                      <em className="icon ni ni-search" />
                                    </button>
                                  </div>
                                </div>
                                {/* .search-wrap */}
                              </div>
                            </div>

                            <div className="nk-fmg-listing nk-block">
                              <div
                                className="toggle-expand-content expanded"
                                data-content="recent-files"
                              >
                                <div className="nk-files nk-files-view-group">
                                  <div className="nk-files-group">
                                    <h6 className="title">
                                      Files for{" "}
                                      {localStorage.getItem("foldername")}
                                    </h6>
                                    <div className="nk-files-list">
                                      {folderFiles.length === 0 && (
                                        <h5
                                          style={{
                                            marginLeft: "0.5rem",
                                            marginTop: "1rem",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          Please move a document here to begin
                                          populating this folder
                                        </h5>
                                      )}
                                      {folderFiles.map((document, index) => (
                                        <FolderButton
                                          showCheckMark={showMoveButton}
                                          onCheckClick={() =>
                                            onCheckClick(document._id)
                                          }
                                          onMenuItemClick={(type) =>
                                            onMenuItemClick(
                                              type,
                                              document._id,
                                              "completed",
                                              document.originalFilename
                                            )
                                          }
                                          extraText={
                                            document.isEditing
                                              ? "Uncompleted Document"
                                              : document.isComplete
                                              ? "Completed Document"
                                              : "Pending Document"
                                          }
                                          showCheckMark={showMoveButton}
                                          fileToMoveId={fileToMoveId}
                                          _id={document._id}
                                          onClick={() => {
                                            if (!document.isComplete)
                                              history.push("/esign-pdf", {
                                                fileName: document.filename,
                                                fileUrl:document.fileUrl
                                              });
                                            else {
                                              localStorage.setItem(
                                                "pdfviewerFileName",
                                                document.filename
                                              );
                                              localStorage.setItem(
                                                "pdfviewerFileUrl",
                                                document.fileUrl
                                              );
                                              localStorage.setItem(
                                                "pdfViewerNodes",
                                                document.nodes
                                              );
                                              history.push(
                                                "/view-signed-documents"
                                              );
                                            }
                                          }}
                                          originalFilename={
                                            document.originalFilename
                                          }
                                          updated_at={document.updated_at}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                {/* .nk-files */}
                              </div>
                            </div>

                            {/* .nk-block */}
                          </div>
                          {/* .nk-fmg-body-content */}
                        </div>
                      )}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showMoveInFolderModal && (
          <MoveInFolder
            onMoved={onMoved}
            onClose={() => setShowMoveInFolderModal(false)}
            folders={folders}
            showMoveToRootFolder={true}
            fileToMoveId={fileToMoveId}
          />
        )}
        {showRenameModal && (
          <RenameFile
            existingName={originamFilename}
            id={showRenameModal}
            onCreated={(created) => onRenamed(created)}
            onClose={() => setShowRenameModal(false)}
          />
        )}
        {showInfoModel && (
          <DeviceAndLocationModal
            documentId={showInfoModel}
            onClose={() => setShowInfoModel(false)}
          />
        )}
      </div>
    </>
  );
};

export default EsignFolderFiles;
