import React from "react";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {Folder} from "@material-ui/icons";

export const FolderList = ({foldername, onClick, onDeleteClick}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  return (
    <div onClick={onClick} className="nk-file-item nk-file">
      <div className="nk-file-info">
        <div className="nk-file-title">
          <div className="nk-file-icon">
            <span className="nk-file-icon-type">
              <Folder style={{color: "rgb(20, 155, 227)"}}/>
            </span>
          </div>
          <div onClick={onClick} className="nk-file-name">
            <div className="nk-file-name-text">{foldername}</div>
          </div>
        </div>
        {/*  <ul className='nk-file-desc'>
                    <li className='date'>
                        Last updated: {updated_at ? moment(updated_at).fromNow() : ""}
                    </li>
                </ul> */}
      </div>
      <div className="nk-file-actions">
        <div className="dropdown">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreHorizIcon style={{color: "grey"}}/>
          </IconButton>
          <Menu
            elevation={0}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(null);
                onDeleteClick();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};
