import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactTimeAgo from "react-time-ago";
import FolderMenu from "./Menu";
import moment from "moment";
import { PictureAsPdf } from "@material-ui/icons";

export const FolderButton = ({
  originalFilename,
  _id,

  updated_at,
  onClick,
  onCheckClick,
  fileToMoveId,
  showCheckMark = true,
  showMoveButton = true,
  extraText = null,
  onMenuItemClick = () => {},
}) => {
  return (
    <div
      onClick={() => {
        onClick();
      }}
      className="nk-file-item nk-file"
    >
      <div className="nk-file-info">
        <div className="nk-file-title">
          {showCheckMark && (
            <input
              checked={fileToMoveId.indexOf(_id) > -1}
              onClick={(e) => {
                e.stopPropagation();
                onCheckClick();
              }}
              style={{ marginRight: "0.5rem" }}
              type="checkbox"
            />
          )}
          <div className="nk-file-icon">
            <span className="nk-file-icon-type">
              <PictureAsPdf style={{ color: "rgb(20, 155, 227)" }} />
            </span>
          </div>
          <Link>
            <div className="nk-file-name">
              <div className="nk-file-name-text">{originalFilename}</div>
            </div>
          </Link>
        </div>
        {extraText && (
          <ul className="nk-file-desc">
            <li className="date">{extraText}</li>
          </ul>
        )}

        <ul className="nk-file-desc">
          <li className="date">
            Last updated: {updated_at ? moment(updated_at).fromNow() : ""}
          </li>
        </ul>
      </div>
      <div className="nk-file-actions">
        <div className="dropdown">
          <FolderMenu
            showMoveButton={showMoveButton}
            onClick={onMenuItemClick}
          />
        </div>
      </div>
    </div>
  );
};
