import React, { useEffect, useRef, useState } from "react";

import "../../assets/css/dashlite.css";
import "../../assets/css/theme.css";
import "../../assets/css/custom-shared-docs.css";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Grid, Container, Modal, TextField, Fab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CreateFolder from "./CreateFolder";

import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { html } from "../Html";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import FileButton from "../../components/FileButton";
import "../homepage.css";
import axios from "axios";
import { getHeaders } from "../../utils";
import { BASE_URL } from "../../constants/apiconstant";
import { useSnackbar } from "notistack";
import Paginator from "../../components/Pagination";
import { removeEsignDOcuments } from "../../actions/assetsAction";
import RenameFile from "./RenameFile";
import Moment from "moment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

const UploadFiles = () => {
  const [modalStyle] = useState(getModalStyle);
  const { innerWidth, innerHeight } = window;
  const history = useHistory();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      borderRadius: "11px",
    };
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: innerWidth > 600 ? 500 : "100%",

      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto !important",
      top: "50%",
      fontFamily: "Nunito, sans-serif !important",
    },
    multilineColor: {
      color: "white",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    flex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "1rem",
      flex: 1,
    },
    rooot: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fieldroot: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [bodyWidth, setBodyWidth] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [showPrimaryAlert, setShowPrimaryAlert] = useState(true);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [totalBytes, setTotalBytes] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };
  useEffect(() => {
    getContacts(1, 20, "DESC", "submitted_date");
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async (new_files) => {
    console.log("New Files==>", new_files);
    console.log("Old Files==>", files);
    var new_file_byte_count = 0;
    var isAllFilesAdded = false;

    for (var i = 0; i < new_files.length; i++) {
      new_file_byte_count = new_file_byte_count + new_files[i].size;
      console.log("new_file_byte_count==>", new_file_byte_count);
      var newTotalBytes = totalBytes + new_file_byte_count;
      console.log("newTotalBytes==>", newTotalBytes);
      if (new_files[i].size <= 1000000000) {
        setFiles((oldArray) => [...oldArray, new_files[i]]);
        setTotalBytes(newTotalBytes);
        isAllFilesAdded = true;
      } else {
        enqueueSnackbar("Error: File too large:" + "" + new_files[i].name, { variant: "error" });
      }
    }
  };

  const handleRemoveItem = (e) => {
    console.log("Inside handleRemoveItem==>", e.target);
    const file_id = e.target.getAttribute("id");
    const id = file_id.split(/[_]+/);
    const file_size = id[id.length - 1];
    console.log("file_size==>", file_size);
    const name = e.target.getAttribute("name");
    console.log("File Name==>", name);
    setFiles(files.filter((item) => item.name !== name));
    console.log("PrevTotalBytes==>", totalBytes);
    var newTotalBytes = totalBytes - file_size;
    console.log("newTotalBytes==>", newTotalBytes);
    setTotalBytes(newTotalBytes);
  };

  // const handleRemove = async (file) => {
  //   console.log("New ==>", file)
  //   var new_file_byte_count = 0;
  //   var newTotalBytes = totalBytes+new_file_byte_count;

  //   for(var i=0;i<new_files.length;i++) {
  //     setFiles(oldArray => [...oldArray, new_files[i]]);
  //     new_file_byte_count = new_file_byte_count+new_files[i].size;
  //     console.log("new_file_byte_count==>", new_file_byte_count)
  //     var newTotalBytes = totalBytes+new_file_byte_count;
  //     console.log("newTotalBytes==>", newTotalBytes)
  //     setTotalBytes(newTotalBytes);
  //   }
  // }

  const handleSubmit = async () => {
    console.log("Inside handleSubmit");
    setIsUploading(true);
    if (files && files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        console.log("File is Uploading==>", i);
        const data = new FormData();
        files[i].id = "";
        // const file_name = files[i].name.split('.').slice(0, -1).join('.');
        data.append("file", files[i]);
        console.log("Form Data Appended==>", i);
        await axios.post(BASE_URL + "createSharedFiles/", data, getHeaders()).then((response) => {
          console.log("handleSubmit response " + i + "=>", response);
          files[i].id = response.data.id;
        });
        console.log("API Called==>", i);
      }
      setLoader(false);
      console.log("Final Fiels Array==>", files);
      history.push("/upload-process-form", {
        files: files,
      });
    } else {
      enqueueSnackbar("You must select at least one file to upload.", { variant: "error" });
    }
    setIsUploading(false);
  };

  const getContacts = async (_page, _rowsPerPage, sortOrder, sortColumn, searchText = "") => {
    try {
      console.log("getContacts Try");
      let url =
        BASE_URL +
        `    getContactsForFileSharing?page=${_page}&rowsPerPage=${_rowsPerPage}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`;
      const { data } = await axios.get(url, getHeaders());
      console.log("getContacts data==>", data);
      if (!data) {
        setShowWarningAlert(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
        <div className="nk-app-root">
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/* main header @s */}
              <Navbar />
              {/* main header @e */}

              <div className="nk-content p-0">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-fmg" style={bodyWidth ? { paddingLeft: 80 } : null}>
                      <Sidebar page="file-sharing" getState={sidebarState} />

                      <div className="nk-fmg-body">
                        <div className="nk-fmg-body-content">
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between position-relative">
                              <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Upload Files</h3>
                              </div>
                              <div className="nk-block-head-content">
                                <div className="nk-fmg-actions">
                                  <ul className="nk-block-tools g-3">
                                    <li>
                                      <button
                                        onClick={history.goBack}
                                        className="btn btn-secondary"
                                        data-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-arrow-left" /> <span>Back</span>
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* .nk-block-head */}
                          <Stack sx={{ width: "100%" }} spacing={2}>
                            {showWarningAlert && (
                              <Alert severity="warning" style={{ border: "1px solid #faebcc", fontSize: ".9em" }}>
                                <strong>Important:</strong> There are no clients or groups at the moment. You can still
                                upload files and assign them later.
                              </Alert>
                            )}
                            {showPrimaryAlert && (
                              <Alert severity="info" style={{ border: "1px solid #bce8f1", fontSize: ".9em" }}>
                                Click on Add files to select all the files that you want to upload, and then click
                                continue. On the next step, you will be able to set a name and description for each
                                uploaded file. Remember that the maximum allowed file size (in mb.) is{" "}
                                <strong>1000</strong>
                              </Alert>
                            )}
                          </Stack>
                          <div className="datatable-wrap my-3" style={{ backgroundColor: "white" }}>
                            <table style={{ width: "100%", height: "300px" }}>
                              <thead style={{ backgroundColor: "#e2e5e9" }}>
                                <tr className="nk-tb-item nk-tb-head">
                                  <th
                                    className="tb-col-mb sorting"
                                    style={{ color: "#42454A", fontSize: ".9em", padding: "8px 8px" }}
                                    colSpan="3"
                                  >
                                    <span>Filename</span>
                                  </th>
                                  <th
                                    className="tb-col-mb sorting"
                                    style={{ color: "#42454A", fontSize: ".9em" }}
                                    colSpan="1"
                                  >
                                    <span>Size</span>
                                  </th>
                                  <th
                                    className="tb-col-mb sorting"
                                    style={{ color: "#42454A", fontSize: ".9em", textAlign: "center" }}
                                    colSpan="1"
                                  >
                                    <span>Status</span>
                                  </th>
                                </tr>
                              </thead>
                              <Dropzone onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) =>
                                  files && files.length > 0 ? (
                                    <tbody {...getRootProps()} style={{ verticalAlign: "-webkit-baseline-middle" }}>
                                      {files.map((file, index) => (
                                        <tr>
                                          <td colSpan="3" style={{ paddingLeft: "8px" }}>
                                            {file.name}
                                          </td>
                                          <td colSpan="1">{file.size}b</td>
                                          <td colSpan="1" style={{ textAlign: "center" }}>
                                            0%{" "}
                                            <span style={{ marginLeft: "3px", verticalAlign: "middle" }}>
                                              <span
                                                name={file.name}
                                                id={index + "_" + file.size}
                                                className="icon ni ni-minus-circle-fill"
                                                style={{ color: "#d34141", fontSize: "15px", cursor: "pointer" }}
                                                onClick={!isUploading ? handleRemoveItem : ""}
                                              ></span>
                                            </span>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : (
                                    <tbody {...getRootProps()}>
                                      <tr className="nk-tb-item odd">
                                        <td
                                          className="nk-tb-col"
                                          colSpan="5"
                                          style={{ textAlign: "center", height: "100%" }}
                                        >
                                          <span style={{ color: "#000" }}>Drag files here.</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                }
                              </Dropzone>
                              <tfoot
                                style={{ backgroundColor: "#e2e5e9", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                              >
                                <tr className="nk-tb-item nk-tb-foot">
                                  <th
                                    style={{
                                      paddingTop: "0.5rem",
                                      paddingBottom: "0.5rem",
                                      fontSize: ".9em",
                                      paddingLeft: "0.5rem",
                                    }}
                                    colSpan="3"
                                  >
                                    <Dropzone
                                      className="drp_zone"
                                      onDrop={(acceptedFiles1) => handleUpload(acceptedFiles1)}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <button className="btn btn-primary" style={{backgroundColor:"rgb(20, 155, 227)"}} {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          Add Files
                                        </button>
                                      )}
                                    </Dropzone>
                                  </th>
                                  <th
                                    style={{
                                      paddingTop: "0.5rem",
                                      paddingBottom: "0.5rem",
                                      fontSize: ".9em",
                                    }}
                                    colSpan="1"
                                  >
                                    {totalBytes}b
                                  </th>
                                  <th
                                    style={{
                                      paddingTop: "0.5rem",
                                      paddingBottom: "0.5rem",
                                      fontSize: ".9em",
                                      textAlign: "center",
                                    }}
                                    colSpan="1"
                                  >
                                    0%
                                  </th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div className="container" style={{ textAlign: "center" }}>
                            {files && files.length > 0 && !isUploading ? (
                              <button className="btn btn-primary" style={{backgroundColor: "rgb(20, 155, 227)"}} onClick={() => handleSubmit()}>
                                Upload Files
                              </button>
                            ) : !isUploading ? (
                              <button className="btn btn-primary" style={{backgroundColor: "rgb(20, 155, 227)"}} disabled>
                                Upload Files
                              </button>
                            ) : (
                              <button className="btn btn-primary" style={{backgroundColor: "rgb(20, 155, 227)"}} disabled>
                                Uploading
                              </button>
                            )}
                          </div>
                        </div>
                        {/* .nk-fmg-body-content */}
                      </div>
                      {/* .nk-fmg-body */}
                    </div>
                    {/* .nk-fmg */}
                  </div>
                </div>
              </div>
            </div>
            {/* content @e */}
          </div>
          {/* wrap @e */}
        </div>
      </div>
    </>
  );
};

export default UploadFiles;
