import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { ListWithCard } from '../components/ListWithCard'
import { Layout } from '../components/Layout'
import { PageHeader } from '../components/PageHeader'
import { AddorUpdateModal } from '../components/AddorUpdateModal'

import '../../../assets/css/dashlite.css'
import '../../../assets/css/theme.css'
import { useSnackBar } from '../utils'
import { getApiKey, getDbCred } from '../api'
import ConfigDetails from '../components/ConfigDetails'
import axios from 'axios'
import { BASE_URL } from '../../../constants/apiconstant'
import { getHeaders } from '../../../utils'

export const Configuration = () => {
  const [modalData, setModalData] = useState(null)
  const [modal, setModal] = useState(null)
  const [loading, setLoading] = useState(true)
  const [apikey, setApikey] = useState(null)
  const [apikeyGenerateLoading, setApiKeyGenerateLoading] = useState(false)

  const [dbCreds, setDbCreds] = useState([])

  useEffect(() => {
    getInitialData()
  }, [])

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const userId = userInfo.result._id
  const { errorSnackBar, successSnackBar } = useSnackBar()
  const getInitialData = async () => {
    try {
      await getApiKey(userId, setApikey, errorSnackBar)
      await getDbCred(userId, setDbCreds, errorSnackBar)
    } catch (error) {
      console.log('Error db cred', error)
      errorSnackBar('Something went wrong , please try again later')
    }
    setLoading(false)
  }

  const generateAPiKey = async () => {
    setApiKeyGenerateLoading(true)
    try {
      const { data } = await axios.post(
        BASE_URL + 'generate-api-key-for-user/',
        { user_id_or_company_id: userId },
        getHeaders()
      )
      if (data.success === 'false' || data.success === false) {
        errorSnackBar(data.message)
      } else {
        setApikey(data.apikey)
      }
    } catch (error) {
      errorSnackBar()
    }
    setApiKeyGenerateLoading(false)
  }

  const onEditClicked = async (menuItem, menuIndex, cardIndex) => {
    if (menuIndex === 0) {
      setModalData({ ...dbCreds[cardIndex] })
      console.log('{ ...dbCreds[index] }', cardIndex, { ...dbCreds[cardIndex] })
      setModal(true)
    } else {
      const dbCredId = dbCreds[cardIndex]._id

      try {
        const { data } = await axios.delete(
          BASE_URL + 'delete-db-cred?id=' + dbCredId,
          getHeaders()
        )
        if (data.success == 'false' || data.success == false) {
          errorSnackBar(data.message)
        } else {
          dbCreds.splice(cardIndex, 1)
          setDbCreds([...dbCreds])
          successSnackBar('Db cred has been deleted')
        }
      } catch (error) {
        errorSnackBar()
      }
    }
  }
  const formatDBCreds = (data) => {
    return data.map((item) => {
      return {
        title: item.configName,
        subtitle: <ConfigDetails mainText="View Details" configData={item} />,
        menuArray: ['Edit', 'Delete'],
      }
    })
  }
  const handleApiKeyMenuItemClick = (menuItem, index) => {
    console.log('menuitem', menuItem, index)
    if (index === 0) {
      generateAPiKey()
    } else {
      navigator.clipboard.writeText(apikey)
    }
  }
  const onDbCedAdded = (data, type, _id) => {
    if (type === 'update') {
      let dbCredsWithUpdatedData = dbCreds.map((dbCred) => {
        if (dbCred._id === _id) return data
        return dbCred
      })
      setDbCreds([...dbCredsWithUpdatedData])
    } else {
      dbCreds.push(data)
      setDbCreds([...dbCreds])
    }
  }
  return (
    <>
      <Layout
        loading={loading}
        pageHeader={
          <PageHeader
            pageTitle="API Portal"
            navItems={[
              {
                text: 'Add',
                onClick: () => {
                  if (dbCreds.length >= 5) {
                    errorSnackBar('You can have maximum 5 Db Creds')
                  } else {
                    setModal(true)
                  }
                },
              },
            ]}
          />
        }
        content={
          <>
          {/*   <ListWithCard
              listTitle="Api key"
              cardContainerStyle={{
                minWidth: 'calc(20% - 16px)',
                width: 'auto',
              }}
              loading={apikeyGenerateLoading}
              onListItemClick={() => {
                if (!apikey) generateAPiKey()
              }}
              onMenuItemClick={handleApiKeyMenuItemClick}
              subtitleStyle={{ color: "white" }}
              cardArray={[
                {
                  title: apikey ? apikey : 'Click here to generate api key',
                  subtitle: "'",
                  menuArray: apikey ? ['Regenerate', 'Copy'] : [],
                },
              ]}
            /> */}
            <ListWithCard
              listTitle="DB Credentials"
              subtitleData={dbCreds}
              onMenuItemClick={onEditClicked}
              cardArray={
                dbCreds.length > 0
                  ? formatDBCreds(dbCreds)
                  : [
                    {
                      title:
                        'No  Db Config found, please create one by clicking on add button above',
                    },
                  ]
              }
            />
          </>
        }
      />
      {modal && (
        <AddorUpdateModal
          modalData={modalData}
          updateConfig={onDbCedAdded}
          onClose={() => {
            setModal(null)
            setModalData(null)
          }}
        />
      )}
    </>
  )
}
