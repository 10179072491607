import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import "./../App.css";
import Editor from "react-medium-editor";
import { useReactToPrint } from "react-to-print";
import { Fab } from "@material-ui/core";

import PrintIcon from "@material-ui/icons/Print";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";
import FontNameForm from "../medium-ext/FontName";
import FontSizeForm from "../medium-ext/FontSize";
import { useDispatch, useSelector } from "react-redux";
import { FileUpdate } from "../actions/assetsAction";
import axios from "axios";
require("medium-editor/dist/css/medium-editor.css");
require("medium-editor/dist/css/themes/default.css");

const EditPage = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showhtml, setShowhtml] = useState("");
  console.log("match ", match);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data } = await axios.get(
      "https://sign.kycaml.systems/file/" + match.params.id
    );
    setShowhtml(data.html);
    console.log("dataaa", data);
  };
  const handleEditorChange = (text) => {
    setShowhtml(text);
  };
  const handleSave = () => {
    const html = document.querySelectorAll("samp")[0].innerHTML;
    const fileid = match.params.id;
    console.log("file idd", fileid);
    dispatch(FileUpdate(fileid, html));
    localStorage.removeItem("htmlData");
    history.push("/document-hub");
  };

  return (
    <div>
      <Editor
        ref={componentRef}
        tag="samp"
        text={showhtml}
        onChange={handleEditorChange}
        options={{
          imageDragging: true,
          placeholder: false,
          anchorPreview: true,
          toolbar: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "fontname",
              "fontsize",
            ],
            static: true,
            sticky: true,
            stickyTopOffset: 0,
          },
          extensions: {
            fontname: new FontNameForm(),
            fontsize: new FontSizeForm(),
          },
        }}
      />
      {/* {daf?.length <= 0 ? (
        <>
        </>
      ) : (
        <>
          <Draggable>
            <img src={daf} style={{ position: 'absolute' }}></img>
          </Draggable>
        </>
      )}{' '} */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          bottom: 20,
          right: 32,
        }}
      >
        <Fab style={{ marginTop: 10, backgroundColor: "#000" }} color="primary">
          <SaveIcon onClick={handleSave} />
        </Fab>

        <Fab
          style={{ marginTop: 10, backgroundColor: "#000" }}
          color="primary"
          onClick={handlePrint}
        >
          <PrintIcon />
        </Fab>
      </div>
    </div>
  );
};

export default EditPage;
