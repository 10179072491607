export const getRemainingFieldCount = (nodesArray, emailFor) => {
  console.log("[getRemainingFieldCount]-nodesArray", nodesArray);
  console.log("[getRemainingFieldCount]-emailFor", emailFor);
  let remainingField = 0;
  let remainingFieldArray = [];

  nodesArray.forEach((node) => {
    if (!node.required && node.fieldType !=='sign' && node.fieldType !=='initial') {
      node.isComplete=true;
    }
    if (node.assigned === emailFor && !node.isComplete) {
      remainingField = remainingField + 1;
      remainingFieldArray.push(node.fieldAlias);
    }
  });
  return { remainingFieldCount: remainingField, remainingFieldArray };
};
