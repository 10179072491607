import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getHeaders } from "../../utils";

import { CircularProgress, Fab, FormControlLabel } from "@material-ui/core";

import { BASE_URL } from "../../constants/apiconstant";
import { useDispatch } from "react-redux";
import { USER_LOGIN_SUCCESS } from "../../constants/userConstants";

export default function SaveDeviceAndLocationInfoForFIleSharing() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getDataFromToken();
  }, []);
  const getDataFromToken = async () => {
    try {
      const token = new URLSearchParams(window.location.search).get("token");
      console.log("wiwnw", window.location.href, localStorage.getItem("token"));
      console.log("tottt", token);
      const response = await axios.get(
        BASE_URL + "saveUserDeviceAndLocationInfoForFileSharing?token=" + token,
        getHeaders()
      );

      const message = response.data.message
      if (message == "sendToSignup") {
        history.push("/signup")
        return
      }
      
      const data = response.data.data;
      console.log("Ddd", data, response.data);
      localStorage.setItem("userRole", data.role);
      localStorage.setItem("userInfo", JSON.stringify({ result: data }));
      localStorage.setItem("token", data.token);
      localStorage.setItem("isNormalUser", data?.role == "member");
      localStorage.setItem("expiry", new Date(new Date().getTime() + 24 * 60 * 60 * 1000).valueOf());
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: { result: data },
      });
      setTimeout(() => {
        history.push("/file-sharing");
      }, 1000);
    } catch (error) {
      console.log("errr", error);
      enqueueSnackbar("The token has expired, please contact your admin to send the file again", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/>
    </div>
  );
}
