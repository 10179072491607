import React, { useState } from 'react';
import { Button, Input, Typography } from '@mui/material';

const ImageUploader = (props) => {
  const { setImagePath, imagePath, preview=false, upload, ...other} = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(file);
        setImageUrl(e.target.result);
        console.log("[handleImageChange]-result", imageUrl)
        upload.current = e.target.result;
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
        <Input type="file" onChange={handleImageChange} />
        &nbsp;
        <Typography>Preview:</Typography>
        {imageUrl && <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '50%' }} />}
    </div>
  );
};

export default ImageUploader;
