import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  TextField,
  useTheme,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../constants/apiconstant";
import { getHeaders } from "../../utils";

export default function RenameFile({ onClose, onCreated, existingName, id }) {
  const [modalStyle] = useState(getModalStyle);
  const [foldername, setFoldername] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const { innerWidth, innerHeight } = window;
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    setFileName(existingName);
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  function getModalStyle() {
    const { innerWidth, innerHeight } = window;

    let top = 50,
      left = innerWidth < 600 ? 0 : 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${left}%, -${top}%)`,
      borderRadius: "11px",
    };
  }
  const renameFile = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        BASE_URL + "rename-esign-file/",
        { id, fileName },
        getHeaders()
      );
      enqueueSnackbar("File has been renamed", { variant: "success" });
      console.log("data", data);
      onCreated(data.data);
      setIsCreated(true);
      onClose();
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong, please try again after some time",
        { variant: "error" }
      );
    }
    setLoading(false);
  };

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="simple-modal-title">
      <div
        style={{
          position: "absolute",
          width: innerWidth > 600 ? 500 : "100%",

          backgroundColor: theme.palette.background.paper,
          // border: '2px solid #000',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          maxHeight: "calc(100vh - 100px)",
          overflow: "auto !important",
          top: "50%",
          ...modalStyle,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5 id="simple-modal-title">Rename File</h5>
          <IconButton
            onClick={onClose}
            color="inherit"
            aria-label="open drawer"
            style={{ marginTop: -10, marginBottom: 10 }}
          >
            <ClearIcon style={{ color: "grey" }} />
          </IconButton>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              style={{ color: "black", width: "100%", marginTop: 9 }}
              id="outlined-basic"
              size="small"
              label="File Name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: 13,
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={renameFile}
            disabled={fileName === "" || isCreated}
          >
            <div>{loading ? <CircularProgress style={{color:"rgb(20, 155, 227)"}} size={20} /> : "Save"} </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
