import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Print } from "@material-ui/icons";
import LeftSidebarImage from "../Shared/LeftSidebarImage";
import { Fab } from "@material-ui/core";
import Swal from "sweetalert2";
import { printPdf, renderPdf } from "../Shared/utils";

export default function ViewFile() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const canvasArrayRef = useRef([]);
  const [canvasArray, setCanvasArray] = useState([]);
  const pdfRef = useRef(null);
  let fileUrl;
  let fileName;
  let contentType;

  fileUrl = localStorage.getItem("viewerFileUrl");
  fileName = localStorage.getItem("viewerFileName");
  contentType = localStorage.getItem("contentType");

  useEffect(() => {
    if (contentType === "pdf") {
      initializePdf();
    } else {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "Getting File",
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [loading]);
  const initializePdf = async () => {
    try {
      const result = await renderPdf(fileUrl);
      canvasArrayRef.current = result.canvasArray;
      setCanvasArray(canvasArrayRef.current);

      pdfRef.current = result.pdf;
    } catch (error) {
      console.log("errr", error);
      enqueueSnackbar("Something went wrong, please try again later", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <div>
      {contentType === "pdf" && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "fixed",
              bottom: 20,
              right: 32,
              height: "auto",
              zIndex: 90999999,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "fixed",
                bottom: 20,
                right: 32,
                height: "auto",
                zIndex: 90999999,
              }}
            >
              <Fab
                onClick={() => printPdf(canvasArrayRef.current)}
                style={{ marginTop: 10, backgroundColor: "#000" }}
                color="primary"
              >
                <Print />
              </Fab>
            </div>
          </div>
          <div id="abcdef">
            {/*   <Header nodes={() => nodesRef.current} loading={loading} data={data} /> */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                marginTop: "13px",
                zIndex: loading ? 0 : 9999,
                marginLeft: "3rem",
              }}
            >
              <LeftSidebarImage canvasArray={canvasArray} />
            </div>
            <div
              style={{
                backgroundColor: "#9e9e9e",
                backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=")`,

                bottom: "0",
                right: "0",
                overflow: "auto",
                position: "absolute",
                top: "0",
                left: "0",
                margin: "0",
                border: "0",

                zIndex: loading ? 0 : 999,
              }}
            >
              <div id="pdf-viewer"></div>
            </div>
          </div>
          <div id="sign-pad-image"></div>
        </div>
      )}
      {(contentType === "jpg" || contentType === "png" || contentType === "jpeg") && (
        <div style={{height: "100%", display: "flex", justifyContent: "center", maxHeight: "100%"}}>
          <img
            src={fileUrl}
            alt="Image"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      )}
      {contentType === "mp3" && (
        <audio controls>
          <source src={fileUrl} type={"audio/ogg"} />
          Your browser does not support the audio element.
        </audio>
      )}
      {contentType === "svg" && (
        <object data={fileUrl} type="image/svg+xml">
          Your browser does not support SVG.
        </object>
      )}
    </div>
  );
}
