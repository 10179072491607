import { ArrowBack } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutuser } from "../actions/userActions";
import logo from "./../assets/images/logo.jpeg";
import "./navbar.css";
const Navbar = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logoutuser());
  };

  return (
    <div className="nk-header nk-header-fixed is-light" style={{ paddingTop: 7, paddingBottom: 7 }}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu">
              <em className="icon ni ni-menu" />
            </a>
          </div>
          <div className="nk-header-app-name">
            <div className="nk-header-app-logo" style={{ width: 48, height: 48 }}>
              <img src={logo} />
            </div>
            {/*  <div className='nk-header-app-info'>
              <span className='sub-text'>PDF Editor</span>
              <span className='lead-text'>Ahrvo</span>
            </div> */}
          </div>
          <div className="nk-header-tools" style={{ display: "flex", alignItems: "center" }}>
            {userInfo.result.companyId && (
              <div
                className="step2"
                style={{ cursor: "pointer", marginRight: "1rem" }}
                onClick={() => {
                  const mainAppUrl = localStorage.getItem("mainAppUrl");
                  window.location.href = mainAppUrl ? mainAppUrl : "https://kycaml.systems";
                }}
              >
                <em className="icon ni ni-signout" />
                <span>Back To Main App</span>
              </div>
            )}
            <div style={{ cursor: "pointer" }} onClick={logoutHandler} className="step3">
              <em className="icon ni ni-signout" />
              <span>Sign out</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
