import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../constants/apiconstant'
import { useSnackBar } from '../utils'
import { Modal } from './Modal'
import { getHeaders } from '../../../utils/'
const mysqlRe = /^[a-zA-Z0-9_\$]*$/
const mongoDBRe = /^[a-zA-Z0-9_]*$/

export const AddorUpdateModal = ({
  type,
  onClose,
  modalData,
  updateConfig,
}) => {
  const [data, setData] = useState({
    configName: '',
    dbType: 'mongodb',
    username: '',
    password: '',
    host: '',
    port: '',
    databaseName: '',
    tablename: '',
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [dbSaveLoading, setDbSaveLoading] = useState(false)

  let fields = [
    'Config Name',
    '',
    'Username',
    'Password',
    'Host',
    'Port',
    'Database Name',
    'Tablename',
  ]
  if (modalData) fields = ['', ...fields]
  useEffect(() => {
    if (modalData) {
      setData({
        _id: modalData._id,
        configName: modalData.configName,
        dbType: modalData.dbType,
        username: modalData.username,
        password: modalData.password,
        host: modalData.host,
        port: modalData.port,
        databaseName: modalData.databaseName,
        tablename: modalData.tablename,
      })
    }
  }, [])
  const { errorSnackBar, successSnackBar } = useSnackBar()
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }
  const handleConfirmClick = () => {
    setIsSubmitted(true)
    let isEmpty = false
    Object.keys(data).forEach((key) => {
      if (key == 'port') return
      if (!data[key]) {
        isEmpty = true
      }
    })
    if (isEmpty) {
      console.log('daaa', data)
      errorSnackBar('Please fill all fields')
      return
    }
    if (data.dbType == 'mongodb' && !mongoDBRe.test(data.tablename.trim())) {
      errorSnackBar('Table name should containe a-z, A-Z , 0-9,  and no spaces')
      return
    }
    if (data.dbType == 'mysql' && !mysqlRe.test(data.tablename.trim())) {
      errorSnackBar(
        'Table name should containe a-z, A-Z, 0-9, _, $ and no spaces'
      )
      return
    }
    createDBConfig()
  }

  const createDBConfig = async () => {
    setDbSaveLoading(true)

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))

    try {
      const response = await axios.post(
        BASE_URL + 'save-db-cred/',
        {
          user_id_or_company_id: userInfo.result._id,

          ...data,
          databaseName: data.databaseName.trim(),
          tablename: data.tablename.trim(),
        },
        getHeaders()
      )
      if (
        response.data.success === 'false' ||
        response.data.success === false
      ) {
        errorSnackBar(response.data.message)
      } else {
        successSnackBar(
          modalData ? 'DB COnfig has been updated' : 'Db config created'
        )
        updateConfig(
          response.data.data,
          modalData ? 'update' : 'create',
          modalData?._id
        )
        if (!modalData) {
          onClose()
        }
      }
    } catch (error) {
      console.log('errr', error)
      errorSnackBar()
    }

    setDbSaveLoading(false)
  }

  return (
    <Modal
      onClose={onClose}
      modalTitle={modalData ? 'Edit Config' : 'Create Config'}
      loading={dbSaveLoading}
      onConfirm={handleConfirmClick}
      modalContent={
        <>
          <Grid container spacing={1}>
            {Object.keys(data).map((key, index) => {
              if (index === 0 && modalData) return null
              if (index === 1 && !modalData) {
                return (
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{
                      marginTop: '1rem',
                      width: '100%',
                      marginLeft: '0.2rem',
                      marginRight: '0.2rem',
                      marginBottom: '4px',
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Database Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name={key}
                      value={data[key]}
                      onChange={handleChange}
                      label="Age"
                    >
                      <MenuItem value={'mysql'}>Mysql</MenuItem>
                      <MenuItem value={'mongodb'}>Mongodb</MenuItem>
                    </Select>
                  </FormControl>
                )
              }
              return (
                <Grid item xs={12}>
                  <TextField
                    helperText={
                      isSubmitted && !data[key] && key != 'port' && 'Required'
                    }
                    style={{ color: 'black', width: '100%', marginTop: 9 }}
                    id="outlined-basic"
                    size="small"
                    disabled={index == (modalData ? 2 : 1)}
                    name={key}
                    label={fields[index]}
                    value={data[key]}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
              )
            })}
          </Grid>
        </>
      }
    />
  )
}
