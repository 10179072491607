import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Dropzone from "../components/Dropzone";
import SampleSchemaDownload from "../components/SampleSchemaDownload";
// import CustomTable from '../../customTable/customTable';
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import axios from "axios";
import { BASE_URL } from "../constants/apiconstant";
import { getHeaders } from "../utils";
//Styles MAterialUI
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,

    border: "1px solid grey",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  dropdown: {
    textAlign: "end",
    marginBottom: "1.5rem",
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function UploadData() {
  const classes = useStyles();
  const [schema, setSchema] = useState("");
  const [csvFileName, setCsvFileName] = useState("");
  const [loadingFile, setLoadingFile] = useState(false);
  const [csvData, setCsvData] = useState({ fields: null, data: null });
  const [bodyWidth, setBodyWidth] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const sidebarState = (state) => {
    console.log(state);
    setBodyWidth(state);
  };
  const columns = () =>
    csvData.fields.map((field) => ({
      datafield: field,
      name: field,
      show: true,
    }));

  const clearCsvData = () => {
    setCsvFileName("");
    setCsvData({ fields: null, data: null });
  };

  const handleFileSelect = async (file) => {
    setLoadingFile(true);
    try {
      console.log("formdaa", file);
      let formdata = new FormData();
      formdata.append("file", file);
      const { data } = await axios.post(BASE_URL + "upload-csv/", formdata);
      const { data: createData } = await axios.post(
        BASE_URL + "create-csv/",
        {
          filename: data.filename,
        },
        getHeaders()
      );
      if (createData.rowsSkipped != 0) {
        enqueueSnackbar(
          createData.rowsSkipped == 1
            ? `${createData.rowsSkipped} row were skipped because of invalid number of columns`
            : `${createData.rowsSkipped} rows were skipped because of invalid number of columns`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
      if (createData.rowsAdded != 0) {
        enqueueSnackbar(
          createData.rowsAdded == 1
            ? `${createData.rowsAdded} row added to db`
            : `${createData.rowsAdded} rows added to db`,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      }
      console.log("daa", data, createData.rowsAdded);
    } catch (error) {
      enqueueSnackbar(
        error?.message || "Something went wrong, please try again later",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }
    setLoadingFile(false);
  };
  return (
    <>
      <div className="p-2">
        <div className="nk-body npc-apps apps-only has-apps-sidebar npc-apps-files">
          <div className="nk-app-root">
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                {/* main header @s */}
                <Navbar />
                {/* main header @e */}

                <div className="nk-content p-0">
                  <div className="nk-content-inner">
                    <div className="nk-content-body">
                      <div
                        className="nk-fmg"
                        style={bodyWidth ? { paddingLeft: 80 } : null}
                      >
                        <Sidebar page="contact" getState={sidebarState} />
                        {/* .nk-fmg-aside */}
                        <div className="nk-fmg-body">
                          <div className="nk-block">
                            <div className={"nk-content"}>
                              <div className="container-fluid">
                                <div className="nk-content-inner">
                                  <div className="nk-content-body">
                                    <div className={classes.dropdown + "p-2"}>
                                      <h4 className="my-3">
                                        {csvFileName ? csvFileName : "Upload"}
                                      </h4>
                                    </div>

                                    <div>
                                      <Dropzone
                                        setFile={handleFileSelect}
                                        loadingFile={loadingFile}
                                        setCsvData={setCsvData}
                                        setCsvFileName={setCsvFileName}
                                        setLoadingFile={setLoadingFile}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <SampleSchemaDownload schema={schema} />
                          </div>
                        </div>
                      </div>
                      {/* .nk-fmg */}
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
          </div>
        </div>
      </div>
    </>
  );
}
