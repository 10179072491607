import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { Card } from './Card'


export const ListWithCard = ({ listTitle, listEmptyText = "", subtitleStyle, loading, cardArray = [], onMenuItemClick, onListItemClick = () => { }, cardContainerStyle }) => {
    return (
        <div className='nk-fmg-listing nk-block' style={{ minHeight: "initial", marginBottom: "2rem" }}>
            <div
                className='toggle-expand-content expanded'
                data-content='recent-files'
            >
                <div className='nk-files nk-files-view-group'>
                    {/* .nk-files-head */}
                    <div className='nk-files-group'>
                        <h6 className='title'>{listTitle}</h6>
                        <div className='nk-files-list'>
                            {loading ? <CircularProgress  style={{color:"rgb(20, 155, 227)"}}/> : (

                                cardArray.map((cardItem, index) => (
                                    <Card title={cardItem.title}
                                        containerStyle={cardContainerStyle}
                                        subtitle={cardItem.subtitle}
                                        subtitleStyle={subtitleStyle}
                                        menuArray={cardItem.menuArray}
                                        onListItemClick={() => onListItemClick(index)}
                                        onMenuItemClick={(menuItem, menuIndex,) => onMenuItemClick(menuItem, menuIndex, index)}

                                    />
                                ))


                            )}




                        </div>
                    </div>


                </div>

            </div>
        </div>

    )
}
